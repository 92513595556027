import { useEffect } from 'react'
import Container from '@app/stories/Container'
import Section from '@app/stories/Section'
import Heading from '@app/stories/Heading'
import Typography from '@app/stories/Typography'
import {
  TermsBlock as Block,
  TermsText as Text,
  TermsTitle as Title,
  TermsLink as Link,
} from '@app/stories/Terms'

const TermsOfUsePage = () => {
  useEffect(() => {
    window.Intercom('trackEvent', 'Page View', {
      page_name: 'Terms of Use Lander',
    })
  }, [])
  return (
    <Container>
      <Section>
        <Heading variant='h1'>Terms of Service</Heading>
        <Block id='emergency-message'>
          <Typography variant='h4'>
            IF YOU HAVE OR ARE HAVING, A MEDICAL EMERGENCY, IMMEDIATELY CALL YOUR LOCAL EMERGENCY
            NOTIFICATION SYSTEM (911), OR GO TO YOUR LOCAL EMERGENCY ROOM.
          </Typography>
        </Block>
        <Block id='terms1'>
          <Text>
            Welcome to Mishe Health Plan, a service provided by Mishe, Inc. (&quot;Mishe&quot;).
            Please read these Terms of Service (these &quot;Terms&quot;) carefully and in their
            entirety. Along with Mishe’s{' '}
            <Link to='https://mishe.co/privacy-policy' target='_blank'>
              Privacy Policy
            </Link>
            applicable to your use of Mishe&apos;s website, and the Mishe Health Plan-specific
            website,
            <Link to='https://mishe.co/plans' target='_blank'>
              https://mishe.co/plans
            </Link>{' '}
            (the &quot;Site&quot;), which are incorporated herein by reference (collectively, the
            &quot;Terms of Use&quot;), these Terms set forth the legally binding terms and
            conditions that govern your use of the Services (as defined below). You acknowledge that
            by using or accessing the Services, you are accepting and consenting to be bound by
            these Terms. If you do not agree with all of the provisions of these Terms, you may not
            access or use the Services. The terms &quot;you&quot; and &quot;your&quot; mean you,
            your dependents, or family members, if any, and any other person accessing your Mishe
            account. For any person who uses your Mishe account or the Services, you represent and
            warrant that you have communicated or shared these Terms with that individual, you have
            valid authority to bind that party to this agreement, and your agreement to these Terms
            can and will be treated as the agreement of that other party.
          </Text>
        </Block>
        <Block id='terms2'>
          <Title>About Mishe Health Plan</Title>
          <Text>
            <b>Mishe Health Plan is NOT health insurance.</b> Mishe Savings Plan is not intended as
            a substitute for insurance, and does not meet any creditable coverage requirements under
            any state law. Mishe Health Plan cannot be combined with federal or state-funded
            programs such as Medicare or Medicaid.
          </Text>
          <Text>
            Mishe Health Plan is a program that provides access to its members (&quot;Members&quot;
            and your status as a Member is referred to as your &quot;Membership&quot;) to discounts
            on medical services rendered by its network of Participating Providers
            (&quot;Participating Providers&quot;) (together the &quot;Services&quot;). Mishe Health
            Plan provides discounts at Participating Providers, but Mishe does not pay it
            Participating Providers nor does it submit claims on behalf of the Participating
            Providers. You are obligated to pay for all services provided. The range of discounts
            will vary depending on the type of services provided. You may obtain a current list of
            Participating Providers{' '}
            <Link to='https://mishe.co/plans' target='_blank'>
              here
            </Link>
            or by calling <Link to='tel:8556474326'>(855) 647-4326</Link>. Mishe does not provide
            medical advice or care, nor does it employ or pay the Participating Providers that
            provide services.
          </Text>
          <Text>
            Please reach out to <Link to='tel:8556474326'>(855) 647-4326</Link> for assistance with
            questions, an up-to-date list of Participating Providers, or to request specific contact
            information for your department of insurance or service of process information for a
            specific state.
          </Text>
        </Block>

        <Block id='terms3'>
          <Title>Your Membership</Title>
          <Text>
            In order to participate in the Services, you must first register for an account with
            Mishe (your &quot;Account&quot;) in accordance with Mishe&apos;s Terms of Use. You must
            then complete the registration process necessary to become a Member by following the
            instructions set forth in the Mishe Health Plan application and providing certain
            information to Mishe, such as a payment method (&quot;Payment Method&quot;), which you
            may edit through the Site under &quot;My Account&quot; and then &quot;Settings&quot;,
            and your name, email address, date of birth, gender, phone #, mailing address, and any
            other information as requested by Mishe (&quot;Registration Data&quot;). You agree that
            you will maintain and update this information as required in order to keep it current,
            complete, and accurate.
          </Text>
          <Text>
            You also grant us the right to disclose to third parties certain Registration Data about
            you for the purpose of administering the program. Mishe&apos;s collection and use of
            personally identifiable information in connection with the Services is governed by
            Mishe&apos;s{' '}
            <Link to='https://mishe.co/privacy-policy' target='_blank'>
              Privacy Policy
            </Link>{' '}
            which is incorporated into these Terms by reference.
          </Text>
          <Text>
            You agree to fully, accurately, and truthfully create your Mishe Account when providing
            the Registration Data. Your Mishe Account and credentials are personal to you, and you
            are solely responsible for maintaining the confidentiality of your Mishe Account or
            credentials, and for all activities that occur under such Mishe Account or credentials.
            You agree to prohibit anyone else from using your Mishe Account or credentials and agree
            to immediately notify Mishe of any actual or suspected unauthorized use of your Mishe
            Account or credentials or other security concerns of which you become aware. You must be
            at least 18 years of age to become a Member.{' '}
          </Text>
          <Text>
            Free trial eligibility is determined by Mishe at its sole discretion. We reserve the
            right to revoke the free trial and put your account on hold in the event that we
            determine that you are ineligible for a free trial offer. Individuals, households, or
            members of an organization with an existing or recent Mishe membership are not
            eligible.{' '}
          </Text>
          <Text>
            Mishe may change the price of your Membership from time to time, and will communicate
            any price changes to you via the email associated with your Membership in advance of any
            price increase, and, if applicable, how to accept those changes. Price changes will take
            effect at the start of the next Membership Cycle following the date of the price change.
            Subject to applicable law, you accept the new price by continuing to use the Mishe
            Services after the price change takes effect. If you do not agree with a price change,
            you have the right to reject the change by unsubscribing from the Services prior to the
            price change going into effect.
          </Text>
        </Block>

        <Block id='terms4'>
          <Title>Use of the Services | Access to Information | Restrictions on Use</Title>
          <Text>
            Whether you are a Member or an Add-On Member, you agree not to use the Services in a way
            that is, in Mishe&apos;s sole discretion, harmful to others (including minors) in any
            way or violates any applicable law, regulation, obligation, or other similar
            restrictions imposed by a government entity. You also agree not to violate any community
            requirements periodically posted by Mishe, remove any copyright, trademark, or other
            proprietary notices from any portion of the Services (including any discount coupons or
            other Member-only content sent to you), cause or launch any programs or scripts for the
            purpose of scraping, indexing, surveying, or otherwise data mining any portion of the
            Services, or attempt to gain unauthorized access to, or impair any aspect of the
            Services, or its related systems or networks (or to other computer systems or networks
            connected to, or used together with the Services), whether through password mining or
            any other means. You shall promptly notify Mishe if you learn of any security breach
            related to the Services.
          </Text>
          <Text>
            You agree that any services or products that you acquire or obtain through the Site or
            Services will be solely for your personal use. Mishe may cancel, suspend, or terminate
            your access to the Site and the Services at any time, and for any reason, including any
            violation of these Terms.
          </Text>
        </Block>

        <Block id='terms5'>
          <Title>No Medical Advice by Mishe</Title>
          <Text>
            The Participating Providers who provide services as part of the Membership are
            independent of Mishe and are merely using the Site or Service as a way to communicate
            with you. Any information or advice received from a Participating Provider comes from
            them alone, and not from Mishe. Your interactions with the Participating Providers via
            the Site or Service are not intended to take the place of your relationship with your
            regular healthcare practitioners or primary care physician. Neither Mishe, nor any of
            its subsidiaries or affiliates, or any third party who may promote the Site or Service,
            or provide a link to the Site or Service shall be liable for any professional advice
            obtained from a Participating Provider via the Site, nor any information obtained on the
            Site. Mishe does not recommend or endorse any specific Participating Providers, tests,
            physicians, medications, products, or procedures. You acknowledge that your reliance on
            any Participating Providers or information delivered by the Participating Providers via
            the Site or Service is solely at your own risk and you assume full responsibility for
            all risks associated therewith.
          </Text>
          <Text>
            Mishe does not make any representations or warranties about the training or skill of any
            Participating Providers who deliver services via the Site or Service. You will be
            provided with an available Participating Provider based solely on the information you
            submit to the Site or Service. You are ultimately responsible for deciding to accept the
            services of your particular Participating Provider.
          </Text>
          <Text>
            The content of the Site, including, without limitation, any text, copy, audio, video,
            photographs, illustrations, graphics, and other visuals, is for informational purposes
            only and does not constitute professional medical advice, diagnosis, treatment, or
            recommendations of any kind by Mishe. You should always seek the advice of your
            qualified health care professionals with any questions or concerns you may have
            regarding your individual needs and any medical conditions. ever disregard professional
            medical advice or delay in seeking it because of the Site.
          </Text>
          <Text>
            All information provided by Mishe, or in connection with any communications supported by
            Mishe, is intended to be for general informational purposes only, and is in no way
            intended to create a provider-patient relationship as defined by state or federal law.
            While Mishe facilitates your selection of, and communications with, Participating
            Providers through Mishe Health Plan, Mishe does not provide medical services, and the
            provider-patient relationship is between you and the Participating Provider.
          </Text>
        </Block>
        <Block id='terms6'>
          <Title>Fees and Payments</Title>
          <Text>
            By starting your Membership, you authorize Mishe to charge your Payment Method a
            membership fee at the then current rate, which may change from time to time (the
            &quot;Membership Fee&quot;), on the first day of each Membership Cycle until your
            Membership is canceled, placed on hold, or terminated. Mishe reserves the right to
            change the timing of its billing, provided that Mishe will adjust the amount it bills in
            accordance with any such change.
          </Text>
          <Text>
            If Mishe does not receive the Membership Fee from you, Mishe may terminate your access
            to the Services. All fees are non-refundable, unless otherwise provided in these Terms.
            As between you and Mishe, Mishe reserves the right to revise any fees at any time in
            Mishe&apos;s sole discretion.
          </Text>
          <Text>
            If a Membership Fee is not successfully settled, and you do not edit your Payment Method
            information or cancel your account (see, &quot;Cancellation&quot; below), you
            nonetheless will remain responsible for any uncollected amounts and authorize us to
            continue billing the Payment Method, as it may be updated, including in the event you
            attempt to create a new account. This may result in a change to your payment billing
            dates. If we cannot charge your account, we reserve the right, but are not obligated, to
            terminate your access to our Site or any portion thereof.
          </Text>
          <Text>
            Mishe will bill the Membership Fee to you through a third-party provider Mishe uses to
            process payments for the Services from your financial service provider. The third-party
            provider alone collects and manages your payment information, including, without
            limitation, any credit card numbers; Mishe does not store or have access to any of your
            payment information. You may have the option of establishing a payment account with the
            third-party provider to facilitate recurring payments, which would then be subject to
            separate terms and conditions. Click here for more information on how Stripe collects,
            uses, and protects your payment information.
          </Text>
        </Block>

        <Block id='terms7'>
          <Title>Cancellation of Membership </Title>
          <Text>
            <b>
              Your Membership starts on the date that you sign up for a membership and provide us
              with your payment method. You will be charged for your Membership starting on the date
              of your membership, unless you have a free trial period (as specified during your
              sign-up for the membership). After your free trial period ends (if any), your monthly
              Membership will automatically renew and continue for a one (1) month period, and your
              annual Membership will automatically renew and continue for a one (1) year period
              (each, a &quot;Membership Cycle&quot;) unless and until you cancel your Membership or
              Mishe terminates it. For example, if your Membership starts on May 12, and your trial
              period ends on June 12, your Membership will automatically renew for an additional
              Membership Cycle on June 12 unless you cancel prior to June 12, or Mishe terminates
              your Membership. Unless you have a discounted Membership Fee, you will be charged
              $9.99 for the Individual Membership for each monthly Membership Cycle, or $99 for the
              Individual Membership for each annual Membership Cycle prior to or at the beginning of
              each Membership Cycle. You will be charged to the credit card number you provided.
            </b>
          </Text>
          <Text>
            <b>
              You can cancel your monthly or annual Membership at any time. To avoid charges
              associated with the next Membership Cycle, cancel at least one (1) day prior to your
              Membership Cycle renewal date. If you cancel your Membership within the first thirty
              (30) days of your Membership, you will receive a full refund of any fees paid. Amounts
              paid for medications and prescriptions are not refundable. You may find your
              Membership Cycle renewal date or cancel or modify your Membership by visiting the
              &quot;Settings&quot; option while logged in to your account online. You can also
              update your billing details on your
              <Link to='https://mishe.co/dashboard' target='_blank'>
                account page.
              </Link>{' '}
              If you cancel your Membership at least one (1) day before the next Membership Fee is
              due, your Membership and access to the Services will terminate on the day your next
              Membership Fee is due. Otherwise, Mishe will bill you for an additional Membership
              Fee, and your Membership and access to the Services shall expire one month after your
              last paid Membership Fee for monthly Membership Cycles, or one year after your last
              paid Membership Fee for annual Membership Cycles.
            </b>
          </Text>
          <Text>
            You may also cancel your membership via mail. Your request must include the full name,
            Member ID and email address connected with your account. Please mail this request to:
            legal@mishe.co.
          </Text>
        </Block>

        <Block id='terms8'>
          <Title>Intellectual Property Rights</Title>
          <Text>
            For purposes of these Terms, &quot;content&quot; is defined as any information, data,
            communications, software, photos, video, graphics, music, sounds, and other material and
            services available only to Members utilizing the Services.
          </Text>
          <Text>
            By accepting these Terms, you acknowledge and agree that all content is protected by
            copyrights, trademarks, service marks, patents, or other proprietary rights and laws,
            and is the sole property of Mishe and/or its Affiliates. You are only permitted to use
            the content as expressly authorized by Mishe or the specific content provider. Except
            for a single copy made for personal use only, you may not copy, reproduce, modify,
            republish, upload, post, transmit, or distribute any content in any form or by any means
            without prior written permission from Mishe or the specific content provider, and you
            are solely responsible for obtaining permission before reusing any content. Any
            unauthorized use of content may violate copyright, trademark, and other applicable laws
            and could result in criminal or civil penalties.
          </Text>
          <Text>
            Neither Mishe nor its Affiliates warrant or represent that your use of content will not
            infringe the rights of third parties.
          </Text>
          <Text>
            All custom graphics, icons, logos, and service names are registered trademarks,
            trademarks, or service marks of Mishe or its Affiliates. All other trademarks or service
            marks are property of their respective owners. Nothing in these Terms grants you any
            right to use any trademark, service mark, logo, and/or the name of Mishe or its
            Affiliates.
          </Text>
        </Block>

        <Block id='terms9'>
          <Title>Termination or Modification by Mishe</Title>
          <Text>
            Mishe reserves the right to modify or terminate the Services, your status as a Member,
            your Add-On Members&apos; status as such and access to the Services and/or your access
            to the Services by terminating or deactivating your Account or canceling your Membership
            for any or no reason, without notice, at any time and without liability to you. If your
            Membership is terminated, you may still have access to your Account, and we may retain
            any content that you posted or data that you generated through your Account and use of
            the Services or delete it, and all rights granted to you in these Terms will immediately
            cease. You acknowledge and agree that your only recourse against Mishe in the event
            Mishe terminates your Membership is a prorated discount of the monthly fee paid for the
            Services calculated as of the date your Membership was canceled. Mishe shall not be
            liable to you or any third party for any claims or damages arising out of any
            termination or suspension, or any other actions taken by us in connection with such
            termination or suspension.
          </Text>
        </Block>

        <Block id='terms10'>
          <Title>Third-Party Content</Title>
          <Text>
            The Services may link you to other sites and services on the internet or otherwise
            include references to information, documents, software, materials, and/or services
            provided by other third parties. These sites and services may contain information or
            material that some people may find inappropriate or offensive. These other sites,
            services, and parties are not under Mishe&apos;s control, and you acknowledge that Mishe
            and its Affiliates are not responsible for the accuracy, copyright compliance, legality,
            decency, or any other aspect of the materials or substance of such sites and services,
            nor are Mishe or its Affiliates responsible for errors or omissions in any references to
            other parties or their products and services. The inclusion of such a link or reference
            is provided merely as a convenience and does not imply endorsement of, or association
            with, the Site or party by us, or any warranty of any kind, either express or implied.
          </Text>
        </Block>

        <Block id='terms11'>
          <Title>Security and Password</Title>
          <Text>
            You are solely responsible for maintaining the confidentiality of your password and
            account and for any and all statements made and acts or omissions that occur through the
            use of your password and account. Therefore, you must take steps to ensure that others
            do not gain access to your password and account. Our personnel will never ask you for
            your password. You may not transfer or share your account with anyone, and we reserve
            the right to immediately terminate your account if you do transfer or share your
            account.
          </Text>
        </Block>

        <Block id='terms12'>
          <Title>Participation in Promotions</Title>
          <Text>
            <b>
              From time to time, the Services may include additional discounts offered by third
              parties. You may enter into correspondence with or participate in promotions of the
              advertisers showing their products on this site. Any such correspondence or
              promotions, including the delivery of and the payment for goods and services, and any
              other terms, conditions, warranties, or representations associated with such
              correspondence or promotions, are solely between you and the advertiser. We assume no
              liability, obligation, or responsibility for any part of any such correspondence or
              promotion.
            </b>
          </Text>
        </Block>

        <Block id='terms13'>
          <Title>Disclaimer of Warranties</Title>
          <Text>
            ALL CONTENT AND SERVICES ARE PROVIDED TO MEMBERS ON AN &quot;AS IS&quot; AND &quot;AS
            AVAILABLE&quot; BASIS WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESSED OR IMPLIED,
            INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR
            A PARTICULAR PURPOSE, OR THE WARRANTY OF NON-INFRINGEMENT. WITHOUT LIMITING THE
            FOREGOING, MISHE MAKES NO WARRANTY THAT (A) THE SERVICES WILL MEET YOUR REQUIREMENTS,
            (B) THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (C) THE RESULTS
            THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES WILL BE EFFECTIVE, ACCURATE OR
            RELIABLE, OR (D) THE QUALITY OF ANY SERVICES, OR DISCOUNTS PROVIDED FROM MISHE OR OUR
            AFFILIATES WILL MEET YOUR EXPECTATIONS OR BE FREE FROM MISTAKES, ERRORS, OR DEFECTS.
          </Text>
          <Text>
            INFORMATION AVAILABLE THROUGH THE SERVICES COULD INCLUDE TECHNICAL OR OTHER MISTAKES,
            INACCURACIES, OR TYPOGRAPHICAL ERRORS. WE MAY MAKE CHANGES TO THE CONTENT AND SERVICES,
            INCLUDING THE PRICES AND DESCRIPTIONS OF ANY PRODUCTS LISTED THEREIN TO STOP PROVIDING
            MAIL DELIVERY SERVICES, AND TO SWITCH OUR ASSOCIATED THIRD-PARTY CONTRACTORS AND
            VENDORS, AT ANY TIME WITHOUT NOTICE. THE MATERIALS OR SERVICES AT THIS SITE MAY BE OUT
            OF DATE, AND WE MAKE NO COMMITMENT TO UPDATE SUCH MATERIALS OR SERVICES.
          </Text>
          <Text>
            THE USE OF THE SERVICES, OR THE DOWNLOADING, OR OTHER ACQUISITION OF ANY MATERIALS
            THROUGH THE SERVICES OR THIS SITE, IS DONE AT YOUR OWN DISCRETION AND RISK, AND WITH
            YOUR AGREEMENT, THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER
            SYSTEM OR LOSS OF DATA THAT RESULTS FROM SUCH ACTIVITIES.
          </Text>
          <Text>
            Through your use of the Services, you may have the opportunities to engage in commercial
            transactions with other users and vendors. You acknowledge that all transactions
            relating to any merchandise or services offered by any party, including, but not limited
            to the purchase terms, payment terms, warranties, guarantees, maintenance, and delivery
            terms relating to such transactions, are agreed to solely between the seller or
            purchaser of such merchandise and services, and you.
          </Text>
          <Text>
            WE MAKE NO WARRANTY REGARDING ANY TRANSACTIONS EXECUTED THROUGH, OR IN CONNECTION WITH
            THE SERVICES, AND YOU UNDERSTAND AND AGREE THAT SUCH TRANSACTIONS ARE CONDUCTED ENTIRELY
            AT YOUR OWN RISK. ANY WARRANTY THAT IS PROVIDED IN CONNECTION WITH ANY PRODUCTS,
            SERVICES, CONTENT, MATERIALS, OR INFORMATION AVAILABLE ON OR THROUGH THE SERVICES, OR
            THIS SITE, FROM A THIRD PARTY IS PROVIDED SOLELY BY SUCH THIRD PARTY, AND NOT BY US OR
            ANY OTHER OF OUR AFFILIATES.
          </Text>
          <Text>
            Content available through the Services often represents the opinions and judgments of an
            information provider, site user, or other person or entity not connected with us. We do
            not endorse, nor are we responsible for the accuracy or reliability of, any opinion,
            advice, or statement made by anyone other than an authorized Mishe, Inc. spokesperson
            speaking in his/her official capacity. Please refer to the specific editorial policies
            posted on various sections of this Site for further information, which policies are
            incorporated by reference into these Terms of Use.
          </Text>
          <Text>
            You understand and agree that temporary interruptions of the Services available through
            this Site may occur as normal events. You further understand and agree that we have no
            control over third-party networks you may access in the course of your use of this Site,
            and therefore, delays and disruption of other network transmissions are completely
            beyond our control.
          </Text>
          <Text>
            You understand and agree that the Services available on this Site are provided &quot;AS
            IS&quot; and that we assume no responsibility for the timeliness, deletion,
            mis-delivery, or failure to store any user communications or personalization settings.
          </Text>
          <Text>
            WARRANTIES RELATING TO PRODUCTS OR SERVICES OFFERED, SOLD, AND DISTRIBUTED BY THE
            PARTICIPATING PROVIDERS LISTED ON THE SITE, MAY BE SUBJECT TO SEPARATE WARRANTY TERMS
            AND CONDITIONS, IF ANY, PROVIDED BY THE PHARMACY OR THIRD PARTIES WITH, OR IN CONNECTION
            WITH, THE APPLICABLE PRODUCTS OR SERVICES. YOU ACKNOWLEDGE AND UNDERSTAND THAT THE USE
            OR MISUSE OF THE PRODUCTS OBTAINED THROUGH OUR SITE MAY RESULT IN UNDESIRABLE OR
            UNEXPECTED CONSEQUENCES. MISHE DOES NOT ACCEPT ANY LIABILITY FOR THE CONSEQUENCES
            ARISING FROM THE APPLICATION, USE, OR MISUSE OF ANY PRODUCTS OR SERVICES CONTAINED ON OR
            MADE AVAILABLE THROUGH THE SITE, INCLUDING ANY INJURY OR DAMAGE TO ANY PERSON OR
            PROPERTY AS A MATTER OF NEGLIGENCE, OR OTHERWISE, INCLUDING YOUR FAILURE TO COMPLY WITH
            ANY WARNING LABELS ATTACHED TO THE PRODUCTS.
          </Text>
          <Text>
            SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES, SO SOME
            OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
          </Text>
        </Block>

        <Block id='terms14'>
          <Title>Limitation of Liability</Title>
          <Text>
            MISHE SHALL NOT BE LIABLE FOR THE ACTS OR OMISSIONS OF THE THIRD PARTIES LISTED ON OUR
            SITE. IN NO EVENT SHALL MISHE OR AN AFFILIATE THEREOF BE LIABLE TO YOU OR ANY THIRD
            PARTY FOR ANY SPECIAL, PUNITIVE, INCIDENTAL, INDIRECT, OR CONSEQUENTIAL DAMAGES OF ANY
            KIND, OR ANY DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, THOSE RESULTING FROM
            LOSS OF USE, DATA OR PROFITS, WHETHER OR NOT MISHE HAS BEEN ADVISED OF THE POSSIBILITY
            OF SUCH DAMAGES, AND ON ANY THEORY OF LIABILITY, ARISING OUT OF OR IN CONNECTION WITH
            THE USE OF THE SERVICES OR OF ANY WEB SITE REFERENCED OR LINKED TO FROM THIS SITE.
          </Text>
        </Block>

        <Block id='terms15'>
          <Title>Linking to the Sites and Social Media Features</Title>
          <Text>
            You may link to our homepage, provided you do so in a way that is fair and legal and
            does not damage our reputation or take advantage of it, but you must not establish a
            link in such a way as to suggest any form of association, approval, or endorsement by
            Mishe without our express written consent.
          </Text>
          <Text>
            FURTHER, MISHE SHALL NOT BE LIABLE IN ANY WAY FOR THIRD-PARTY GOODS AND SERVICES OFFERED
            THROUGH THIS SITE OR FOR ASSISTANCE IN CONDUCTING COMMERCIAL TRANSACTIONS THROUGH THIS
            SITE, INCLUDING, WITHOUT LIMITATION, THE PROCESSING OF MEMBERSHIP FEES.
          </Text>

          <Text>
            YOUR SOLE REMEDY FOR DISSATISFACTION WITH THE SITE, SITE-RELATED SERVICES OR PRODUCTS,
            OR LINKED WEBSITES IS TO STOP USING THE SITE OR THOSE SERVICES OR PRODUCTS. SOME
            JURISDICTIONS PROHIBIT THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR
            INCIDENTAL DAMAGES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. TO THE EXTENT ANY
            ASPECTS OF THE FOREGOING LIMITATIONS OF LIABILITY ARE NOT ENFORCEABLE, THE MAXIMUM
            LIABILITY OF MISHE TO YOU WITH RESPECT TO YOUR USE OF THIS SITE IS $50 (FIFTY DOLLARS).
            YOU HEREBY AGREE TO WAIVE, TO THE FULLEST EXTENT PERMITTED BY LAW, ALL LAWS THAT LIMIT
            THE EFFICACY OF SUCH INDEMNIFICATIONS OR RELEASES.
          </Text>
        </Block>

        <Block id='terms16'>
          <Title>Indemnification</Title>
          <Text>
            Upon a request by Mishe, you agree to defend, indemnify, and hold Mishe and its
            Affiliates harmless from all liabilities, claims, and expenses, including
            attorney&apos;s fees, that arise from your use or misuse of the Site, the Services, or
            any products or prescriptions obtained through the Site, or Services. Mishe reserves the
            right, at its own expense, to assume the exclusive defense and control of any matter
            otherwise subject to indemnification by you, in which event you will cooperate with
            Mishe in asserting any available defenses.
          </Text>
        </Block>

        <Block id='terms17'>
          <Title>Governing Law</Title>
          <Text>
            This Site (excluding any linked sites) is controlled by us from our offices within New
            York, United States of America. It can be accessed from all 50 states, as well as from
            other countries around the world. As each of these places has laws that may differ from
            those of New York, by accessing this Site both of us agree that the statutes and laws of
            the State of New York, without regard to the conflicts of laws principles thereof and
            the United Nations Convention on the International Sales of Goods, will apply to all
            matters relating to the use of this Site and the purchase of products and services
            available through this Site. Each of us agrees and hereby submits to the exclusive
            personal jurisdiction and venue any court of competent jurisdiction within the State of
            New York with respect to such matters.
          </Text>
        </Block>

        <Block id='terms18'>
          <Title>Notices</Title>
          <Text>
            All notices to a party shall be in writing and shall be made either via email or
            conventional mail. Notices to us must be sent to the attention of Legal at
            legal@mishe.co if by email, or to Mishe, Inc., 421 Broadway, San Diego, CA 92101 if by
            conventional mail. Notices to you may be sent to the address supplied by you as part of
            your Registration Data. In addition, we may broadcast notices or messages through the
            Services or Site to inform you of changes to the Services or Site or other matters of
            importance, and such broadcasts shall constitute notice to you at the time of sending.
          </Text>
        </Block>

        <Block id='terms19'>
          <Title>Entire Agreement</Title>
          <Text>
            These Terms of Use, including, without limitation, the Mishe Privacy Policy referenced
            herein and linked hereto constitute the entire agreement and understanding between us
            concerning the subject matter of this agreement and supersedes all prior agreements and
            understandings of the parties with respect to that subject matter. These Terms may not
            be altered, supplemented, or amended by the use of any other document(s). Any attempt to
            alter, supplement, or amend this document or to enter an order for products or services
            which are subject to additional or altered terms and conditions shall be null and void,
            unless otherwise agreed to in a written agreement signed by you and us. To the extent
            that anything in or associated with this Site is in conflict or inconsistent with these
            Terms, these Terms shall take precedence.
          </Text>
        </Block>

        <Block id='terms20'>
          <Title>Testimonials</Title>
          <Text>
            From time to time, Mishe may allow you to submit user feedback and testimonials
            (&quot;Testimonials&quot;) about your experience with Mishe. Testimonials include all
            feedback submitted via any channel, including, but not limited to, via the Site and
            email, survey, and customer service communications. By submitting a Testimonial, you
            agree that Mishe may use your Testimonial, including in edited and partial form,
            together with your name, location, and any other personal information you provide in any
            and all forms of marketing and promotional material, including, without limitation,
            website publication, print ads, online ads, television ads, radio ads, and for any and
            all other uses.
          </Text>
        </Block>

        <Block id='terms21'>
          <Title>Messaging Services</Title>
          <Text>
            As part of Mishe Health Plan, Mishe may send pricing information and coupons via
            communications, including, but not limited to, email and text messaging. By becoming a
            Member of Mishe Health Plan, you are consenting to be contacted with Service-related
            information. You acknowledge that there may be some risk that the information in the
            communication(s) could be read by a third party.
          </Text>
        </Block>

        <Block id='terms22'>
          <Title>Miscellaneous</Title>
          <Text>
            Any cause of action brought by you against us or our Affiliates must be instituted
            within one year after the cause of action arises or be deemed forever waived and barred.
          </Text>
          <Text>
            You may not assign your rights and obligations under these Terms to any party, and any
            purported attempt to do so will be null and void. We may freely assign our rights and
            obligations under these Terms.
          </Text>
          <Text>
            You agree not to sell, resell, reproduce, duplicate, copy, or use for any commercial
            purposes any portion of this Site, or use of or access to this Site.
          </Text>
          <Text>
            In addition to any excuse provided by applicable law, we shall be excused from liability
            for non-delivery or delay in delivery of products and services available through our
            Site arising from any event beyond our reasonable control, whether or not foreseeable by
            either party, including but not limited to, labor disturbance, war, fire, accident,
            adverse weather, inability to secure transportation, governmental act or regulation, and
            other causes or events beyond our reasonable control, whether or not similar to those
            which are enumerated above.
          </Text>
          <Text>
            If any part of these Terms is held invalid or unenforceable, that portion shall be
            construed in a manner consistent with applicable law to reflect, as nearly as possible,
            the original intentions of the parties, and the remaining portions shall remain in full
            force and effect.
          </Text>
          <Text>
            Any failure by us to enforce or exercise any provision of these Terms or related rights
            shall not constitute a waiver of that right or provision.
          </Text>
          <Text>
            Please contact us at
            <Link to='mailto:legal@mishe.co'>legal@mishe.co</Link> with any questions regarding
            these Terms.
          </Text>
        </Block>

        <Block id='privacy16'>
          <Title>Notice of Effective Date:</Title>
          <Text>The effective date of the notice is July 1, 2024.</Text>
        </Block>
      </Section>
    </Container>
  )
}

export default TermsOfUsePage
