import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import Button from '@app/stories/Button'
import Typography from '@app/stories/Typography'
import Divider from '@app/stories/Divider'
import Icon from '@app/stories/Icons'

const StepperBubbleComponent = ({
  bubbles,
  activeStep,
  setActiveStep,
  customColor = '',
  customBackgroundColor = '',
}) => {
  const StepBubble = ({ stepNumber, stepName, bubbleActiveStep }) => {
    const isActive = bubbleActiveStep === stepNumber
    const isCompleted = bubbleActiveStep > stepNumber
    const isDisabled = bubbleActiveStep < stepNumber

    return (
      <Box
        style={{
          position: 'relative',
          zIndex: '11',
          background: customBackgroundColor || 'transparent',
        }}
      >
        <Button
          size='large'
          style={{
            borderRadius: '50%',
            width: `${isActive ? '38px' : '32px'}`,
            height: `${isActive ? '38px' : '32px'}`,
            minWidth: `${isActive ? '38px' : '32px'}`,
            color: `${isDisabled ? 'white' : 'primary'}`,
            background: customColor || 'primary',
            padding: '0',
            margin: '0 5px',
          }}
          onClick={() => setActiveStep(stepNumber)}
        >
          <Typography variant='h6'>
            {isCompleted && <Icon name='check' />}
            {!isCompleted && stepNumber + 1}
          </Typography>
        </Button>
        {isActive && (
          <Typography
            variant='body2'
            style={{
              position: 'absolute',
              bottom: '-25px',
              left: '50%',
              transform: 'translateX(-50%)',
              whiteSpace: 'nowrap',
            }}
          >
            {stepName}
          </Typography>
        )}
      </Box>
    )
  }

  StepBubble.propTypes = {
    stepNumber: PropTypes.number.isRequired,
    stepName: PropTypes.string.isRequired,
    bubbleActiveStep: PropTypes.number.isRequired,
  }

  return (
    <Box
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      width='100%'
      position='relative'
      marginBottom='50px'
    >
      <Divider
        style={{
          position: 'absolute',
          zIndex: '10',
          top: '50%',
          left: '0',
          width: '100%',
          background: customColor || 'primary',
        }}
      />
      {bubbles && (
        <>
          {bubbles.map((bubble, index) => (
            <StepBubble
              key={bubble.key ? bubble.key : index}
              stepNumber={index}
              stepName={bubble.stepName ? bubble.stepName : `Step ${index + 1}`}
              bubbleActiveStep={activeStep}
            />
          ))}
        </>
      )}
    </Box>
  )
}

StepperBubbleComponent.propTypes = {
  bubbles: PropTypes.array.isRequired,
  activeStep: PropTypes.number.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  customColor: PropTypes.string,
  customBackgroundColor: PropTypes.string,
}

export default StepperBubbleComponent
