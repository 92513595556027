import { createRoot } from 'react-dom/client'
import intercom from '@intercom/messenger-js-sdk'
import App from './App'

// Initialize Intercom Messenger SDK
intercom({
  region: 'us',
  app_id: 'kgysbru8',
  custom_launcher_selector: '.open-live-chat',
  session_duration: 60 * 60 * 1000,
})

createRoot(document.querySelector('#root')).render(<App />)
