import PropTypes from 'prop-types'
import { Grid, Typography } from '@mui/material'
import DobPicker from '@app/components/DobPicker'
import TextField from '@app/stories/TextField'
import SelectField from '@app/stories/Select'
import StateSelectInput from '@app/components/StateSelectInput'
import { GENDER_OPTIONS } from '@app/libs/validators'

const PatientInfoForm = ({ values, setters }) => {
  const { firstname, lastname, email, phone, dob, gender, street, street2, city, state, zip } =
    values

  const {
    setFirstname,
    setLastname,
    setEmail,
    setPhone,
    setDob,
    setGender,
    setStreet,
    setStreet2,
    setCity,
    setState,
    setZip,
  } = setters

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={12} lg={12} margin='normal'>
        <Typography variant='h4' family='poppins'>
          Please fill out any missing info
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextField
          // required
          display='standard'
          id='t-first-name'
          label='First Name'
          variant='outlined'
          fullWidth
          value={firstname}
          onChange={(e) => setFirstname(e.target.value)}
          style={{
            margin: '0',
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextField
          // required
          display='standard'
          id='t-last-name'
          label='Last Name'
          variant='outlined'
          fullWidth
          value={lastname}
          onChange={(e) => setLastname(e.target.value)}
          style={{
            margin: '0',
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextField
          // required
          display='standard'
          id='t-email'
          label='Email'
          variant='outlined'
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextField
          // required
          display='standard'
          id='t-phone'
          label='Phone'
          variant='outlined'
          fullWidth
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        {/* Date of birth */}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{
            backgroundColor: '#ffffff',
          }}
        >
          <DobPicker dob={dob} setDob={setDob} />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <SelectField
          display='standard'
          // required
          label='Gender'
          name='gender'
          id='t-gender'
          margin='none'
          style={{
            textAlign: 'left',
          }}
          value={gender}
          onChange={(e) => setGender(e.target.value)}
          options={GENDER_OPTIONS}
        />
      </Grid>
      {/* Patient Address */}
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <TextField
          // required
          display='standard'
          id='t-street'
          label='Street Address'
          variant='outlined'
          value={street}
          onChange={(e) => setStreet(e.target.value)}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <TextField
          display='standard'
          id='t-street2'
          label='Apt, Suite, etc.'
          variant='outlined'
          value={street2}
          onChange={(e) => setStreet2(e.target.value)}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6} md={5} lg={5}>
        <TextField
          // required
          display='standard'
          id='t-city'
          label='City'
          variant='outlined'
          value={city}
          onChange={(e) => setCity(e.target.value)}
          fullWidth
          style={{
            margin: '0',
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <StateSelectInput
          id='t-state'
          label='State'
          variant='outlined'
          defaultValue=''
          fullWidth
          onChange={setState}
          state={state}
          style={{
            backgroundColor: '#fff',
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={3}>
        <TextField
          // required
          display='standard'
          id='t-zip'
          label='Zip Code'
          variant='outlined'
          value={zip}
          onChange={(e) => setZip(e.target.value)}
          fullWidth
          style={{
            margin: '0',
          }}
        />
      </Grid>
      {/* END Patient Address */}
    </Grid>
  )
}

PatientInfoForm.propTypes = {
  setters: PropTypes.shape({
    setFirstname: PropTypes.func.isRequired,
    setLastname: PropTypes.func.isRequired,
    setEmail: PropTypes.func.isRequired,
    setPhone: PropTypes.func.isRequired,
    setDob: PropTypes.func.isRequired,
    setGender: PropTypes.func.isRequired,
    setStreet: PropTypes.func.isRequired,
    setStreet2: PropTypes.func.isRequired,
    setCity: PropTypes.func.isRequired,
    setState: PropTypes.func.isRequired,
    setZip: PropTypes.func.isRequired,
  }).isRequired,
  values: PropTypes.shape({
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    dob: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    gender: PropTypes.string.isRequired,
    street: PropTypes.string.isRequired,
    street2: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
  }).isRequired,
}

export default PatientInfoForm
