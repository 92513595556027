import { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import Box from '@mui/material/Box'
import Hidden from '@mui/material/Hidden'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@app/stories/Typography'

const TypeFilters = ({ filterFunction }) => {
  const [medicationFilter, setMedicationFilter] = useState(false)
  const [referralFilter, setReferralFilter] = useState(false)
  const [paidFilter, setPaidFilter] = useState(false)
  const [unpaidFilter, setUnpaidFilter] = useState(false)
  const [failedFilter, setFailedFilter] = useState(false)

  return (
    <>
      <Typography variant='h5' gutterBottom>
        Filter by type.
      </Typography>
      <Box display='flex' flexDirection='column'>
        <Box padding='8px'>
          <Hidden mdDown>
            <ButtonGroup>
              <Button
                variant={medicationFilter ? 'contained' : 'outlined'}
                name='medication'
                color='primary'
                value='medication'
                selected={medicationFilter}
                onClick={() => {
                  filterFunction('medication', !medicationFilter)
                  setMedicationFilter(!medicationFilter)
                }}
              >
                Medications
              </Button>
              <Button
                variant={referralFilter ? 'contained' : 'outlined'}
                name='referral'
                color='primary'
                value='referral'
                selected={referralFilter}
                onClick={() => {
                  filterFunction('referral', !referralFilter)
                  setReferralFilter(!referralFilter)
                }}
              >
                Referrals
              </Button>
            </ButtonGroup>
          </Hidden>
          <Hidden mdUp>
            <Box display='flex' flexDirection='column' style={{ width: '100%' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={medicationFilter}
                    onChange={() => {
                      filterFunction('medication', !medicationFilter)
                      setMedicationFilter(!medicationFilter)
                    }}
                    value='medication'
                    name='medication'
                    color='primary'
                  />
                }
                label='Medications'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={referralFilter}
                    onChange={() => {
                      filterFunction('referral', !referralFilter)
                      setReferralFilter(!referralFilter)
                    }}
                    value='referral'
                    name='referral'
                    color='primary'
                  />
                }
                label='Referrals'
              />
            </Box>
          </Hidden>
        </Box>
        <Box>
          <Typography variant='h5' gutterBottom>
            Filter by status.
          </Typography>
        </Box>
        <Hidden mdDown>
          <Box padding='8px'>
            <ButtonGroup>
              <Button
                variant={paidFilter ? 'contained' : 'outlined'}
                name='paid'
                color='primary'
                value='other'
                selected={paidFilter}
                onClick={() => {
                  filterFunction('paid', !paidFilter)
                  setPaidFilter(!paidFilter)
                }}
              >
                Paid
              </Button>
              <Button
                variant={unpaidFilter ? 'contained' : 'outlined'}
                name='unpaid'
                color='primary'
                value='other'
                selected={unpaidFilter}
                onClick={() => {
                  filterFunction('unpaid', !unpaidFilter)
                  setUnpaidFilter(!unpaidFilter)
                }}
              >
                Unpaid
              </Button>
              <Button
                variant={failedFilter ? 'contained' : 'outlined'}
                name='failed'
                color='primary'
                value='other'
                selected={failedFilter}
                onClick={() => {
                  filterFunction('failed', !failedFilter)
                  setFailedFilter(!failedFilter)
                }}
              >
                Failed
              </Button>
            </ButtonGroup>
          </Box>
        </Hidden>
        <Hidden mdUp>
          <Box display='flex' flexDirection='column' padding='8px' style={{ width: '100%' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={paidFilter}
                  onChange={() => {
                    filterFunction('paid', !paidFilter)
                    setPaidFilter(!paidFilter)
                  }}
                  value='paid'
                  name='paid'
                  color='primary'
                />
              }
              label='Paid'
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={unpaidFilter}
                  onChange={() => {
                    filterFunction('unpaid', !unpaidFilter)
                    setUnpaidFilter(!unpaidFilter)
                  }}
                  value='unpaid'
                  name='unpaid'
                  color='primary'
                />
              }
              label='Unpaid'
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={failedFilter}
                  onChange={() => {
                    filterFunction('failed', !failedFilter)
                    setFailedFilter(!failedFilter)
                  }}
                  value='failed'
                  name='failed'
                  color='primary'
                />
              }
              label='Failed'
            />
          </Box>
        </Hidden>
      </Box>
    </>
  )
}

TypeFilters.propTypes = {
  filterFunction: PropTypes.func.isRequired,
}

export default TypeFilters
