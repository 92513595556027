import VouchersProvider from '@app/context/Vouchers'
import VoucherDetailsProvider from '@app/context/VoucherDetails'
import AddNoteModalProvider from '@app/context/AddNoteModal'
import AddOrderModalProvider from '@app/context/AddOrderModal'

const DashboardProviders = ({ children }) => {
  return (
    <VouchersProvider>
      <VoucherDetailsProvider>
        <AddNoteModalProvider>
          <AddOrderModalProvider>{children}</AddOrderModalProvider>
        </AddNoteModalProvider>
      </VoucherDetailsProvider>
    </VouchersProvider>
  )
}

export default DashboardProviders
