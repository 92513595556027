import { useEffect, useState } from 'react'
import firebase from 'firebase/compat/app'
import { useParams, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { styled } from '@mui/material/styles'
import { Grid, Box, Divider, Button } from '@mui/material'
import DateRangeIcon from '@mui/icons-material/DateRange'
import { DatePicker } from '@mui/x-date-pickers'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import Typography from '@app/stories/Typography'
import LiveChat from '@app/stories/LiveChat'
import Section from '@app/stories/Section'
import parseQueryString from '@app/libs/parseQueryStrings'
import { collection } from '@app/firebase/firestore'
import { uploadFile } from '@app/firebase/storage'
import QuickCheckoutContainerOrders from '@app/containers/QuickCheckoutEmrOrderProvider'
import EmrOrdersCheckoutForm from '@app/components/EmrOrdersCheckoutForm'
import Container from '@app/stories/Container'
import { useCurrentUser } from '@app/context/CurrentUser'
import RequiredSignInForm from '@app/components/RequiredSignIn'
import TextField from '@app/stories/TextField'
import SelectField from '@app/stories/Select'
import { STATE_OPTIONS } from '@app/libs/geo'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

const EmrCheckout = () => {
  const { typeOfOrder } = useParams()
  const { search } = useLocation()
  const { orderId } = parseQueryString(search)
  const { profileData } = useCurrentUser()
  const [order, setOrder] = useState(null)
  const [loading, setLoading] = useState(true)
  const [paid, setPaid] = useState(null)

  const [shippingInfo, setShippingInfo] = useState(null)
  const [shippingErrorMessage, setShippingErrorMessage] = useState(null)
  const [shippingStreet, setShippingStreet] = useState('')
  const [shippingStreet2, setShippingStreet2] = useState('')
  const [shippingCity, setShippingCity] = useState('')
  const [stateSelect, setStateSelect] = useState('invalid')
  const [shippingZip, setShippingZip] = useState('')

  const [idNumber, setIdNumber] = useState('')
  const [idExpiry, setIdExpiry] = useState(null)
  const [idImage, setIdImage] = useState(null)
  const [preview, setPreview] = useState(null)

  const [patientInfo, setPatientInfo] = useState(null)

  const [idErrorMessage, setIdErrorMessage] = useState(null)

  const [validIdInfo, setValidIdInfo] = useState(false)
  const [selectedDeliveryOption, setSelectedDeliveryOption] = useState(null)

  const DeliveryOptions = [
    {
      key: 'Overnight',
      value: 'Overnight',
      text: 'Overnight',
      label: 'Overnight',
    },
    { key: 'TwoDay', value: 'TwoDay', text: 'Two Day', label: 'Two Day' },
  ]

  useEffect(() => {
    if (!idImage) {
      setPreview(null)
      return
    }
    setPreview(URL.createObjectURL(idImage))
  }, [idImage])

  useEffect(() => {
    if (profileData?.address) {
      if (profileData?.address?.street) {
        setShippingStreet(profileData.address.street)
      }
      if (profileData?.address?.street2) {
        setShippingStreet2(profileData.address.street2)
      }
      if (profileData?.address?.city) {
        setShippingCity(profileData.address.city)
      }
      if (profileData?.address?.state) {
        setStateSelect(profileData.address.state)
      }
      if (profileData?.address?.zip) {
        setShippingZip(profileData.address.zip)
      }
    }
  }, [profileData])

  const checkExpiration = (expiryDate) => {
    if (!expiryDate) {
      console.log('no expiry date - already uploaded')
      return true
    }
    console.log(expiryDate)
    const today = new Date()
    const idDate =
      expiryDate instanceof firebase.firestore.Timestamp ? expiryDate.toDate() : expiryDate
    const diffTime = idDate - today
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    if (diffDays < 7) {
      return false
    }
    return true
  }

  const checkForId = (idInfo) => {
    const { idNumber: idNumberCheck, idExpiry: idExpiryCheck, idLink: idFileCheck } = idInfo

    setIdErrorMessage(null)

    console.log(idNumberCheck)
    console.log(idExpiry)
    console.log(idFileCheck)

    if (!idNumberCheck) {
      console.log('no number')
      setIdErrorMessage('Missing ID Number')
      return false
    }

    if (!idFileCheck) {
      console.log('no file')
      setIdErrorMessage('Missing Image of ID')

      return false
    }

    if (!idExpiryCheck) {
      console.log('no expiry')
      setIdErrorMessage('Missing Expiration Date')
      return false
    }

    if (!checkExpiration(idExpiryCheck)) {
      setIdErrorMessage(
        'Your ID is expired or will expire within 7 days, please provide a valid ID',
      )
      return false
    }

    return true
  }

  const uploadIdCard = async (uid, file) => {
    console.log('Uploading id card')
    const fileRef = await uploadFile(`/idCards/${uid}`, file, { ownerId: uid })
    console.log(fileRef)
    return fileRef
  }

  const updateProfile = async (uid, idNumber, idExpiry, idLink) => {
    console.log('Updating profile')

    const profileRef = collection('profiles').doc(uid)
    const profileDoc = await profileRef.get()
    if (!profileDoc.exists) {
      console.log('Profile not found') //  eslint-disable-line no-console
      return
    }

    const profileData = {
      idInfo: {
        idNumber,
        idExpiry,
        idLink,
      },
    }
    console.log(profileData)

    await profileRef
      .set(profileData, { merge: true })
      .then(() => {
        console.log('Profile updated with id info')
        return true
      })
      .catch((err) => {
        console.log('Failed to update profile with id')
        console.log(err)
        return false
      })
  }

  const checkShippingInfo = (shippingInfoValues) => {
    if (!shippingInfoValues) {
      setShippingErrorMessage('Please enter your shipping information')
      return false
    }
    if (!shippingInfoValues.address) {
      setShippingErrorMessage('Please enter your shipping address')
      return false
    }
    if (!shippingInfoValues.city) {
      setShippingErrorMessage('Please enter your shipping city')
      return false
    }
    if (!shippingInfoValues.state || shippingInfoValues.state === 'invalid') {
      setShippingErrorMessage('Please enter your shipping state')
      return false
    }
    if (!shippingInfoValues.zip) {
      setShippingErrorMessage('Please enter your shipping zip')
      return false
    }
    setShippingErrorMessage(null)
    setShippingInfo(shippingInfoValues)
    return true
  }

  const prepaymentCheck = async () => {
    console.log('Checking prepayment')
    console.log(validIdInfo)

    const idInfo = {}

    if (validIdInfo) {
      idInfo.idNumber = profileData?.idInfo?.idNumber
      idInfo.idExpiry = profileData?.idInfo?.idExpiry
      idInfo.idLink = profileData?.idInfo?.idLink
    } else {
      idInfo.idNumber = idNumber
      idInfo.idExpiry = idExpiry
      idInfo.idLink = idImage
    }

    const idStatus = checkForId(idInfo)
    console.log(idStatus)

    if (!idStatus) {
      setShippingErrorMessage('Please upload your ID')
      return false
    }

    const shippingInfoValues = {
      address: shippingStreet,
      address2: shippingStreet2,
      city: shippingCity,
      state: stateSelect,
      zip: shippingZip,
    }

    console.log(shippingInfoValues)

    const shippingInfoStatus = checkShippingInfo(shippingInfoValues)
    console.log(shippingInfoStatus)

    if (!shippingInfoStatus) {
      setShippingErrorMessage('Please enter your shipping information')
      return false
    }

    // validIDInfo is a state set when profile data is pulled to check if they have existing data
    if (!validIdInfo) {
      const idURL = await uploadIdCard(profileData.id, idImage)
      await updateProfile(profileData.id, idNumber, idExpiry, idURL)
    }

    setPatientInfo({
      uid: profileData.id,
      idInfo,
      shippingInfo: shippingInfoValues,
    })

    return true
  }

  const checkProfileForID = (prof) => {
    console.log(prof?.idInfo?.idExpiry)
    setIdErrorMessage(null)

    if (!prof?.idInfo?.idNumber) {
      console.log(' ** Profile does not have ID number')
      return false
    }
    if (!prof?.idInfo?.idExpiry) {
      console.log(' ** Profile does not have expiry')
      return false
    }
    // check if date is expired or will with in 7 days
    if (!checkExpiration(prof?.idInfo?.idExpiry)) {
      console.log(' ** ID expired')
      setIdErrorMessage(
        'Your ID is expired or will expire within 7 days please provide a valid ID.',
      )
      return false
    }

    if (!prof?.idInfo?.idLink) {
      console.log(' ** Profile does not have link to ID Image')
      return false
    }
    return true
  }

  useEffect(() => {
    const getOrder = async () => {
      const orderRef = collection('emrOrders').doc(orderId)
      setLoading(false)
      if (!orderRef) {
        return undefined
      }
      const orderDoc = await orderRef?.get()
      if (!orderDoc?.exists) {
        return undefined
      }
      if (orderDoc.data().status === 'paid' || orderDoc.data().status === 'fufilled') {
        setPaid(true)
      }
      setOrder(orderDoc.data())
    }
    if (!profileData.id) {
      return undefined
    }
    getOrder()
  }, [orderId, profileData.id])

  useEffect(() => {
    // when profile is loaded, check if there is id info
    if (!profileData) {
      return undefined
    }

    setValidIdInfo(checkProfileForID(profileData))
  }, [profileData])

  return (
    <>
      <Helmet>
        <title>Checkout</title>
        <meta
          property='og:title'
          content='Mishe Health Plan: Direct care made easy. No insurance needed.'
        />
        <meta
          name='description'
          property='og:description'
          content='Flexible treatment options, transparent pricing, modern payment tools, and your own personal care concierge team. Mishe is bringing healing back to healthcare'
        />
        <meta name='robots' content='noindex' />
      </Helmet>
      <Section>
        <Container>
          {!profileData.id && (
            <Grid container display='flex' justifyContent='center' spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <RequiredSignInForm />
              </Grid>
            </Grid>
          )}
          {profileData.id && (
            <Grid container display='flex' justifyContent='center' spacing={3}>
              {!paid && (
                <>
                  {order && !loading && (
                    <>
                      {typeOfOrder === 'medication' && (
                        <>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            {/* Shipping address form */}
                            <Grid
                              container
                              spacing={1}
                              alignItems='center'
                              style={{
                                border: '1px solid rgba(0,0,0,0.1)',
                                boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                                borderRadius: '5px',
                                padding: '20px',
                                margin: '20px 0',
                              }}
                            >
                              {/* ID Form */}
                              {!validIdInfo && (
                                <>
                                  <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Typography variant='h4' align='center'>
                                      Government ID
                                    </Typography>
                                  </Grid>
                                  {idErrorMessage && (
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                      <Typography
                                        variant='body1'
                                        style={{
                                          color: 'red',
                                        }}
                                      >
                                        {idErrorMessage}
                                      </Typography>
                                    </Grid>
                                  )}
                                  <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Box>
                                      <Typography variant='caption'>
                                        Enter your ID/Drivers License Number as it appears.
                                      </Typography>
                                    </Box>
                                    <TextField
                                      id='dirx-id-number'
                                      label='ID/Drivers License Number'
                                      variant='outlined'
                                      fullWidth
                                      disabled={shippingInfo && true}
                                      value={idNumber}
                                      onChange={(e) => {
                                        setIdNumber(e.target.value)
                                      }}
                                      sx={{
                                        marginTop: '0px',
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Box>
                                      <Typography variant='caption'>
                                        ID must expire more than 7 days from today.
                                      </Typography>
                                    </Box>
                                    <DatePicker
                                      id='dirx-id-expiry'
                                      value={idExpiry}
                                      onChange={setIdExpiry}
                                      minDate={
                                        new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
                                      }
                                      format='MM-dd-yyyy'
                                      disabled={shippingInfo && true}
                                      label='ID/Drivers License Expiration Date'
                                      inputVariant='outlined'
                                      bgcolor='background.paper'
                                      keyboardIcon={<DateRangeIcon />}
                                      KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                      }}
                                      slotProps={{
                                        textField: {
                                          required: true,
                                          fullWidth: true,
                                        },
                                      }}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    sx={{ marginTop: '10px' }}
                                  >
                                    <Box
                                      mt={1}
                                      width='100%'
                                      display='flex'
                                      alignItems='center'
                                      justifyContent='center'
                                    >
                                      <Box
                                        display='flex'
                                        alignItems='center'
                                        justifyContent='center'
                                        flexDirection='column'
                                        width='100%'
                                      >
                                        <Button
                                          component='label'
                                          variant='outlined'
                                          tabIndex={-1}
                                          startIcon={<CloudUploadIcon />}
                                          disabled={shippingInfo && true}
                                          size='large'
                                          sx={{
                                            minWidth: '155px',
                                          }}
                                        >
                                          <Typography variant='body1' whiteSpace='nowrap'>
                                            Upload ID
                                          </Typography>
                                          <VisuallyHiddenInput
                                            id='dirx-id-file'
                                            type='file'
                                            accept='image/*'
                                            disabled={shippingInfo && true}
                                            onChange={(e) => {
                                              setIdImage(e.target.files[0])
                                              const objectUrl = URL.createObjectURL(
                                                e.target.files[0],
                                              )
                                              setPreview(objectUrl)
                                            }}
                                          />
                                        </Button>
                                        <Button
                                          component='label'
                                          variant='outlined'
                                          tabIndex={-1}
                                          startIcon={<DeleteForeverIcon />}
                                          disabled={shippingInfo && true}
                                          size='large'
                                          sx={{
                                            marginTop: '10px',
                                            minWidth: '155px',
                                          }}
                                          onClick={() => {
                                            setIdImage(null)
                                          }}
                                        >
                                          <Typography variant='body1' whiteSpace='nowrap'>
                                            Clear
                                          </Typography>
                                        </Button>
                                      </Box>
                                      <Box
                                        width='100%'
                                        display='flex'
                                        alignItems='center'
                                        justifyContent='center'
                                        padding={2}
                                      >
                                        {preview ? (
                                          <img
                                            id='id_preview'
                                            src={preview}
                                            alt='ID Preview'
                                            style={{
                                              width: '100%',
                                              maxWidth: '400px',
                                              height: 'auto',
                                            }}
                                          />
                                        ) : (
                                          <Typography variant='body1'>
                                            Please upload a clear picture of the front of your ID.
                                          </Typography>
                                        )}
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Divider sx={{ margin: '20px 0' }} />
                                  </Grid>
                                </>
                              )}
                              {/* End of ID Form */}
                              <Grid item mt={2} xs={12}>
                                <Typography variant='h4' align='center'>
                                  Shipping Info
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                  id='dirx-sh-address'
                                  label='Shipping Address'
                                  variant='outlined'
                                  fullWidth
                                  value={shippingStreet}
                                  onChange={(e) => {
                                    setShippingStreet(e.target.value)
                                  }}
                                  disabled={shippingInfo && true}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                  id='dirx-sh-address2'
                                  label='Apartments, Suite, etc. (optional)'
                                  variant='outlined'
                                  fullWidth
                                  value={shippingStreet2}
                                  onChange={(e) => {
                                    setShippingStreet2(e.target.value)
                                  }}
                                  disabled={shippingInfo && true}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                  id='dirx-sh-city'
                                  label='City'
                                  variant='outlined'
                                  fullWidth
                                  value={shippingCity}
                                  onChange={(e) => {
                                    setShippingCity(e.target.value)
                                  }}
                                  disabled={shippingInfo && true}
                                />
                              </Grid>
                              <Grid item xs={6} sm={6} md={6} lg={6}>
                                <SelectField
                                  id='dirx-state'
                                  label='State'
                                  variant='outlined'
                                  fullWidth
                                  value={stateSelect}
                                  onChange={(e) => {
                                    setStateSelect(e.target.value)
                                  }}
                                  options={STATE_OPTIONS}
                                  disabled={shippingInfo && true}
                                />
                              </Grid>
                              <Grid item xs={6} sm={6} md={6} lg={6}>
                                <TextField
                                  id='dirx-zip'
                                  label='Zipcode'
                                  variant='outlined'
                                  fullWidth
                                  value={shippingZip}
                                  onChange={(e) => {
                                    setShippingZip(e.target.value)
                                  }}
                                  disabled={shippingInfo && true}
                                />
                              </Grid>
                              {/* conditionally rendered commented out but its there */}
                              {/* {shippingSpeedOptions && ( */}
                              <>
                                <Grid item mt={2} xs={12}>
                                  <Typography variant='h4' align='center'>
                                    Shipping Options
                                  </Typography>
                                </Grid>
                                <Grid item mb={2} xs={12}>
                                  <SelectField
                                    id='dirx-delivery-speed'
                                    label='Delivery Speed'
                                    variant='outlined'
                                    fullWidth
                                    value={selectedDeliveryOption}
                                    onChange={(e) => {
                                      setSelectedDeliveryOption(e.target.value)
                                    }}
                                    options={DeliveryOptions}
                                    disabled={shippingInfo && true}
                                  />
                                </Grid>
                              </>
                              {/* )} */}
                              {shippingErrorMessage && (
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  <Typography variant='body1'>{shippingErrorMessage}</Typography>
                                </Grid>
                              )}
                              {!shippingInfo && (
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  <Button variant='contained' onClick={prepaymentCheck} fullWidth>
                                    Proceed to Payment
                                  </Button>
                                </Grid>
                              )}
                              {shippingInfo && !shippingErrorMessage && (
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  <Button
                                    variant='contained'
                                    onClick={() => {
                                      setShippingInfo(null)
                                    }}
                                    fullWidth
                                    disabled
                                  >
                                    Edit Shipping Info
                                  </Button>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Box
                              sx={{
                                border: '1px solid rgba(0,0,0,0.1)',
                                boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                                borderRadius: '5px',
                                padding: '20px',
                                margin: '20px 0',
                              }}
                              alignItems='center'
                            >
                              <Box
                                display='flex'
                                alignItems='center'
                                justifyContent='center'
                                flexGrow={1}
                              >
                                <Typography variant='h4' align='center' gutterBottom>
                                  Order Summary
                                </Typography>
                              </Box>
                              <Box display='flex' alignItems='center' flexGrow={1}>
                                <Typography variant='h4' align='center' gutterBottom>
                                  {order?.details?.medicationName}
                                </Typography>
                              </Box>
                              <Box display='flex' alignItems='center' flexGrow={1}>
                                <Typography variant='h4' align='center' gutterBottom>
                                  Strength: {order?.details?.strength}
                                </Typography>
                              </Box>
                              <Box display='flex' alignItems='center' flexGrow={1}>
                                <Typography variant='h4' align='center' gutterBottom>
                                  Count per fill: {order?.details?.count}
                                </Typography>
                              </Box>
                              <Box display='flex' alignItems='center' flexGrow={1}>
                                <Typography variant='h4' align='center' gutterBottom>
                                  Refills allowed: {order?.details?.refills?.allowed}
                                </Typography>
                              </Box>
                              <Divider
                                style={{
                                  margin: '20px 0',
                                }}
                              />
                              <Box display='block' alignItems='center' flexGrow={1}>
                                <Box
                                  display='flex'
                                  alignItems='center'
                                  justifyContent='space-between'
                                >
                                  <Typography variant='h4' align='center' gutterBottom>
                                    Medication Price:{' '}
                                  </Typography>
                                  <Typography variant='h4' align='center' gutterBottom>
                                    {order?.details?.price &&
                                      `$${parseFloat(
                                        order?.details?.controlledSubstance
                                          ? order?.details?.price - 25
                                          : order?.details?.price,
                                      ).toFixed(2)}`}
                                  </Typography>
                                </Box>
                                <Box
                                  display='flex'
                                  alignItems='center'
                                  justifyContent='space-between'
                                >
                                  <Typography variant='h4' align='center' gutterBottom>
                                    Shipping:{' '}
                                  </Typography>
                                  <Typography variant='h4' align='center' gutterBottom>
                                    {order?.details?.controlledSubstance ? '$25.00' : 'Free'}
                                  </Typography>
                                </Box>
                                <Box
                                  display='flex'
                                  alignItems='center'
                                  justifyContent='space-between'
                                >
                                  <Typography variant='h4' align='center' gutterBottom>
                                    Total:{' '}
                                  </Typography>
                                  <Typography variant='h4' align='center' gutterBottom>
                                    {`$${order?.details?.price.toFixed(2)}`}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                            {shippingInfo && !shippingErrorMessage && (
                              <QuickCheckoutContainerOrders
                                clientSecret={order.stripe.secret}
                                paymentIntent={order.stripe.paymentIntentId}
                              >
                                <EmrOrdersCheckoutForm
                                  orderType='medication'
                                  orderId={orderId}
                                  patientInfo={patientInfo}
                                />
                              </QuickCheckoutContainerOrders>
                            )}
                          </Grid>
                        </>
                      )}
                      {typeOfOrder === 'referral' && (
                        <QuickCheckoutContainerOrders
                          clientSecret={order.stripe.secret}
                          paymentIntent={order.stripe.paymentIntentId}
                        >
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Box
                              style={{
                                border: '1px solid rgba(0,0,0,0.1)',
                                boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                                borderRadius: '5px',
                                padding: '20px',
                                margin: '20px 0',
                              }}
                              alignItems='center'
                            >
                              <Box
                                display='flex'
                                alignItems='center'
                                justifyContent='center'
                                flexGrow={1}
                              >
                                <Typography variant='h4' align='center' gutterBottom>
                                  Order Summary
                                </Typography>
                              </Box>
                              <Box display='flex' alignItems='center' flexGrow={1}>
                                <Typography variant='h4' align='center' gutterBottom>
                                  {order?.details?.serviceName}
                                </Typography>
                              </Box>
                              <Box display='flex' alignItems='center' flexGrow={1}>
                                <Typography variant='h4' align='center' gutterBottom>
                                  {order?.details?.organizationName}
                                </Typography>
                              </Box>
                              <Divider
                                style={{
                                  margin: '20px 0',
                                }}
                              />
                              <Box display='flex' alignItems='center' flexGrow={1}>
                                <Typography variant='h4' align='center' gutterBottom>
                                  Price:{' '}
                                  {order?.details?.price &&
                                    `$${parseFloat(order?.details?.price).toFixed(2)}`}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <EmrOrdersCheckoutForm />
                          </Grid>
                        </QuickCheckoutContainerOrders>
                      )}
                    </>
                  )}
                  {loading && (
                    <Typography variant='h2' align='center' gutterBottom>
                      Loading...
                    </Typography>
                  )}
                  {!order && !loading && (
                    <Typography variant='h2' align='center' gutterBottom>
                      Order not found
                    </Typography>
                  )}
                </>
              )}
              {paid && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box
                    style={{
                      border: '1px solid rgba(0,0,0,0.1)',
                      boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                      borderRadius: '5px',
                      padding: '20px',
                      margin: '20px 0',
                    }}
                    alignItems='center'
                  >
                    <Box display='flex' alignItems='center' justifyContent='center' flexGrow={1}>
                      <Typography variant='h4' align='center' gutterBottom>
                        Thank you! You will be redirected to you dashboard shortly...
                      </Typography>
                    </Box>
                    <Box display='flex' alignItems='center' flexGrow={1}>
                      {typeOfOrder === 'medication' && (
                        <Typography variant='body1' weight='light' align='center' gutterBottom>
                          You will receive an email with your order details shortly. Tracking info
                          will be available through your dashboard once your order has shipped.
                        </Typography>
                      )}
                      {typeOfOrder === 'referral' && (
                        <>
                          <Typography variant='body1' weight='light' align='center' gutterBottom>
                            A voucher has been generated and is accessible through your dashboard.
                            You will receive an email with your voucher details shortly. Please
                            schedue your appointment with the provider with the booking link in the
                            voucher on your dashboard.
                          </Typography>
                          <Button href='/dashboard' variant='contained'>
                            Go to dashboard
                          </Button>
                        </>
                      )}
                    </Box>
                  </Box>
                </Grid>
              )}
            </Grid>
          )}
          <LiveChat />
        </Container>
      </Section>
    </>
  )
}

export default EmrCheckout
