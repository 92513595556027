import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Container from '@app/stories/Container'
import { LiveChatSection, LiveChatContainer } from '@app/stories/LiveChat'

import { EnrollmentForm } from '@app/components/HubSpotForm'

const WhyMishePage = () => {
  const { search } = useLocation()
  const ref = new URLSearchParams(search).get('ref')
  if (ref) {
    localStorage.setItem('refvalue', ref)
  }

  useEffect(() => {
    window.Intercom('trackEvent', 'Page View', {
      page_name: 'Clinic Enrollment',
    })
  }, [])

  return (
    <>
      <Helmet>
        <title>Mishe&#39;s Care Network | Easy Enrollment. Enhanced Rates.</title>
        <meta
          property='og:title'
          content='Mishe&#39;s Care Network | Easy Enrollment. Enhanced Rates.'
        />
        <meta
          name='description'
          property='og:description'
          content='Enrollment in Mishe&#39;s Care Network is quick and straightforward. Gain a built-in referral source with quality clinicians.'
        />
        <meta property='og:url' content='https://mishe.co/enrollment' />
        <link rel='canonical' href='https://mishe.co/enrollment' />
      </Helmet>
      <LiveChatSection>
        <Container>
          <LiveChatContainer>
            <Container>
              <Grid container spacing={3} align='center' alignItems='center'>
                <Grid item xs={12} sm={12} md={12} lg={12} align='center'>
                  <EnrollmentForm />
                </Grid>
              </Grid>
            </Container>
          </LiveChatContainer>
        </Container>
      </LiveChatSection>
    </>
  )
}

export default WhyMishePage
