import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import TextField from '@app/stories/TextField'
import Button from '@app/stories/Button'
import Typography from '@app/stories/Typography'

import CodeEntry from '@app/components/Login/CodeEntry'

import { useWhiteLabelingContext } from '@app/context/WhiteLabelingContext'

const SignInFormPhone = ({
  onSubmit,
  onCodeSubmit,
  errors = {},
  disable,
  stepHandler,
  codeHandler,
  phoneHandler,
}) => {
  const { code, setCode } = codeHandler
  const { step, setStep } = stepHandler
  const { phone, setPhone } = phoneHandler

  const { planColorPrimary } = useWhiteLabelingContext()

  const formatPhoneNumber = (value) => {
    const cleaned = ('' + value).replace(/\D/g, '')
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    match = cleaned.match(/^(\d{3})(\d{3})(\d{1,3})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    match = cleaned.match(/^(\d{3})(\d{1,3})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2]
    }
    match = cleaned.match(/^(\d{1,3})$/)
    if (match) {
      return '(' + match[1]
    }
    return value
  }

  if (step === 1) {
    console.log('Rendering step 1')
    return (
      <Box mt={2}>
        <Box mb={2}>
          <TextField
            required
            value={phone}
            onChange={(event) => {
              setPhone(formatPhoneNumber(event.target.value))
            }}
            name='phone'
            type='tel'
            label='Phone Number'
            placeholder='Phone Number'
            display='standard'
            inputProps={{
              title: 'phone',
              maxLength: 14, // Update maxLength to 14 to account for the formatting characters
              pattern: '\\([0-9]{3}\\) [0-9]{3}-[0-9]{4}', // Update pattern to match the new format
            }}
            disabled={disable}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: planColorPrimary,
                },
                '&:hover fieldset': {
                  borderColor: planColorPrimary,
                },
                '&.Mui-focused fieldset': {
                  borderColor: planColorPrimary,
                },
              },
              '& .MuiInputLabel-root': {
                color: planColorPrimary,
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: planColorPrimary,
              },
            }}
          />
        </Box>
        <Box mb={2}>
          <Box display='flex' justifyContent='center' width='100%' id='recaptcha-container' />
        </Box>
        <Box mb={2}>
          {errors?.code === 'auth/too-many-requests' && (
            <Box mb={2}>
              <Typography variant='body2' weight='light' color='error'>
                You have exceeded the number of attempts. Please try again later.
              </Typography>
            </Box>
          )}
          <Button
            id='signInButton'
            fullWidth
            disabled={
              disable || !phone || phone.length !== 14 || errors.code === 'auth/too-many-requests'
            }
            onClick={() => {
              onSubmit()
            }}
            style={{
              backgroundColor:
                disable || !phone || phone.length !== 14 || errors.code === 'auth/too-many-requests'
                  ? '#ccc'
                  : planColorPrimary,
              color: 'white',
              whiteSpace: 'nowrap',
            }}
          >
            Send Code
          </Button>
        </Box>
      </Box>
    )
  }

  if (step === 2) {
    console.log('Rendering step 2')
    return (
      <Box mt={2}>
        <Box mb={2} width='100%'>
          <Typography variant='body2' weight='light'>
            Enter the code sent to your phone number.
          </Typography>
        </Box>
        <Box mb={2}>
          <CodeEntry code={code} setCode={setCode} disable={disable} />
        </Box>
        <Box mb={2}>
          <Typography variant='body2' weight='light'>
            Didn't get a code?{' '}
            <Button
              size='small'
              variant='outlined'
              display='standard'
              onClick={() => {
                setStep(1)
              }}
              sx={{
                marginLeft: '5px',
              }}
            >
              Re-send code
            </Button>
          </Typography>
        </Box>
        <Box>
          {errors.code && (
            <Typography variant='body2' weight='light'>
              {errors.code}
            </Typography>
          )}
          <Button
            id='codeSubmitButton'
            fullWidth
            disabled={disable || !code || code.length !== 6}
            onClick={() => {
              onCodeSubmit()
            }}
          >
            Verify
          </Button>
        </Box>
      </Box>
    )
  }

  return <></>
}

SignInFormPhone.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCodeSubmit: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    phone: PropTypes.string,
    code: PropTypes.string,
  }),
  stepHandler: PropTypes.shape({
    step: PropTypes.number.isRequired,
    setStep: PropTypes.func.isRequired,
  }).isRequired,
  codeHandler: PropTypes.shape({
    code: PropTypes.string.isRequired,
    setCode: PropTypes.func.isRequired,
  }).isRequired,
  phoneHandler: PropTypes.shape({
    phone: PropTypes.string.isRequired,
    setPhone: PropTypes.func.isRequired,
  }).isRequired,
  disable: PropTypes.bool.isRequired,
}

export default SignInFormPhone
