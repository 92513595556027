import { memo } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@app/stories/Typography'
import Divider from '@app/stories/Divider'
import Button from '@app/stories/Button'
import { useVoucherDetails } from '@app/context/VoucherDetails'

import { useNavigate } from 'react-router-dom'
import EobButton from '@app/components/EobButton'

import { formatCurrency } from '@app/libs/formatters'

const ExternalClaimDetail = () => {
  const { externalClaim, handleClose } = useVoucherDetails()

  const navigate = useNavigate()
  const closeAndNavigateBack = () => {
    handleClose()
    navigate('/dashboard/vouchers') // navigate back to the vouchers page
  }

  return (
    <Box>
      <Box
        sx={{
          justifyContent: {
            xs: 'center',
            sm: 'center',
          },
          marginBottom: {
            xs: '20px',
            sm: '20px',
          },
        }}
      >
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} sm={12} md={12} id='claimDetailsTopBar'>
            <Box
              sx={{
                display: {
                  xs: 'flex',
                  sm: 'flex',
                },
                justifyContent: {
                  xs: 'center',
                  sm: 'center',
                  md: 'flex-start',
                  lg: 'flex-start',
                },
              }}
            >
              <Typography variant='h3'>Claim Details</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <Button
              variant='contained'
              color='primary'
              onClick={closeAndNavigateBack}
              style={{
                height: '40px',
                width: '100%',
              }}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Grid container spacing={2} mt={1} mb={3}>
        <Grid item xs={12} sm={12} md={6}>
          <EobButton claims={externalClaim} label='Explanation of All Benefits' />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Box
            justifyContent='space-between'
            alignItems='flex-end'
            sx={{
              borderBottom: '1px solid #e0e0e0',
              padding: '10px 0',
              fontWeight: 'bold',
              display: {
                xs: 'none',
                md: 'flex',
              },
            }}
          >
            <Box display='flex' justifyContent='center' sx={{ width: '10%' }}>
              <Typography align='center' variant='h6'>
                #
              </Typography>
            </Box>
            <Box paddingLeft='16px' sx={{ width: '30%' }}>
              <Typography variant='h6'>Treatment</Typography>
            </Box>
            <Box display='flex' justifyContent='center' sx={{ width: '10%' }}>
              <Typography align='center' variant='h6'>
                Procedure Code
              </Typography>
            </Box>
            <Box display='flex' justifyContent='center' sx={{ width: '10%' }}>
              <Typography align='center' variant='h6'>
                Date
              </Typography>
            </Box>
            <Box display='flex' justifyContent='center' sx={{ width: '15%' }}>
              <Typography align='center' variant='h6'>
                Provider
              </Typography>
            </Box>
            <Box display='flex' justifyContent='center' sx={{ width: '15%' }}>
              <Typography align='center' variant='h6'>
                POS
              </Typography>
            </Box>
            <Box display='flex' justifyContent='center' sx={{ width: '10%' }}>
              <Typography align='center' variant='h6'>
                Price
              </Typography>
            </Box>
            <Box display='flex' justifyContent='center' sx={{ width: '10%' }}>
              <Typography align='center' variant='h6'>
                EOB
              </Typography>
            </Box>
          </Box>
          {externalClaim
            ?.sort((a, b) => (a?.BILL_ENTRY_NUM > b?.BILL_ENTRY_NUM ? 1 : -1))
            .map((claim, index) => (
              <Box
                key={index}
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                sx={{
                  borderBottom: '1px solid #e0e0e0',
                  padding: '10px 0',
                  flexDirection: {
                    xs: 'column',
                    md: 'row',
                  },
                }}
              >
                {console.log(claim)}
                <Box
                  display='flex'
                  justifyContent='center'
                  sx={{
                    width: {
                      xs: '100%',
                      md: '10%',
                    },
                    padding: {
                      xs: '10px 0',
                      md: '0px 16px',
                    },
                    justifyContent: {
                      xs: 'flex-start',
                      md: 'center',
                    },
                  }}
                >
                  <Typography
                    variant='h4'
                    sx={{
                      paddingRight: '8px',
                      display: {
                        xs: 'block',
                        md: 'none',
                      },
                    }}
                  >
                    #
                  </Typography>
                  <Typography variant='h4' weight='light'>
                    {claim?.BILL_ENTRY_NUM}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: {
                      xs: '100%',
                      md: '30%',
                    },
                    padding: {
                      xs: '10px 0',
                      md: '0px 16px',
                    },
                  }}
                >
                  <Typography
                    variant='body1'
                    sx={{
                      paddingRight: '8px',
                      display: {
                        xs: 'block',
                        md: 'none',
                      },
                    }}
                  >
                    Treatment
                  </Typography>
                  <Typography
                    variant='body1'
                    weight='light'
                    sx={{
                      textTransform: 'capitalize',
                    }}
                  >
                    {claim?.service_info?.name?.toLowerCase() || '---'}
                  </Typography>
                </Box>
                <Box
                  display='flex'
                  justifyContent='center'
                  sx={{
                    width: {
                      xs: '100%',
                      md: '10%',
                    },
                    padding: {
                      xs: '10px 0',
                      md: '0px 16px',
                    },
                    justifyContent: {
                      xs: 'flex-start',
                      md: 'center',
                    },
                  }}
                >
                  <Typography
                    variant='body1'
                    sx={{
                      paddingRight: '8px',
                      display: {
                        xs: 'block',
                        md: 'none',
                      },
                    }}
                  >
                    Procedure Code:
                  </Typography>
                  <Typography variant='body1' weight='light'>
                    {claim?.service_info?.code || '---'}
                  </Typography>
                </Box>
                <Box
                  display='flex'
                  justifyContent='center'
                  sx={{
                    width: {
                      xs: '100%',
                      md: '10%',
                    },
                    padding: {
                      xs: '10px 0',
                      md: '0px 16px',
                    },
                    justifyContent: {
                      xs: 'flex-start',
                      md: 'center',
                    },
                  }}
                >
                  <Typography
                    variant='body1'
                    sx={{
                      paddingRight: '8px',
                      display: {
                        xs: 'block',
                        md: 'none',
                      },
                    }}
                  >
                    Date:
                  </Typography>
                  <Typography variant='body1' weight='light'>
                    {claim?.service_info?.date_of_service_initial}
                  </Typography>
                </Box>
                <Box
                  display='flex'
                  justifyContent='center'
                  sx={{
                    width: {
                      xs: '100%',
                      md: '15%',
                    },
                    padding: {
                      xs: '10px 0',
                      md: '0px 16px',
                    },
                    justifyContent: {
                      xs: 'flex-start',
                      md: 'center',
                    },
                    textTransform: 'capitalize',
                  }}
                >
                  <Typography
                    variant='body1'
                    sx={{
                      paddingRight: '8px',
                      display: {
                        xs: 'block',
                        md: 'none',
                      },
                    }}
                  >
                    Provider:
                  </Typography>
                  <Typography variant='body1' weight='light'>
                    {claim?.provider_info?.name?.toLowerCase() ||
                      claim?.point_of_service_description?.toLowerCase()}
                  </Typography>
                </Box>
                <Box
                  display='flex'
                  justifyContent='center'
                  sx={{
                    width: {
                      xs: '100%',
                      md: '15%',
                    },
                    padding: {
                      xs: '10px 0',
                      md: '0px 16px',
                    },
                    justifyContent: {
                      xs: 'flex-start',
                      md: 'center',
                    },
                    textTransform: 'capitalize',
                  }}
                >
                  <Typography
                    variant='body1'
                    sx={{
                      paddingRight: '8px',
                      display: {
                        xs: 'block',
                        md: 'none',
                      },
                    }}
                  >
                    POS:
                  </Typography>
                  <Typography variant='body1' weight='light'>
                    {claim?.point_of_service_description?.toLowerCase()}
                  </Typography>
                </Box>
                <Box
                  display='flex'
                  justifyContent='center'
                  sx={{
                    width: {
                      xs: '100%',
                      md: '10%',
                    },
                    padding: {
                      xs: '10px 0',
                      md: '0px 16px',
                    },
                    justifyContent: {
                      xs: 'flex-start',
                      md: 'center',
                    },
                  }}
                >
                  <Typography
                    variant='body1'
                    sx={{
                      paddingRight: '8px',
                      display: {
                        xs: 'block',
                        md: 'none',
                      },
                    }}
                  >
                    Price:
                  </Typography>
                  <Typography variant='body1' weight='light'>
                    {formatCurrency(claim?.financials?.original_charge || 0)}
                  </Typography>
                </Box>
                <Box
                  display='flex'
                  justifyContent='center'
                  sx={{
                    width: {
                      xs: '100%',
                      md: '10%',
                    },
                    padding: {
                      xs: '10px 0',
                      md: '0px 16px',
                    },
                    justifyContent: {
                      xs: 'flex-start',
                      md: 'center',
                    },
                  }}
                >
                  <EobButton claims={[claim]} label='EOB' />
                </Box>
              </Box>
            ))}
        </Grid>
      </Grid>
      <Divider />
    </Box>
  )
}

export default memo(ExternalClaimDetail)
