import { useState, useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSearchBox, useInstantSearch, useConfigure } from 'react-instantsearch'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Button from '@app/stories/Button'
import Typography from '@app/stories/Typography'
import TextField from '@app/stories/TextField'
import { useMapEffects } from '@app/context/MapEffects'

import { useWhiteLabelingContext } from '@app/context/WhiteLabelingContext'

// Custom debounce function
const debounce = (func, delay) => {
  let timeoutId
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(() => {
      func(...args)
    }, delay)
  }
}

const CustomConfigure = ({ configuration }) => {
  const { refine } = useConfigure(configuration)

  useEffect(() => {
    refine(configuration)
  }, [configuration, refine])

  return null
}

CustomConfigure.propTypes = {
  configuration: PropTypes.shape({
    hitsPerPage: PropTypes.number,
  }).isRequired,
}

const HomeScreenTextField = styled(TextField)({
  '& label.Mui-focused': {
    backgroundColor: '#652d92',
    color: '#fff',
  },
  '& .MuiFormLabel-root': {
    color: '#fff',
  },
  '& .MuiInput-underline:after': {
    backgroundColor: '#fff',
    borderRadius: '28px',
    borderBottomColor: '#fff',
    color: '#fff',
  },
  '& .MuiInputBase-input': {
    backgroundColor: '#652d92',
    color: '#fff',
    fontSize: 16,
    borderRadius: '28px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      background: '#transparent',
      borderRadius: '28px',
      borderColor: '#fff',
      color: '#fff',
    },
    '&:hover fieldset': {
      background: '#transparent',
      borderRadius: '28px',
      borderColor: '#fff',
      color: '#fff',
    },
    '&.Mui-focused fieldset': {
      background: '#transparent',
      borderRadius: '28px',
      borderColor: '#fff',
      color: '#fff',
    },
  },
})

const CustomSearchBox = ({
  searchBoxText,
  displayHits,
  showInitialHits = false,
  showZipcode,
  showZipcodeMobile,
  smallZipcode = false,
  zipcode = '',
  setZipcode,
  clearSearchButton = false,
  homePageSearch,
  searchOnMap,
  ...props
}) => {
  const { query, refine } = useSearchBox(props)
  const { status } = useInstantSearch()
  const { searchMapCoordinates } = useMapEffects()
  const [inputValue, setInputValue] = useState(query)
  const [zipcodeValue, setZipcodeValue] = useState(zipcode)
  const [isClicked, setIsClicked] = useState(false)
  const [zipIsClicked, setZipIsClicked] = useState(false)

  const isSearchStalled = status === 'stalled'
  const searchDelay = 500
  const searchTimeout = useRef(null)
  const zipcodeTimeout = useRef(null)

  const { planColorPrimary } = useWhiteLabelingContext()

  const handleFocusClick = (target) => {
    setIsClicked(false)
    setZipIsClicked(false)
    if (target === 'main') {
      setIsClicked(true)
    }
    if (target === 'zip') {
      setZipIsClicked(true)
    }
  }

  const debouncedHandleQuery = useCallback(
    debounce(({ queryValue = '' }) => {
      setInputValue(queryValue)
      if (queryValue?.length === 0 && !showInitialHits) {
        displayHits(false)
        return
      }

      if (queryValue?.length > 0 || showInitialHits) {
        displayHits(true)
      }

      refine(queryValue)
    }, searchDelay),
    [showInitialHits, displayHits, refine],
  )

  const debouncedHandleZipcode = useCallback(
    debounce((value) => {
      setZipcode(value)
    }, 1000),
    [setZipcode],
  )

  const handleZipcode = useCallback(
    (value) => {
      if (zipcodeTimeout.current) {
        clearTimeout(zipcodeTimeout.current)
      }

      setZipcodeValue(value)
      debouncedHandleZipcode(value)
    },
    [debouncedHandleZipcode],
  )

  const handleQuery = useCallback(
    ({ queryValue = '' }) => {
      if (searchTimeout.current) {
        clearTimeout(searchTimeout.current)
      }

      setInputValue(queryValue)
      if (queryValue?.length === 0 && !showInitialHits) {
        displayHits(false)
        return
      }

      if (queryValue?.length > 0 || showInitialHits) {
        displayHits(true)
      }

      debouncedHandleQuery({ queryValue })
    },
    [showInitialHits, displayHits, debouncedHandleQuery],
  )

  useEffect(() => {
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current)
    }
    if (zipcode?.length > 4) {
      displayHits(true)
    }
    searchTimeout.current = setTimeout(() => {
      refine(inputValue)
    }, searchDelay)
  }, [zipcode])

  useEffect(() => {
    searchOnMap(searchMapCoordinates)
  }, [searchMapCoordinates])

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <form
        action=''
        role='search'
        noValidate
        onSubmit={(event) => {
          event.preventDefault()
          event.stopPropagation()
        }}
        onReset={(event) => {
          event.preventDefault()
          event.stopPropagation()
          handleQuery('')
        }}
      >
        {homePageSearch ? (
          <Box
            style={{
              position: 'relative',
            }}
          >
            {showZipcodeMobile && (
              <HomeScreenTextField
                onClick={() => handleFocusClick('zip')}
                autoFocus={zipIsClicked}
                style={{
                  marginTop: '1rem',
                  marginBottom: '0px',
                  zIndex: '5',
                  width: '100%',
                }}
                display='standard'
                autoComplete='off'
                autoCorrect='off'
                autoCapitalize='off'
                spellCheck={false}
                maxLength={10}
                type='search'
                value={zipcodeValue}
                onChange={(event) => {
                  handleZipcode(event.currentTarget.value)
                }}
                id='zip'
                label={smallZipcode ? 'Zip' : 'Zip Code'}
                variant='outlined'
              />
            )}
            <HomeScreenTextField
              onClick={() => handleFocusClick('main')}
              autoFocus={isClicked}
              style={{
                marginTop: '1rem',
                marginBottom: '0px',
                zIndex: '5',
                width: showZipcode ? '75%' : '100%',
                color: '#fff',
              }}
              display='standard'
              autoComplete='off'
              autoCorrect='off'
              autoCapitalize='off'
              spellCheck={false}
              maxLength={512}
              type='search'
              value={inputValue}
              onChange={(event) => {
                handleQuery({
                  queryValue: event.currentTarget.value,
                })
              }}
              id='medication'
              label={searchBoxText}
              variant='outlined'
            />
            {showZipcode && (
              <HomeScreenTextField
                onClick={() => handleFocusClick('zip')}
                autoFocus={zipIsClicked}
                style={{
                  marginTop: '1rem',
                  marginBottom: '0px',
                  zIndex: '5',
                  marginLeft: '1rem',
                  width: 'calc(25% - 1rem)',
                }}
                display='standard'
                autoComplete='off'
                autoCorrect='off'
                autoCapitalize='off'
                spellCheck={false}
                maxLength={10}
                type='search'
                value={zipcodeValue}
                onChange={(event) => {
                  handleZipcode(event.currentTarget.value)
                }}
                id='zip'
                label={smallZipcode ? 'Zip' : 'Zip Code'}
                variant='outlined'
              />
            )}
            {clearSearchButton && (
              <Button
                style={{
                  position: 'absolute',
                  bottom: '2px',
                  right: showZipcode ? 'calc(25% + 2px)' : '2px',
                  zIndex: '6',
                  border: 'none',
                  borderRadius: '0px 28px 28px 0px',
                  background: '#652d92',
                  boxShadow: 'none',
                  borderLeft: '1px solid #e0e0e0',
                  height: '52px',
                  color: '#fff',
                }}
                onClick={() => {
                  handleQuery({ queryValue: '' })
                  handleZipcode('')
                }}
              >
                <Typography variant='body1' align='center' color='inherit'>
                  Clear
                </Typography>
              </Button>
            )}
            <Box position='absolute' bottom='-24px' left='16px' zIndex='5' color='#fff'>
              <span hidden={!isSearchStalled}>Searching…</span>
            </Box>
          </Box>
        ) : (
          <Box
            style={{
              position: 'relative',
            }}
          >
            <TextField
              sx={{
                marginTop: '1rem',
                marginBottom: '0px',
                zIndex: '5',
                width: showZipcode ? '75%' : '100%',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: planColorPrimary || '#652d92',
                  },
                  '&:hover fieldset': {
                    borderColor: planColorPrimary || '#652d92',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: planColorPrimary || '#652d92',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: planColorPrimary || '#652d92',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: planColorPrimary || '#652d92',
                },
              }}
              display='standard'
              autoComplete='off'
              autoCorrect='off'
              autoCapitalize='off'
              spellCheck={false}
              maxLength={512}
              type='search'
              value={inputValue}
              onChange={(event) => {
                handleQuery({
                  queryValue: event.currentTarget.value,
                })
              }}
              autoFocus
              id='medication'
              label={searchBoxText}
              variant='outlined'
            />
            {showZipcode && (
              <TextField
                sx={{
                  marginTop: '1rem',
                  marginBottom: '0px',
                  zIndex: '5',
                  marginLeft: '1rem',
                  width: 'calc(25% - 1rem)',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: planColorPrimary || '#652d92',
                    },
                    '&:hover fieldset': {
                      borderColor: planColorPrimary || '#652d92',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: planColorPrimary || '#652d92',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: planColorPrimary || '#652d92',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: planColorPrimary || '#652d92',
                  },
                }}
                display='standard'
                autoComplete='off'
                autoCorrect='off'
                autoCapitalize='off'
                spellCheck={false}
                maxLength={10}
                type='search'
                value={zipcodeValue}
                onChange={(event) => {
                  handleZipcode(event.currentTarget.value)
                }}
                autoFocus
                id='zip'
                label={smallZipcode ? 'Zip' : 'Zip Code'}
                variant='outlined'
              />
            )}
            {clearSearchButton && (
              <Button
                style={{
                  position: 'absolute',
                  bottom: '2px',
                  right: showZipcode ? 'calc(25% + 2px)' : '2px',
                  zIndex: '6',
                  border: 'none',
                  borderRadius: '0px',
                  background: '#fff',
                  boxShadow: 'none',
                  borderLeft: '1px solid #e0e0e0',
                  height: '52px',
                }}
                onClick={() => {
                  handleQuery({ queryValue: '' })
                  handleZipcode('')
                }}
              >
                <Typography variant='body1' align='center' color='primary'>
                  Clear
                </Typography>
              </Button>
            )}
            <Box position='absolute' bottom='-24px' left='16px' zIndex='5'>
              <span hidden={!isSearchStalled}>Searching…</span>
            </Box>
          </Box>
        )}
      </form>
    </div>
  )
}

CustomSearchBox.propTypes = {
  searchBoxText: PropTypes.string.isRequired,
  zipcode: PropTypes.string,
  setZipcode: PropTypes.func.isRequired,
  displayHits: PropTypes.func.isRequired,
  showInitialHits: PropTypes.bool,
  showZipcode: PropTypes.bool.isRequired,
  smallZipcode: PropTypes.bool,
  clearSearchButton: PropTypes.bool,
}

export default CustomSearchBox
