import { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import Collapse from '@mui/material/Collapse'
import Typography from '@app/stories/Typography'
import Button from '@app/stories/Button'
import { useCurrentUser, useCurrentClinic } from '@app/context/CurrentUser'
import Icon from '@app/stories/Icons'
import SelectField from '@app/stories/Select'

// import BarChart from '@app/components/Charts/BarChart'
import ResponsiveBarChart from '@app/components/Charts/ResponsiveChart'
import { moneyFormatter } from '@app/libs/formatters'

import { collection } from '@app/firebase/firestore'
import useReports from '@app/hooks/useReports'

const ReportsContent = () => {
  const [reportType, setReportType] = useState('')
  const [reportTypeSpecific, setReportTypeSpecific] = useState('')
  const [openMenu, setOpenMenu] = useState('')
  const { profileData, authData } = useCurrentUser()

  const {
    fetchRevenueByReferringProvider,
    fetchRevenueByProvider,
    fetchRevenueByPlan,
    fetchRevenueByService,
    revenueReferringProvider,
    revenueProvider,
    revenueByPlan,
    revenueByService,
  } = useReports({ organizationId: authData.organizationId })

  const [loading, setLoading] = useState(false)

  const [graphData, setGraphData] = useState(null)

  const [noDataMessage, setNoDataMessage] = useState('')

  const [rows, setRows] = useState([])
  const [columns, setColumns] = useState([])
  const [selectedPlan, setSelectedPlan] = useState('All Plans')

  const { plansAssociated = {} } = useCurrentClinic()

  const [plans, setPlans] = useState([])

  useEffect(() => {
    if (plansAssociated) {
      const planList = Object.values(plansAssociated).map((plan) => ({
        value: plan.slug,
        label: plan.name,
      }))
      if (
        !planList.some(
          (plan) => plan?.value === 'Cost Plus Health Plan' || plan?.value === 'Mishe Health Plan',
        )
      ) {
        planList.unshift({
          value: 'Mishe Health Plan',
          label: 'Mishe Health Plan',
        })
      }
      planList.unshift({ value: 'All Plans', label: 'All Plans' })
      setPlans((prevPlans) => {
        if (JSON.stringify(prevPlans) !== JSON.stringify(planList)) {
          return planList
        }
        return prevPlans
      })
    }
    console.log('plansAssociated changed', plansAssociated)
  }, [plansAssociated])

  useEffect(() => {
    if (reportTypeSpecific === 'byService') {
      setColumns([
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'name', headerName: 'Service', width: 300 },
        { field: 'count', headerName: 'Count', width: 150 },
        { field: 'revenue', headerName: 'Revenue', width: 200 },
      ])
    }
    if (reportTypeSpecific === 'byReferringProvider') {
      setColumns([
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'name', headerName: 'Referring Provider', width: 300 },
        { field: 'count', headerName: 'Count', width: 150 },
        { field: 'revenue', headerName: 'Revenue', width: 200 },
      ])
    }
    if (reportTypeSpecific === 'byProvider') {
      setColumns([
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'name', headerName: 'Provider', width: 300 },
        { field: 'count', headerName: 'Count', width: 150 },
        { field: 'revenue', headerName: 'Revenue', width: 200 },
      ])
    }
    if (reportTypeSpecific === 'byPlan') {
      setColumns([
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'name', headerName: 'Plan', width: 300 },
        { field: 'count', headerName: 'Count', width: 150 },
        { field: 'revenue', headerName: 'Revenue', width: 200 },
      ])
    }
    if (reportTypeSpecific === 'outstandingOrders') {
      setColumns([
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'name', headerName: 'Service', width: 300 },
        { field: 'count', headerName: 'Count', width: 150 },
        { field: 'revenue', headerName: 'Pending Revenue', width: 200 },
      ])
    }
    if (reportTypeSpecific === 'vouchersNotRedeemed') {
      setColumns([
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'name', headerName: 'Service', width: 300 },
        { field: 'count', headerName: 'Count', width: 150 },
        { field: 'revenue', headerName: 'Pending Payout', width: 200 },
      ])
    }
    if (reportTypeSpecific === 'vouchersRedeemedNotPaid') {
      setColumns([
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'name', headerName: 'Service', width: 300 },
        { field: 'count', headerName: 'Count', width: 150 },
        { field: 'revenue', headerName: 'Pending Payout', width: 200 },
      ])
    }
    if (reportTypeSpecific === 'patientTotalSpend') {
      setColumns([
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'name', headerName: 'Service', width: 300 },
        { field: 'count', headerName: 'Count', width: 150 },
        { field: 'revenue', headerName: 'Total Spend', width: 200 },
      ])
    }
  }, [reportTypeSpecific])

  useEffect(() => {
    if (graphData) {
      const updatedRows = graphData.dataset.map((data, index) => ({
        id: index + 1,
        ...data,
      }))
      setRows(updatedRows)
    }
  }, [graphData])

  useEffect(() => {
    const fetchData = async () => {
      console.log('fetchData')
      // fetch data

      if (reportTypeSpecific === 'byService') {
        console.log('is this triggering?')
        fetchRevenueByService()
        setLoading(false)
      }

      if (reportTypeSpecific === 'byReferringProvider') {
        fetchRevenueByReferringProvider()
        setLoading(false)
      }

      if (reportTypeSpecific === 'byProvider') {
        fetchRevenueByProvider()
        setLoading(false)
      }

      if (reportTypeSpecific === 'byPlan') {
        fetchRevenueByPlan()
        setLoading(false)
      }

      if (reportTypeSpecific === 'outstandingOrders') {
        const data = await collection('emrOrders')
          .where('referred.clinic.id', '==', authData.organizationId)
          .where('status', '==', 'unpaid')
          .get()

        if (data.empty) {
          console.log('No matching documents.')
          setLoading(false)
          setNoDataMessage('It looks like there is no data to display.')
          return undefined
        }

        const counts = {}

        data.forEach((doc) => {
          const order = doc.data()
          if (counts[order?.details?.serviceName]) {
            counts[order?.details?.serviceName].count += 1
            counts[order?.details?.serviceName].revenue += parseFloat(order?.details?.price) || 0
          } else {
            counts[order?.details?.serviceName] = {
              name: order?.details?.serviceName,
              count: 1,
              revenue: parseFloat(order?.details?.price) || 0,
            }
          }
        })

        const dataset = Object.values(counts)

        const xAxis = [
          {
            scaleType: 'band',
            dataKey: 'name',
            tickPlacement: 'middle',
            valueFormatter: (value) => {
              // cut text if too long
              if (value?.length > 20) {
                return value.substring(0, 20) + '...'
              }
              return value || 'N/A'
            },
          },
        ]
        const yAxis = [
          { id: 'left_axis_id' },
          { id: 'right_axis_id', valueFormatter: moneyFormatter },
        ]

        const seriesData = [
          {
            type: 'bar',
            dataKey: 'count',
            label: 'Count',
            yAxisKey: 'left_axis_id',
            color: '#652d92',
          },
          {
            type: 'bar',
            dataKey: 'revenue',
            label: 'Pending Revenue',
            yAxisKey: 'right_axis_id',
            color: '#652d9233',
            valueFormatter: moneyFormatter,
          },
        ]

        setGraphData({
          title: 'Outstanding Orders by Service',
          dataset,
          xAxis,
          yAxis,
          seriesData,
          chartSettings: {
            height: 400,
          },
        })
        setLoading(false)
      }

      if (reportTypeSpecific === 'vouchersNotRedeemed') {
        const data = await collection('vouchers')
          .where('organization.id', '==', authData.organizationId)
          .where('status', '==', 'purchased')
          .get()

        if (data.empty) {
          console.log('No matching documents.')
          setLoading(false)
          setNoDataMessage('It looks like there is no data to display.')
          return undefined
        }

        const counts = {}

        data.forEach((doc) => {
          const voucher = doc.data()
          if (counts[voucher?.service?.name]) {
            counts[voucher?.service?.name].count += 1
            counts[voucher?.service?.name].revenue +=
              parseFloat(voucher?.service?.pricePaidToDoc) || 0
          } else {
            counts[voucher?.service?.name] = {
              name: voucher?.service?.name,
              count: 1,
              revenue: parseFloat(voucher?.service?.price) || 0,
            }
          }
        })

        const dataset = Object.values(counts)

        const xAxis = [
          {
            scaleType: 'band',
            dataKey: 'name',
            tickPlacement: 'middle',
            valueFormatter: (value) => {
              // cut text if too long
              if (value?.length > 20) {
                return value.substring(0, 20) + '...'
              }
              return value || 'N/A'
            },
          },
        ]
        const yAxis = [
          { id: 'left_axis_id' },
          { id: 'right_axis_id', valueFormatter: moneyFormatter },
        ]

        const seriesData = [
          {
            type: 'bar',
            dataKey: 'count',
            label: 'Count',
            yAxisKey: 'left_axis_id',
            color: '#652d92',
          },
          {
            type: 'bar',
            dataKey: 'revenue',
            label: 'Pending Payout',
            yAxisKey: 'right_axis_id',
            color: '#652d9233',
            valueFormatter: moneyFormatter,
          },
        ]

        setGraphData({
          title: 'Vouchers Not Redeemed by Service',
          dataset,
          xAxis,
          yAxis,
          seriesData,
          chartSettings: {
            height: 400,
          },
        })
        setLoading(false)
      }

      if (reportTypeSpecific === 'vouchersRedeemedNotPaid') {
        const data = await collection('vouchers')
          .where('organization.id', '==', authData.organizationId)
          .where('status', '==', 'redeemed')
          .where('paid', '==', false)
          .get()
          .catch((err) => {
            console.error('Error getting documents', err)
            return {
              empty: true,
            }
          })

        if (data.empty) {
          console.log('No matching documents.')
          setLoading(false)
          setNoDataMessage('It looks like there is no data to display.')
          return undefined
        }

        const counts = {}

        data.forEach((doc) => {
          const voucher = doc.data()
          if (counts[voucher?.service?.name]) {
            counts[voucher?.service?.name].count += 1
            counts[voucher?.service?.name].revenue +=
              parseFloat(voucher?.service?.pricePaidToDoc) || 0
          } else {
            counts[voucher?.service?.name] = {
              name: voucher?.service?.name,
              count: 1,
              revenue: parseFloat(voucher?.service?.price) || 0,
            }
          }
        })

        const dataset = Object.values(counts)

        const xAxis = [
          {
            scaleType: 'band',
            dataKey: 'name',
            tickPlacement: 'middle',
            valueFormatter: (value) => {
              // cut text if too long
              if (value?.length > 20) {
                return value.substring(0, 20) + '...'
              }
              return value || 'N/A'
            },
          },
        ]
        const yAxis = [
          { id: 'left_axis_id' },
          { id: 'right_axis_id', valueFormatter: moneyFormatter },
        ]

        const seriesData = [
          {
            type: 'bar',
            dataKey: 'count',
            label: 'Count',
            yAxisKey: 'left_axis_id',
            color: '#652d92',
          },
          {
            type: 'bar',
            dataKey: 'revenue',
            label: 'Pending Payout',
            yAxisKey: 'right_axis_id',
            color: '#652d9233',
            valueFormatter: moneyFormatter,
          },
        ]

        setGraphData({
          title: 'Vouchers Redeemed but not Paid by Service',
          dataset,
          xAxis,
          yAxis,
          seriesData,
          chartSettings: {
            height: 400,
          },
        })
        setLoading(false)
      }

      if (reportTypeSpecific === 'patientTotalSpend') {
        console.log('patientTotalSpend')

        const data = await collection('vouchers')
          .where('patient_info.user_id', '==', profileData.id)
          .get()
          .catch((err) => {
            console.error('Error getting documents', err)
            return {
              empty: true,
            }
          })

        console.log('data', data)

        if (data.empty) {
          console.log('No matching documents.')
          setLoading(false)
          setNoDataMessage('It looks like there is no data to display.')
          return undefined
        }

        setGraphData({
          title: 'Patient Total Spend by Service',
          dataset,
          xAxis,
          yAxis,
          seriesData,
          chartSettings: {
            height: 400,
          },
        })
        setLoading(false)
      }

      if (!reportTypeSpecific) {
        return undefined
      }
    }
    setNoDataMessage('')
    setLoading(true)
    fetchData()
  }, [reportTypeSpecific])

  const graphConfig = ({
    xAxisScaleType,
    xAxisDataKey,
    xAxisTickPlacement,
    yAxisLeftId,
    yAxisRightId,
    seriesData,
    dataset,
    title,
  }) => {
    const xAxis = [
      {
        scaleType: xAxisScaleType,
        dataKey: xAxisDataKey,
        tickPlacement: xAxisTickPlacement,
        valueFormatter: (value) => {
          if (value?.length > 20) {
            return value.substring(0, 20) + '...'
          }
          return value || 'N/A'
        },
      },
    ]
    const yAxis = [{ id: yAxisLeftId }, { id: yAxisRightId, valueFormatter: moneyFormatter }]

    setGraphData({
      title,
      dataset,
      xAxis,
      yAxis,
      seriesData,
      chartSettings: {
        height: 400,
      },
    })
  }

  useEffect(() => {
    const dataset = []

    revenueReferringProvider &&
      revenueReferringProvider.forEach(({ voucher, emrOrder }) => {
        const revenue = parseFloat(voucher?.service?.price) || 0

        const referringProvider = emrOrder.referringProvider.id
        const findExistingReferringProvider = dataset.find(
          ({ referringProviderId }) => referringProviderId === referringProvider,
        )
        if (findExistingReferringProvider) {
          const { count, revenue: prevRevenue } = findExistingReferringProvider

          const foundIndex = dataset.findIndex(
            ({ referringProviderId }) => referringProviderId == referringProvider,
          )
          dataset[foundIndex] = {
            name: emrOrder.referringProvider.name,
            referringProviderId: referringProvider,
            count: count + 1,
            revenue: revenue + prevRevenue,
          }
        } else {
          dataset.push({
            name: emrOrder.referringProvider.name,
            referringProviderId: referringProvider,
            count: 1,
            revenue,
          })
        }
      })
    graphConfig({
      xAxisScaleType: 'band',
      xAxisDataKey: 'name',
      xAxisTickPlacement: 'middle',
      yAxisLeftId: 'left_axis_id',
      yAxisRightId: 'right_axis_id',
      seriesData: [
        {
          type: 'bar',
          dataKey: 'count',
          label: 'Count',
          yAxisKey: 'left_axis_id',
          color: '#652d92',
        },
        {
          type: 'bar',
          dataKey: 'revenue',
          label: 'Revenue',
          yAxisKey: 'right_axis_id',
          color: '#652d9233',
          valueFormatter: moneyFormatter,
        },
      ],
      dataset,
      title: 'Revenue and Encounter Count by Referring Provider',
    })
  }, [revenueReferringProvider])

  useEffect(() => {
    const datasetRevenueProvider = []
    revenueProvider.forEach(({ voucher, emrOrder }) => {
      const referredOrganizationId = emrOrder.referred.organizationId
      const name = emrOrder.referred.organizationName
      const revenue = parseFloat(voucher?.service?.price) || 0

      const findExistingReferringProvider = datasetRevenueProvider.find(
        ({ referredId }) => referredId === referredOrganizationId,
      )
      if (findExistingReferringProvider) {
        const { count, revenue: prevRevenue } = findExistingReferringProvider

        const foundIndex = datasetRevenueProvider.findIndex(
          ({ referredId }) => referredId == referredOrganizationId,
        )
        datasetRevenueProvider[foundIndex] = {
          name,
          referredId: referredOrganizationId,
          count: count + 1,
          revenue: revenue + prevRevenue,
        }
      } else {
        datasetRevenueProvider.push({
          name,
          referredId: referredOrganizationId,
          count: 1,
          revenue,
        })
      }
    })

    graphConfig({
      xAxisScaleType: 'band',
      xAxisDataKey: 'name',
      xAxisTickPlacement: 'middle',
      yAxisLeftId: 'left_axis_id',
      yAxisRightId: 'right_axis_id',
      seriesData: [
        {
          type: 'bar',
          dataKey: 'count',
          label: 'Count',
          yAxisKey: 'left_axis_id',
          color: '#652d92',
        },
        {
          type: 'bar',
          dataKey: 'revenue',
          label: 'Revenue',
          yAxisKey: 'right_axis_id',
          color: '#652d9233',
          valueFormatter: moneyFormatter,
        },
      ],
      dataset: datasetRevenueProvider,
      title: 'Revenue and Encounter Count by Provider',
    })
  }, [revenueProvider])

  useEffect(() => {
    const counts = {}
    revenueByPlan.forEach(({ network, service }) => {
      const networkName = network?.name
      const networkId = network?.id
      if (counts.networkId === networkId) {
        counts[networkName].count += 1
        counts[networkName].revenue += parseFloat(service?.price) || 0
      } else {
        if (networkName && networkId) {
          counts[networkName] = {
            name: networkName,
            networkId,
            count: 1,
            revenue: parseFloat(service?.price) || 0,
          }
        }
      }
    })

    const dataset = Object.values(counts)

    graphConfig({
      xAxisScaleType: 'band',
      xAxisDataKey: 'name',
      xAxisTickPlacement: 'middle',
      yAxisLeftId: 'left_axis_id',
      yAxisRightId: 'right_axis_id',
      seriesData: [
        {
          type: 'bar',
          dataKey: 'count',
          label: 'Count',
          yAxisKey: 'left_axis_id',
          color: '#652d92',
        },
        {
          type: 'bar',
          dataKey: 'revenue',
          label: 'Revenue',
          yAxisKey: 'right_axis_id',
          color: '#652d9233',
          valueFormatter: moneyFormatter,
        },
      ],
      dataset,
      title: 'Revenue and Encounter Count by Provider',
    })
  }, [revenueByPlan])

  useEffect(() => {
    console.log('why are you triggering')
    const revenueByServiceData = {}
    revenueByService.forEach(({ voucher }) => {
      if (revenueByServiceData[voucher?.service?.name]) {
        revenueByServiceData[voucher?.service?.name].count += 1
        revenueByServiceData[voucher?.service?.name].revenue +=
          parseFloat(voucher?.service?.price) || 0
      } else {
        revenueByServiceData[voucher?.service?.name] = {
          name: voucher?.service?.name,
          count: 1,
          revenue: parseFloat(voucher?.service?.price) || 0,
        }
      }
    })
    const dataset = Object.values(revenueByServiceData)

    graphConfig({
      xAxisScaleType: 'band',
      xAxisDataKey: 'name',
      xAxisTickPlacement: 'middle',
      yAxisLeftId: 'left_axis_id',
      yAxisRightId: 'right_axis_id',
      seriesData: [
        {
          type: 'bar',
          dataKey: 'count',
          label: 'Count',
          yAxisKey: 'left_axis_id',
          color: '#652d92',
        },
        {
          type: 'bar',
          dataKey: 'revenue',
          label: 'Revenue',
          yAxisKey: 'right_axis_id',
          color: '#652d9233',
          valueFormatter: moneyFormatter,
        },
      ],
      dataset,
      title: 'Revenue and Encounter Count by Service',
    })
  }, [revenueByService])

  useEffect(() => {
    setOpenMenu('')
  }, [reportType])

  useEffect(() => {
    if (authData.roles.includes('PROVIDER')) {
      setReportType('Provider Revenue')
    } else {
      setReportType('Patient Spending')
    }
  }, [authData.roles])

  const ReportTypeSpecificButton = ({ value, label }) => (
    <Box width='100%' bgcolor={reportTypeSpecific === value ? '#652d9211' : 'transparent'}>
      <Button
        width='100%'
        variant='text'
        size='medium'
        color='primary'
        onClick={() => setReportTypeSpecific(value)}
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          height: '30px',
        }}
      >
        <Typography variant='body1' weight='light' family='poppins'>
          {label}
        </Typography>
      </Button>
    </Box>
  )

  const ReportTypeButton = ({ value }) => (
    <Button
      width='100%'
      variant='text'
      onClick={() => setOpenMenu((prevState) => (prevState === value ? '' : value))}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: openMenu === value ? '#652d9244' : 'transparent',
        borderRadius: '0',
        margin: '0',
        height: '50px',
      }}
    >
      <Typography
        variant='h5'
        weight='light'
        family='poppins'
        margin='0'
        color={openMenu === value ? 'white' : 'primary'}
      >
        {value}
      </Typography>
      {openMenu === value && (
        <Box color='white'>
          <Icon name='chevronRight' />
        </Box>
      )}
    </Button>
  )

  const ReportSectionButton = ({ value }) => (
    <Button
      width='100%'
      variant='text'
      onClick={() => setReportType(value)}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        borderRadius: '0',
        backgroundColor: reportType === value ? '#652d92' : 'transparent',
        margin: '0',
        height: '50px',
      }}
    >
      <Typography
        variant='h5'
        weight='light'
        family='poppins'
        margin='0'
        color={reportType === value ? 'white' : 'primary'}
      >
        {value}
      </Typography>
      {reportType === value && (
        <Box color='white'>
          <Icon name='chevronRight' />
        </Box>
      )}
    </Button>
  )

  return (
    <Box>
      <Box
        display='flex'
        width='100%'
        maxHeight='150px'
        sx={{
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          overflow: {
            xs: '',
            md: 'hidden',
          },
          maxHeight: {
            xs: 'auto',
            md: '150px',
          },
          boxShadow: {
            xs: 'none',
            md: '0px 0px 1px rgba(0, 0, 0, 0.4)',
          },
        }}
      >
        <Box
          display='flex'
          flexDirection='column'
          sx={{
            width: {
              xs: '100%',
              md: '33.3%',
            },
            overflow: {
              xs: '',
              md: 'hidden',
            },
          }}
        >
          {authData.roles.includes('PROVIDER') ? (
            <>
              {/* Mobile Tier 1 */}
              <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <SelectField
                  label='Report Type'
                  value={reportType}
                  onChange={(e) => setReportType(e.target.value)}
                  options={[
                    { value: 'Provider Revenue', label: 'Provider Revenue' },
                    { value: 'Plan Spending', label: 'Plan Spending' },
                  ]}
                />
              </Box>
              {/*  Desktop Tier 1 */}
              <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <ReportSectionButton value='Provider Revenue' />
                <ReportSectionButton value='Plan Spending' />
                <ReportSectionButton value='Plan Savings' />
              </Box>
            </>
          ) : (
            <>
              <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <SelectField
                  label='Report Type'
                  value={reportType}
                  onChange={(e) => setReportType(e.target.value)}
                  options={[{ value: 'Patient Spending', label: 'Patient Spending' }]}
                />
              </Box>
              <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <ReportSectionButton value='Patient Spending' />
              </Box>
            </>
          )}
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          sx={{
            width: {
              xs: '100%',
              md: '33.3%',
            },
            overflow: {
              xs: '',
              md: 'hidden',
            },
          }}
        >
          {/* Mobile Tier 2 */}
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            {authData.roles.includes('PROVIDER') ? (
              <>
                <Collapse in={reportType === 'Provider Revenue'}>
                  <SelectField
                    label='Report Type'
                    value={openMenu}
                    onChange={(e) => setOpenMenu(e.target.value)}
                    options={[
                      {
                        value: 'Revenue and Encounter Count',
                        label: 'Revenue and Encounter Count',
                      },
                      {
                        value: 'Dollar Total and Count',
                        label: 'Dollar Total and Count',
                      },
                    ]}
                  />
                </Collapse>
                <Collapse in={reportType === 'Plan Spending'}>
                  <SelectField
                    label='Report Type'
                    value={openMenu}
                    onChange={(e) => setOpenMenu(e.target.value)}
                    options={[
                      {
                        value: 'Revenue and Encounter Count',
                        label: 'Revenue and Encounter Count',
                      },
                      {
                        value: 'Dollar Total and Count',
                        label: 'Dollar Total and Count',
                      },
                    ]}
                  />
                </Collapse>
              </>
            ) : (
              <>
                <Collapse in={reportType === 'Patient Spending'}>
                  <SelectField
                    label='Report Type'
                    value={openMenu}
                    onChange={(e) => setOpenMenu(e.target.value)}
                    options={[{ value: 'Encounter Spending', label: 'Encounter Spending' }]}
                  />
                </Collapse>
              </>
            )}
          </Box>
          {/* Desktop Tier 2 */}
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <Collapse in={reportType === 'Provider Revenue'}>
              <ReportTypeButton value='Revenue and Encounter Count' />
              <ReportTypeButton value='Dollar Total and Count' />
            </Collapse>
            <Collapse in={reportType === 'Plan Spending'}>
              <ReportTypeButton value='Revenue and Encounter Count' />
              <ReportTypeButton value='Dollar Total and Count' />
            </Collapse>
            <Collapse in={reportType === 'Plan Savings'}>
              <ReportTypeButton value='Encounter Savings' />
              {/* <ReportTypeButton value='Procedure Savings' /> */}
              <ReportTypeButton value='Medication Savings' />
            </Collapse>
            <Collapse in={reportType === 'Patient Spending'}>
              <Button
                width='100%'
                variant='text'
                onClick={() =>
                  setOpenMenu((prevState) =>
                    prevState === 'Encounter Spending' ? '' : 'Encounter Spending',
                  )
                }
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  backgroundColor: openMenu === 'Encounter Spending' ? '#652d9244' : 'transparent',
                  borderRadius: '0',
                  margin: '0',
                  height: '50px',
                }}
              >
                <Typography
                  variant='h5'
                  weight='light'
                  family='poppins'
                  margin='0'
                  color={openMenu === 'Encounter Spending' ? 'white' : 'primary'}
                >
                  Encounter Spending
                </Typography>
                {openMenu === 'Encounter Spending' && (
                  <Box color='white'>
                    <Icon name='chevronRight' />
                  </Box>
                )}
              </Button>
            </Collapse>
          </Box>
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          sx={{
            width: {
              xs: '100%',
              md: '33.4%',
            },
            overflow: {
              xs: '',
              md: 'hidden',
            },
          }}
        >
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            {authData.roles.includes('PROVIDER') ? (
              <>
                <Collapse in={openMenu === 'Revenue and Encounter Count'}>
                  <SelectField
                    label='Report Type'
                    value={reportTypeSpecific}
                    onChange={(e) => setReportTypeSpecific(e.target.value)}
                    options={[
                      { value: 'byService', label: 'By Service' },
                      {
                        value: 'byReferringProvider',
                        label: 'By Referring Provider',
                      },
                      { value: 'byProvider', label: 'By Provider' },
                      { value: 'byPlan', label: 'By Plan' },
                    ]}
                  />
                </Collapse>
                <Collapse in={openMenu === 'Dollar Total and Count'}>
                  <SelectField
                    label='Report Type'
                    value={reportTypeSpecific}
                    onChange={(e) => setReportTypeSpecific(e.target.value)}
                    options={[
                      { value: 'outstandingOrders', label: 'Outstanding Orders' },
                      {
                        value: 'vouchersNotRedeemed',
                        label: 'Vouchers not Redeemed',
                      },
                      {
                        value: 'vouchersRedeemedNotPaid',
                        label: 'Vouchers Redeemed but not Paid',
                      },
                    ]}
                  />
                </Collapse>
              </>
            ) : (
              <>
                <Collapse in={openMenu === 'Encounter Spending'}>
                  <SelectField
                    label='Report Type'
                    value={reportTypeSpecific}
                    onChange={(e) => setReportTypeSpecific(e.target.value)}
                    options={[{ value: 'patientTotalSpend', label: 'Total Spending' }]}
                  />
                </Collapse>
              </>
            )}
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <Collapse in={openMenu === 'Revenue and Encounter Count'}>
              <ReportTypeSpecificButton value='byService' label='By Service' />
              <ReportTypeSpecificButton value='byReferringProvider' label='By Referring Provider' />
              <ReportTypeSpecificButton value='byProvider' label='By Provider' />
              {/* <ReportTypeSpecificButton value='byPlan' label='By Plan' /> */}
            </Collapse>
            <Collapse in={openMenu === 'Dollar Total and Count'}>
              <ReportTypeSpecificButton value='outstandingOrders' label='Outstanding Orders' />
              <ReportTypeSpecificButton value='vouchersNotRedeemed' label='Vouchers not Redeemed' />
              <ReportTypeSpecificButton
                value='vouchersRedeemedNotPaid'
                label='Vouchers Redeemed but not Paid'
              />
            </Collapse>
            <Collapse in={openMenu === 'Encounter Savings'}>
              <ReportTypeSpecificButton value='totalSavings' label='Total Savings' />
              <ReportTypeSpecificButton value='itemizedSavings' label='Itemized Savings' />
            </Collapse>
            <Collapse in={openMenu === 'Procedure Savings'}>
              <ReportTypeSpecificButton value='outstandingOrders' label='Outstanding Orders' />
              <ReportTypeSpecificButton value='vouchersNotRedeemed' label='Vouchers not Redeemed' />
            </Collapse>
            <Collapse in={openMenu === 'Medication Savings'}>
              <ReportTypeSpecificButton value='outstandingOrders' label='Total Savings' />
              <ReportTypeSpecificButton value='vouchersNotRedeemed' label='Itemized Savings' />
            </Collapse>
            {/* Patient Section */}
            <Collapse in={openMenu === 'Encounter Spending'}>
              <ReportTypeSpecificButton value='patientTotalSpend' label='Total Spending' />
            </Collapse>
          </Box>
        </Box>
      </Box>
      {/* Plan Selection Area */}
      <Collapse in={reportType === 'Plan Savings' || reportType === 'Plan Spending'}>
        <Box
          display='flex'
          width='100%'
          marginTop='24px'
          alignItems='center'
          justifyContent='space-between'
        >
          <Box width='80%'>
            <SelectField
              label='Select Plan'
              value={selectedPlan}
              onChange={(e) => setSelectedPlan(e.target.value)}
              options={plans}
            />
          </Box>
          <Box width='20%' paddingLeft='16px'>
            <Button
              variant='contained'
              color='primary'
              disabled={true}
              style={{
                width: '100%',
                marginTop: '8px',
              }}
            >
              Advanced Filters
            </Button>
          </Box>
        </Box>
      </Collapse>
      {/* Graph Area */}
      <Box display='flex' width='100%'>
        <Box
          id='graphs'
          display={reportTypeSpecific ? 'block' : 'none'}
          width='100%'
          marginTop='24px'
        >
          {/* {loading && (
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              height='100%'
            >
              <Typography variant='h4' weight='light' family='poppins'>
                Loading...
              </Typography>
            </Box>
          )} */}
          {graphData && (
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              width='100%'
            >
              <Typography variant='h4' weight='light' family='poppins'>
                {graphData.title}
                {/* {loading ? '' : graphData.title} */}
              </Typography>
              <ResponsiveBarChart
                dataset={graphData.dataset}
                xAxis={graphData.xAxis}
                yAxis={graphData.yAxis}
                seriesData={graphData.seriesData}
                chartSettings={graphData.chartSettings}
              />
            </Box>
          )}
          {noDataMessage && !loading && (
            <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
              <Typography variant='h4' weight='light' family='poppins'>
                {noDataMessage}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      {/* Data Area */}
      {graphData && reportTypeSpecific && (
        <Box width='100%'>
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
              filter: {
                filterModel: {
                  items: [],
                  quickFilterExcludeHiddenColumns: true,
                },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            sx={{
              minHeight: '200px',
            }}
          />
        </Box>
      )}
    </Box>
  )
}

export default ReportsContent
