import { createContext, useCallback, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { collection } from '@app/firebase/firestore'

const Vouchers = createContext()

export const useVouchers = () => useContext(Vouchers)

const docsToListVouchers = async (snap, bundles = false) => {
  const ref = await snap
  if (ref?.docs?.length === 0 || !ref?.docs) return []
  return ref.docs
    .map((each) => {
      if (!bundles) {
        if (each?.data()?.bundle) return null
      }
      const id = each.id
      const { createdAt, ...data } = each.data()
      const date = createdAt.toDate()
      const redeemedAt = data.redeemedAt?.toDate()
      const cancelledAt = data.cancelledAt?.toDate()
      const timestamp = date.getTime()
      return { ...data, id, date, timestamp, redeemedAt, cancelledAt }
    })
    .filter(Boolean)
    .sort((first, second) => second.date - first.date)
}

// TODO - turn into util
function convertToDate(dateString) {
  const [year, month, day] = dateString.split('-').map(Number)
  return new Date(year, month - 1, day)
}

const docsToListClaims = async (snap) => {
  const ref = await snap
  if (ref?.docs?.length === 0 || !ref?.docs) return []

  const groupedClaims = ref.docs.reduce((acc, each) => {
    const id = each.id
    const { service_info, patient_info, provider_info, DOCUMENT_NUM, BILL_ENTRY_NUM, ...data } =
      each.data()
    const date = convertToDate(service_info.date_of_service_initial) || null

    const claim = {
      ...data,
      DOCUMENT_NUM,
      BILL_ENTRY_NUM,
      id,
      date,
      timestamp: date?.getTime() || null,
      redeemedAt: null,
      cancelledAt: null,
      external: true,
      service: {
        ...service_info,
      },
      appointmentDate: date,
      customer: patient_info,
      organization: provider_info,
    }

    if (!acc[DOCUMENT_NUM]) {
      acc[DOCUMENT_NUM] = []
    }

    acc[DOCUMENT_NUM].push(claim)
    return acc
  }, {})

  const sortedGroups = Object.entries(groupedClaims).map(([document_num, group]) => ({
    ...group[0],
    id: document_num,
    document_num,
    external: true,
    claims: group.sort((a, b) => parseInt(a.BILL_ENTRY_NUM) - parseInt(b.BILL_ENTRY_NUM)),
  }))

  return sortedGroups.sort((a, b) => b.claims[0].timestamp - a.claims[0].timestamp)
}

const VouchersProvider = ({ children }) => {
  const [vouchers, setVouchers] = useState([])
  const [vouchersLoading, setVouchersLoading] = useState(false)
  const [externalClaims, setExternalClaims] = useState([])
  const [externalClaimsLoading, setExternalClaimsLoading] = useState(false)

  const changeVouchers = useCallback(async (snap, isPatient = false) => {
    if (snap?.v2) {
      const voucherPromises = docsToListVouchers(snap.vouchers)
      const bundlePromises = docsToListVouchers(snap.bundles, true)

      const [formattedVouchers, formattedBundles] = await Promise.all([
        voucherPromises,
        bundlePromises,
      ])

      const combinedVouchers = [...formattedVouchers, ...formattedBundles].sort(
        (first, second) => second.date - first.date,
      )
      setVouchers(combinedVouchers)
    } else if (snap) {
      setVouchers(await docsToListVouchers(snap, isPatient))
    } else {
      setVouchers([])
    }
  }, [])

  const bundleClaims = (claims) => {
    return claims.map((claim) => {
      return {
        ...claim,
        bundle: true,
      }
    })
  }

  const changeExternalClaims = useCallback(async (snap) => {
    if (snap) {
      setExternalClaims(await docsToListClaims(snap))
    } else {
      setExternalClaims([])
    }
  }, [])

  const changeSubVoucherStatus = useCallback((id, subID, status) => {
    setVouchers((list) =>
      list.map((voucher) => {
        if (voucher.id === id) {
          const updatedSubVoucher = voucher.subVouchers.map((subVoucherData) =>
            subVoucherData.id === subID ? { ...subVoucherData, status } : subVoucherData,
          )
          return {
            ...voucher,
            subVouchers: updatedSubVoucher,
          }
        } else {
          return voucher
        }
      }),
    )
  }, [])

  const changeStatus = useCallback((id, status) => {
    setVouchers((list) =>
      list.map((voucher) => (voucher.id === id ? { ...voucher, status } : voucher)),
    )
  }, [])

  const editVoucher = useCallback(
    async (id, status) => {
      try {
        await collection('vouchers').doc(id).update({ status })
        changeStatus(id, status)
        return true
      } catch (error) {
        changeStatus(id, 'error')
        return false
      }
    },
    [changeStatus],
  )

  const editSubVoucher = useCallback(
    async (id, subID, status) => {
      try {
        await collection('vouchers').doc(id).collection('subVouchers').doc(subID).update({ status })
        changeSubVoucherStatus(id, subID, status)
        return true
      } catch (error) {
        changeSubVoucherStatus(id, subID, 'error')
        return false
      }
    },
    [changeStatus],
  )

  const cancelVoucher = useCallback(
    (id) => {
      editVoucher(id, 'cancelled')
      if (window?.Intercom) {
        window.Intercom('trackEvent', 'Voucher Canceled', {
          voucherID: id,
        })
      }
    },
    [editVoucher],
  )

  const redeemVoucher = useCallback(
    (id, subID) => {
      console.log('redeemVoucher', id, subID)
      let editVoucherResult = null

      if (subID) {
        console.log('Redeeming subVoucher')
        editVoucherResult = editSubVoucher(id, subID, 'redeemed')
      } else {
        editVoucherResult = editVoucher(id, 'redeemed')
      }
      if (editVoucherResult) {
        if (window?.Intercom) {
          window.Intercom('trackEvent', 'Voucher Redeemed', {
            voucherID: id,
          })
        }
      }
      return editVoucherResult
    },
    [editVoucher],
  )

  const unredeemVoucher = useCallback(
    async (id) => {
      editVoucher(id, 'purchased')
      if (window?.Intercom) {
        window.Intercom('trackEvent', 'Voucher UnRedeemed', {
          voucherID: id,
        })
      }
    },
    [editVoucher],
  )
  const value = {
    vouchers,
    changeVouchers,
    cancelVoucher,
    redeemVoucher,
    unredeemVoucher,
    setVouchers,
    vouchersLoading,
    setVouchersLoading,
    externalClaims,
    changeExternalClaims,
    setExternalClaims,
    externalClaimsLoading,
    setExternalClaimsLoading,
  }
  return <Vouchers.Provider value={value}>{children}</Vouchers.Provider>
}

VouchersProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default VouchersProvider
