import firebase from 'firebase/compat/app'
import { v4 as uuidv4 } from 'uuid'
import { collection } from '@app/firebase/firestore'

const useChat = () => {
  const formatMessages = (snap) => {
    return snap.docs.map((each) => {
      try {
        const messageID = each.id
        const { sentAt, ...data } = each.data()
        const date = sentAt.toDate()
        const timestamp = date.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        })
        return { ...data, messageID, date, sentAt: timestamp }
      } catch (error) {
        console.log(error)
        return null
      }
    })
  }

  const subscribeToChat = ({ chatID, callback }) => {
    try {
      console.log('subscribing to chat')
      const unsubscribe = collection('chats')
        .doc(chatID)
        .collection('messages')
        .orderBy('sentAt', 'desc')
        .onSnapshot((snap) => {
          const messages = formatMessages(snap)
          callback(messages)
        })
      return unsubscribe
    } catch (error) {
      console.error('  ** error subscribing to chat')
      console.log(error)
      return null
    }
  }

  const sendMessage = async ({
    chatID,
    message = '',
    profileData,
    currentFile = [],
    currentImage = {},
    fileType,
  }) => {
    const { id, firstname, organizationName } = profileData

    if (!chatID || !id) {
      console.log('Missing chatID, message, or user id')
      console.log(`chatID: ${chatID}, message: ${message}, user id: ${id}`)
      return null
    }

    if (currentImage?.file && fileType === 'image') {
      const { file } = currentImage
      const storageRef = firebase.storage().ref()
      const fileRef = storageRef.child(`chat/${chatID}/${uuidv4()}`)
      await fileRef.put(file)
      const url = await fileRef.getDownloadURL()

      const messageBody = {
        content: {
          url,
          fileName: file.name,
          fileType,
          text: message || '',
        },
        sentAt: firebase.firestore.Timestamp.now(),
        sentById: id,
        sentByName: firstname || organizationName,
        type: 'image',
      }

      await collection('chats').doc(chatID).collection('messages').add(messageBody)

      console.log('Success - image')

      return messageBody
    }

    if (currentFile && fileType === 'file') {
      const file = currentFile
      const storageRef = firebase.storage().ref()
      const fileRef = storageRef.child(`chat/${chatID}/${uuidv4()}`)
      await fileRef.put(file)
      const url = await fileRef.getDownloadURL()

      const messageBody = {
        content: {
          url,
          fileName: file.name,
          fileType,
          text: message || '',
        },
        sentAt: firebase.firestore.Timestamp.now(),
        sentById: id,
        sentByName: firstname || organizationName,
        type: 'file',
      }

      await collection('chats').doc(chatID).collection('messages').add(messageBody)

      console.log('Success - file')
      return messageBody
    }

    if (message && message.length > 0) {
      const messageBody = {
        content: {
          text: message,
        },
        sentAt: firebase.firestore.Timestamp.now(),
        sentById: id,
        sentByName: firstname || organizationName,
        type: 'text',
      }

      console.log(messageBody)
      console.log(chatID)

      await collection('chats').doc(chatID).collection('messages').add(messageBody)

      console.log('Success - text')
      return messageBody
    }
    return null
  }

  return {
    subscribeToChat,
    formatMessages,
    sendMessage,
  }
}

export default useChat
