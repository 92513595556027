import PropTypes from 'prop-types'
import styled from 'styled-components'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import Typography from '../Typography'

import { useWhiteLabelingContext } from '@app/context/WhiteLabelingContext'

const StyledFormControl = styled(FormControl)`
  background: ${(props) => props.theme.palette.background.paper};
  width: 100%;
  input {
    background: ${(props) => props.theme.palette.background.paper};
    border-radius: ${(props) => props.theme.shape.borderRadius}px;
  }
`

const SelectField = ({
  helperText = '',
  label,
  text = '',
  options,
  required = false,
  size = 'medium',
  margin = 'normal',
  titleSelect = false,
  display = '',
  ...props
}) => {
  const { planColorPrimary } = useWhiteLabelingContext()

  return (
    <StyledFormControl
      variant='outlined'
      margin={margin}
      error={!!helperText}
      required={required}
      size={size}
      sx={
        display !== 'standard'
          ? {
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: planColorPrimary,
                },
                '&:hover fieldset': {
                  borderColor: planColorPrimary,
                },
                '&.Mui-focused fieldset': {
                  borderColor: planColorPrimary,
                },
              },
              '& .MuiInputLabel-root': {
                color: planColorPrimary,
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: planColorPrimary,
              },
            }
          : undefined
      }
    >
      <InputLabel>{label}</InputLabel>
      <Select label={label || text} {...props}>
        {options.map((each) => (
          <MenuItem key={each.value} value={each.value}>
            {titleSelect ? (
              <Typography
                variant='h3'
                color='primary'
                weight='light'
                style={{
                  marginBottom: '0px',
                }}
              >
                {each.label}
              </Typography>
            ) : (
              each.label
            )}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </StyledFormControl>
  )
}

SelectField.propTypes = {
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
  text: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  required: PropTypes.bool,
  size: PropTypes.string,
  margin: PropTypes.string,
  titleSelect: PropTypes.bool,
  display: PropTypes.string,
}

export default SelectField
