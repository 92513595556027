import { useState } from 'react'
import { Button } from '@mui/material'
import Icon from '@app/stories/Icons'
import Typography from '@app/stories/Typography'
import GeneralModal from '@app/containers/GeneralModal'
import { Box, List, ListItem, CircularProgress } from '@mui/material'

const denyAcceptReasons = ['pending', 'accepted', 'success']

const customCloseComponent = (handleAccept, shareStatus, loading) => {
  return (handleClose) => (
    <Box
      display='flex'
      flexDirection='row'
      justifyContent='center'
      alignItems='center'
      style={{ marginTop: '16px' }}
    >
      <Button
        variant='outlined'
        size='small'
        style={{ textTransform: 'none', display: 'flex', alignItems: 'center', marginRight: '8px' }}
        onClick={() => {
          handleClose()
        }}
      >
        <Icon name='close' />
        <Typography variant='body2' style={{ marginLeft: '8px', marginRight: '8px' }}>
          Close
        </Typography>
      </Button>
      <Button
        variant='contained'
        size='small'
        style={{ textTransform: 'none', display: 'flex', alignItems: 'center' }}
        disabled={loading || denyAcceptReasons.includes(shareStatus)}
        onClick={() => {
          handleAccept()
        }}
      >
        <Icon name='check' />
        <Typography variant='body2' style={{ marginLeft: '8px', marginRight: '8px' }}>
          Accept
        </Typography>
      </Button>
    </Box>
  )
}

const ShareClaimsModal = ({
  buttonText = 'Share Claims',
  headerText = 'Granting access to view claims',
  plan = {},
  memberInfo = {},
}) => {
  const [loading, setLoading] = useState(false)
  const [shareStatus, setShareStatus] = useState(null)
  return (
    <GeneralModal
      className='view-shared-claims-modal'
      headerText={headerText}
      maxWidth='lg'
      customButton={
        <Button
          variant='outlined'
          size='small'
          style={{ textTransform: 'none', display: 'flex', alignItems: 'center' }}
        >
          <Icon name='assignmentOutlined' />
          <Typography variant='body2' style={{ marginLeft: '8px' }}>
            {buttonText}
          </Typography>
        </Button>
      }
      customClose={customCloseComponent(
        async () => {
          setLoading(true)
          const { status, shareStatus } = await plan
            .handleGrantClaimsShare(memberInfo.memberId)
            .catch((e) => {
              console.error(e)
              return 'error'
            })

          if (status === 'error') {
            setLoading(false)
            setShareStatus('error')
            return status
          }

          setLoading(false)
          setShareStatus(shareStatus)
          return status
        },
        shareStatus,
        loading,
      )}
      allowClickAway={true}
    >
      <Box p={2}>
        {loading ? (
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            style={{ marginTop: '16px' }}
          >
            <CircularProgress />
          </Box>
        ) : shareStatus === 'error' ? (
          <Typography variant='body1'>Error granting plan subscriber access to claims.</Typography>
        ) : shareStatus === 'accepted' ? (
          <Typography variant='body1'>Access to claims granted.</Typography>
        ) : (
          <>
            <Typography variant='body1'>
              Your plan subscriber has requested to access to view your claims.
            </Typography>
            <Typography variant='body1'>
              If you accept the share request they will have access to the following.
            </Typography>
            <List>
              <ListItem>
                <Icon name='bullet' />
                Provider Name
              </ListItem>
              <ListItem>
                <Icon name='bullet' />
                Treatment Name
              </ListItem>
              <ListItem>
                <Icon name='bullet' />
                Rendered Date
              </ListItem>
              <ListItem>
                <Icon name='bullet' />
                Amount
              </ListItem>
            </List>
          </>
        )}
      </Box>
    </GeneralModal>
  )
}

export default ShareClaimsModal
