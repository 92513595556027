import { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import TextField from '@app/stories/TextField'
import Button from '@app/stories/Button'
import Icon from '@app/stories/Icons'

import { useWhiteLabelingContext } from '@app/context/WhiteLabelingContext'

const SignInForm = ({ onSubmit, errors = {} }) => {
  const [passwordVisible, setPasswordVisible] = useState(false)

  const { planColorPrimary } = useWhiteLabelingContext()

  return (
    <form title='sign-in-form' onSubmit={onSubmit}>
      <TextField
        required
        name='email'
        type='email'
        label='Email address'
        placeholder='Email address'
        display='standard'
        inputProps={{
          title: 'email',
        }}
        error={!!errors.email}
        helperText={errors.email}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: planColorPrimary,
            },
            '&:hover fieldset': {
              borderColor: planColorPrimary,
            },
            '&.Mui-focused fieldset': {
              borderColor: planColorPrimary,
            },
          },
          '& .MuiInputLabel-root': {
            color: planColorPrimary,
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: planColorPrimary,
          },
        }}
      />
      <Box position='relative'>
        <TextField
          required
          name='password'
          type={passwordVisible ? 'text' : 'password'}
          label='Password'
          placeholder='Password'
          display='standard'
          inputProps={{
            title: 'password',
          }}
          error={!!errors.password}
          helperText={errors.password}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: planColorPrimary,
              },
              '&:hover fieldset': {
                borderColor: planColorPrimary,
              },
              '&.Mui-focused fieldset': {
                borderColor: planColorPrimary,
              },
            },
            '& .MuiInputLabel-root': {
              color: planColorPrimary,
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: planColorPrimary,
            },
          }}
        />
        <Box
          position='absolute'
          right={0}
          top={0}
          bottom={0}
          mt={2}
          mb={2}
          color={planColorPrimary}
        >
          <Button variant='icon' onClick={() => setPasswordVisible(!passwordVisible)}>
            <Icon color='inherit' name={passwordVisible ? 'visibility' : 'visibilityOff'} />
          </Button>
        </Box>
      </Box>
      <Box mt={2}>
        <Button
          type='submit'
          variant='unstyled'
          id='signInButton'
          fullWidth
          style={{
            backgroundColor: planColorPrimary,
            color: 'white',
            whiteSpace: 'nowrap',
          }}
        >
          Login
        </Button>
      </Box>
    </form>
  )
}

SignInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
  }),
}

export default SignInForm
