import { useState } from 'react'
import { Button, CircularProgress } from '@mui/material'
import Icon from '@app/stories/Icons'
import Typography from '@app/stories/Typography'
import GeneralModal from '@app/containers/GeneralModal'
import Box from '@mui/material/Box'

const denyRequestReasons = ['pending', 'accepted', 'success']

const customCloseComponent = (handleRequest, shareStatus, loading) => {
  return (handleClose) => (
    <Box
      display='flex'
      flexDirection='row'
      justifyContent='center'
      alignItems='center'
      style={{ marginTop: '16px' }}
    >
      <Button
        variant='outlined'
        size='small'
        style={{ textTransform: 'none', display: 'flex', alignItems: 'center', marginRight: '8px' }}
        onClick={() => {
          handleClose()
        }}
      >
        <Icon name='close' />
        <Typography variant='body2' style={{ marginLeft: '8px', marginRight: '8px' }}>
          Close
        </Typography>
      </Button>
      {shareStatus !== 'pending' && (
        <Button
          size='small'
          variant='contained'
          disabled={loading || denyRequestReasons.includes(shareStatus)}
          style={{
            textTransform: 'none',
            display: 'flex',
            alignItems: 'center',
            marginLeft: '8px',
          }}
          onClick={handleRequest}
        >
          <Icon name='check' />
          <Typography variant='body2' style={{ marginLeft: '8px', marginRight: '8px' }}>
            Confirm
          </Typography>
        </Button>
      )}
    </Box>
  )
}

const capitalizeFirstLetter = (string) => {
  return string.replace(/\b\w/g, (char) => char.toUpperCase())
}

const RequestClaimsShareModal = ({
  buttonText = 'Request Access',
  headerText = 'Requesting view access to claims',
  plan = {},
  memberInfo = {},
}) => {
  const [requestStatus, setRequestStatus] = useState(memberInfo.shareStatus)
  const [loading, setLoading] = useState(false)

  const memberName = memberInfo.name
    ? capitalizeFirstLetter(memberInfo.name)
    : `${capitalizeFirstLetter(memberInfo.firstname || '')} ${capitalizeFirstLetter(memberInfo.lastname || '')}`.trim()

  return (
    <GeneralModal
      className='request-claims-share-modal'
      headerText={headerText}
      maxWidth='lg'
      customButton={
        <Button
          variant='outlined'
          size='small'
          style={{ textTransform: 'none', display: 'flex', alignItems: 'center' }}
        >
          <Icon name='assignmentOutlined' />
          <Typography variant='body2' style={{ marginLeft: '8px' }}>
            {buttonText}
          </Typography>
        </Button>
      }
      customClose={customCloseComponent(
        async () => {
          setLoading(true)
          const { status, shareStatus } = await plan
            .handleRequestClaimsShare(memberInfo.memberId)
            .catch((e) => {
              console.error(e)
              return 'error'
            })

          if (status === 'error') {
            setLoading(false)
            setRequestStatus('error')
            return status
          }

          setLoading(false)
          setRequestStatus(shareStatus)
          return status
        },
        requestStatus,
        loading,
      )}
      allowClickAway={true}
    >
      <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
        {requestStatus === 'not-shared' && (
          <>
            {memberInfo.age <= 16 ? (
              <Typography variant='body1' align='center'>
                This member is under 16.
                <br />
                Their claims will automatically be approved for sharing upon request.
              </Typography>
            ) : (
              <>
                <Typography variant='body1'>
                  {memberName} has not shared their claims with you.
                </Typography>
                <Typography variant='body1'>
                  Would you like to request view access to their claims?
                </Typography>
              </>
            )}
          </>
        )}
        {requestStatus === 'pending' && (
          <Typography variant='body1'>
            {memberName} has not yet responded to your request to view their claims.
          </Typography>
        )}
        {requestStatus === 'success' && (
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            style={{ marginTop: '16px' }}
          >
            {memberInfo.age <= 16 ? (
              <Typography variant='body1' align='center'>
                The request to view {memberName}&apos;s claims has been automatically approved.
                Reload this page to view their claims.
              </Typography>
            ) : (
              <Typography variant='body1' align='center'>
                The request to view {memberName}&apos;s claims has been sent.
                <br />
                They will need to approve your request before you can view their claims.
                {memberInfo.claimed && (
                  <Typography variant='body1' align='center'>
                    An email notification has been sent with instructions on how to respond to the
                    request.
                  </Typography>
                )}
              </Typography>
            )}
          </Box>
        )}
        {requestStatus === 'error' && (
          <Typography variant='body1'>
            There was an error sending your request at this time.
          </Typography>
        )}
        {loading && (
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            style={{ marginTop: '16px' }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </GeneralModal>
  )
}

export default RequestClaimsShareModal
