import { useEffect, useMemo, useState } from 'react'
import { Box, CircularProgress } from '@mui/material'
import { retrieveFiretableImageURL } from '@app/libs/formatters'
import frontCard from '@app/assets/images/memberCards/DEFAULT_member_card.svg?no-inline'
import frontDentalCard from '@app/assets/images/memberCards/MCC_dental_card.svg?no-inline'
import backCard from '@app/assets/images/memberCards/MCC_member_card_back.png'
import backDentalCard from '@app/assets/images/memberCards/MCC_dental_back_card.png'
import PatientMemberCardRenderer from '@app/components/PatientDashboard/PlansTab/memberCards/PatientMemberCardRenderer'
import { drawMCCDentalCard, drawDefaultCard, drawMCCCard } from '@app/utils/planUtil'

// Bad code to figure out is its MCC health plan. we shouldn't be doing plan specific checks in the code. it should now know what MCC is
// correct way is to just referece the draw function in the db, using a plan ID, since essentially isMCCHealthPlanis used for drawing only
const isMCCHealthPlan = (planDomain) => {
  return planDomain === 'mcchealthplan.com'
}
// 1. add plan?.planData?.planDesign?.designType?.typeOfPlan to all plans
// 2. add memberCardBackSource to all plans that have a back card
// 3. add memberCardkSource to all plans that have a front card
// then we can remove the planType useMemo and the planMap

const plansTypeMap = {
  // ALL DEFAULTS SHOULD BE REMOVED ONCE WE EXECUTE ON THE ABOVE STEP
  dental: {
    draw: () => {},
    drawMCC: drawMCCDentalCard,
    drawMCCBack: () => {}, // dental card back has no MCC back drawing, its an image, but following API format so sending in empty functoin
    drawBack: () => {}, // dental card back has no drawing, its an image, but following API format so sending in empty functoin

    // default backgrouns for  front dental card. setting it to MCC, but that shouold be in memebrsource
    defaultFront: frontDentalCard,
    // update this url with an svg that will be added to memberBackCardSource
    defaultBack: backDentalCard,
  },
  medical: {
    draw: drawDefaultCard,
    drawMCC: drawMCCCard,
    drawMCCBack: () => {}, // medical card back has no MCC drawing for now, its an image, but following API format so sending in empty functoin
    drawBack: () => {}, // medical card back has no drawing for now, its an image, but following API format so sending in empty functoin
    defaultFront: frontCard, // remove front card in future when all plans have cardSource
    // update this url with an svg that will be added to memberBackCardSource
    defaultBack: backCard,
  },
}

const fetchImage = (source, defaultCard) => {
  if (source && source.length) {
    return retrieveFiretableImageURL(source)
  }
  return defaultCard
}

const PatientMemberCards = ({ profile, uid, plan, planID }) => {
  const [frontCardImage, setFrontCardImage] = useState(null)
  const [backCardImage, setBackCardImage] = useState(null)

  const planData = plan?.planData || {}
  const { memberCardSource, memberBackCardSource, customDomain } = planData
  const { firstname, lastname } = profile || {}

  const planType = useMemo(() => {
    if (!plan) return false
    return planData.planDesign?.designType?.typeOfPlan || 'medical'
  }, [plan])

  const frontCardTitle = useMemo(() => {
    return `${firstname}_${lastname}_Member_${planType}_Card${Date.now().toString(36).slice(-5)}`
  }, [profile, planType])

  const backCardTitle = useMemo(() => {
    return `${firstname}_${lastname}_MemberBack_${planType}_Card${Date.now().toString(36).slice(-5)}`
  }, [profile, planType])

  useEffect(() => {
    if (
      planType &&
      isMCCHealthPlan(customDomain) // only mcc dental or medical has back cards for now. remove once we add to memberBackCardSource
    ) {
      const backImage = fetchImage(memberBackCardSource, plansTypeMap[planType]?.defaultBack)
      setBackCardImage(backImage)
    }

    if (planType) {
      const frontImage = fetchImage(memberCardSource, plansTypeMap[planType]?.defaultFront)
      setFrontCardImage(frontImage)
    }
  }, [plan, planType])

  if (!planType) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box position='relative' display='inline-block'>
      {frontCardImage && (
        <PatientMemberCardRenderer
          firstName={firstname}
          lastName={lastname}
          memberId={plan.external_id || uid} // remove UID once https://mishe.atlassian.net/browse/SCRUM-203 done
          groupId={plan.group_id}
          planID={planID}
          planData={planData}
          draw={
            isMCCHealthPlan(customDomain) // bad code. it should not have any awareness of the plan. remove once we add all correct data to card sources
              ? plansTypeMap[planType].drawMCC
              : plansTypeMap[planType].draw
          }
          cardBackground={frontCardImage}
          cardTitle={frontCardTitle}
        />
      )}
      {backCardImage && (
        <PatientMemberCardRenderer
          draw={
            isMCCHealthPlan(customDomain) // leaving for consistency. there is no drawing done on dental cards for now, but adding in for readability from plans map
              ? plansTypeMap[planType].drawMCCBack
              : plansTypeMap[planType].drawBack
          }
          cardBackground={backCardImage}
          cardTitle={backCardTitle}
        />
      )}
    </Box>
  )
}

export default PatientMemberCards
