import React, { useEffect, useState, useRef } from 'react'
import VoucherDetailsControlBar from '@app/components/VoucherDetails/VoucherDetailsControlBar'
import ExternalClaimDetail from '@app/components/ExternalClaimDetails/ExternalClaimDetail'
import AddNoteModal from '@app/containers/AddNoteModalContainer'
import AddOrderModal from '@app/containers/AddOrderModalContainer'
import { useVoucherDetails } from '@app/context/VoucherDetails'
import { useLocation, useParams } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Loader from '@app/stories/Loader'
import { getVoucher, getSubVoucher, getExternalClaim } from '@app/utils/voucherUtils'
import { useCurrentUser } from '@app/context/CurrentUser'

const VoucherDetailsContainer = () => {
  const { voucherId, subvoucherId } = useParams()
  const { voucher, setVoucher, externalClaim, setExternalClaim } = useVoucherDetails()
  const location = useLocation()
  const search = location.search
  const { profileData } = useCurrentUser()
  const [error, setError] = useState(null)

  const isExternalClaim = new URLSearchParams(search).get('e') === '1'

  const prevVoucherIdRef = useRef()
  const prevSubvoucherIdRef = useRef()

  useEffect(() => {
    const fetchVoucher = async () => {
      if (voucher && !isExternalClaim) {
        // avoids refetching if the voucher data is already loaded in context
        console.log('There is a voucher ')
        if (!subvoucherId || (subvoucherId && voucher.parentUid === voucherId)) {
          console.log('subvoucherId', subvoucherId)
          return
        }
      }

      setError(null)

      try {
        if (isExternalClaim) {
          const externalClaimData = await getExternalClaim(voucherId, profileData.id)
          console.log('externalClaimData', externalClaimData)
          if (externalClaimData?.length > 0) setExternalClaim(externalClaimData)
          else setExternalClaim([])
        } else if (subvoucherId) {
          console.log('subvoucherId', subvoucherId)
          const subVoucherData = await getSubVoucher(voucherId, subvoucherId)
          if (!subVoucherData.empty)
            setVoucher({ ...subVoucherData.data(), id: subvoucherId, parentUid: voucherId })
          else setVoucher([])
        } else {
          console.log('voucherId', voucherId)
          const voucherData = await getVoucher(voucherId)
          if (!voucherData.empty) setVoucher({ ...voucherData.data(), id: voucherId })
          else setVoucher([])
        }
      } catch (err) {
        console.error('Error fetching voucher:', err)
        setError(err.message)
      }
    }

    console.log('calling fetchVoucher')
    // if (prevVoucherIdRef.current !== voucherId || prevSubvoucherIdRef.current !== subvoucherId) {
    fetchVoucher()
    // }

    prevVoucherIdRef.current = voucherId
    prevSubvoucherIdRef.current = subvoucherId
  }, [voucherId, subvoucherId, setVoucher, setExternalClaim, isExternalClaim, voucher])

  if (error) {
    return <Typography>Can't load voucher, contact Support</Typography>
  }

  if (!voucher && !externalClaim) {
    return <Loader />
  }

  return (
    <>
      {isExternalClaim ? (
        <>
          <ExternalClaimDetail />
        </>
      ) : (
        <>
          <VoucherDetailsControlBar />
          <AddNoteModal />
          <AddOrderModal />
        </>
      )}
    </>
  )
}

export default VoucherDetailsContainer
