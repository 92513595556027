import firebase from './app'
import 'firebase/compat/functions'

// Connect Functions emulator if enabled
const functionsEmulator = import.meta.env.VITE_FIREBASE_EMULATOR_FUNCTIONS
if (functionsEmulator) {
  const [host, port] = functionsEmulator.split(':')
  firebase.functions().useEmulator(host, Number(port))
}

export const call = (name, options = {}) => firebase.functions().httpsCallable(name)(options)

export const sendVerificationEmail = async (info) => {
  const response = await call('auth-resendVerificationEmail', info)
  return response.data
}

export const createOrderNetwork = async (info) => {
  const response = await call('networkPayments-createOrder', info)
  return response.data
}

export const createPaymentIntentNetwork = async (info) => {
  const response = await call('networkPayments-createPaymentIntent', info)
  return response.data
}

export const attachCustomer = async (info) => {
  const response = await call('networkPayments-attachCustomer', info)
  return response.data
}

export const createPaymentIntentMedication = async (info) => {
  const response = await call('networkPayments-createPaymentIntentMedication', info)
  return response.data
}

export const createMakoCard = async (info) => {
  const response = await call('makoRx-createCard', info)
  return response.data
}

export const createVideoChatRoom = async (info) => {
  const response = await call('videoChat-createVideoChatRoom', info)
  return response.data
}

export const createAccount = async (info) => {
  const response = await call('auth-createAccount', info)
  return response.data
}

export const createMedOrder = async (info) => {
  const response = await call('emrOrders-createMedicationOrder', info)
  return response.data
}

export const sendProviderEnrollmentNotification = async (info) => {
  const response = await call('providers-enrollmentSubmission', info)
  return response.data
}

export const getDiRxOrderStatus = async (info) => {
  const response = await call('emrOrders-getTrackingDetails', info)
  return response.data
}

export const createChatVoucher = async (info) => {
  const response = await call('chats-createChatVoucher', info)
  return response.data
}

export const createStripeAccount = async (accountDetail) => {
  console.log('accountDetail', accountDetail)
  const response = await call('stripe-createStripeConnect', accountDetail)
  return response.data
}

export const stripePaymentEmbedded = async (stripeConnectDetails) => {
  const response = await call('stripe-stripePaymentEmbedded', stripeConnectDetails)
  return response.data
}

export const createCustomerAttachPayment = async ({ email, customerId }) => {
  const response = await call('stripe-createCustomerAttachPayment', {
    email,
    customerId,
  })
  return response.data
}

export const makeDefaultPaymentMethod = async ({ customerId, paymentMethodId }) => {
  const response = await call('stripe-makeDefaultPaymentMethod', {
    customerId,
    paymentMethodId,
  })
  return response.data
}

export const saveSetupIntentCustomer = async ({ setupIntentInfo }) => {
  const response = await call('stripe-saveSetupIntentCustomer', {
    setupIntentInfo,
  })
  return response.data
}
export const verifyMicroDeposit = async ({ setupIntentId, microDepositCode }) => {
  const response = await call('stripe-verifyMicroDeposit', {
    setupIntentId,
    microDepositCode,
  })
  return response.data
}

export const retrieveCustomerDetails = async ({ customerId }) => {
  const response = await call('stripe-retrieveCustomerDetails', {
    customerId,
  })
  return response.data
}

export const createChargeInvoice = async ({ customerId, amount }) => {
  const response = await call('stripe-createChargeInvoice', {
    customerId,
    amount,
  })
  return response.data
}

export const getVouchersData = async ({ vouchersId }) => {
  const response = await call('vouchers-getVouchersData', {
    vouchersId,
  })
  console.log('response', response)
  return response.data
}

export const enrollmentFormData = async ({
  firstname,
  lastname,
  email,
  phone,
  password,
  gender,
  last4ssn,
  terms,
  hipaa,
  dob,
  planId,
}) => {
  const response = await call('auth-enrollment', {
    firstname,
    lastname,
    email,
    phone,
    password,
    gender,
    last4ssn,
    terms,
    hipaa,
    dob,
    planId,
  })
  console.log('response', response)
  return response.data
}

export const requestClaimsShare = async ({ planId, requestedMemberId }) => {
  const response = await call('networks-requestClaimsShare', {
    planId,
    requestedMemberId,
  })
  return response.data
}

export const acceptClaimsShare = async ({ planId, requestorMemberId }) => {
  const response = await call('networks-acceptClaimsShare', {
    planId,
    requestorMemberId,
  })
  return response.data
}

export const getSharedClaims = async ({ planId, requestedMemberId }) => {
  const response = await call('networks-getSharedClaims', {
    planId,
    requestedMemberId,
  })
  return response.data
}

export const setTimezoneFromCoordinates = async ({ lng, lat, organizationId }) => {
  const response = await call('auth-timezoneFromCoordinates', { lng, lat, organizationId })
  return response.data
}
