// https://firebase.google.com/docs/auth/custom-email-handler
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'
import auth from '@app/firebase/auth'
// hooks
import { useCurrentUser } from '@app/context/CurrentUser'
import useNotification from '@app/hooks/useNotification'
// components
import Notification from '@app/components/NotificationBox'
import Loader from '@app/stories/Loader'
import Container from '@app/stories/Container'
import Section from '@app/stories/Section'
import Button from '@app/stories/Button'

const SUCCESS_VERIFICATION = 'Your e-mail has been verified'
const FAILED_VERIFICATION = 'Verification code is invalid or expired'
const WRONG_ACTION_MODE = 'Unknown authentication action mode provided'

const getParams = (location) => {
  const params = new URLSearchParams(location.search)
  // get the action to complete
  const mode = params.get('mode')
  // get the one-time code from the query parameter
  const actionCode = params.get('oobCode')
  // (optional) Get the continue URL from the query parameter if available
  const continueUrl = params.get('continueUrl') || '/dashboard'
  // (optional) Get the language code if available
  const lang = params.get('lang') || 'en'

  return { mode, actionCode, continueUrl, lang }
}

const AuthAction = () => {
  const { refresh } = useCurrentUser()
  const location = useLocation()
  const { value, error, success } = useNotification()

  useEffect(() => {
    const { mode, actionCode } = getParams(location)
    // handle the user management action
    if (mode === 'verifyEmail') {
      auth()
        .applyActionCode(actionCode)
        .then(refresh) // try to refresh user's id token
        .then(() => success(SUCCESS_VERIFICATION)) // Email address has been verified
        .catch(() => error(FAILED_VERIFICATION)) // code is invalid or expired
    } else {
      error(WRONG_ACTION_MODE)
    }
  }, [location, error, success, refresh])

  return (
    <Section>
      <Container>
        {!value && <Loader text='Authenticating' />}
        {value && (
          <>
            <Notification autoHide={false} {...value} />
            <Box display='flex' justifyContent='center' mt={2}>
              <Button to='/dashboard'>go to dashboard</Button>
            </Box>
          </>
        )}
      </Container>
    </Section>
  )
}

export default AuthAction
