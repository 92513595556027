import PropTypes from 'prop-types'
import { TableCell, TableRow } from '@mui/material'
import VoucherRenderElements from '@app/components/VoucherRenderElements'

/**
 * ResponsiveVoucherRow Component This component renders a table's voucher specific rows,
 * DYNAMICALLY, based on header values formatted a certain way (see below) Make sure you follow the
 * API correctly when using this
 *
 * Its built to be responsive, displaying data in a table format on larger screens and switching to
 * a cards display vertically stacked smaller mobile screens
 *
 * @example
 *   // Example usage:
 *   const headers = [
 *     { id: 'info', label: 'Info', type: 'iconOpenClose', align: 'center' },
 *     { id: 'organization', label: 'Clinic', type: 'link', align: 'left' },
 *     { id: 'service', label: 'Service', type: 'text', align: 'left' },
 *     { id: 'appDate', label: 'Appt Date', type: 'date', align: 'left' },
 *     { id: 'status', label: 'Status', type: 'chip', align: 'center' },
 *   ]
 *
 * @param {Array} headers - An array of objects defining the structure and type of each table
 *   column.
 * @param {Object} voucher - The data object for the current row, containing the values for each
 *   header field.
 * @param {Boolean} isActive - Field denoting if a certain behavior is active. (such as isOpen)
 * @param {Function} handleActivate - Function to call when we activate the user interaction
 *   behavior (such as click to open)
 * @param {Function} handleDeactivate - Function to call when we deactivate the user interaction
 *   behavior (such as click to open)
 * @param {String} backgroundColor - Optional coloring to applied to the rows
 */

const ResponsiveVoucherRow = ({
  headers,
  voucher,
  isActive,
  handleActivate,
  handleDeactivate,
  backgroundColor = '#FFFFFF',
}) => {
  return (
    <TableRow style={{ backgroundColor }} className='responsive-row'>
      {headers.map(({ id, label, type, align }) => (
        <TableCell key={id} data-label={label} align={align}>
          <VoucherRenderElements
            type={type}
            params={{
              id,
              voucher,
              isActive,
              ctaActions: { activate: handleActivate, deactivate: handleDeactivate },
            }}
          />
        </TableCell>
      ))}
    </TableRow>
  )
}

ResponsiveVoucherRow.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      align: PropTypes.oneOf(['left', 'center', 'right']),
    }),
  ).isRequired,
  voucher: PropTypes.object.isRequired,
  isActive: PropTypes.bool,
  handleActivate: PropTypes.func,
  handleDeactivate: PropTypes.func,
  backgroundColor: PropTypes.string,
}

export default ResponsiveVoucherRow
