import { useMemo } from 'react'
import { useCurrentUser, useCurrentClinic } from '@app/context/CurrentUser'
import Box from '@mui/material/Box'
import SignOutButton from '@app/containers/SignOutButton'
import NavLink from '@app/stories/NavLink'
import SideMenu from './SideMenu'

import useSignInUpModals from '@app/hooks/useSignInUpModals'
import SignInModal from './SignInModal'
import Typography from '@app/stories/Typography'
import NavButtonContainer from '@app/containers/NavButtonContainer'
import DropDownMenu from './DropDownMenu'

const TopCorner = ({
  primaryColor,
  secondaryColor,
  loginMethods = ['email', 'phone', 'register'],
  isPlan,
  setEnrollmentOpen,
}) => {
  const { profileData, authData } = useCurrentUser()
  const { plansAssociated: clinicPlansAssociated = {} } = useCurrentClinic()

  const { signInOpen, handleOpen } = useSignInUpModals()

  if (profileData.id === null) {
    return 'trying to authenticate...'
  }

  // Memo the sign in modal
  const signInModal = useMemo(() => {
    return (
      <SignInModal
        open={signInOpen}
        loginMethods={loginMethods}
        preventRegister={loginMethods?.includes('preEnrollment')}
        handleClose={() => handleOpen('signIn')}
        callback={() => {
          handleOpen('singIn')
        }}
        customColor={primaryColor}
      />
    )
  }, [signInOpen, handleOpen, loginMethods])

  const validPlans = useMemo(() => {
    let goodPlans = []
    const plans = authData.roles?.includes('PROVIDER')
      ? clinicPlansAssociated
      : profileData.plansAssociated
    if (plans) {
      Object.keys(plans).forEach((planID) => {
        if (
          plans[planID]?.name === 'Cost Plus Health Plan' ||
          plans[planID]?.name === 'Mishe Health Plan'
        ) {
          console.warn('Mishe Health Plan is not a valid plan', planID)
          return null
        }

        if (plans[planID]?.membershipStatus !== 'active') {
          console.warn('Plan is not active', planID)
          return null
        }

        if (!plans[planID]?.name) {
          console.warn('Plan has no name', planID)
          return null
        }

        if (!plans[planID]?.slug) {
          console.warn('Plan has no slug', planID)
          return null
        }

        goodPlans.push({
          id: planID,
          ...plans[planID],
        })
      })
    }

    return goodPlans
  }, [profileData.plansAssociated, clinicPlansAssociated, authData.roles])

  const [firstPlans, lastPlan] = useMemo(() => {
    if (validPlans.length > 1) {
      return [validPlans.slice(0, -1), validPlans.slice(-1)[0]]
    } else {
      return [validPlans, null]
    }
  }, [validPlans])

  return (
    <>
      {signInModal}
      <Box
        sx={{
          display: {
            xs: 'block',
            md: 'none',
          },
        }}
      >
        <Box color={secondaryColor} width='100%'>
          <SideMenu
            plans={validPlans}
            loginMethods={loginMethods}
            isPlan={isPlan}
            setEnrollmentOpen={setEnrollmentOpen}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: {
            xs: 'none',
            md: 'flex',
          },
        }}
      >
        {validPlans.length > 0 && (
          <DropDownMenu
            text={profileData.id ? 'My Plans' : 'Plans'}
            drawerProps={{
              style: { width: '200px' },
            }}
            variant='longer'
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
          >
            {lastPlan ? (
              <>
                {firstPlans.map((plan) => (
                  <Box key={plan.id} color={secondaryColor} width='100%'>
                    <NavLink to={`/plans/${plan.slug}`} align='left' width='full'>
                      {plan.name}
                    </NavLink>
                  </Box>
                ))}
                <Box color={secondaryColor} width='100%'>
                  <NavLink
                    to={`/plans/${lastPlan.slug}`}
                    align='left'
                    width='full'
                    style={{ borderRadius: '0 0 21.125px 21.125px' }}
                  >
                    {lastPlan.name}
                  </NavLink>
                </Box>
              </>
            ) : (
              validPlans.map((plan) => (
                <Box key={plan.id} color={secondaryColor} width='100%'>
                  <NavLink
                    to={`/plans/${plan.slug}`}
                    align='left'
                    width='full'
                    style={{ borderRadius: '0 21.125px 21.125px 21.125px' }}
                  >
                    {plan.name}
                  </NavLink>
                </Box>
              ))
            )}
          </DropDownMenu>
        )}
        {profileData.id ? (
          <>
            <Box color={secondaryColor} display='flex'>
              <NavButtonContainer color={secondaryColor}>
                <NavLink to='/dashboard' display='standard' style={{ borderRadius: '21.125px' }}>
                  <Typography variant='body1' weight='medium' color={secondaryColor}>
                    Dashboard
                  </Typography>
                </NavLink>
              </NavButtonContainer>
              <NavButtonContainer color={secondaryColor}>
                <SignOutButton color={secondaryColor} />
              </NavButtonContainer>
            </Box>
          </>
        ) : (
          <>
            <NavButtonContainer>
              <Box color={secondaryColor} width='100%'>
                <NavLink
                  onClick={() => {
                    handleOpen('singIn')
                  }}
                  display='standard'
                  style={{ borderRadius: '21.125px' }}
                >
                  Login
                </NavLink>
              </Box>
            </NavButtonContainer>
            {loginMethods.includes('register') && (
              <>
                {isPlan ? (
                  <NavButtonContainer>
                    <Box color={secondaryColor} width='100%'>
                      <NavLink
                        onClick={() => {
                          setEnrollmentOpen(true)
                        }}
                        display='standard'
                        style={{ borderRadius: '21.125px' }}
                      >
                        Activate Benefits
                      </NavLink>
                    </Box>
                  </NavButtonContainer>
                ) : (
                  <NavButtonContainer>
                    <Box color={secondaryColor} width='100%'>
                      <NavLink to='/signup' display='standard' style={{ borderRadius: '21.125px' }}>
                        Sign Up
                      </NavLink>
                    </Box>
                  </NavButtonContainer>
                )}
              </>
            )}
          </>
        )}
      </Box>
    </>
  )
}

export default TopCorner
