import { memo } from 'react'
import PropTypes from 'prop-types'
import firebase from 'firebase/compat/app'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@app/stories/Typography'
import Divider from '@app/stories/Divider'
import OrderDetailsCard from '@app/components/OrderDetailsCard'

const dateFormatOptions = {
  weekday: 'short',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}

const VoucherOrders = ({ orders }) => {
  return (
    <Box>
      <Box mt={2}>
        <Typography variant='h4' mb={2}>
          Orders
        </Typography>
      </Box>
      <Divider />
      <Box
        mt={2}
        style={{
          maxHeight: '80vh',
          overflowY: 'scroll',
        }}
      >
        <Grid container>{orders?.map((order) => OrderDetailsCard({ order }))}</Grid>
      </Box>
    </Box>
  )
}

VoucherOrders.propTypes = {
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      details: PropTypes.shape({
        medicationName: PropTypes.string,
        strength: PropTypes.string,
        count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        refills: PropTypes.shape({
          allowed: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          filled: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
      }).isRequired,
      trackingInfo: PropTypes.shape({
        trackingNumber: PropTypes.string.isRequired,
        carrier: PropTypes.string.isRequired,
      }),
      timestamp: PropTypes.instanceOf(firebase.firestore.Timestamp).isRequired,
    }),
  ),
}

export default memo(VoucherOrders)
