import JoinMishe from '@app/pages/JoinMishe'

const defaults = {
  domain: 'mishe.co',
  route: 'joinmishe',
  providerManualLink: 'https://www.canva.com/design/DAGirhWCZ7k/aaAyOiiAaB7YoL9E73Ez8A/view?embed',
}

/**
 * An array of objects defining custom domain-based routes.
 *
 * Each object in the array contains the following properties:
 *
 * - Domain {string}: The domain name for which the custom route is defined.
 * - Route {string}: The route path associated with the domain.
 * - ProviderManualLink {string|null}: A link to the provider manual, or null if not applicable.
 */
const customDomainBasedRoutes = [
  {
    domain: 'mcchealthplan.com',
    route: 'joinmishe/mcc',
    providerManualLink: null,
  },
]

/**
 * Retrieves all the routes defined in the custom domain-based routes.
 *
 * @returns {object[]} - An array of route objects from the custom domain-based routes.
 */
const getRoutes = () => {
  return customDomainBasedRoutes.map((entry) => ({
    path: entry.route,
    element: (
      <JoinMishe providerManualLink={entry.providerManualLink || defaults.providerManualLink} />
    ),
  }))
}

/**
 * Retrieves the route associated with a given domain. If no custom route is found, it returns the
 * default route. Optionally, a leading slash can be added to the route.
 *
 * @param {string} domain - The domain to look up in the custom domain-based routes.
 * @param {boolean} [prefixSlash] - Whether to prepend a leading slash to the route. Default is
 *   `false`
 * @returns {string} - The route associated with the domain, or the default route.
 */
const getRouteFromDomain = (domain, prefixSlash = false) => {
  const customDomainBasedRoute = customDomainBasedRoutes.find(
    (customDomainBasedRoute) => customDomainBasedRoute.domain === domain,
  )
  if (customDomainBasedRoute) {
    return prefixSlash ? `/${customDomainBasedRoute.route}` : customDomainBasedRoute.route
  }
  return prefixSlash ? `/${defaults.route}` : defaults.route
}

/**
 * Retrieves all the domains and routes defined in the custom domain-based routes.
 *
 * @returns {object[]} - An array of domain and route objects from the custom domain-based routes.
 */
const getDomainsAndRoutes = () => {
  return customDomainBasedRoutes.map((customDomainBasedRoute) => ({
    domain: customDomainBasedRoute.domain,
    route: customDomainBasedRoute.route,
  }))
}

export default {
  getRouteFromDomain,
  getDomainsAndRoutes,
  getRoutes,
  defaults,
  customDomainBasedRoutes,
}
