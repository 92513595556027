import { useState } from 'react'
import { query, getDocs, orderBy } from 'firebase/firestore'
import { collection, subCollectionModular } from '@app/firebase/firestore'

const useInvoicePayments = () => {
  const [invoicePaymentData, setInvoicePaymentData] = useState([])
  const [invoicePaymentDataLoading, setInvoicePaymentDataLoading] = useState(null)

  const fetchInvoicePayments = async ({ planAdminUID }) => {
    try {
      const planAdminCollection = collection('planAdmins')
      let constructQueryOrder = [orderBy('invoiceCreatedAt', 'desc')]
      setInvoicePaymentDataLoading(true)
      const planAdminDoc = await planAdminCollection
        .where('adminIDs', 'array-contains', planAdminUID)
        .limit(1)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            return { error: 'No plan admin found', code: 'no-plan-admin' }
          }
          return querySnapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              id: doc.id,
            }
          })
        })
        .catch((err) => {
          console.log(err)
          return { error: err, code: 'error-checking' }
        })

      console.log('planAdminDoc', planAdminDoc)

      const paymentQuery = query(
        subCollectionModular({
          docName: 'planAdmins',
          docId: planAdminDoc[0].id,
          subDocName: 'payments',
        }),
        ...constructQueryOrder,
      )

      const querySnapshotPaymentDoc = await getDocs(paymentQuery)

      let payoutLoop = 0
      const invoicePaymentData = []
      const totalPayouts = querySnapshotPaymentDoc.docs.length
      console.log('querySnapshotPaymentDoc', querySnapshotPaymentDoc)
      console.log('totalPayouts--', totalPayouts)

      if (querySnapshotPaymentDoc.empty === false) {
        for await (let paymentDoc of querySnapshotPaymentDoc.docs) {
          payoutLoop++

          invoicePaymentData.push({
            ...paymentDoc.data(),
            id: paymentDoc.id,
            uid: paymentDoc.id,
          })
          console.log('totalPayouts,', totalPayouts, payoutLoop)

          if (payoutLoop === totalPayouts) {
            setInvoicePaymentDataLoading(false)
            setInvoicePaymentData(invoicePaymentData)
          }
        }
      }
    } catch (error) {
      console.log(error)
      setInvoicePaymentDataLoading(false)
    }
  }

  const fetchEmrOrderById = async ({ emrOrderId }) => {
    try {
      const emrOrderCollection = collection('emrOrders')
      const emrOrderDetails = await emrOrderCollection
        .doc(emrOrderId)
        .get()
        .then((doc) => {
          console.log('doc', doc.data())
          if (doc.empty) {
            return { error: 'No emrOrder Found', code: 'no-emrOrder' }
          }
          return [
            {
              ...doc.data(),
              id: doc.id,
            },
          ]
        })
        .catch((err) => {
          console.log(err)
          return { error: err, code: 'error-checking' }
        })

      if (emrOrderDetails.error) {
        return []
      } else {
        return emrOrderDetails
      }
    } catch (error) {
      console.log(error)
      setInvoicePaymentDataLoading(false)
    }
  }

  return {
    fetchInvoicePayments,
    invoicePaymentData,
    invoicePaymentDataLoading,
    fetchEmrOrderById,
  }
}

export default useInvoicePayments
