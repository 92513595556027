import { FC, ReactNode } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useCurrentUser } from '@app/context/CurrentUser'

const PrivateRoute: FC = () => {
  const { pathname, search } = useLocation()
  const { profileData } = useCurrentUser()

  let node: ReactNode = 'Loading'
  if (!profileData?.id) {
    console.log('PROFILE is anon, going to signin page')
    node = <Navigate to='/signin' state={pathname + search} replace />
  } else if (profileData.id) {
    console.log('PROFILE has an id, go to outlet')

    node = <Outlet />
  }

  return node
}

export default PrivateRoute
