import { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  InstantSearch,
  useSearchBox,
  Hits,
  useInstantSearch,
  Configure,
  Pagination,
} from 'react-instantsearch'
import Box from '@mui/material/Box'
import TextField from '@app/stories/TextField'
import Typography from '@app/stories/Typography'
import searchClient from '@app/libs/algolia'

const HitBuilder = ({ hit, HitComponent, hitClick = null }) => {
  const { indexUiState } = useInstantSearch()

  return (
    <HitComponent
      key={hit.objectId}
      data={hit}
      onClick={hitClick}
      exactMatch={
        indexUiState?.query?.toString() === hit?.cptCode?.toString() && indexUiState?.query
      }
    />
  )
}

HitBuilder.propTypes = {
  hit: PropTypes.object.isRequired,
  HitComponent: PropTypes.node.isRequired,
  hitClick: PropTypes.func,
}

const CustomSearchBox = ({ searchBoxText, ...props }) => {
  const { query, refine } = useSearchBox(props)
  const { status } = useInstantSearch()
  const [inputValue, setInputValue] = useState(query)
  const isSearchStalled = status === 'stalled'

  const searchDelay = 500
  const searchTimeout = useRef(null)

  const setQuery = (newQuery) => {
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current)
    }
    setInputValue(newQuery)

    searchTimeout.current = setTimeout(() => {
      console.log('Searching!')
      refine(newQuery)
    }, searchDelay)
  }

  return (
    <div>
      <form
        action=''
        role='search'
        noValidate
        onSubmit={(event) => {
          event.preventDefault()
          event.stopPropagation()
        }}
        onReset={(event) => {
          event.preventDefault()
          event.stopPropagation()
          setQuery('')
        }}
      >
        <TextField
          style={{
            marginTop: '1rem',
            marginBottom: '20px',
          }}
          display='standard'
          autoComplete='off'
          autoCorrect='off'
          autoCapitalize='off'
          spellCheck={false}
          maxLength={512}
          type='search'
          value={inputValue}
          onChange={(event) => {
            setQuery(event.currentTarget.value)
          }}
          autoFocus
          required
          id={searchBoxText}
          label={searchBoxText}
          variant='outlined'
          fullWidth
        />
        <span hidden={!isSearchStalled}>Searching…</span>
      </form>
    </div>
  )
}

CustomSearchBox.propTypes = {
  searchBoxText: PropTypes.string.isRequired,
}

const InstantSearchContainer = ({
  HitComponent,
  hitClick = null,
  indexName,
  configuration,
  searchBoxText,
  noResultsMessage,
  variant = '',
}) => {
  const NoResultsBoundary = ({ children, fallback }) => {
    const { results } = useInstantSearch()

    // The `__isArtificial` flag makes sure not to display the No Results message
    // when no hits have been returned.

    if (!results.__isArtificial && results.nbHits === 0) {
      return (
        <>
          {fallback}
          <div hidden>{children}</div>
        </>
      )
    }

    return children
  }

  NoResultsBoundary.propTypes = {
    children: PropTypes.node.isRequired,
    fallback: PropTypes.node.isRequired,
  }

  const NoResults = () => {
    const { indexUiState } = useInstantSearch()

    return (
      <Typography variant='h3' weight='bold' align='center'>
        {noResultsMessage} {indexUiState.query}
      </Typography>
    )
  }

  return (
    <>
      <InstantSearch
        searchClient={searchClient}
        indexName={indexName}
        future={{
          preserveSharedStateOnUnmount: true,
        }}
        initialUiState={{
          [indexName]: {
            query: '',
          },
        }}
      >
        <Configure
          analytics={false}
          // if the url is mishe.co then use this UUrfH2FiTx2LjkdzUhnP
          {...configuration}
        />
        <CustomSearchBox
          searchBoxText={searchBoxText}
          style={{
            marginBottom: '20px',
          }}
        />
        {variant !== 'noPagination' && (
          <Box
            mb={3}
            width='100%'
            display='flex'
            justifyContent='center'
            style={{
              overflow: 'hidden',
            }}
          >
            <Pagination showFirst showLast showPrevious={false} showNext={false} />
          </Box>
        )}
        <NoResultsBoundary fallback={<NoResults />}>
          <Hits hitComponent={({ hit }) => HitBuilder({ hit, HitComponent, hitClick })} />
        </NoResultsBoundary>
      </InstantSearch>
    </>
  )
}

InstantSearchContainer.propTypes = {
  HitComponent: PropTypes.func.isRequired,
  hitClick: PropTypes.func,
  indexName: PropTypes.string.isRequired,
  configuration: PropTypes.object.isRequired,
  searchBoxText: PropTypes.string.isRequired,
  noResultsMessage: PropTypes.string.isRequired,
  variant: PropTypes.string,
}

export default InstantSearchContainer
