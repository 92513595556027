import { useEffect, useState } from 'react'
import Backdrop from '@mui/material/Backdrop'
import SignInFormContainer from '@app/containers/SignInForm'
import SignInFormPhoneContainer from '@app/containers/SignInFormPhone'
import Box from '@mui/material/Box'
import Button from '@app/stories/Button'
import Typography from '@app/stories/Typography'
import Link from '@app/stories/Link'
import Modal from '@app/stories/Modal'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import EmailIcon from '@mui/icons-material/Email'
import Divider from '@app/stories/Divider'

const SignInModal = ({
  open,
  handleClose,
  loginMethods = [],
  preventRegister,
  callback,
  customColor = '#652d92',
}) => {
  const [loginMethod, setLoginMethod] = useState('')

  useEffect(() => {
    if (!open) {
      setLoginMethod('')
    }
  }, [open])

  const LoginMethodsBuilder = ({ methods }) => {
    let components = []
    if (methods.includes('email') || methods.length === 0) {
      components.push(
        <Box mb={2}>
          <Button
            fullWidth
            size='medium'
            variant='unstyled'
            display='standard'
            onClick={() => {
              setLoginMethod('email')
            }}
            style={{
              backgroundColor: customColor,
              color: 'white',
              whiteSpace: 'nowrap',
            }}
          >
            <EmailIcon fontSize='medium' style={{ color: 'white', marginRight: '5px' }} />
            Login with Email
          </Button>
        </Box>,
      )
    }

    if (methods.includes('phone') || methods.length === 0) {
      components.push(
        <Box mb={2}>
          <Button
            fullWidth
            size='medium'
            variant='unstyled'
            display='standard'
            onClick={() => {
              setLoginMethod('phone')
            }}
            style={{
              backgroundColor: customColor,
              color: 'white',
              whiteSpace: 'nowrap',
            }}
          >
            <PhoneIphoneIcon fontSize='medium' style={{ color: 'white', marginRight: '5px' }} />
            Login with Phone Number
          </Button>
        </Box>,
      )
    }

    return components
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Box
        padding={1}
        sx={{
          width: { xs: '100%', sm: '400px' },
        }}
        justifyContent='center'
        alignItems='center'
      >
        <Typography variant='h2' gutterBottom align='center'>
          Login
        </Typography>
        <Divider
          style={{
            marginTop: '20px',
            marginBottom: '20px',
          }}
        />
        {loginMethod === '' && (
          <>
            <LoginMethodsBuilder methods={loginMethods} />
          </>
        )}
        {loginMethod === 'email' && (
          <>
            <SignInFormContainer callback={callback} />
            <br />
            <Box color={customColor}>
              <Button to='/reset-password' variant='unstyled' onClick={handleClose} color='inherit'>
                Forgot your password?
              </Button>
            </Box>
          </>
        )}
        {loginMethod === 'phone' && <SignInFormPhoneContainer callback={callback} />}
        {loginMethod !== '' && (
          <Box mt={2}>
            <Button
              fullWidth
              size='medium'
              display='standard'
              onClick={() => {
                setLoginMethod('')
              }}
              style={{
                border: `1px solid ${customColor}`,
                backgroundColor: 'white',
                whiteSpace: 'nowrap',
                color: customColor,
              }}
            >
              Back
            </Button>
          </Box>
        )}
        <Divider
          style={{
            marginTop: '20px',
            marginBottom: '20px',
          }}
        />
        {!preventRegister && (
          <Box mb={2}>
            <Typography variant='body2' weight='light'>
              Don&apos;t have an account?{' '}
              <Link to='/signup' onClick={handleClose} display='standard'>
                Register here.
              </Link>
            </Typography>
          </Box>
        )}
      </Box>
    </Modal>
  )
}

export default SignInModal
