import { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { collection } from '@app/firebase/firestore'
import { formatDateForUI } from '@app/libs/formatters'
import { CircularProgress } from '@mui/material'
import { useCurrentUser } from '@app/context/CurrentUser'

const getClaims = async (planID) => {
  if (!planID) return []
  const claimsRef = await collection('vouchers').where('plan.id', '==', planID).get()
  const externalClaimsRef = await collection('external-claims')
    .where('plan_info.id', '==', planID)
    .get()

  console.log('externalClaimsRef', externalClaimsRef.empty)

  externalClaimsRef.docs.map((doc) => {
    console.log('doc', doc.data())
  })

  const externalClaims = externalClaimsRef.docs.map((doc, index) => {
    const { DOCUMENT_NUM, service_info, financials, provider_info } = doc.data()

    const {
      code: cptCodes = '',
      name: serviceName = '---',
      date_of_service_initial: date = '---',
    } = service_info || {}

    const { original_charge: price = '---' } = financials || {}

    const { name: providerName = '---' } = provider_info || {}

    const options = {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }

    const priceIsNaN = isNaN(parseFloat(price))
    return {
      id: DOCUMENT_NUM || `missing-doc-num-${index}`,
      cpt: cptCodes || '---',
      treatment: serviceName || '---',
      billed: priceIsNaN
        ? '---'
        : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
            parseFloat(price),
          ),
      date: formatDateForUI(date) || 'Pending',
      clinicName: '---',
      providerName: providerName || '---',
      source: 'External',
      missingDocumentNumber: !DOCUMENT_NUM,
    }
  })

  const allClaims = [
    ...claimsRef.docs.map((doc) => {
      const { appointmentDate, organization, service } = doc.data()

      const { cptCodes = '', name: treatment = '', price = '' } = service || {}
      const { name: clinicName = '', providerName = '' } = organization || {}

      const options = {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }

      const priceIsNaN = isNaN(parseFloat(price))

      return {
        id: doc.id,
        cpt: (cptCodes && cptCodes?.join(' ')) || '---',
        treatment: treatment || '---',
        billed: priceIsNaN
          ? '---'
          : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
              parseFloat(price),
            ),
        date: formatDateForUI(appointmentDate) || 'Pending',
        clinicName: clinicName || '---',
        providerName: providerName || '---',
        source: 'Internal',
      }
    }),
    ...externalClaims,
  ]

  return allClaims
}

const ClaimsActivity = () => {
  const { profileData, managedPlans } = useCurrentUser()

  const columns = [
    { field: 'id', headerName: 'ID', width: 200 },
    { field: 'cpt', headerName: 'CPT Codes', width: 150 },
    {
      field: 'treatment',
      headerName: 'Treatment',
      width: 150,
      editable: false,
    },
    {
      field: 'billed',
      headerName: 'Billed',
      width: 150,
      editable: false,
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 110,
      editable: false,
    },
    {
      field: 'clinicName',
      headerName: 'Clinic Name',
      width: 160,
      editable: false,
    },
    {
      field: 'providerName',
      headerName: 'Provider Name',
      width: 160,
      editable: false,
    },
    {
      field: 'source',
      headerName: 'Source',
      width: 160,
      editable: false,
    },
  ]

  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    setLoading(true)
    setError(false)
    getClaims(managedPlans?.managedPlanID)
      .then((claims) => {
        setRows(claims)
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error getting claims:', error)
        setError('We encountered an error while fetching claims.')
        setLoading(false)
      })
  }, [managedPlans])

  return (
    <Box>
      {loading && (
        <Box display='flex' justifyContent='center' alignItems='center' minHeight='200px'>
          <CircularProgress size={100} />
        </Box>
      )}
      {error && (
        <Box display='flex' justifyContent='center' alignItems='center' minHeight='200px'>
          {error}
        </Box>
      )}
      {!loading && !error && (
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
            filter: {
              filterModel: {
                items: [],
                quickFilterExcludeHiddenColumns: true,
              },
            },
          }}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          checkboxSelection
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          sx={{
            minHeight: '200px',
          }}
        />
      )}
    </Box>
  )
}

export default ClaimsActivity
