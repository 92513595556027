import { useEffect } from 'react'
import Container from '@app/stories/Container'
import Section from '@app/stories/Section'
import Heading from '@app/stories/Heading'
import Divider from '@app/stories/Divider'
import Tabs, { Tab } from '@app/stories/Tabs'
import EULAConsumerContent from './EULAConsumerContent'
import EULAProviderContent from './EULAProviderContent'
import PrivacyPolicyContent from './PrivacyPolicyContent'
import useSteps from '@app/hooks/useSteps'

const EULAPage = () => {
  const { step, changeStep } = useSteps()

  useEffect(() => {
    window.Intercom('trackEvent', 'Page View', {
      page_name: 'EULA Page',
    })
  }, [])

  return (
    <Container>
      <Section>
        <Tabs value={step} onChange={changeStep}>
          <Tab label='Consumer' size='growXS' />
          <Tab label='Provider' size='growXS' />
        </Tabs>
        <Section hidden={step !== 0}>
          <Heading variant='h1'>End User License Agreement (CONSUMER)</Heading>
          <EULAConsumerContent />
          <Divider />
          <Divider />
          <br />
          <br />
          <PrivacyPolicyContent />
        </Section>
        <Section hidden={step !== 1}>
          <Heading variant='h1'>End User License Agreement (PROVIDER)</Heading>
          <EULAProviderContent />
          <Divider />
          <Divider />
          <br />
          <br />
          <PrivacyPolicyContent />
        </Section>
      </Section>
    </Container>
  )
}

export default EULAPage
