import { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { v4 as uuidv4 } from 'uuid'

import { uploadFile } from '@app/firebase/storage'
import Modal from '@app/stories/Modal'
import Heading from '@app/stories/Heading'
import Divider from '@app/stories/Divider'
import Button from '@app/stories/Button'
import { useVoucherDetails } from '@app/context/VoucherDetails'
import { useCurrentUser } from '@app/context/CurrentUser'
import Icon from '@app/stories/Icons'

import {
  MDXEditor,
  toolbarPlugin,
  headingsPlugin,
  quotePlugin,
  listsPlugin,
  linkPlugin,
  linkDialogPlugin,
  tablePlugin,
  UndoRedo,
  BlockTypeSelect,
  CreateLink,
  InsertTable,
  ListsToggle,
  BoldItalicUnderlineToggles,
} from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'

import DOMPurify from 'dompurify'
import { ADD_NOTE } from '@app/libs/constant'
import { updateDocumentModular } from '@app/firebase/firestore'

const AddNoteModal = ({ open, handleClose, noteType = 'text', onUpload, draftNote }) => {
  const { profileData, authData } = useCurrentUser()

  const [disabled, setDisabled] = useState(false)
  const [errorMsg, setErrorMsg] = useState(null)
  const [markdownNote, setMarkdownNote] = useState(draftNote?.content)
  const [textNotePopUp, setTextNotePopUp] = useState(false)

  const { addTextNote, addFileNote, addImageNote, currentFile, setCurrentFile, voucher } =
    useVoucherDetails()

  useEffect(() => {
    draftNote ? setMarkdownNote(draftNote?.content) : setMarkdownNote('')
  }, [draftNote])

  useEffect(() => {
    if (noteType === 'text') {
      setTextNotePopUp(true)
    }
  }, [noteType])

  const defaultText = `# Medical Documentation Workflow

## Subjective
- **Chief Complaint**
- **History of Present Illness**
- **Review of Systems**
- **Past Medical History**
- **Family History**
- **Social History**

## Objective
- **Vital Signs**
- **Physical Examination Findings**
- **Laboratory Results**
- **Imaging Results**
- **Photos or Videos**
  - Log as attachment via **+ ADD VISIT NOTE** and Image/File selection.

## Assessment
- **Diagnosis or Differential Diagnoses**
- **Clinical Reasoning**

## Plan
- **Treatment Plan**
- **Treatment Orders**
  - Log via **+ ADD ORDER** and Referral.
- **Medications**
  - Log via **+ ADD ORDER** and Medication.
- **Follow-Up Visit**
  - Log via **+ ADD ORDER** and Referral.
- **Patient Education**
- **Specialist Consultations**
  - Log via **+ ADD ORDER** and Referral.`

  const uploadNote = useCallback(
    async (noteStatus) => {
      try {
        setDisabled(true)
        setErrorMsg(null)

        const providerName = `${profileData?.firstname || ''} ${profileData?.lastname || ''}`

        const note = {
          content: null,
          noteStatus,
          patient: {
            firstName: voucher?.customer?.firstname?.toLowerCase()?.trim(),
            lastName: voucher?.customer?.lastname?.toLowerCase()?.trim(),
            id: voucher.user.id,
          },
          voucher: {
            code: voucher.code,
            id: voucher?.uid || voucher?.id || null,
            originalOrderId: voucher?.emrOrderID || voucher.orderId || null,
          },
          organization: {
            id: authData.organizationId,
            name: authData.organizationName,
            provider: providerName,
          },
        }
        console.log('note')
        console.log(note)

        if (noteType === 'text') {
          console.log('  -- text note')

          const safeNote = DOMPurify.sanitize(markdownNote)

          // TODO: add validation but also add fallback for doctors who dont have a display name
          note.content = safeNote
          console.log(note)
          await addTextNote(note)
        } else if (noteType === 'image') {
          const url = await uploadFile(`/notes/${voucher.code}/${uuidv4()}`, currentFile, {
            ownerID: profileData.id,
          })
          if (url !== null) {
            note.content = url
            note.fileName = currentFile.name
            addImageNote(note)
          }
          if (url.error) {
            if (url.error.code === 403) alert('Image with that name already exists!')
          }
        } else if (noteType === 'file') {
          const url = await uploadFile(`/notes/${voucher.code}/${uuidv4()}`, currentFile, {
            ownerID: profileData.id,
          })
          if (url !== null) {
            note.content = url
            note.fileName = currentFile.name
            addFileNote(note)
          }
          if (url.error) {
            if (url.error.code === 403) alert('File with that name already exists!')
          }
        }
        setDisabled(false)
        setErrorMsg(null)
        onUpload()
      } catch (error) {
        console.log('error')
        console.log(error)
        setDisabled(false)
        setErrorMsg('Failed to upload.')
      }
    },
    [
      voucher,
      profileData,
      noteType,
      onUpload,
      addTextNote,
      addImageNote,
      currentFile,
      addFileNote,
      markdownNote,
    ],
  )

  const updateNote = async (status) => {
    console.log('draftNoteeeeee', draftNote)

    try {
      await updateDocumentModular({
        collectionName: 'notes',
        documentName: draftNote?.uid,
        data: {
          noteStatus: status,
          content: markdownNote,
        },
      })
      handleClose()
    } catch (error) {
      console.log('error', error)
    }
  }

  return (
    <Modal open={open} onClose={handleClose} fullWidth maxWidth='md'>
      <style jsx>
        {' '}
        {`
          .mdxeditor-popup-container {
            z-index: 100001;
          }
        `}{' '}
      </style>
      <style jsx>
        {' '}
        {`
          ._contentEditable_yms4a_379 {
            height: 55vh;
          }
        `}{' '}
      </style>
      <Box>
        {textNotePopUp && (
          <Box
            position='fixed'
            zIndex='100002'
            bgcolor='#652d92'
            p={3}
            borderRadius={4}
            boxShadow={4}
            color='#fff'
            sx={{
              width: {
                xs: '90%',
                md: '50%',
              },
              top: {
                xs: '20%',
                md: '30%',
              },
              left: {
                xs: '5%',
                md: '25%',
              },
            }}
          >
            <Box display='flex' justifyContent='center' alignItems='center' gap={1} mb={3}>
              <Box
                sx={{
                  display: {
                    xs: 'none',
                    md: 'block',
                  },
                }}
              >
                <Icon name='info' fontSize='large' color='white' />
              </Box>
              <Typography variant='h3' weight='bold' align='center' mb={0}>
                Note on Documentation
              </Typography>
            </Box>
            <Typography variant='body1' weight='bold' align='center' mb={2}>
              This section allows free-text entry. You may keep, edit, or remove any subsections
              based on relevance to the current visit. Ensure the documentation aligns with the
              patient’s specific case and medical needs.
            </Typography>
            <Button onClick={() => setTextNotePopUp(false)} variant='outlined' size='medium'>
              Okay
            </Button>
          </Box>
        )}
        <Heading variant='h3' weight='bold' align='center'>
          Adding {noteType} note
        </Heading>
        {errorMsg && (
          <Typography variant='body1' align='center'>
            {errorMsg}
          </Typography>
        )}
        <Divider spacing='vertical' />
        {noteType === 'text' && (
          <Box
            mt={2}
            sx={{
              textAlign: 'start',
              minHeight: '60vh',
            }}
          >
            <MDXEditor
              markdown={draftNote?.content || defaultText}
              onChange={setMarkdownNote}
              plugins={[
                headingsPlugin(),
                quotePlugin(),
                listsPlugin(),
                linkPlugin(),
                linkDialogPlugin(),
                tablePlugin(),
                toolbarPlugin({
                  toolbarContents: () => (
                    <>
                      <UndoRedo />
                      <BlockTypeSelect />
                      <BoldItalicUnderlineToggles />
                      <CreateLink />
                      <ListsToggle />
                      <InsertTable />
                    </>
                  ),
                }),
              ]}
            />
          </Box>
        )}
        {noteType === 'image' && (
          <>
            <Box mt={2}>
              <Button variant='contained' size='small' component='label' disabled={disabled}>
                Choose Image
                <input
                  type='file'
                  hidden
                  onChange={(e) => {
                    setCurrentFile(e.target.files[0])
                  }}
                />
              </Button>
            </Box>
            {currentFile && (
              <Box mt={2}>
                <Typography variant='body1' align='center'>
                  {currentFile.name}
                </Typography>
              </Box>
            )}
          </>
        )}
        {noteType === 'file' && (
          <>
            <Box mt={2}>
              <Button variant='contained' size='small' component='label' disabled={disabled}>
                Choose File
                <input
                  type='file'
                  hidden
                  onChange={(e) => {
                    setCurrentFile(e.target.files[0])
                  }}
                />
              </Button>
            </Box>
            {currentFile && (
              <Box mt={2}>
                <Typography variant='body1' align='center'>
                  {currentFile.name}
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>
      <Box display='flex' justifyContent='space-around' mt={4}>
        <Button
          id='cancel-note'
          disabled={disabled}
          size='small'
          variant='outlined'
          severity='danger'
          onClick={handleClose}
          fullWidth
        >
          Cancel
        </Button>
        {noteType === 'text' && (
          <Button
            id='confirm-note'
            disabled={disabled}
            size='small'
            variant='outlined'
            onClick={() =>
              draftNote?.content ? updateNote(ADD_NOTE.DRAFT) : uploadNote(ADD_NOTE.DRAFT)
            }
            color='success'
            fullWidth
          >
            Save Draft
          </Button>
        )}
        <Button
          id='confirm-note'
          disabled={disabled}
          size='small'
          variant='outlined'
          onClick={() =>
            draftNote?.content ? updateNote(ADD_NOTE.FINALIZE) : uploadNote(ADD_NOTE.FINALIZE)
          }
          fullWidth
        >
          Sign Note
        </Button>
      </Box>
    </Modal>
  )
}

AddNoteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  noteType: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
}

export default AddNoteModal
