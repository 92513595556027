import { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import Checkbox from '@mui/material/Checkbox'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import { Divider } from '@mui/material'
import { signIn } from '@app/firebase/auth'
import { collection } from '@app/firebase/firestore'
import Link from '@app/stories/Link'
import { GENDER_OPTIONS } from '@app/libs/validators'
import PhoneField from '@app/stories/PhoneInput'
import SelectField from '@app/stories/Select'
import TextField from '@app/stories/TextField'
import Notification from '@app/components/NotificationText'
import Typography from '@app/stories/Typography'
import CheckoutStatus from '@app/stories/CheckoutStatus'
import Calendar from '@app/components/Calendar'
import ServiceCard from '@app/stories/ServiceCardNetworkView'
import Button from '@app/stories/Button'
import { useQuickCheckout } from '@app/context/QuickCheckoutNetwork'
import { useCurrentUser } from '@app/context/CurrentUser'
import { useCurrentNetwork } from '@app/context/CurrentNetwork'
import parseQueryString from '@app/libs/parseQueryStrings'
import { scrollToHash } from '@app/libs/scroll'
import BookingPolicy from '@app/stories/BookingPolicy'
import { convertDateToUtcMidnight } from '@app/libs/formatters'
import DobPicker from '@app/components/DobPicker'

import DateRangeIcon from '@mui/icons-material/DateRange'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { DatePicker } from '@mui/x-date-pickers'

import useIdentificationCards from '@app/hooks/useIdentificationCards'

import styled from '@mui/system/styled'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

const CheckoutForm = ({ price }) => {
  const { search } = useLocation()
  const stripe = useStripe()
  const elements = useElements()
  const { profileData } = useCurrentUser()
  const { createAccount, createOrder, networkObj, service, disabled, setDisabled } =
    useQuickCheckout()

  const { unformattedUTMs, allUTMs } = useCurrentNetwork()
  const [dates, setDates] = useState([])
  const [error, setError] = useState('')
  const [referral, setReferral] = useState('')
  const [pendingClientSecret, setPendingClientSecret] = useState('')
  const [successfulPaymentIntent, setSuccessfulPaymentIntent] = useState('')
  const [paymentStatus, setPaymentStatus] = useState(null)
  const [utms, setUtms] = useState({})

  const [serviceCardData, setServiceCardData] = useState(null)

  const [errorTimer, setErrorTimer] = useState(null)
  const [loadError, setLoadError] = useState(null)

  const [validIdInfo, setValidIdInfo] = useState(false)
  const [idNumber, setIdNumber] = useState(null)
  const [idExpiry, setIdExpiry] = useState(null)
  const [idImage, setIdImage] = useState(null)
  const [preview, setPreview] = useState(null)

  const [idErrorMessage, setIdErrorMessage] = useState(null)

  const [selectedDate, setSelectedDate] = useState('')

  const { checkProfileForID, checkForIdentificationInfo, updateProfileWithIdentificationInfo } =
    useIdentificationCards()

  useEffect(() => {
    console.log('no return')
    if (loadError) {
      clearTimeout(errorTimer)
    }
    setErrorTimer(
      setTimeout(() => {
        setLoadError(true)
      }, 10000),
    )
  }, [])

  useEffect(() => {
    if (profileData) {
      setValidIdInfo(checkProfileForID({ profile: profileData, setIdErrorMessage }))
      setSelectedDate(profileData?.dob)
    }
  }, [profileData])

  useEffect(() => {
    if (!idImage) {
      setPreview(null)
      return
    }
    setPreview(URL.createObjectURL(idImage))
  }, [idImage])

  const errors = {
    firstname: '',
    lastname: '',
    email: '',
    dob: '',
    gender: '',
    phone: '',
    terms: '',
    hipaa: '',
    smsOptIn: '',
    bookingPolicy: '',
  }

  useEffect(() => {
    const { ref, payment_intent, payment_intent_client_secret, ...utmsQuery } =
      parseQueryString(search)
    if (ref) {
      localStorage.setItem('refvalue', ref)
    }
    setReferral(ref)
    setUtms(utmsQuery)
    setPendingClientSecret(payment_intent_client_secret)
  }, [search])

  useEffect(() => {
    // Apply to all events below - Sidney requested we add the referring / referred clinic info to this event.
    async function checkPaymentStatus() {
      console.log('getting payment status')
      const result = await stripe.retrievePaymentIntent(pendingClientSecret)
      const { paymentIntent } = result
      console.log(paymentIntent)
      if (result.error) {
        setPaymentStatus('error')
        window.Intercom('trackEvent', 'Checkout Error', {
          typeOfCheckout: 'Network',
          network: networkObj.name,
          error: result.error || 'None',
          ...utms,
          ...allUTMs,
        })
        return undefined
      }

      if (!paymentIntent) {
        setPaymentStatus('error')
        return undefined
      }

      if (paymentIntent.error) {
        console.log(paymentIntent.error)
        return undefined
      }

      if (paymentIntent.status === 'succeeded') {
        console.log('Payment Succeeded!')
      }
      if (paymentIntent.status === 'requires_payment_method') {
        console.log('Payment Failed!')
      }
      if (paymentIntent.status === 'requires_action') {
        console.log('Payment Requires Action!')
      }
      if (paymentIntent.status === 'processing') {
        console.log('Payment Processing!')
      }

      // Sidney requested we add the referring / referred clinic info to this event.
      window.Intercom('trackEvent', `Checkout ${paymentIntent.status}`, {
        typeOfCheckout: 'Network',
        network: networkObj.name,
        paymentIntent: paymentIntent.id,
        paymentStatus: paymentIntent.status,
        error: paymentIntent.error || 'None',
        ...utms,
        ...allUTMs,
      })
      setSuccessfulPaymentIntent(paymentIntent.id)
      setPaymentStatus(`${paymentIntent.error ? 'error' : paymentIntent.status}`)
    }
    if (!stripe || !pendingClientSecret || !networkObj) {
      return undefined
    }

    checkPaymentStatus()
    return undefined
  }, [pendingClientSecret, stripe, utms, allUTMs, networkObj])

  useEffect(() => {
    if (paymentStatus === 'error' || paymentStatus === 'requires_payment_method') {
      setPendingClientSecret(null)
    }
    return undefined
  }, [paymentStatus])

  const errorHandler = ({ profileValues, passwordValue, confirmPassword, existingProfileData }) => {
    if (profileData.id) {
      if (passwordValue !== confirmPassword?.value) {
        setError('Passwords do not match.')
        setDisabled(false)
        return false
      }

      if (!profileValues?.email) {
        setError('Please enter your email.')
        setDisabled(false)
        return false
      }
    }

    if (!profileValues?.firstname) {
      setError('Please enter your first name.')
      setDisabled(false)
      return false
    }

    if (!profileValues?.lastname) {
      setError('Please enter your last name.')
      setDisabled(false)
      return false
    }

    if (!profileValues?.phone) {
      setError('Please enter your phone number.')
      setDisabled(false)
      return false
    }

    if (!profileValues?.dob) {
      setError('Please enter your date of birth.')
      setDisabled(false)
      return false
    }

    if (dates.length < 3) {
      setError('Please select 3 preferred dates.')
      setDisabled(false)
      return false
    }

    if (!profileValues?.terms && !existingProfileData?.terms) {
      setError('Please agree to the terms.')
      setDisabled(false)
      return false
    }

    if (!profileValues?.hipaa && !existingProfileData?.hipaa) {
      setError('Please agree to the HIPAA Authorization.')
      setDisabled(false)
      return false
    }

    if (!profileValues?.smsOptIn && !existingProfileData?.smsOptIn) {
      setError('Please agree to the text opt in.')
      setDisabled(false)
      return false
    }

    if (!profileValues?.bookingPolicy) {
      setError('Please agree to the booking policy.')
      setDisabled(false)
      return false
    }

    if (!validIdInfo && (!idImage || !idExpiry || !idNumber)) {
      setError('Please upload your ID.')
      setDisabled(false)
      return false
    }

    return true
  }

  const handleIdInfo = useCallback(
    async (uid) => {
      setIdErrorMessage(null)

      console.log('  -- uploading id info')
      console.log('=======================IdImage')
      console.log(idImage)

      if (
        !checkForIdentificationInfo({
          idNumber,
          idExpiry,
          idImageFile: idImage,
          errorCallback: setIdErrorMessage,
        })
      ) {
        console.log('  ** id info not valid')
        setDisabled(false)
        return
      }

      const idUploadLink = await updateProfileWithIdentificationInfo({
        uid,
        idNumber,
        idExpiry,
        idFile: idImage,
      })

      console.log('  -- id upload result')
      console.log(idUploadLink ? 'success' : 'failure')

      if (!idUploadLink) {
        console.log('  ** error uploading id info')
        setIdErrorMessage(
          'Oh uh, looks like we ran into an issue uploading your ID info.\nPlease contact support if this issue persists.',
        )
        setDisabled(false)
        return
      }

      return idUploadLink
    },
    [idNumber, idExpiry, idImage],
  )

  const checkoutSequence = async (event) => {
    event.preventDefault()
    setDisabled(true)
    setError(null)
    console.log('running checkout sequence')

    // Deconstruct form data
    const {
      firstname,
      lastname,
      email,
      password,
      confirmPassword,
      phone,
      dob,
      gender,
      terms,
      hipaa,
      smsOptIn,
      bookingPolicy,
    } = event?.target?.elements || {}

    console.log('Form Data')
    console.log(event.target.elements)

    // Get password value if it exists
    const passwordValue = password?.value

    // Get values from destructured form data
    const profileValues = {
      firstname: firstname?.value || profileData?.firstname,
      lastname: lastname?.value || profileData?.lastname,
      email: email?.value || profileData?.email,
      phone: phone?.value || profileData?.phone,
      dob: convertDateToUtcMidnight(dob?.value) || null,
      gender: gender?.value || profileData?.gender,
      terms: terms?.checked,
      hipaa: hipaa?.checked,
      smsOptIn: smsOptIn?.checked,
      bookingPolicy: bookingPolicy?.checked,
      ref: referral || '',
    }

    // Check for errors
    console.log('Running error handler')

    try {
      if (
        !errorHandler({
          profileValues,
          passwordValue,
          confirmPassword,
          existingProfileData: profileData,
        })
      ) {
        console.log('** Error handler threw error')
        return
      }
    } catch (e) {
      console.log('** Error handler caught error')
      console.log(e)
      setDisabled(false)
      return
    }

    // Check if user is logged in
    let userID = profileData.id || null
    if (!userID) {
      console.log('no uid running create account flow')
      const { status, uid, code } = await createAccount(
        profileValues?.email,
        passwordValue,
        profileValues,
      )

      if (status === 'error') {
        // check firebase signIn response for errors
        if (code === 'auth/email-already-in-use') {
          console.log('email already in use')
          try {
            const signInResult = await signIn(profileValues.email, passwordValue)
            userID = signInResult?.user?.uid
          } catch (signInError) {
            console.log('error signing in caught')
            console.log(signInError)
            if (signInError.code === 'auth/wrong-password') {
              setError('Incorrect Password')
              setDisabled(false)
              return
            }
            setDisabled(false)
            return
          }
        }

        if (!code === 'auth/weak-password') {
          setError('Password is too weak.')
          setDisabled(false)
          return
        }

        if (code === 'auth/invalid-email') {
          setError('Invalid Email')
          setDisabled(false)
          return
        }

        if (code === 'auth/operation-not-allowed') {
          setError('Please contact support. There was an error creating your account.')
          setDisabled(false)
          return
        }
      } else {
        userID = uid
      }
    } else {
      profileValues.email = profileData.email
    }

    profileValues.uid = userID
    profileValues.dates = dates

    console.log('  Profile Values')
    console.log(profileValues)

    if (!validIdInfo) {
      console.log('  ** id info not valid - updating')
      console.log('    -- user ID')
      console.log(userID)
      const handleIdInfoResult = await handleIdInfo(userID)
      console.log('    -- handleIdInfoResult')
      console.log(handleIdInfoResult)
      profileValues.idLink = handleIdInfoResult || null
    }

    // Check if stripe and elements are loaded
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      console.log('stripe or elements null')
      return
    }
    // Create order
    const orderId = await createOrder(profileValues)
    console.log(orderId)

    if (!orderId) {
      setDisabled(false)
      setPaymentStatus('error')
      console.log('** error creating order - id is false')
      return
    }

    // Trigger payment on stripe

    const result = await stripe.confirmPayment({
      // `Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.href}${unformattedUTMs || ''}`,
      },
      redirect: 'if_required',
    })

    console.log('Stripe result')
    console.log(result)

    if (result?.error) {
      window.Intercom('trackEvent', 'Checkout Error', {
        typeOfCheckout: 'Network',
        network: networkObj.name,
        errorType: result.error.type,
        errorCode: result.error.code,
        errorDeclineReason: result.error.decline_code || 'None',
        ...utms,
        ...allUTMs,
      })
      setDisabled(false)
      setPaymentStatus('error')
      return
    }

    window.Intercom('trackEvent', `Checkout ${result.paymentIntent.status}`, {
      typeOfCheckout: 'Network',
      network: networkObj.name,
      paymentIntent: result.paymentIntent.id,
      paymentStatus: result.paymentIntent.status,
      ...allUTMs,
    })

    if (
      result.paymentIntent.status === 'requires_payment_method' ||
      result.paymentIntent.status === 'requires_action'
    ) {
      setDisabled(false)
    } else {
      setSuccessfulPaymentIntent(result.paymentIntent.id)
    }
    setPaymentStatus(result.paymentIntent.status)
    scrollToHash('#checkoutContainer')
  }

  const getClinicData = async (clinicID) => {
    return collection('organizations')
      .doc(clinicID)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          console.error('  ** No such clinic document!')
          return null
        }
        return {
          id: doc.id,
          ...doc.data(),
        }
      })
      .catch((err) => {
        console.error('  ** Error getting clinic data:', err)
        return null
      })
  }

  const getClinicsData = async (clinicIDs) => {
    const clinics = await Promise.all(
      clinicIDs
        .map(async (clinicID) => {
          const clinic = await getClinicData(clinicID)
          return clinic
        })
        .filter((clinic) => clinic),
    )
    return clinics
  }

  useEffect(() => {
    const buildServiceCardData = async () => {
      if (!service || !networkObj?.id) {
        console.error('  ** No Service or network ID')
        console.warn('  service: ', service)
        console.warn('  networkObj: ', networkObj)
        return
      }

      if (service?.bundle) {
        console.log('Getting Bundle Data')

        console.log('Service')
        console.log(service)

        console.log('Network')
        console.log(networkObj)

        const bundleComponents = await collection('networks')
          .doc(networkObj.id)
          .collection('services')
          .doc(service.id)
          .collection('bundleComponents')
          .get()
          .then((snapshot) => {
            console.log('  --- Bundle Components Snapshot')
            console.log(snapshot)
            if (snapshot.empty) {
              console.error('  ** Bundle components subcollection is empty!')
              return null
            }
            return snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
          })
          .catch((err) => {
            console.error('Error getting bundle components:', err)
            return null
          })

        console.log('  --- Bundle Components')
        console.log(bundleComponents)

        if (!bundleComponents) {
          console.error('  ** No Bundle Components')
          return
        }

        const clinicIDs = bundleComponents.map((bundleComponent) => {
          console.log('Bundle Component')
          console.log(bundleComponent)
          return bundleComponent?.clinicID || bundleComponent?.organizationId || null
        })

        // make unique
        const uniqueClinicIDs = [...new Set(clinicIDs)].filter((id) => id)

        const clinicsData = await getClinicsData(uniqueClinicIDs)

        const serviceData = {
          service,
          bundleComponents,
          clinics: clinicsData,
          readablePrice: (price / 100).toFixed(2),
        }

        console.log('Service Data')
        console.log(serviceData)

        setServiceCardData(serviceData)
      } else {
        console.log('Getting Service Data')

        if (!service?.organizationId) {
          console.error('  ** No Clinic ID')
          return
        }

        const clinic = await getClinicData(service.organizationId)

        const serviceData = {
          service,
          clinic,
          readablePrice: (price / 100).toFixed(2),
        }

        console.log('Service Data')
        console.log(serviceData)

        setServiceCardData(serviceData)
      }
    }

    buildServiceCardData()
    return undefined
  }, [networkObj, service, price])

  return (
    <Box
      id='checkoutContainer'
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
      }}
    >
      {!pendingClientSecret && paymentStatus !== 'succeeded' && paymentStatus !== 'processing' ? (
        <>
          {serviceCardData && (
            <Box
              mb={3}
              sx={{
                width: {
                  xs: '100%',
                  md: '50%',
                },
                padding: {
                  xs: '0',
                  md: '0 1rem',
                },
              }}
            >
              <ServiceCard
                serviceCardData={serviceCardData}
                variant={service?.bundle ? 'bundle' : 'service'}
              />
            </Box>
          )}
          {networkObj && service ? (
            <Box
              sx={{
                width: {
                  xs: '100%',
                  md: '50%',
                },
                padding: {
                  xs: '0',
                  md: '0 1rem',
                },
              }}
            >
              <form title='customer-info-form' onSubmit={checkoutSequence}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography variant='h6' gutterBottom>
                      Select 3 preferred times & dates.
                    </Typography>
                    <Calendar setDates={setDates} dates={dates} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      required
                      disabled={disabled}
                      name='firstname'
                      label='first name'
                      defaultValue={profileData?.firstname || ''}
                      autoComplete='given-name'
                      helperText={errors.firstname}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      required
                      disabled={disabled}
                      name='lastname'
                      label='last name'
                      defaultValue={profileData?.lastname || ''}
                      autoComplete='family-name'
                      helperText={errors.lastname}
                    />
                  </Grid>
                  {!profileData.id && (
                    <>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          required
                          disabled={disabled}
                          name='email'
                          type='email'
                          label='e-mail'
                          defaultValue={profileData?.email || ''}
                          autoComplete='email'
                          helperText={errors.email}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={3}>
                        <TextField
                          required
                          disabled={disabled}
                          name='password'
                          type='password'
                          autoComplete='new-password'
                          label='password'
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={3}>
                        <TextField
                          required
                          disabled={disabled}
                          name='confirmPassword'
                          type='password'
                          autoComplete='new-password'
                          label='Confirm Password'
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} sm={12} md={6}>
                    <PhoneField
                      required
                      name='phone'
                      disabled={disabled}
                      label='telephone number'
                      defaultValue={profileData?.phone || ''}
                      autoComplete='tel'
                      helperText={errors.phone}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} mt={2}>
                    <DobPicker dob={selectedDate} setDob={setSelectedDate} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <SelectField
                      required
                      disabled={disabled}
                      label='gender'
                      name='gender'
                      defaultValue={profileData?.gender || 'undefined'}
                      options={GENDER_OPTIONS}
                      helperText={errors.gender}
                    />
                  </Grid>
                  {!validIdInfo && (
                    <>
                      <Divider
                        style={{
                          width: '100%',
                          marginTop: '20px',
                        }}
                      />
                      <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                        <Typography variant='h4' align='center'>
                          Government ID
                        </Typography>
                      </Grid>
                      {idErrorMessage && (
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Typography
                            variant='body1'
                            style={{
                              color: 'red',
                            }}
                          >
                            {idErrorMessage}
                          </Typography>
                        </Grid>
                      )}
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box>
                          <Typography variant='caption'>
                            Enter your ID/Drivers License Number as it appears.
                          </Typography>
                        </Box>
                        <TextField
                          id='dirx-id-number'
                          value={idNumber}
                          onChange={(e) => {
                            setIdNumber(e.target.value)
                          }}
                          label='ID/Drivers License Number'
                          variant='outlined'
                          fullWidth
                          disabled={disabled && true}
                          sx={{
                            marginTop: '0px',
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box>
                          <Typography variant='caption'>
                            ID must expire more than 7 days from today.
                          </Typography>
                        </Box>
                        <DatePicker
                          id='id-expiry'
                          label='ID/Drivers License Expiration Date'
                          value={idExpiry}
                          onChange={setIdExpiry}
                          inputVariant='outlined'
                          bgcolor='background.paper'
                          format='MM-dd-yyyy'
                          keyboardIcon={<DateRangeIcon />}
                          KeyboardButtonProps={{ 'aria-label': 'change date' }}
                          slotProps={{
                            textField: { required: true, fullWidth: true },
                          }}
                          disabled={disabled && true}
                          minDate={new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: '10px' }} mb={2}>
                        <Box
                          mt={1}
                          width='100%'
                          display='flex'
                          alignItems='center'
                          justifyContent='center'
                        >
                          <Box
                            display='flex'
                            alignItems='center'
                            justifyContent='center'
                            flexDirection='column'
                            width='100%'
                          >
                            <Button
                              component='label'
                              variant='outlined'
                              tabIndex={-1}
                              startIcon={<CloudUploadIcon />}
                              disabled={disabled && true}
                              size='large'
                              sx={{
                                minWidth: '155px',
                              }}
                            >
                              <Typography variant='body1' whiteSpace='nowrap'>
                                Upload ID
                              </Typography>
                              <VisuallyHiddenInput
                                id='dirx-id-file'
                                type='file'
                                accept='image/*'
                                disabled={disabled && true}
                                onChange={(e) => {
                                  setIdImage(e.target.files[0])
                                  const objectUrl = URL.createObjectURL(e.target.files[0])
                                  setPreview(objectUrl)
                                }}
                              />
                            </Button>
                            <Button
                              component='label'
                              variant='outlined'
                              tabIndex={-1}
                              startIcon={<DeleteForeverIcon />}
                              disabled={disabled && true}
                              size='large'
                              sx={{
                                marginTop: '10px',
                                minWidth: '155px',
                              }}
                              onClick={() => {
                                setIdImage(null)
                              }}
                            >
                              <Typography variant='body1' whiteSpace='nowrap'>
                                Clear
                              </Typography>
                            </Button>
                          </Box>
                          <Box
                            width='100%'
                            display='flex'
                            alignItems='center'
                            justifyContent='center'
                            padding={2}
                          >
                            {preview ? (
                              <img
                                id='id_preview'
                                src={preview}
                                alt='ID Preview'
                                style={{
                                  width: '100%',
                                  maxWidth: '400px',
                                  height: 'auto',
                                }}
                              />
                            ) : (
                              <Typography variant='body1'>
                                Please upload a clear picture of the front of your ID.
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} sm={12} md={12}>
                    <Divider
                      style={{
                        width: '100%',
                        marginTop: '20px',
                        marginBottom: '20px',
                      }}
                    />
                    <PaymentElement id='payment-element' />
                    <Grid
                      container
                      style={{ marginTop: '30px' }}
                      spacing={1}
                      justifyContent={
                        !profileData?.terms || !profileData?.hipaa || !profileData?.smsOptIn
                          ? 'space-between'
                          : 'flex-end'
                      }
                    >
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <BookingPolicy />
                        <Box display='flex' alignItems='center'>
                          <Checkbox
                            disabled={disabled}
                            name='bookingPolicy'
                            color='primary'
                            inputProps={{ 'aria-label': 'terms' }}
                          />
                          <Typography
                            variant='body2'
                            color={errors.bookingPolicy ? 'error' : 'initial'}
                          >
                            I have read and agree to the booking policy.
                          </Typography>
                        </Box>
                        <Divider
                          style={{
                            width: '100%',
                            marginTop: '20px',
                            marginBottom: '20px',
                          }}
                        />
                      </Grid>
                      {(!profileData?.terms || !profileData?.hipaa || !profileData?.smsOptIn) && (
                        <Grid item xs={12} sm={12}>
                          <Typography variant='body2'>
                            To use this account please review and agree to the following:
                          </Typography>
                        </Grid>
                      )}
                      {!profileData?.terms && (
                        <Grid item xs={12} sm={12}>
                          <Box display='flex' alignItems='center'>
                            <Checkbox
                              disabled={disabled}
                              name='terms'
                              color='primary'
                              inputProps={{ 'aria-label': 'terms' }}
                            />
                            <Typography variant='body2' color={errors.terms ? 'error' : 'initial'}>
                              <Link
                                display='standard'
                                size='small'
                                target='_blank'
                                to='/privacy-policy'
                                style={{ fontWeight: '300' }}
                              >
                                Privacy Policy
                              </Link>{' '}
                              ,{' '}
                              <Link
                                display='standard'
                                size='small'
                                target='_blank'
                                to='/terms'
                                style={{ fontWeight: '300' }}
                              >
                                Terms of Use
                              </Link>{' '}
                              and{' '}
                              <Link
                                display='standard'
                                size='small'
                                target='_blank'
                                to='/eula-consumer'
                                style={{ fontWeight: '300' }}
                              >
                                EULA
                              </Link>
                              .
                            </Typography>
                          </Box>
                        </Grid>
                      )}
                      {!profileData?.hipaa && (
                        <Grid item xs={12} sm={12}>
                          <Box display='flex' alignItems='center'>
                            <Checkbox
                              disabled={disabled}
                              name='hipaa'
                              color='primary'
                              inputProps={{ 'aria-label': 'hipaa' }}
                            />
                            <Typography variant='body2' color={errors.hipaa ? 'error' : 'initial'}>
                              <Link
                                display='standard'
                                size='small'
                                target='_blank'
                                to='/hipaa'
                                style={{ fontWeight: '300' }}
                              >
                                HIPAA Authorization
                              </Link>
                            </Typography>
                            .
                          </Box>
                        </Grid>
                      )}
                      {!profileData?.smsOptIn && (
                        <Grid item xs={12} sm={12}>
                          <Box display='flex' alignItems='center'>
                            <Checkbox
                              disabled={disabled}
                              name='smsOptIn'
                              color='primary'
                              inputProps={{ 'aria-label': 'Text Opt In' }}
                            />
                            <Typography
                              variant='body2'
                              color={errors.smsOptIn ? 'error' : 'initial'}
                            >
                              Agree to receive SMS about your account and orders. Message and data
                              rates may apply.
                            </Typography>
                          </Box>
                        </Grid>
                      )}
                      <Grid item xs={12} sm={12}>
                        <Box display='flex' alignItems='center'>
                          <Button
                            type='submit'
                            disabled={disabled}
                            style={{
                              width: '100%',
                            }}
                            className='book-network-service-btn'
                          >
                            Confirm Payment
                          </Button>
                        </Box>
                      </Grid>
                      {disabled && (
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Box display='flex' alignItems='center'>
                            <Typography variant='body2' color='primary'>
                              Your payment is processing. Please do not leave this page or refresh.
                              You will be shown a confirmation once payment is complete. This may
                              take a few moments.
                            </Typography>
                          </Box>
                        </Grid>
                      )}
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        {error && (
                          <Notification
                            message={error}
                            style={{
                              backgroundColor: 'rgba(254, 35, 32, 0.19)',
                              padding: '10px',
                              borderRadius: '5px',
                              color: '#000',
                            }}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        {paymentStatus === 'requires_payment_method' ||
                          (paymentStatus === 'requires_action' && (
                            <Notification
                              message='Payment Failed. Please try again.'
                              style={{
                                backgroundColor: 'rgba(254, 35, 32, 0.19)',
                                padding: '10px',
                                borderRadius: '5px',
                                color: '#000',
                              }}
                            />
                          ))}
                        {paymentStatus === 'error' && (
                          <Notification
                            message='There was an error with your payment. Please try again.'
                            style={{
                              backgroundColor: 'rgba(254, 35, 32, 0.19)',
                              padding: '10px',
                              borderRadius: '5px',
                              color: '#000',
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Box>
          ) : (
            <Box display='flex' justifyContent='center' mt={2}>
              <Grid container display='flex' justifyContent='center'>
                <Grid item xs={12}>
                  <Grid mt={2} spacing={3} display='flex' justifyContent='center' container>
                    <Grid lg={12} md={12} sm={12} xs={12} item>
                      <Typography variant='h4' align='center'>
                        Confirming network details...
                      </Typography>
                    </Grid>
                    <Grid lg={6} md={6} sm={9} xs={9} item>
                      <LinearProgress variant='buffer' value={95} valueBuffer={100} />
                    </Grid>
                    {loadError && (
                      <Grid lg={12} md={12} sm={12} xs={12} item>
                        <Typography variant='h4' align='center'>
                          We apologize for the delay this is taking longer than usual.
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )}
        </>
      ) : (
        <CheckoutStatus status={paymentStatus} orderId={successfulPaymentIntent} />
      )}
    </Box>
  )
}

CheckoutForm.propTypes = {
  price: PropTypes.number.isRequired,
}

export default CheckoutForm
