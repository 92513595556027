import Grid from '@mui/material/Grid'
import ProviderVouchersForm from '@app/containers/ProviderVouchersForm'
import Heading from '@app/stories/Heading'
import ClaimVoucherModal from '@app/containers/ClaimVoucherModal'
import ResponsiveTable from '@app/components/ResponsiveTable'
import { Toggle } from '@app/hooks/useToggleOpen'
import ResponsiveVoucherRow from '@app/components/ResponsiveVoucherRow'
import { useVouchers } from '@app/context/Vouchers'
import VouchersIndividual from '@app/components/VouchersIndividual'
import VouchersBundle from '@app/components/VouchersBundle'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import CircularProgress from '@mui/material/CircularProgress'
import { useCurrentUser } from '@app/context/CurrentUser'

const HEADERS = [
  { id: 'info', label: 'Info', align: 'center', type: 'IconOpenClose' },
  { id: 'customer', label: 'Consumer', align: 'left', type: 'MemberInfo' },
  { id: 'service', label: 'Treatment', align: 'left', type: 'Text' },
  { id: 'appointmentDate', label: 'Appt Date', align: 'left', type: 'DatePicker' },
  { id: 'createdAt', label: 'Purchased On', align: 'left', type: 'Date' },
  { id: 'code', label: 'Claim ID', align: 'left', type: 'Text' },
  { id: 'status', label: 'Status', align: 'center', type: 'StatusChip' },
  { id: 'redeem', label: 'Redeem', align: 'center', type: 'RedeemAction' },
  { id: 'action', label: 'Actions', align: 'center', type: 'CopyLink' },
]

const BUNDLE_HEADERS = [
  { id: 'service', label: 'Treatment', align: 'left', type: 'Text' },
  { id: 'appointmentDate', label: 'Appt Date', align: 'left', type: 'DatePicker' },
  { id: 'details', label: 'Details', align: 'left', type: 'Detail' },
  { id: 'code', label: 'Claim Id', align: 'left', type: 'Text' },
  { id: 'status', label: 'Status', align: 'center', type: 'StatusChip' },
  { id: 'redeem', label: 'Redeem', align: 'center', type: 'RedeemAction' },
  { id: 'action', label: 'Actions', align: 'center', type: 'CopyLink' },
]

const ProviderVouchers = () => {
  const { vouchers, vouchersLoading } = useVouchers()
  const { profileData } = useCurrentUser()

  return (
    <Grid container spacing={2} mt={2}>
      <Grid item xs={12}>
        <Heading variant='body1'>
          Enter order #, claim # or consumer last name and DOB to search for voucher
        </Heading>
      </Grid>
      <ProviderVouchersForm uid={profileData.id} />
      <div style={{ width: '100%', maxWidth: '100%', overflowX: 'auto' }}>
        <ResponsiveTable style={{ width: '100%', tableLayout: 'fixed' }} headers={HEADERS}>
          <ClaimVoucherModal uid={profileData.id} />

          {vouchersLoading ? (
            <TableRow>
              <TableCell align='center' colSpan={HEADERS.length}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (
            vouchers.map((voucher) => (
              <Toggle key={voucher.id}>
                {({ open, handleClick, handleClose }) => (
                  <>
                    <ResponsiveVoucherRow
                      headers={HEADERS}
                      voucher={voucher}
                      isActive={open}
                      handleActivate={handleClick}
                      handleDeactivate={handleClose}
                    />
                    {voucher.bundle ? (
                      <VouchersBundle
                        headers={BUNDLE_HEADERS}
                        cols={HEADERS.length}
                        open={open}
                        voucher={voucher}
                      />
                    ) : (
                      <VouchersIndividual headers={HEADERS} open={open} voucher={voucher} />
                    )}
                  </>
                )}
              </Toggle>
            ))
          )}
        </ResponsiveTable>
      </div>
    </Grid>
  )
}

export default ProviderVouchers
