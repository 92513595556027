import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { Divider, Hidden } from '@mui/material'
import Typography from '@app/stories/Typography'
import Container from '@app/stories/Container'
import Section from '@app/stories/Section'
import IconButton from '@app/stories/IconButton'
import Icon from '@app/stories/Icons'
import FormattedText from '@app/stories/FormatFromDB'
import Link from '@app/stories/Link'
import { scrollToHash } from '@app/libs/scroll'

import MuiMardown from 'mui-markdown'
import DOMPurify from 'dompurify'

const BlogBody = ({
  markdownContent = null,
  slug,
  title = null,
  content,
  authorName = null,
  authorImage = null,
  authorSocials = null,
  publishedOn = null,
  tableOfContents = null,
}) => {
  if (markdownContent) {
    return (
      <Section bgcolor='background.smoke'>
        <Container>
          <Box>
            <MuiMardown>{DOMPurify.sanitize(markdownContent)}</MuiMardown>
          </Box>
        </Container>
      </Section>
    )
  }

  return (
    <>
      <Section bgcolor='background.smoke'>
        <Container>
          <Grid container spacing={3} display='flex' justifyContent='center'>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Box
                display='flex'
                width='100%'
                sx={{
                  justifyContent: {
                    xs: 'center',
                    sm: 'center',
                    md: 'start',
                    lg: 'start',
                  },
                }}
              >
                <Box display='flex' alignItems='center' width='100%'>
                  <Typography variant='h2' weight='bold' align='center'>
                    {title}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Box
                display='flex'
                width='100%'
                sx={{
                  justifyContent: {
                    xs: 'center',
                    sm: 'center',
                    md: 'start',
                    lg: 'start',
                  },
                }}
              >
                <Box display='flex' alignItems='center' width='100%'>
                  {authorImage && (
                    <img
                      src={authorImage}
                      alt='The Author Icon'
                      loading='lazy'
                      height='75px'
                      width='75px'
                      style={{
                        borderRadius: '100%',
                        overflow: 'hidden',
                      }}
                    />
                  )}
                  <Box
                    style={{
                      marginLeft: '25px',
                    }}
                  >
                    <FormattedText text={authorName} textType='h3' />
                    {publishedOn && publishedOn?.toDate?.() && (
                      <Typography variant='caption' weight='light'>
                        <Box component='span' color='primary.main'>
                          Published on: {publishedOn?.toDate().toLocaleDateString()}
                        </Box>
                      </Typography>
                    )}
                    <Hidden mdUp>
                      <>
                        {(authorSocials?.twitter ||
                          authorSocials?.linkedIn ||
                          authorSocials?.facebook) && (
                          <Box display='flex' justifyContent='start'>
                            {authorSocials.twitter && (
                              <Box>
                                <IconButton
                                  target='_blank'
                                  href={authorSocials.twitter}
                                  size='large'
                                >
                                  <Icon name='twitter' color='primary' />
                                </IconButton>
                              </Box>
                            )}
                            {authorSocials.facebook && (
                              <Box>
                                <IconButton
                                  target='_blank'
                                  href={authorSocials.facebook}
                                  size='large'
                                >
                                  <Icon name='facebook' color='primary' />
                                </IconButton>
                              </Box>
                            )}
                            {authorSocials.linkedIn && (
                              <Box>
                                <IconButton
                                  target='_blank'
                                  href={authorSocials.linkedIn}
                                  size='large'
                                >
                                  <Icon name='linkedin' color='primary' />
                                </IconButton>
                              </Box>
                            )}
                          </Box>
                        )}
                      </>
                    </Hidden>
                  </Box>
                </Box>
                <Hidden mdDown>
                  <>
                    {(authorSocials?.twitter ||
                      authorSocials?.linkedIn ||
                      authorSocials?.facebook) && (
                      <Box
                        sx={{
                          display: {
                            xs: 'flex',
                            sm: 'flex',
                            md: 'flex',
                            lg: 'flex',
                          },
                          justifyContent: {
                            xs: 'center',
                            sm: 'center',
                            md: 'start',
                            lg: 'start',
                          },
                        }}
                        mt={2}
                      >
                        {authorSocials.twitter && (
                          <Box>
                            <IconButton target='_blank' href={authorSocials.twitter} size='large'>
                              <Icon name='twitter' color='primary' />
                            </IconButton>
                          </Box>
                        )}
                        {authorSocials.facebook && (
                          <Box>
                            <IconButton target='_blank' href={authorSocials.facebook} size='large'>
                              <Icon name='facebook' color='primary' />
                            </IconButton>
                          </Box>
                        )}
                        {authorSocials.linkedIn && (
                          <Box>
                            <IconButton target='_blank' href={authorSocials.linkedIn} size='large'>
                              <Icon name='linkedin' color='primary' />
                            </IconButton>
                          </Box>
                        )}
                      </Box>
                    )}
                  </>
                </Hidden>
              </Box>
              <Divider
                style={{
                  marginTop: '25px',
                  marginBottom: '25px',
                }}
              />
            </Grid>
            {tableOfContents.length !== 0 && (
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <Box
                  display='flex'
                  width='100%'
                  sx={{
                    justifyContent: {
                      xs: 'center',
                      sm: 'center',
                      md: 'start',
                      lg: 'start',
                    },
                  }}
                >
                  <Box alignItems='center' width='100%'>
                    <Typography variant='h4'>Table of Contents</Typography>
                    <Box
                      sx={{
                        display: {
                          xs: 'flex',
                          sm: 'flex',
                          md: 'flex',
                          lg: 'flex',
                        },
                        justifyContent: {
                          xs: 'center',
                          sm: 'center',
                          md: 'start',
                          lg: 'start',
                        },
                      }}
                    >
                      <Grid container spacing={2} display='flex' mt={1}>
                        {tableOfContents.map((item) => (
                          <Grid item key={item}>
                            <Box mt={2}>
                              <Link
                                to={`#${item}`}
                                type='internal'
                                variant='body1'
                                weight='bold'
                                onClick={() => {
                                  scrollToHash(`#${item}`)
                                }}
                              >
                                {item}
                              </Link>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Box>
                </Box>
                <Divider
                  style={{
                    marginTop: '25px',
                    marginBottom: '25px',
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={8} lg={8}>
              {content.map((block) => (
                <div key={block} id={block.id || ''}>
                  {block.title && block.title && (
                    <Box mb={2}>
                      <Typography variant='h3' weight='medium' gutterBottom>
                        {block.title}
                      </Typography>
                    </Box>
                  )}
                  {block.text && block.text !== '' && block.text !== null && (
                    <Box mb={5}>
                      <FormattedText
                        style={{ maxWidth: '750px', fontSize: '18px' }}
                        weight='light'
                        text={block.text}
                      />
                    </Box>
                  )}
                  {block.image && block.image !== '' && block.image !== null && (
                    <Box mb={5} justifyContent='center'>
                      <img src={block.image} alt='Blogimage' width='100%' />
                    </Box>
                  )}
                </div>
              ))}
              <Box
                display='flex'
                sx={{
                  justifyContent: {
                    xs: 'center',
                    sm: 'center',
                    md: 'start',
                    lg: 'start',
                  },
                }}
                mt={2}
              >
                <Box display='flex' mb={1} alignItems='center'>
                  <Typography variant='body1' weight='semibold'>
                    <Box component='span' color='primary.main'>
                      Share On:
                    </Box>
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    target='_blank'
                    href={`https://twitter.com/intent/tweet?url=https://mishe.co/blog/${slug}`}
                    size='large'
                  >
                    <Icon name='twitter' color='primary' />
                  </IconButton>
                </Box>
                <Box>
                  <IconButton
                    target='_blank'
                    href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fmishe.co%2Fblog%2F${slug}`}
                    size='large'
                  >
                    <Icon name='facebook' color='primary' />
                  </IconButton>
                </Box>
                <Box mb={1}>
                  <IconButton
                    target='_blank'
                    href={`https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fmishe.co%2Fblog%2F${slug}`}
                    size='large'
                  >
                    <Icon name='linkedin' color='primary' />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </>
  )
}

BlogBody.propTypes = {
  markdownContent: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.node.isRequired,
  slug: PropTypes.string.isRequired,
  authorName: PropTypes.string,
  authorImage: PropTypes.string,
  publishedOn: PropTypes.string,
  // array
  tableOfContents: PropTypes.arrayOf(PropTypes.string),
  authorSocials: PropTypes.shape({
    twitter: PropTypes.string,
    facebook: PropTypes.string,
    linkedIn: PropTypes.string,
  }),
}

export default BlogBody
