import { useEffect, useRef, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import DownloadIcon from '@mui/icons-material/Download'
import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

const width = 1024
const height = 638

const PatientMemberCardRenderer = ({
  firstName,
  lastName,
  memberId,
  groupId,
  planData,
  planID,
  draw,
  cardBackground,
  cardTitle,
}) => {
  const canvasRef = useRef(null)

  const [hover, setHover] = useState(false)
  const [imageLoaded, setImageLoaded] = useState(false)

  useEffect(() => {
    if (!cardBackground) return

    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')

    const img = new Image()
    img.crossOrigin = 'anonymous' // Set crossOrigin attribute
    img.onload = () => {
      ctx.drawImage(img, 0, 0, width, height)
      draw(ctx, {
        firstName,
        lastName,
        memberId,
        groupId,
        planData,
        width,
        height,
        planID,
      })
      setImageLoaded(true)
    }

    img.src = cardBackground
  }, [imageLoaded, cardBackground, firstName, lastName, memberId, planData, groupId, planID])

  const handleDownload = async () => {
    const canvas = canvasRef.current

    if (!canvas) {
      console.error('no canvas to download')
      return
    }

    try {
      const dataUrl = canvas.toDataURL('image/png')

      // Create a download link for the generated image
      const link = document.createElement('a')

      link.href = dataUrl
      link.download = `${cardTitle}.png`

      document.body.appendChild(link) // Append link to the DOM for Safari compatibility
      link.click() // Trigger the download
      document.body.removeChild(link) // Remove the link from the DOM
    } catch (error) {
      console.error('Error generating or downloading the image:', error)
    }
  }

  return (
    <Box
      position='relative'
      display='inline-block'
      id={`${cardTitle}_container`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {!imageLoaded && (
        <Box position='absolute' top='50%' left='50%' transform='translate(-50%, -50%)' zIndex={1}>
          <CircularProgress />
        </Box>
      )}
      <canvas
        ref={canvasRef}
        width={width}
        height={height}
        style={{ width: '100%', height: 'auto', display: 'block' }}
      />
      {hover && imageLoaded && (
        <Box
          position='absolute'
          top={0}
          left={0}
          width='100%'
          height='100%'
          display='flex'
          alignItems='center'
          justifyContent='center'
          bgcolor='rgba(0, 0, 0, 0.3)'
        >
          <IconButton onClick={handleDownload} color='primary' size='large'>
            <DownloadIcon size='large' fontSize='inherit' />
          </IconButton>
        </Box>
      )}
    </Box>
  )
}

export default PatientMemberCardRenderer
