import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useCurrentUser } from '@app/context/CurrentUser'
import { useNavigate } from 'react-router-dom'

const VoucherDetailsEntry = ({ voucher, children }) => {
  const navigate = useNavigate()

  const { authData } = useCurrentUser()

  const onClick = useCallback(() => {
    if (authData.acceptedTerms || !authData.roles.includes('PROVIDER')) {
      if (voucher.external) {
        navigate(`/dashboard/vouchers/${voucher.id}?e=1`)
      } else if (voucher.parentUid) {
        navigate(`/dashboard/vouchers/${voucher.parentUid}/${voucher.id}`)
      } else if (voucher) {
        navigate(`/dashboard/vouchers/${voucher.id}`)
      }
    }
  }, [authData.acceptedTerms, authData.roles, voucher])

  const isDisabled = !authData.acceptedTerms && authData.roles.includes('PROVIDER')
  return (
    <div
      onClick={!isDisabled ? onClick : undefined}
      style={{
        cursor: !isDisabled ? 'pointer' : 'not-allowed',
      }}
    >
      {children}
    </div>
  )
}

VoucherDetailsEntry.propTypes = {
  voucher: PropTypes.shape({
    uid: PropTypes.string,
    code: PropTypes.string,
    service: PropTypes.shape({
      name: PropTypes.string,
    }),
    customer: PropTypes.shape({
      lastname: PropTypes.string,
    }),
  }).isRequired,
  children: PropTypes.node.isRequired, // Allows any valid React child
}

export default VoucherDetailsEntry
