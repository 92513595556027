import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import useToggleOpen from '@app/hooks/useToggleOpen'
import Modal from '@app/stories/Modal'
import Heading from '@app/stories/Heading'
import Divider from '@app/stories/Divider'
import Icon from '@app/stories/Icons'
import IconButton from '@app/stories/IconButton'
import Button from '@app/stories/Button'

const ShareFallbackModal = () => {
  const [anchorel, handleClick, handleClose] = useToggleOpen(null)
  const open = Boolean(anchorel)
  const { title } = document
  const url = window.location.href

  const shareProfile = (event) => {
    if (navigator.share) {
      navigator
        .share({
          title,
          url,
        })
        .then(() => {
          console.log('Thanks for sharing!')
        })
        .catch(() => {
          handleClick(event)
        })
    } else {
      handleClick(event)
    }
  }

  return (
    <>
      <IconButton onClick={shareProfile} size='large'>
        <span className='material-icons'>ios_share</span>
      </IconButton>
      <Modal maxWidth='sm' open={open} anchorel={anchorel} onClose={handleClose}>
        <Box>
          <Heading variant='h3' weight='bold' align='center'>
            Share this Organization
          </Heading>
          <Divider spacing='vertical' />
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={6}>
              <Button
                size='large'
                variant='outlined'
                fullWidth
                onClick={() => {
                  window.open(`https://twitter.com/intent/tweet?url=${url}`, '_blank')
                }}
              >
                <Box mr={1}>
                  <Icon name='twitter' color='purple' />
                </Box>
                Twitter
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Button
                size='large'
                variant='outlined'
                fullWidth
                onClick={() => {
                  window.open(
                    `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
                    '_blank',
                  )
                }}
              >
                <Box mr={1}>
                  <Icon name='facebook' color='purple' />
                </Box>
                Facebook
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Button
                size='large'
                fullWidth
                variant='outlined'
                onClick={() => {
                  window.open(
                    `https://www.linkedin.com/sharing/share-offsite/?url=${url}`,
                    '_blank',
                  )
                }}
              >
                <Box mr={1}>
                  <Icon name='linkedin' color='purple' />
                </Box>
                LinkedIn
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Button
                size='large'
                variant='outlined'
                fullWidth
                onClick={() => {
                  window.navigator.clipboard.writeText(url)
                }}
              >
                Copy Link
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box mt={4}>
          <Button size='medium' variant='contained' onClick={handleClose}>
            close
          </Button>
        </Box>
      </Modal>
    </>
  )
}

export default ShareFallbackModal
