import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { Highlight } from 'react-instantsearch'
import Typography from '@app/stories/Typography'
import Link from '@app/stories/Link'

const BlogCard = ({ blog = null }) => {
  const { slug, authorName, publishedOn } = blog
  return (
    <Grid item xs={4} sm={4} md={12} lg={12}>
      <Link
        to={`/blog/${slug}`}
        type='internal'
        style={{
          textDecoration: 'none',
          color: 'inherit',
        }}
      >
        <Box
          display='flex'
          width='100%'
          sx={{
            justifyContent: {
              xs: 'center',
              sm: 'center',
              md: 'start',
              lg: 'start',
            },
          }}
        >
          <Box
            style={{
              boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.20)',
              padding: '10px',
              borderRadius: '5px',
              width: '100%',
            }}
          >
            <Typography variant='body1' weight='bold'>
              Title: <Highlight attribute='title' hit={blog} />
            </Typography>
            <Typography variant='body1' weight='light'>
              Author: {authorName}
            </Typography>
            {publishedOn && publishedOn?.toDate?.() && (
              <Typography variant='caption' weight='light'>
                <Box component='span' color='primary.main'>
                  Published on: {publishedOn?.toDate().toLocaleDateString()}
                </Box>
              </Typography>
            )}
            <Typography variant='body1' weight='light'>
              URL: {`${window.location.origin}/blog/${slug}`}
            </Typography>
          </Box>
        </Box>
      </Link>
    </Grid>
  )
}

BlogCard.propTypes = {
  blog: PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.string,
    authorName: PropTypes.string,
    publishedOn: PropTypes.shape({
      toDate: PropTypes.func,
    }),
  }),
}

export default BlogCard
