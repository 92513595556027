import { useEffect } from 'react'
import useInvoicePayments from '@app/hooks/useInvoicePayments'
import { useCurrentUser } from '@app/context/CurrentUser'
import InvoicePaymentTable from './PlanAdminInvoicePaymentTable'

const PlanAdminInvoices = () => {
  const { fetchInvoicePayments, fetchEmrOrderById, invoicePaymentData, invoicePaymentDataLoading } =
    useInvoicePayments()

  const { profileData } = useCurrentUser()

  useEffect(() => {
    profileData.id && fetchInvoicePayments({ planAdminUID: profileData.id })
  }, [profileData.id])

  return (
    <div>
      {!invoicePaymentDataLoading && (
        <InvoicePaymentTable
          fetchEmrOrderById={fetchEmrOrderById}
          invoicesData={invoicePaymentData}
        />
      )}
    </div>
  )
}

export default PlanAdminInvoices
