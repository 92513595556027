import { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import Fade from '@mui/material/Fade'
import Button from '@app/stories/Button'
import Icon from '@app/stories/Icons'
import Typography from '@app/stories/Typography'

const DropDownMenu = ({
  children,
  text,
  variant = 'default',
  drawerProps,
  primaryColor,
  secondaryColor,
  ...props
}) => {
  const [open, setOpen] = useState(false)
  const sideBar = variant === 'sideBar'
  const textSwap = variant === 'textSwap'
  const longer = variant === 'longer'
  const handleTimeout = useRef(null)

  const outlineStyle = `1px solid ${secondaryColor}`

  const { style: drawerStyle = {}, sx: drawerSx = {}, ...restDrawerProps } = drawerProps || {}

  return (
    <>
      <Box
        position='relative'
        onMouseEnter={() => {
          setOpen(true)
          clearTimeout(handleTimeout.current)
        }}
        onMouseLeave={() => {
          handleTimeout.current = setTimeout(() => {
            setOpen(false)
          }, 1000)
        }}
        width={sideBar ? 'calc(100% + 1px)' : 'auto'}
        bgcolor={open ? primaryColor || '#652d92' : 'transparent'}
        borderRadius={open ? '21.125px 21.125px 0 0' : '0'}
        margin='0 0.25rem'
        outline={open ? outlineStyle : 'none'}
        {...props}
      >
        <Button
          variant='text'
          onClick={() => setOpen(!open)}
          style={{
            color: secondaryColor, //eslint-disable-line
            width: '100%',
            height: sideBar ? '42.25px' : 'auto',
            borderRadius: '21.125px 21.125px 0 0',
            justifyContent: 'space-between',
          }}
        >
          {textSwap ? (
            <Box display='flex' alignItems='center' justifyContent='space-between' width='100%'>
              <Typography variant='h4' weight='light' component='p' marginBottom={0}>
                {text}
              </Typography>
              <Icon
                name={open ? 'closeArrow' : 'openArrow'}
                style={{
                  marginLeft: '5px',
                }}
              />
            </Box>
          ) : (
            <>
              {text}
              {sideBar && (
                <Icon
                  name={open ? 'chevronRight' : 'chevronLeft'}
                  style={{
                    marginLeft: '5px',
                  }}
                />
              )}
              {!sideBar && (
                <Icon
                  name={open ? 'closeArrow' : 'openArrow'}
                  style={{
                    marginLeft: '5px',
                  }}
                />
              )}
            </>
          )}
        </Button>
        <Fade in={open} mountOnEnter unmountOnExit>
          <Box
            bgcolor={open ? primaryColor || '#652d92' : 'transparent'}
            outline={open ? outlineStyle : 'none'}
            style={{
              position: 'absolute',
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'flex-start',
              top: sideBar ? '0' : '100%',
              left: sideBar ? '100%' : '0',
              zIndex: 1,
              borderRadius:
                sideBar || longer ? '0 21.125px 21.125px 21.125px' : '0 0 21.125px 21.125px',
              color: '#fff',
              ...drawerStyle,
            }}
            sx={{
              ...drawerSx,
            }}
            {...restDrawerProps}
          >
            {children}
          </Box>
        </Fade>
      </Box>
    </>
  )
}

DropDownMenu.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  variant: PropTypes.string,
}

export default DropDownMenu
