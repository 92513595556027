import PropTypes from 'prop-types'
import { Highlight } from 'react-instantsearch'
import { Box, Typography } from '@mui/material'
import Button from '@app/stories/Button'

const DiagnosisCard = ({ data, buttonText = null, onClick }) => {
  return (
    <Box display='flex' justifyContent='space-between' width='100%'>
      <Box>
        <Typography variant='h5' weight='medium'>
          <Highlight attribute='code' hit={data} />
        </Typography>
        <Typography variant='body1' weight='light'>
          <Highlight attribute='longDescription' hit={data} />
        </Typography>
      </Box>
      <Box>
        <Button
          variant='outlined'
          onClick={() => {
            onClick(data)
          }}
        >
          {buttonText || 'Select'}
        </Button>
      </Box>
    </Box>
  )
}

DiagnosisCard.propTypes = {
  data: PropTypes.shape({
    code: PropTypes.string,
    longDescription: PropTypes.string,
  }).isRequired,
  buttonText: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

export default DiagnosisCard
