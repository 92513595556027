import { Box, Hidden } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Grid from '@mui/material/Grid'
import Button from '@app/stories/Button'
import MisheLeaves from '@app/assets/images/MisheLeaves.png'
import PageSection from '@app/components/PageSection'
import Typography from '@app/stories/Typography'
import background from '@app/assets/images/backgroundTest2.png'
import WordShifterFade from '@app/components/WordShifterFade'
import PhysicianPage1 from '@app/assets/images/PhysicianPage1.png'
import ComponentSequentialLoader from '@app/components/ComponentSequentialLoader'
import ComponentShifterFadeControl from '@app/components/ComponentShifterFadeControl'
import NoUse1 from '@app/assets/images/NoUse1.png'
import NoUse2 from '@app/assets/images/NoUse2.png'
import NoUse3 from '@app/assets/images/NoUse3.png'
import NoUse4 from '@app/assets/images/NoUse4.png'
import ForCliniciansSection1 from '@app/assets/images/for-clinicians-section-1.png'
import ForCliniciansSection2 from '@app/assets/images/for-clinicians-section-2.png'
import ForCliniciansSection3 from '@app/assets/images/for-clinicians-section-3.png'

const HealthPlansPage = () => {
  return (
    <Box>
      <Hidden mdDown>
        <PageSection
          startLoaded
          direction='left'
          image={PhysicianPage1}
          height={700}
          imgWidth='60%'
          infoWidth='660px'
          bgImage={background}
        >
          <Box
            padding='1rem'
            bgcolor='rgba(217, 193, 244, 0.2)'
            boxShadow='0px 4px 4px rgba(0, 0, 0, 0.1)'
          >
            <Box padding='2rem' bgcolor='#652d92' marginLeft='2rem'>
              <Typography
                variant='h5'
                family='poppins'
                weight='medium'
                style={{
                  lineheight: '1.5',
                  color: '#fff',
                  fontSize: '36px',
                  margin: '0',
                }}
              >
                Change is coming.
                <br /> Eliminate fiduciary concerns.
                <br /> Protect your business.
              </Typography>
            </Box>
            {/* <Box
              padding='1rem 0 0 2rem'
              display='flex'
              justifyContent='flex-end'
            >
              <Button
                variant='text'
                style={{
                  padding: '0',
                  height: 'auto'
                }}
              >
                <Typography
                  variant='body1'
                  family='poppins'
                  weight='light'
                  color='primary'
                >
                  Request a demo
                </Typography>
              </Button>
            </Box> */}
          </Box>
        </PageSection>
        <PageSection height={300} bgColorSlide='#652d92' bgColor='#6d319e'>
          <ComponentSequentialLoader length={750}>
            <Box
              display='flex'
              justifyContent='flex-end'
              alignItems='center'
              flexDirection='column'
              color='whitesmoke'
            >
              <Box width='150px'>
                <img src={NoUse1} alt='NoUse1' width='150px' />
              </Box>
              <Typography align='center' variant='h5'>
                <b>Convenient access</b> to care through our curated, nationwide, virtual network
              </Typography>
            </Box>
            <Box
              display='flex'
              justifyContent='flex-end'
              alignItems='center'
              flexDirection='column'
              color='whitesmoke'
            >
              <Box width='150px'>
                <img src={NoUse2} alt='NoUse2' width='150px' />
              </Box>
              <Typography align='center' variant='h5'>
                <b>Proven cost savings</b> from curated partners in a broad set of specialty areas
              </Typography>
            </Box>
            <Box
              display='flex'
              justifyContent='flex-end'
              alignItems='center'
              flexDirection='column'
              color='whitesmoke'
            >
              <Box width='150px'>
                <img src={NoUse3} alt='NoUse3' width='150px' />
              </Box>
              <Typography align='center' variant='h5'>
                Increased specialist access to boost member satisfaction and{' '}
                <b>improve Star Ratings</b>
              </Typography>
            </Box>
            <Box
              display='flex'
              justifyContent='flex-end'
              alignItems='center'
              flexDirection='column'
              color='whitesmoke'
            >
              <Box width='150px' display='flex' justifyContent='center' alignItems='center'>
                <img src={NoUse4} alt='NoUse4' width='150px' />
              </Box>
              <Typography align='center' variant='h5'>
                <b>Integration and communication</b> with your care navigators and care managers
              </Typography>
            </Box>
          </ComponentSequentialLoader>
        </PageSection>
        <PageSection height={550} bgColor='#f6eeff'>
          <ComponentShifterFadeControl cycleLength={10000}>
            <Box height='460px' width='1087px'>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={5} lg={5}>
                  <Box mb={1}>
                    <Typography variant='caption' weight='semibold'>
                      <Box component='span' color='primary.main'>
                        GROW YOUR PRACTICE
                      </Box>
                    </Typography>
                  </Box>
                  <Box mb={2}>
                    <Typography variant='h3' gutterBottom>
                      Market your services
                      <br />
                      with a{' '}
                      <Box component='span' color='primary.main'>
                        digital front door.
                      </Box>
                    </Typography>
                  </Box>
                  <Typography variant='body1' weight='light' style={{ maxWidth: '480px' }}>
                    Price transparency is a priority for the 1-in-3 consumers paying out-of-pocket
                    for care. Unlock demand from the 60% of people avoiding care due to price
                    uncertainty.
                  </Typography>
                  <br /> <br />
                  <Box mb={3}>
                    <Typography
                      variant='body2'
                      gutterBottom
                      weight='light'
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <CheckCircleIcon style={{ color: '#662D91' }} />
                      <span style={{ marginLeft: 20 }}>
                        Meet consumers online where they are searching for care
                      </span>
                    </Typography>
                  </Box>
                  <Box mb={3}>
                    <Typography
                      variant='body2'
                      gutterBottom
                      weight='light'
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <CheckCircleIcon style={{ color: '#662D91' }} />
                      <span style={{ marginLeft: 20 }}>
                        Build trust and acquire new patients while reducing AR
                      </span>
                    </Typography>
                  </Box>
                  <Typography
                    variant='body2'
                    gutterBottom
                    weight='light'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <CheckCircleIcon style={{ color: '#662D91' }} />
                    <span style={{ marginLeft: 20 }}>
                      Built-in referral source from our community of clinicians
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={1} lg={1} />
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <img src={ForCliniciansSection1} alt='ForCliniciansSection1' width='100%' />
                </Grid>
              </Grid>
            </Box>
            <Box height='460px' width='1087px'>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={5} lg={5}>
                  <Box mb={1}>
                    <Typography variant='caption' weight='semibold'>
                      <Box component='span' color='primary.main'>
                        SUPERIOR PAYMENT EXPERIENCE
                      </Box>
                    </Typography>
                  </Box>
                  <Box mb={2}>
                    <Typography variant='h3' gutterBottom>
                      {/* The industry is changing.
                      <br /> */}
                      Stay competitive with{' '}
                      <Box component='span' color='primary.main'>
                        innovative offerings.
                      </Box>
                    </Typography>
                  </Box>
                  <Typography variant='body1' weight='light' style={{ maxWidth: '480px' }}>
                    Direct access to your services guarantees payment and eliminates insurance
                    abrasion. Improve relationships by helping consumers shop and access care with
                    confidence.
                  </Typography>
                  <br /> <br />
                  <Box mb={3}>
                    <Typography
                      variant='body2'
                      gutterBottom
                      weight='light'
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <CheckCircleIcon style={{ color: '#662D91' }} />
                      <span style={{ marginLeft: 20 }}>
                        Contactless upfront payment, no billing
                      </span>
                    </Typography>
                  </Box>
                  <Box mb={3}>
                    <Typography
                      variant='body2'
                      gutterBottom
                      weight='light'
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <CheckCircleIcon style={{ color: '#662D91' }} />
                      <span style={{ marginLeft: 20 }}>
                        Accept HSA/FSA, cryptocurrency and buy-now-pay-later payments
                      </span>
                    </Typography>
                  </Box>
                  <Typography
                    variant='body2'
                    gutterBottom
                    weight='light'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <CheckCircleIcon style={{ color: '#662D91' }} />
                    <span style={{ marginLeft: 20 }}>Reduce headaches, surprises and costs</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={1} lg={1} />
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <img src={ForCliniciansSection2} alt='ForCliniciansSection2' width='100%' />
                </Grid>
              </Grid>
            </Box>
            <Box height='460px' width='1087px'>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={5} lg={5}>
                  <Box mb={1}>
                    <Typography variant='caption' weight='semibold'>
                      <Box component='span' color='primary.main'>
                        TAKE BACK CONTROL
                      </Box>
                    </Typography>
                  </Box>
                  <Box mb={2}>
                    <Typography variant='h3' gutterBottom>
                      <Box component='span' color='primary.main'>
                        Bypass insurance{' '}
                      </Box>
                      &<br />
                      simplify your practice.
                    </Typography>
                  </Box>
                  <Typography variant='body1' weight='light' style={{ maxWidth: '480px' }}>
                    Ensure a frictionless payment experience with no middle-man interference. Get
                    paid for innovative offerings while exploring new delivery channels and care
                    pathways.
                  </Typography>
                  <br /> <br />
                  <Box mb={3}>
                    <Typography
                      variant='body2'
                      gutterBottom
                      weight='light'
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <CheckCircleIcon style={{ color: '#662D91' }} />
                      <span style={{ marginLeft: 20 }}>
                        Maximize productivity with dynamic pricing
                      </span>
                    </Typography>
                  </Box>
                  <Box mb={3}>
                    <Typography
                      variant='body2'
                      gutterBottom
                      weight='light'
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <CheckCircleIcon style={{ color: '#662D91' }} />
                      <span style={{ marginLeft: 20 }}>Distribute demand efficiently</span>
                    </Typography>
                  </Box>
                  <Typography
                    variant='body2'
                    gutterBottom
                    weight='light'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <CheckCircleIcon style={{ color: '#662D91' }} />
                    <span style={{ marginLeft: 20 }}>
                      Protect against no-shows and last-minute cancellations
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={1} lg={1} />
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <img src={ForCliniciansSection3} alt='ForCliniciansSection3' width='100%' />
                </Grid>
              </Grid>
            </Box>
          </ComponentShifterFadeControl>
        </PageSection>
        {/* <PageSection
          height={600}
          bgColorSlide='#fff'
          direction='up'
          slideBorderRadius='80px 80px 0 0'
          slideBoxShadow='0px -8px 8px 0px rgba(0,0,0,0.1)'
          childrenOnSlide
        >
          <Box
            display='flex'
            justifyContent='center'
            height='600px'
            padding='100px'
          >
            <ComponentShifterFade length={5000}>
              <Typography variant='h2' family='poppins' weight='light'>
                Hello this is where we can put some interestin paragraphs about
                things or maybe reviews or something who knows
              </Typography>
              <Typography variant='h2' family='poppins' weight='light'>
                Hello this 2
              </Typography>
              <Typography variant='h2' family='poppins' weight='light'>
                Hello this 3
              </Typography>
            </ComponentShifterFade>
          </Box>
        </PageSection> */}
      </Hidden>
      <Hidden mdUp>
        <PageSection
          startLoaded
          height={500}
          imgWidth='100%'
          infoWidth='100%'
          bgImage={MisheLeaves}
        >
          <Box
            padding='1rem'
            bgcolor='rgba(217, 193, 244, 0.9)'
            boxShadow='0px 4px 4px rgba(0, 0, 0, 0.1)'
            margin='1rem'
          >
            <Box padding='1rem' bgcolor='#652d92'>
              <Typography
                variant='h1'
                family='poppins'
                style={{
                  color: '#d9c1f4',
                  fontSize: '16px',
                  margin: '0',
                }}
              >
                Members
              </Typography>
              <Typography
                variant='h5'
                family='poppins'
                weight='light'
                style={{
                  color: '#fff',
                  fontSize: '24px',
                  margin: '0',
                }}
              >
                We don&apos;t just make your benefits better. We help you use them wherever you are.
              </Typography>
            </Box>
            <Box padding='1rem 2rem 0 2rem' display='flex' justifyContent='space-between'>
              <Typography variant='body1' family='poppins' weight='light' color='primary'>
                Are you a member?
              </Typography>
              <Button
                variant='text'
                style={{
                  margin: '0 1rem',
                  padding: '0',
                  height: 'auto',
                }}
              >
                <Typography variant='body1' family='poppins' weight='light' color='primary'>
                  Sign In
                </Typography>
              </Button>
            </Box>
          </Box>
        </PageSection>
        <PageSection height={100} bgColorSlide='#652d92' bgColor='#6d319e' width='100%'>
          <WordShifterFade inputText={['Find Care', 'Book Care', 'Get Care']} />
        </PageSection>
        <PageSection>
          <ComponentSequentialLoader length={1500}>
            <Typography align='center'>1</Typography>
            <Typography align='center'>2</Typography>
            <Typography align='center'>3</Typography>
            <Typography align='center'>4</Typography>
            <Typography align='center'>5</Typography>
          </ComponentSequentialLoader>
        </PageSection>
      </Hidden>
    </Box>
  )
}

export default HealthPlansPage
