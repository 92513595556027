import { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import TextTruncate from 'react-text-truncate'
import Typography from '../Typography'
import FormattedText from '@app/stories/FormatFromDB'

const MoreButton = styled.button`
  cursor: pointer;
  color: #662d91;
  text-decoration: none;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  background: transparent;
  border: none;
`

const NetworkServiceCardDescription = ({ text = '' }) => {
  const [descriptionTextLimit, setDescriptionTextLimit] = useState(3)

  return (
    <Box width='100%' margin='8px 0px'>
      <Grid
        container
        alignItems='center'
        width='100%'
        style={{
          padding: '0px 4px 0px 4px',
        }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant='body1' weight='light' margin='0px'>
            {descriptionTextLimit !== 0 && (
              <TextTruncate
                line={descriptionTextLimit}
                element='span'
                truncateText='…'
                text={text.replaceAll('|', ' ')}
                textTruncateChild={
                  <MoreButton
                    onClick={(e) => {
                      e.stopPropagation()
                      setDescriptionTextLimit(0)
                    }}
                  >
                    show more
                  </MoreButton>
                }
              />
            )}
            {descriptionTextLimit === 0 && (
              <Box>
                <FormattedText text={text} mt={0} />
                <MoreButton
                  onClick={(e) => {
                    e.stopPropagation()
                    setDescriptionTextLimit(3)
                  }}
                >
                  show less
                </MoreButton>
              </Box>
            )}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

NetworkServiceCardDescription.propTypes = {
  text: PropTypes.string,
}

export default NetworkServiceCardDescription
