import Container from '@app/stories/Container'
import Section from '@app/stories/Section'
import BlogsSearch from '@app/containers/BlogsSearch'
import Typography from '@app/stories/Typography'

const BlogDirectory = () => {
  return (
    <>
      <Section>
        <Container>
          <Typography variant='h1' weight='bold'>
            Blog Directory
          </Typography>
          <BlogsSearch />
        </Container>
      </Section>
    </>
  )
}

export default BlogDirectory
