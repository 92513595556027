import PropTypes from 'prop-types'
import { Box, Button, Checkbox, FormControlLabel, TextField, Typography, Grid } from '@mui/material'
import InstantSearchContainerCustom from '@app/containers/InstantSearchContainerCustom'
import MedicationSearchCard from '@app/components/MedicationSearchCard'

const MedicationSearch = ({ values, setters }) => {
  const {
    medicationSelected,
    SIG,
    quantity,
    drugAllergies,
    otherMedications,
    medicalConditions,
    pregnant,
    nursing,
    safetyCap,
  } = values
  const {
    setMedicationSelected,
    setSIG,
    setQuantity,
    setDrugAllergies,
    setOtherMedications,
    setMedicalConditions,
    setPregnant,
    setNursing,
    setSafetyCap,
  } = setters

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          {!medicationSelected && (
            <Box width='100%' position='relative'>
              {/* We are filtering by pharmacy here because the order flow does not support in person */}
              <InstantSearchContainerCustom
                HitComponent={MedicationSearchCard}
                hitClick={setMedicationSelected}
                indexName='medications'
                configuration={{
                  filters: `pharmacyName:dirx`,
                  hitsPerPage: 5,
                }}
                searchBoxText='Search for a medication'
                noResultsMessage='No results'
              />
            </Box>
          )}
          {medicationSelected && (
            <Box display='flex' flexDirection='column' alignItems='flex-start'>
              <MedicationSearchCard data={medicationSelected} />
              <Button onClick={() => setMedicationSelected(null)} variant='outlined'>
                <Typography variant='body1' color='primary'>
                  Clear Selection
                </Typography>
              </Button>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} sm={6} style={{ marginTop: '16px' }}>
          <Box>
            <TextField
              id='quantity'
              label='Quantity'
              variant='outlined'
              fullWidth
              value={medicationSelected?.requiredQuantity || quantity}
              onChange={(e) => setQuantity(e.target.value)}
              disabled={medicationSelected?.requiredQuantity}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box>
            <TextField
              id='refillsAllowed'
              label='Refills'
              variant='outlined'
              fullWidth
              defaultValue='0'
              disabled
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box>
            <TextField
              id='SIG'
              label='SIG'
              variant='outlined'
              fullWidth
              multiline
              value={SIG}
              onChange={(e) => setSIG(e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box>
            <TextField
              id='dirx-drug-allergy'
              label='Drug Allergies'
              variant='outlined'
              fullWidth
              value={drugAllergies}
              onChange={(e) => setDrugAllergies(e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box>
            <TextField
              id='dirx-other-medications'
              label='Other Medications'
              variant='outlined'
              fullWidth
              value={otherMedications}
              onChange={(e) => setOtherMedications(e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box>
            <TextField
              id='dirx-medical-conditions'
              label='Medical Conditions'
              variant='outlined'
              fullWidth
              value={medicalConditions}
              onChange={(e) => setMedicalConditions(e.target.value)}
            />
          </Box>
        </Grid>
      </Grid>
      <Box mt={2}>
        <FormControlLabel
          control={
            <Checkbox
              id='dirx-pregnant'
              color='primary'
              checked={pregnant}
              onChange={(e) => setPregnant(e.target.checked)}
            />
          }
          label='Pregnant'
        />
        <FormControlLabel
          control={
            <Checkbox
              id='dirx-nursing'
              color='primary'
              checked={nursing}
              onChange={(e) => setNursing(e.target.checked)}
            />
          }
          label='Nursing'
        />
        <FormControlLabel
          control={
            <Checkbox
              id='dirx-safety-cap'
              color='primary'
              checked={safetyCap}
              onChange={(e) => setSafetyCap(e.target.checked)}
            />
          }
          label='Safety Cap'
        />
      </Box>
    </>
  )
}

MedicationSearch.propTypes = {
  values: PropTypes.shape({
    medicationSelected: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    SIG: PropTypes.string,
    quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    drugAllergies: PropTypes.string,
    otherMedications: PropTypes.string,
    medicalConditions: PropTypes.string,
    pregnant: PropTypes.bool,
    nursing: PropTypes.bool,
    safetyCap: PropTypes.bool,
  }).isRequired,
  setters: PropTypes.shape({
    setMedicationSelected: PropTypes.func.isRequired,
    setSIG: PropTypes.func.isRequired,
    setQuantity: PropTypes.func.isRequired,
    setDrugAllergies: PropTypes.func.isRequired,
    setOtherMedications: PropTypes.func.isRequired,
    setMedicalConditions: PropTypes.func.isRequired,
    setPregnant: PropTypes.func.isRequired,
    setNursing: PropTypes.func.isRequired,
    setSafetyCap: PropTypes.func.isRequired,
  }).isRequired,
}

export default MedicationSearch
