import { useCallback, useState } from 'react'
import { getDocument, collection } from '@app/firebase/firestore'
import { currentUser } from '@app/firebase/auth'
import { call } from '@app/firebase/functions'
import { checkFields } from '@app/firebase/utils'
import { usePostHog } from 'posthog-js/react'

/** Fetch and format the managed plan data for a user if they have the PLAN_ADMIN role. */
const fetchManagedPlanData = async (userId, roles) => {
  if (!roles.includes('PLAN_ADMIN')) return {}

  const planAdminSnapshot = await collection('planAdmins')
    .where('adminIDs', 'array-contains', userId)
    .get()

  if (planAdminSnapshot.empty) return {}

  const planAdminData = planAdminSnapshot.docs[0].data()
  const managedPlanID = planAdminData?.managedPlanId
  if (!managedPlanID) return {}

  const planData = await getDocument('networks', managedPlanID)
  return { planData, managedPlanID, planAdminMessage: '' }
}

/**
 * Fetch token claims. We get roles, user_id, and meta info like organizationId, organizationName,
 * acceptedTerms from the token
 */
const fetchTokenClaims = async () => {
  const tokenResult = await currentUser().getIdTokenResult(true)
  const { roles = [], meta, user_id } = tokenResult.claims
  const { organizationId, organizationName, acceptedTerms } = meta || {}

  // why dont we just have PATIENT in the role? TODO add patient to roles and remove this
  if (!roles.includes('PROVIDER') && !roles.includes('PLAN_ADMIN')) {
    roles.push('PATIENT')
  }

  return { organizationId, organizationName, acceptedTerms, roles, user_id }
}

/**
 * Get the user's profile document from Firestore. this will only have partial user info and the
 * rest we need to merge from auth token
 */
const getProfileDocument = async (uid, email) => {
  try {
    return await getDocument('profiles', uid)
  } catch (error) {
    console.error('Error fetching profile document:', error)
    return { email, cart: [] }
  }
}

/** Get all profile profile data by concurrently retrieving token claims, */
const getAllProfileData = async (uid, email) => {
  // fetch token claims.
  const tokenData = await fetchTokenClaims()
  // then concurrently fetch the profile document and managed plan data.
  const [profileData, managedPlanData] = await Promise.all([
    getProfileDocument(uid, email),
    fetchManagedPlanData(tokenData.user_id, tokenData.roles),
  ])

  return { tokenData, profileData, managedPlanData }
}

/** Update the intercom users' record */
const updateIntercomUser = async (uid, email, data) => {
  if (!window?.Intercom) return

  try {
    const intercomHashResponse = await call('intercom-getIntercomHash')
    if (!intercomHashResponse?.data) {
      console.warn('Intercom hash not available.')
      return
    }
    const hash = intercomHashResponse.data
    window.Intercom('update', checkFields(uid, email, hash, data))

    // create connected intercome contact if none exists
    if (!data.intercomContactID) {
      try {
        const contactResult = await call('intercom-addContactID')
        console.log('Intercom contact created:', contactResult)
      } catch (error) {
        console.error('Error adding Intercom contact:', error)
      }
    }
  } catch (error) {
    console.error('Error updating Intercom:', error)
  }
}

/** Setup posthog analytics can make this into a util and use it at other places */
const identifyUserInAnalytics = async (uid, email, posthog) => {
  try {
    posthog.identify(uid, { email: email || '' })
    console.log('user identified in to posthog:', uid)
  } catch (error) {
    console.error('couldnt find posthog connection:', error)
  }
}

const useUser = () => {
  const [profile, setProfile] = useState({})
  const [clinic, setClinic] = useState({})
  const [managedPlans, setManagedPlans] = useState({})
  const [authData, setAuthData] = useState({})
  const posthog = usePostHog()

  /** DO WE NEED THIS? */
  const refresh = useCallback(async () => {
    try {
      const tokenData = await fetchTokenClaims()
      setAuthData((current) => ({ ...current, ...tokenData }))
    } catch (error) {
      console.error('Error refreshing user:', error)
    }
  }, [])

  /** Fefresh the complete profile data by reusing getAllProfileData. */
  const refreshProfile = useCallback(async () => {
    try {
      const userValue = currentUser()
      const userJSON = userValue?.toJSON() || {}

      if (!userJSON) {
        console.error('No current user found.')
        return false
      }
      // Use getAllProfileData to get all updated profile data.

      const { tokenData, profileData, managedPlanData } = await getAllProfileData(
        userJSON.uid,
        userJSON.email,
      )
      if (userJSON.email && userJSON.uid) {
        await updateIntercomUser(userJSON.uid, userJSON.email, profileData)
        await identifyUserInAnalytics(userJSON.uid, userJSON.email, posthog)
      }

      setProfile(profileData)
      setAuthData(tokenData)
      setManagedPlans(managedPlanData)

      if (tokenData?.organizationId) {
        try {
          const clinicData = await getDocument('organizations', tokenData.organizationId)
          setClinic(clinicData)
        } catch (error) {
          console.error('Error fetching clinic data:', error)
        }
      }

      return true
    } catch (error) {
      console.error('Error refreshing profile:', error)
      return false
    }
  }, [])

  /**
   * Log in the user by fetching full profile data, updating external integrations, and setting
   * clinic data if available.
   */
  const login = useCallback(
    async (userValue) => {
      const userJSON = userValue?.toJSON() || {}

      // Concurrently fetch profile data.
      const { tokenData, profileData, managedPlanData } = await getAllProfileData(
        userJSON.uid,
        userJSON.email,
      )

      // Update profile state.
      setProfile(profileData)
      setAuthData(tokenData)
      setManagedPlans(managedPlanData)
      // If an organization is associated, fetch its clinic data.
      if (tokenData?.organizationId) {
        try {
          const clinicData = await getDocument('organizations', tokenData?.organizationId)
          setClinic(clinicData)
        } catch (error) {
          console.error('Error fetching clinic data:', error)
        }
      }

      if (userJSON.email && userJSON.uid) {
        await updateIntercomUser(userJSON.uid, userJSON.email, profileData)
        await identifyUserInAnalytics(userJSON.uid, userJSON.email, posthog)
      }

      return profileData.cart
    },
    [posthog],
  )

  /** Log out the user by resetting state and restarting Intercom. */
  const logout = useCallback(() => {
    if (window?.Intercom) {
      window.Intercom('shutdown')
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'kgysbru8',
      })
    }
    try {
      posthog.reset()
    } catch (error) {
      console.error('error resetting PostHog:', error)
    }
    setProfile({})
    setClinic({})
    setManagedPlans({})
    setAuthData({})
  }, [posthog])

  return {
    profileData: profile,
    managedPlans,
    authData,
    login,
    logout,
    refresh,
    refreshProfile,
    clinic,
  }
}

export default useUser
