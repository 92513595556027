import { useCurrentUser } from '@app/context/CurrentUser'
import Loader from '@app/stories/Loader'
import { Box } from '@mui/material'
// A simple loading indicator (customize as needed)
const Loading = () => (
  <Box display='flex' justifyContent='center'>
    <Loader />
  </Box>
)
/**
 * Higher-Order Component to guard a component until profile context values are loaded.
 *
 * @param {React.ComponentType} Component - The component to render once context is ready.
 * @param {Function} checkFn - A predicate that receives the current profile and returns true if
 *   it's ready.
 * @param {React.ReactNode} fallback - What to render while waiting for the context (defaults to a
 *   loading indicator).
 * @returns {React.FC} A component that renders the fallback until the predicate passes.
 */
export const withContextGuard = (
  Component,
  checkFn = (data) => !!Object.keys(data).length,
  fallback = <Loading />,
) => {
  return (props) => {
    const { profileData, authData } = useCurrentUser()
    if (!checkFn(profileData)) {
      return fallback
    }
    if (!checkFn(authData)) {
      return fallback
    }
    return <Component {...props} />
  }
}
