import Box from '@mui/material/Box'
import Button from '@app/stories/Button'
import TextField from '@app/stories/TextField'

import { useWhiteLabelingContext } from '@app/context/WhiteLabelingContext'

const ResetPasswordForm = (props) => {
  const { planColorPrimary } = useWhiteLabelingContext()

  return (
    <form title='reset-password-flow' {...props}>
      <TextField
        title='email'
        name='email'
        type='email'
        placeholder='Email'
        label='Email'
        required
      />
      <Box mt={2}>
        <Button
          variant='unstyled'
          type='submit'
          value='Reset Password'
          fullWidth
          style={{
            backgroundColor: planColorPrimary,
            color: 'white',
          }}
        >
          Reset Password
        </Button>
      </Box>
    </form>
  )
}

export default ResetPasswordForm
