import { Fragment } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Typography from '@app/stories/Typography'
import Button from '@app/stories/Button'

import { useMapEffects } from '@app/context/MapEffects'

const PharmacySearchCard = ({
  data,
  selected = {},
  onClick = () => {},
  locationID = '',
  variant = '',
  handleSwap = () => {},
  activeStep = 0,
}) => {
  const { handleHover, handleClick } = useMapEffects()

  return (
    <Fragment>
      <Box>
        {variant === 'selected' ? (
          <Box
            display='flex'
            flexDirection='column'
            alignItems='flex-start'
            justifyContent='space-between'
            width='100%'
            padding='16px 0px'
            borderRadius='12px'
            color='#7004c6'
          >
            <Box>
              <Box>
                <Typography
                  variant='h5'
                  weight='bold'
                  family='poppins'
                  style={{ textTransform: 'capitalize', margin: '0px' }}
                >
                  {data.name.toLowerCase()}
                </Typography>
              </Box>
              <Box color='#7004c695'>
                <Typography
                  variant='body1'
                  weight='light'
                  family='poppins'
                  style={{ textTransform: 'capitalize' }}
                >
                  {data.address.toLowerCase()} {data.city.toLowerCase()}, {data.state}{' '}
                  {data.zipcode}
                </Typography>
              </Box>
              {data?._rankingInfo?.geoDistance && (
                <Box color='#888'>
                  <Typography variant='body1' weight='light' color='inherit'>
                    Distance:{' '}
                    {parseInt(data._rankingInfo.geoDistance / 1609.344, 10).toLocaleString()} miles
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        ) : (
          <Box
            onMouseEnter={() => handleHover(true, locationID)}
            onMouseLeave={() => handleHover(false)}
            display='flex'
            flexDirection='column'
            alignItems='flex-start'
            justifyContent='space-between'
            width='100%'
            minHeight='150px'
            padding='16px'
            bgcolor='#7004c615'
            borderRadius='12px'
            border={selected === data ? '2px solid #7004c6' : 'none'}
            boxShadow='0px 4px 4px rgba(0, 0, 0, 0.25)'
            marginBottom='16px'
            color='#7004c6cc'
          >
            <Box>
              <Box>
                <Typography
                  variant='h5'
                  weight='bold'
                  family='poppins'
                  style={{ textTransform: 'capitalize', margin: '0px' }}
                >
                  {data.name.toLowerCase()}
                </Typography>
              </Box>
              <Box color='#7004c695'>
                <Typography
                  variant='body1'
                  weight='light'
                  family='poppins'
                  style={{ textTransform: 'capitalize' }}
                >
                  {data.address.toLowerCase()} {data.city.toLowerCase()}, {data.state}{' '}
                  {data.zipcode}
                </Typography>
              </Box>
              {data?._rankingInfo?.geoDistance && (
                <Box>
                  <Typography variant='body1' weight='light'>
                    Distance:{' '}
                    {parseInt(data._rankingInfo.geoDistance / 1609.344, 10).toLocaleString()} miles
                  </Typography>
                </Box>
              )}
            </Box>
            <Box display='flex' justifyContent='flex-end' width='100%'>
              {selected === data ? (
                <Button
                  variant='contained'
                  size='small'
                  style={{
                    backgroundColor: '#7004c6',
                    borderRadius: '16px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    handleSwap(activeStep + 1)
                    handleHover(false)
                    handleClick(true, locationID)
                  }}
                >
                  <Typography variant='body1' weight='light' family='poppins'>
                    Select this Pharmacy
                  </Typography>
                </Button>
              ) : (
                <Button
                  variant='contained'
                  size='small'
                  style={{
                    backgroundColor: '#7004c6',
                    borderRadius: '16px',
                  }}
                  onClick={() => {
                    onClick(data)
                    handleHover(false)
                    handleClick(true, locationID)
                  }}
                >
                  <Typography variant='body1' weight='light' family='poppins'>
                    View Pharmacy
                  </Typography>
                </Button>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Fragment>
  )
}

PharmacySearchCard.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipcode: PropTypes.string,
    _rankingInfo: PropTypes.shape({
      geoDistance: PropTypes.number,
    }).isRequired,
  }).isRequired,
  selected: PropTypes.object,
  onClick: PropTypes.func,
  locationID: PropTypes.string,
  variant: PropTypes.string,
  selectedSearch: PropTypes.bool,
  handleSwap: PropTypes.func,
  activeStep: PropTypes.number,
}

export default PharmacySearchCard
