import firebase from 'firebase/compat/app'
import { collection } from '@app/firebase/firestore'
import { uploadFile } from '@app/firebase/storage'

// Desc: Custom hook to check if user has uploaded a valid ID
// Usage:
//   const { checkProfileForID } = useIdentificationCards()
//   const hasID = checkProfileForID(profile)
//   if (hasID) {
//     console.log('User has a valid ID')
//   }
const useIdentificationCards = () => {
  // BEGIN Utility Functions
  const checkExpiration = (expiryDate) => {
    if (!expiryDate) {
      console.log('no expiry date - already uploaded')
      return true
    }
    console.log(expiryDate)
    const today = new Date()
    const idDate =
      expiryDate instanceof firebase.firestore.Timestamp ? expiryDate.toDate() : expiryDate
    const diffTime = idDate - today
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    if (diffDays < 7) {
      return false
    }
    return true
  }

  const uploadIdCard = async ({ uid, file }) => {
    console.log('  -- Uploading id card')
    console.log('  -- UID: ', uid)
    console.log('  -- File: ', file)
    const fileRef = await uploadFile(`/idCards/${uid}`, file, { ownerId: uid })
    console.log('  -- fileRef: ', fileRef)
    return fileRef
  }

  // END Utility Functions

  const checkForIdentificationInfo = ({ idNumber, idExpiry, idImageFile, errorCallback }) => {
    console.log('  -- Checking for ID info')

    if (errorCallback) errorCallback(null)

    if (!idNumber) {
      console.warn('  ** no number')
      if (errorCallback) errorCallback('Missing ID Number')
      return false
    }

    if (!idImageFile) {
      console.warn('  ** no file')
      if (errorCallback) errorCallback('Missing Image of ID')

      return false
    }

    if (!idExpiry) {
      console.warn('  ** no expiry')
      if (errorCallback) errorCallback('Missing Expiration Date')
      return false
    }

    if (!checkExpiration(idExpiry)) {
      if (errorCallback)
        errorCallback('Your ID is expired or will expire within 7 days, please provide a valid ID')
      return false
    }

    return true
  }

  const checkProfileForID = ({ profile, errorCallback }) => {
    if (errorCallback) errorCallback(null)

    if (!profile?.idInfo?.idNumber) {
      console.log(' ** Profile does not have ID number')
      return false
    }
    if (!profile?.idInfo?.idExpiry) {
      console.log(' ** Profile does not have expiry')
      return false
    }
    // check if date is expired or will with in 7 days
    if (!checkExpiration(profile?.idInfo?.idExpiry)) {
      console.log(' ** ID expired')
      if (errorCallback)
        errorCallback('Your ID is expired or will expire within 7 days please provide a valid ID.')
      return false
    }

    if (!profile?.idInfo?.idLink) {
      console.log(' ** Profile does not have link to ID Image')
      return false
    }
    return true
  }

  // THIS can only be called on the owner of the profile (UID)
  const updateProfileWithIdentificationInfo = async ({
    uid,
    idNumber,
    idExpiry,
    idFile,
    errorCallback,
  }) => {
    console.log('  == Updating profile')
    console.log('  -- UID: ', uid)
    console.log('  -- ID Number: ', idNumber)
    console.log('  -- ID Expiry: ', idExpiry)
    console.log('  -- ID File: ', idFile)

    const profileRef = collection('profiles').doc(uid)

    const profileDoc = await profileRef.get().catch((err) => {
      console.warn('  ** Error getting profile')
      console.warn(err)
      if (errorCallback) errorCallback('Error getting profile')
      return
    })

    if (!profileDoc || !profileDoc?.exists) {
      console.warn('  ** Profile not found')
      if (errorCallback) errorCallback('Profile not found')
      return
    }

    const idLink = await uploadIdCard({ uid, file: idFile })
    console.log('  -- ID Link: ', idLink)

    if (!idLink) {
      console.warn('  ** Failed to upload ID')
      if (errorCallback) errorCallback('Failed to upload ID')
      return
    }

    const profileData = {
      idInfo: {
        idNumber,
        idExpiry,
        idLink,
      },
    }

    console.log('  -- Profile Data to Upload: ', profileData)

    await profileRef
      .set(profileData, { merge: true })
      .then(() => {
        console.log('  == Profile updated with id info ==')
        return true
      })
      .catch((err) => {
        console.log('Failed to update profile with id')
        console.log(err)
        return false
      })

    return idLink
  }

  return {
    checkProfileForID,
    checkForIdentificationInfo,
    updateProfileWithIdentificationInfo,
  }
}

export default useIdentificationCards
