import Box from '@mui/material/Box'
import Button from '@app/stories/Button'
import Icon from '@app/stories/Icons'
import { usePagination as usePaginationAlgolia } from 'react-instantsearch'
import { useWhiteLabelingContext } from '@app/context/WhiteLabelingContext'

const CustomPagination = (props) => {
  const { pages, currentRefinement, isFirstPage, isLastPage, refine } = usePaginationAlgolia(props)

  const { planColorPrimary } = useWhiteLabelingContext()

  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      borderRadius='4px'
      marginBottom='8px'
    >
      <Box flex='1'>
        <Button
          variant='unstyled'
          onClick={() => refine(currentRefinement - 1)}
          disabled={isFirstPage}
          style={{
            minWidth: '24px',
            width: '100%',
            margin: '0px',
            minHeight: '40px',
            height: '40px',
            borderRadius: '4px 0px 0px 4px',
            backgroundColor: 'white',
            padding: '0px',
            color: isFirstPage ? '#00000030' : planColorPrimary,
            borderTop: `1px solid ${planColorPrimary}`,
            borderBottom: `1px solid ${planColorPrimary}`,
            borderLeft: `1px solid ${planColorPrimary}`,
          }}
        >
          <Icon name='chevronLeft' />
        </Button>
      </Box>
      {pages.map((page) => (
        <Box flex='1'>
          <Button
            variant='unstyled'
            key={page}
            onClick={() => refine(page)}
            disabled={currentRefinement === page}
            style={{
              width: '100%',
              minWidth: '24px',
              margin: '0px',
              minHeight: '40px',
              height: '40px',
              padding: '0px',
              backgroundColor: currentRefinement === page ? planColorPrimary : 'white',
              color: currentRefinement === page ? 'white' : planColorPrimary,
              borderRadius: '0px',
              borderTop: `1px solid ${planColorPrimary}`,
              borderBottom: `1px solid ${planColorPrimary}`,
              borderLeft: `1px solid ${planColorPrimary}`,
            }}
          >
            {page + 1}
          </Button>
        </Box>
      ))}
      <Box flex='1'>
        <Button
          variant='unstyled'
          onClick={() => refine(currentRefinement + 1)}
          disabled={isLastPage}
          style={{
            width: '100%',
            minWidth: '24px',
            margin: '0px',
            minHeight: '40px',
            height: '40px',
            padding: '0px',
            borderRadius: '0px 4px 4px 0px',
            color: isLastPage ? '#00000030' : planColorPrimary,
            backgroundColor: 'white',
            border: `1px solid ${planColorPrimary}`,
          }}
        >
          <Icon name='chevronRight' />
        </Button>
      </Box>
    </Box>
  )
}

export default CustomPagination
