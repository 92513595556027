import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import PlanAdminInvoiceRow from '@app/components/Invoice/PlanAdminInvoiceRow'

export default function InvoicePaymentTable({ invoicesData, fetchEmrOrderById }) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label='collapsible table'>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align='center'>Amount</TableCell>
            <TableCell align='center'>Invoice ID</TableCell>
            <TableCell align='center'>Status</TableCell>
            <TableCell align='center'>Paid Date</TableCell>
            <TableCell align='center'>Finalized Date</TableCell>
            <TableCell align='center'>Invoice </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoicesData &&
            invoicesData.map((row) => (
              <PlanAdminInvoiceRow key={row.id} row={row} fetchEmrOrderById={fetchEmrOrderById} />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
