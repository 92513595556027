import PropTypes from 'prop-types'
import { useState } from 'react'
import Grid from '@mui/material/Grid'
import Container from '@app/stories/Container'
import Section from '@app/stories/Section'
import Box from '@mui/material/Box'
import Button from '@app/stories/Button'

import InstantSearchContainerCustom from '@app/containers/InstantSearchContainerCustom'
import ServiceSearchCardNetwork from '@app/components/ServiceSearchCardNetwork'
import PlanAdminProviderSearchCard from '@app/components/PlanAdminProviderSearchCard'
import Typography from '@app/stories/Typography'

import { useWhiteLabelingContext } from '@app/context/WhiteLabelingContext'

const NetworkCareTypesListNetwork = ({ networkSlug, networkData }) => {
  const {
    searchTypes,
    providerSearchButtonText,
    servicesSearchButtonText,
    preventBooking,
    noResultsContent = {},
    showServiceSearchResponsibilityBreakdown = false,
  } = networkData
  const [searchLimit, setSearchLimit] = useState(10)
  const [currentSearchType, setCurrentSearchType] = useState('providers')

  const { planColorPrimary } = useWhiteLabelingContext()

  console.log(networkData, 'networkData')

  const ProvidersSearchComponent = () => {
    const { providerSearchTagFilters = [] } = networkData

    const configuration = {
      hitsPerPage: searchLimit,
    }

    if (providerSearchTagFilters?.length > 0) {
      configuration.filters = providerSearchTagFilters.map((tag) => `tags:${tag}`).join(' OR ')
    }

    return (
      <InstantSearchContainerCustom
        HitComponent={PlanAdminProviderSearchCard}
        // hitClick={`/plans/${networkSlug}/service/`}
        indexName='providers'
        showInitialHits
        configuration={configuration}
        searchBoxText='Search for a provider'
        noResultsMessage={noResultsContent?.text || 'No results'}
        usePagination
        networkLanderSearch
        noResultsColor='#000'
        customZIndex={5}
        setSearchLimit={setSearchLimit}
        searchLimit={searchLimit}
        whiteLabelingPrimaryColor={planColorPrimary}
        noResultsLink={noResultsContent?.link}
      />
    )
  }

  const SearchComponent = () => {
    return (
      <Box
        width='100%'
        sx={{
          minHeight: {
            xs: '110vh',
            sm: '110vh',
            md: '75vh',
            lg: '75vh',
          },
        }}
      >
        <InstantSearchContainerCustom
          HitComponent={ServiceSearchCardNetwork}
          hitClick={`/plans/${networkSlug}/service/`}
          indexName='network_services'
          showInitialHits
          configuration={{
            filters: `network.slug:${networkSlug}`,
            hitsPerPage: searchLimit,
          }}
          searchBoxText='Search for symptom, service, or provider'
          noResultsMessage='No results'
          noResultsColor='#000'
          customZIndex={5}
          networkLanderSearch
          setSearchLimit={setSearchLimit}
          searchLimit={searchLimit}
          usePagination
          overflowShown
          whiteLabelingPrimaryColor={planColorPrimary}
          preventBooking={preventBooking}
          showServiceSearchResponsibilityBreakdown={showServiceSearchResponsibilityBreakdown}
          sortByItems={[
            {
              label: 'Most Relevant',
              value: 'network_services',
            },
            {
              label: 'Price: Low to High',
              value: 'network_services_price_asc',
            },
            {
              label: 'Price: High to Low',
              value: 'network_services_price_desc',
            },
          ]}
        />
      </Box>
    )
  }
  if (!searchTypes || searchTypes?.length === 0) {
    return (
      <Container>
        <Box position='relative'>
          <SearchComponent />
        </Box>
      </Container>
    )
  }

  if (searchTypes.includes('providers') && searchTypes.includes('services')) {
    return (
      <Section id='specialties'>
        <Box
          position='relative'
          padding='20px'
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          sx={{
            marginBottom: {
              xs: '0px',
              md: '40px',
            },
            paddingTop: {
              xs: '16px',
              md: '64px',
            },
          }}
        >
          <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            textAlign='center'
            marginBottom='20px'
          >
            <Typography variant='h3' gutterBottom color={planColorPrimary} align='center'>
              {networkData?.planSearchTextPrimary || 'Search for providers or services'}
            </Typography>
            <Typography variant='body1' gutterBottom weight='light' align='center'>
              {networkData?.planSearchTextSecondary ||
                'Find the right provider or service to meet your needs'}
            </Typography>
          </Box>
          <Box
            display='flex'
            justifyContent='center'
            marginBottom='20px'
            borderBottom={`1px solid ${planColorPrimary}`}
            width='min-content'
            paddingX='32px'
          >
            <Button
              size='medium'
              variant='unstyled'
              onClick={() => setCurrentSearchType('providers')}
              style={{
                borderBottom:
                  currentSearchType === 'providers'
                    ? `5px solid ${planColorPrimary}`
                    : '5px solid #ffffff00',
                marginLeft: '0px',
                borderRadius: '0px',
                color: currentSearchType === 'providers' ? `${planColorPrimary}` : '#000',
                whiteSpace: 'nowrap',
              }}
            >
              {providerSearchButtonText || 'Providers'}
            </Button>
            <Button
              size='medium'
              variant='unstyled'
              onClick={() => setCurrentSearchType('services')}
              style={{
                borderBottom:
                  currentSearchType === 'services'
                    ? `5px solid ${planColorPrimary}`
                    : '5px solid #ffffff00',
                marginLeft: '0px',
                borderRadius: '0px',
                color: currentSearchType === 'services' ? `${planColorPrimary}` : '#000',
                whiteSpace: 'nowrap',
              }}
            >
              {servicesSearchButtonText || 'Services'}
            </Button>
          </Box>
        </Box>
        <Container>
          <Grid container spacing={3} display='flex' alignItems='center'>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                height: {
                  xs: '175vh',
                  md: '85vh',
                },
              }}
            >
              {currentSearchType === 'providers' && <ProvidersSearchComponent />}
              {currentSearchType === 'services' && <SearchComponent />}
            </Grid>
          </Grid>
        </Container>
      </Section>
    )
  }

  if (searchTypes.includes('providers')) {
    return (
      <Section id='specialties'>
        <Container>
          <Grid container spacing={3} display='flex' alignItems='center'>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ height: { xs: '140vh', md: '85vh' } }}>
              <ProvidersSearchComponent />
            </Grid>
          </Grid>
        </Container>
      </Section>
    )
  }

  return (
    <Section id='specialties'>
      <Container>
        <Grid container spacing={3} display='flex' alignItems='center'>
          <Grid item xs={12} sm={12} md={12} lg={12} style={{ height: '80vh' }}>
            <SearchComponent />
          </Grid>
        </Grid>
      </Container>
    </Section>
  )
}

NetworkCareTypesListNetwork.propTypes = {
  networkSlug: PropTypes.string.isRequired,
}

export default NetworkCareTypesListNetwork
