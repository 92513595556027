import Proptypes from 'prop-types'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Hidden from '@mui/material/Hidden'
import HSAFSABadge from '@app/assets/images/fsa-hsa-eligible.png'
import BottomTopBar from '@app/stories/BottomTopBar'
import BottomBar from '@app/stories/BottomBar'
import NavLink from '@app/stories/NavLink'
import IconButton from '@app/stories/IconButton'
import Icon from '@app/stories/Icons'
import Typography from '@app/stories/Typography'
import Link from '@app/stories/Link'

import LOGO from '@app/assets/images/LogoText.svg'

import { useWhiteLabelingContext } from '@app/context/WhiteLabelingContext'

const currentYear = () => {
  return new Date().getFullYear()
}

const QuickLinks = ({ links }) => {
  return (
    <Box>
      <Grid item xs={12} sm={12} md={6}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: {
              xs: 'center',
              md: 'flex-start',
            },
          }}
        >
          <Box mb={1}>
            <Typography variant='body1' weight='bold' color='primary'>
              QUICK LINKS
            </Typography>
          </Box>
          {links.map((item) => {
            return (
              <Box key={item.name} mb={1}>
                <Link
                  display='inline'
                  size='small'
                  to={item.link}
                  type={item.type || 'internal'}
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{
                    textDecoration: 'none',
                  }}
                >
                  <Typography variant='body2'>{item.name}</Typography>
                </Link>
              </Box>
            )
          })}
        </Box>
      </Grid>
    </Box>
  )
}

const Footer = () => {
  const quickLinks = [
    { name: 'For Providers', link: '/providers' },
    { name: 'For Brokers', link: '/brokers' },
    { name: 'Provider Enrollment', link: '/joinmishe' },
    { name: ' Help Center', type: 'external', link: 'https://help.mishe.co' },
  ]

  const { planColorPrimary } = useWhiteLabelingContext()

  return (
    <>
      {planColorPrimary === '#652d92' && (
        <BottomTopBar>
          <Grid container spacing={3}>
            <Hidden mdDown>
              <Box display='flex' justifyContent='flex-start'>
                <Box width='50%' padding='24px'>
                  <Link type='internal' to='/'>
                    <img src={LOGO} height='37px' alt='logo' style={{ paddingBottom: '10px' }} />
                  </Link>
                  <Typography variant='body2' weight='light'>
                    We&apos;re healing our healthcare system by offering access, transparency and
                    equity to all. Access medical care without restrictions, surprises, and
                    additional costs with Mishe.
                  </Typography>
                  <br />
                  <Typography variant='body2' weight='light'>
                    Mishe does not provide medical advice, diagnosis, or treatment. Mishe does not
                    recommend or endorse any specific physicians, professionals, products,
                    procedures, or services. Mishe is not health insurance. Mishe provides seamless
                    access to quality medical care. If you or someone you know is experiencing an
                    emergency or crisis and needs immediate help, call 911 or go to the nearest
                    emergency room.
                  </Typography>
                </Box>
                <Box width='50%' padding='24px'>
                  <QuickLinks links={quickLinks} />
                </Box>
              </Box>
            </Hidden>
            <Hidden mdUp>
              <Box textAlign='center' padding='24px'>
                <Link type='internal ' to='/'>
                  <img src={LOGO} height='37px' alt='logo' style={{ paddingBottom: '10px' }} />
                </Link>
                <Typography variant='body2' weight='light' align='center'>
                  We&apos;re healing our healthcare system by offering access, transparency and
                  equity to all. Access medical care without restrictions, surprises, and additional
                  costs with Mishe.
                </Typography>
                <br />
                <Typography variant='body2' weight='light' align='center'>
                  Mishe does not provide medical advice, diagnosis, or treatment. Mishe does not
                  recommend or endorse any specific physicians, professionals, products, procedures,
                  or services. Mishe is not health insurance. Mishe provides seamless access to
                  quality medical care. If you or someone you know is experiencing an emergency or
                  crisis and needs immediate help, call 911 or go to the nearest emergency room.
                </Typography>
              </Box>
            </Hidden>
            <Hidden mdUp>
              <Box width='100%' justifyContent='center' padding='24px'>
                <QuickLinks links={quickLinks} />
              </Box>
            </Hidden>
            <Hidden smDown>
              <Box ml={2} style={{ width: '100%' }}>
                <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'left' }}>
                  <img src={HSAFSABadge} height='33px' alt='HSA / FSA badge' />
                </Grid>
              </Box>
            </Hidden>
          </Grid>
        </BottomTopBar>
      )}
      <BottomBar
        style={{
          backgroundColor: planColorPrimary,
        }}
      >
        <Hidden mdDown>
          <Box display='flex' alignItems='center' justifyContent='flex-start' width='33%'>
            <Box display='flex' alignItems='center' justifyContent='center'>
              <IconButton target='_blank' href='https://twitter.com/mishehealth' size='large'>
                <Icon name='twitter' color='white' />
              </IconButton>
              <IconButton
                target='_blank'
                href='https://www.instagram.com/mishe.health/'
                size='large'
              >
                <Icon name='instagram' color='white' />
              </IconButton>
              <IconButton target='_blank' href='https://www.facebook.com/mishe.health' size='large'>
                <Icon name='facebook' color='white' />
              </IconButton>
              <IconButton target='_blank' href='https://www.youtube.com/@mishehealth' size='large'>
                <Icon name='youtube' color='white' />
              </IconButton>
            </Box>
          </Box>
          <Box display='flex' alignItems='center' justifyContent='center' width='33%'>
            <Grid item xs={12} sm={12} md={4}>
              <Typography variant='body2' style={{ whiteSpace: 'nowrap' }}>
                &#169; Copyright {currentYear()} Mishe, Inc | All Rights Reserved
              </Typography>
            </Grid>
          </Box>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='flex-end'
            width='33%'
            marginRight='48px'
          >
            <Box display='flex' alignItems='center' justifyContent='flex-end'>
              <NavLink to='/privacy-policy' style={{ whiteSpace: 'nowrap' }}>
                privacy policy
              </NavLink>
              <NavLink to='/terms' style={{ whiteSpace: 'nowrap' }}>
                terms of use
              </NavLink>
            </Box>
          </Box>
        </Hidden>
        <Hidden mdUp>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            width='100%'
            flexDirection='column'
          >
            <Box margin='auto'>
              <IconButton target='_blank' href='https://twitter.com/mishehealth' size='large'>
                <Icon name='twitter' color='white' />
              </IconButton>
              <IconButton
                target='_blank'
                href='https://www.instagram.com/mishe.health/'
                size='large'
              >
                <Icon name='instagram' color='white' />
              </IconButton>
              <IconButton target='_blank' href='https://www.facebook.com/mishe.care' size='large'>
                <Icon name='facebook' color='white' />
              </IconButton>
              <IconButton
                target='_blank'
                href='https://www.youtube.com/channel/UCQOg7eSM8FqkMPnzFbC0VdA'
                size='large'
              >
                <Icon name='youtube' color='white' />
              </IconButton>
            </Box>
            <Typography variant='body2' align='center' style={{ whiteSpace: 'nowrap' }}>
              &#169; Copyright {currentYear()} Mishe, Inc | All Rights Reserved
            </Typography>
            <Box margin='auto' mt={3}>
              <NavLink to='/privacy-policy'>privacy policy</NavLink>
              <NavLink to='/terms'>terms of use</NavLink>
            </Box>
          </Box>
        </Hidden>
      </BottomBar>
    </>
  )
}
QuickLinks.propTypes = {
  links: Proptypes.arrayOf(
    Proptypes.shape({
      name: Proptypes.string,
      link: Proptypes.string,
      type: Proptypes.string,
    }),
  ).isRequired,
}

export default Footer
