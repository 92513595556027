import { Typography, Box, CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import { stripePaymentEmbedded } from '@app/firebase/functions'
import { useCurrentUser } from '@app/context/CurrentUser'
import { collection } from '@app/firebase/firestore'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import {
  ConnectPayouts,
  ConnectComponentsProvider,
  ConnectAccountOnboarding,
  ConnectNotificationBanner,
} from '@stripe/react-connect-js'
import { loadConnectAndInitialize } from '@stripe/connect-js'
import { useWhiteLabelingContext } from '@app/context/WhiteLabelingContext'
import StripeConnectAccount from './StripeConnectAccount'

const VITE_STRIPE_KEY = import.meta.env.VITE_STRIPE_KEY

const EFTEnrollment = () => {
  const { profileData, authData } = useCurrentUser()
  const { planColorSecondary, planColorPrimary } = useWhiteLabelingContext()

  const [pageDataLoading, setPageDataLoading] = useState(false)
  const [orgBillingDetails, setOrgBillingDetails] = useState(false)

  const [showCreateStripeAccount, setShowCreateStripeAccount] = useState(false)
  const [setUpEnrollmentProcess, setSetupEnrollmentProcess] = useState(false)
  const [stripeConnectInstance, setStripeConnectInstance] = useState(null)
  const [showAccessLevelMessage, setShowAccessLevelMessage] = useState(false)

  useEffect(() => {
    setPageDataLoading(true)
    if (authData.organizationId) {
      const getClinicData = async () => {
        collection('organizations')
          .doc(authData.organizationId)
          .onSnapshot(
            (doc) => {
              if (!doc.exists) {
                console.error('No such clinic document!')
                return null
              }
              const { billing, allowBilling } = doc.data()
              setPageDataLoading(false)
              setOrgBillingDetails({
                id: doc.id,
                ...billing,
              })
              const checkEnable =
                allowBilling?.find((e) => e.email === profileData.email)?.enable === true
              console.log('checkEnable------', checkEnable)
              if (billing && checkEnable) {
                setShowCreateStripeAccount(true)
                setShowAccessLevelMessage(false)
              } else if (billing && !checkEnable) {
                setShowAccessLevelMessage('You do not have access to this page')
              } else if (!billing && checkEnable) {
                setSetupEnrollmentProcess(true)
                setShowAccessLevelMessage(false)
              } else if (!billing && !checkEnable) {
                setShowAccessLevelMessage('You do not have access to this page')
              }
            },
            (error) => {
              console.error('  ** Error getting clinic data:', error)
            },
          )
      }
      getClinicData()
    } else {
      setPageDataLoading(false)
    }
  }, [authData.organizationId, profileData.email])

  console.log('orgBillingDetails----', orgBillingDetails)

  const getStripeConnectInstance = () => {
    const fetchClientSecret = async () => {
      return await stripePaymentEmbedded({
        stripeConnectAccountId: orgBillingDetails?.stripeConnectAccountId,
      })
        .then(async (data) => {
          const { client_secret: clientSecret } = data
          console.log('clientSecret', clientSecret)
          return clientSecret
        })
        .catch((err) => {
          console.log('err', err)
        })
    }

    return loadConnectAndInitialize({
      // This is your test publishable API key.
      publishableKey: VITE_STRIPE_KEY || '',
      fetchClientSecret: fetchClientSecret,
      fonts: [
        {
          cssSrc: 'https://fonts.googleapis.com/css2?family=Poppins',
        },
      ],
      appearance: {
        overlays: 'dialog',
        variables: {
          colorPrimary: planColorPrimary,
          colorSecondary: planColorSecondary || '#f7f9fc',
          colorBackground: 'white',
          colorError: 'red',
          colorSuccess: 'green',
          colorText: 'black',
          fontFamily: 'Poppins',
          fontWeight: '400',
          fontSize: '12px',
          lineHeight: '16px',
          color: '#000',
          '&.Mui-selected, &.Mui-selected:hover': {
            color: '#fff',
            backgroundColor: '#662d91',
          },
        },
      },
    })
  }

  useEffect(() => {
    if (orgBillingDetails?.stripeConnectAccountId) {
      setStripeConnectInstance(getStripeConnectInstance())
    }
  }, [orgBillingDetails])

  console.log('orgBillingDetails', orgBillingDetails)

  const { detailsSubmitted, payoutsEnabled, chargesEnabled } = orgBillingDetails || {
    detailsSubmitted: false,
    payoutsEnabled: false,
    chargesEnabled: false,
  }

  console.log('showCreateStripeAccount', showCreateStripeAccount)
  console.log('stripeConnectInstance-----', stripeConnectInstance)
  return (
    <div>
      {pageDataLoading ? (
        <CircularProgress />
      ) : (
        <>
          {showAccessLevelMessage && <Typography>{showAccessLevelMessage}</Typography>}

          {setUpEnrollmentProcess && <StripeConnectAccount />}

          {showCreateStripeAccount && stripeConnectInstance && (
            <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 5,
                }}
              >
                <Box
                  sx={{
                    bgcolor: 'background.paper',
                    width: { xs: '100%', md: '50%', lg: '20%' },
                    borderRadius: 1,
                    border: 1,
                    borderColor: 'grey.300',
                    borderStyle: 'solid',
                    padding: 2,
                  }}
                >
                  <Typography variant='h4'>Bank Setup Status</Typography>

                  <Box sx={{ width: '100%', paddingTop: '2rem', bgcolor: 'background.paper' }}>
                    <Box display='flex' alignItems='center' mb={2}>
                      {orgBillingDetails?.detailsSubmitted ? <CheckIcon /> : <CloseIcon />}
                      <Typography variant='body1'>Details submitted</Typography>
                    </Box>
                    <Box display='flex' alignItems='center' mb={2}>
                      {orgBillingDetails?.payoutsEnabled ? <CheckIcon /> : <CloseIcon />}
                      <Typography variant='body1'>Payouts enabled</Typography>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    bgcolor: 'background.paper',
                    width: { xs: '100%', md: '50%', lg: '30%' },
                    borderRadius: 1,
                    border: 1,
                    borderColor: 'grey.300',
                    borderStyle: 'solid',
                    padding: 2,
                  }}
                >
                  <Typography variant='h4'>Stripe account details</Typography>

                  <ConnectNotificationBanner
                    collectionOptions={{
                      fields: 'eventually_due',
                      futureRequirements: 'include',
                    }}
                  />

                  {detailsSubmitted && payoutsEnabled && chargesEnabled ? (
                    <Box display='flex' alignItems='center' mt={5}>
                      <Typography variant='body1'>
                        You are all set up. We will let you know if any more information is
                        required.{' '}
                      </Typography>
                    </Box>
                  ) : (
                    <ConnectAccountOnboarding
                      collectionOptions={{
                        fields: 'eventually_due',
                        futureRequirements: 'include',
                      }}
                    />
                  )}
                </Box>

                <Box
                  sx={{
                    bgcolor: 'background.paper',
                    width: { xs: '100%', md: '100%', lg: '50%' },
                    borderRadius: 1,
                    border: 1,
                    borderColor: 'grey.300',
                    borderStyle: 'solid',
                    padding: 2,
                  }}
                >
                  <Typography marginBottom={5} variant='h4'>
                    Payouts
                  </Typography>
                  <ConnectPayouts />
                </Box>
              </Box>
            </ConnectComponentsProvider>
          )}
        </>
      )}
    </div>
  )
}

export default EFTEnrollment
