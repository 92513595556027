import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { Container, Hidden } from '@mui/material'
import Typography from '@app/stories/Typography'
import IconButton from '@app/stories/IconButton'
import Icon from '@app/stories/Icons'
import Link from '@app/stories/Link'

const BlogBody = () => {
  return (
    <Container>
      <Box
        sx={{
          marginTop: {
            xs: '0px',
            sm: '0px',
            md: '50px',
            lg: '50px',
          },
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Hidden mdDown>
              <Box mb={1}>
                <Typography variant='caption' weight='semibold'>
                  <Box component='span' color='primary.main'>
                    Share On:
                  </Box>
                </Typography>
              </Box>
              <Box>
                <IconButton
                  target='_blank'
                  href='https://twitter.com/intent/tweet?url=https://mishe.co/blog/introtomishe'
                  size='large'
                >
                  <Icon name='twitter' color='primary' />
                </IconButton>
              </Box>
              <Box>
                <IconButton
                  target='_blank'
                  href='https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fmishe.co%2Fblog%2Fintrotomishe'
                  size='large'
                >
                  <Icon name='facebook' color='primary' />
                </IconButton>
              </Box>
              <Box mb={1}>
                <IconButton
                  target='_blank'
                  href='https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fmishe.co%2Fblog%2Fintrotomishe'
                  size='large'
                >
                  <Icon name='linkedin' color='primary' />
                </IconButton>
              </Box>
            </Hidden>
          </Grid>
          <Grid item xs={12} sm={12} md={1} lg={1} />
          <Grid item xs={12} sm={12} md={9} lg={9}>
            <Box mb={5}>
              <Typography variant='h1' gutterBottom weight='medium'>
                How I Almost Bankrupted My Patient
              </Typography>
              <Typography variant='h4' gutterBottom weight='light'>
                Dr. Sandeep Palakodeti, MD, MPH
              </Typography>
              <Hidden mdUp>
                <Box display='flex'>
                  <Box mt={1}>
                    <Typography variant='caption' weight='semibold'>
                      <Box component='span' color='primary.main'>
                        Share On:
                      </Box>
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton
                      target='_blank'
                      href='https://twitter.com/intent/tweet?url=https://mishe.co/blog/introtomishe'
                      size='large'
                    >
                      <Icon name='twitter' color='primary' />
                    </IconButton>
                  </Box>
                  <Box>
                    <IconButton
                      target='_blank'
                      href='https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fmishe.co%2Fblog%2Fintrotomishe'
                      size='large'
                    >
                      <Icon name='facebook' color='primary' />
                    </IconButton>
                  </Box>
                  <Box mb={1}>
                    <IconButton
                      target='_blank'
                      href='https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fmishe.co%2Fblog%2Fintrotomishe'
                      size='large'
                    >
                      <Icon name='linkedin' color='primary' />
                    </IconButton>
                  </Box>
                </Box>
              </Hidden>
            </Box>
            <Box mb={4}>
              <Typography variant='body1' weight='medium' style={{ maxWidth: '750px' }}>
                Ever since Veronica’s* husband had died a year ago, she has been the sole
                breadwinner for her family, and the only caregiver for her elderly mother and
                special needs child. The gnawing in her stomach she suffered with for years only
                seemed to be getting worse. She tried some over the counter medications, but nothing
                seemed to work. The pain persisted after she drank her morning coffee, and now she
                was starting to notice a change in her stools as well.
              </Typography>
            </Box>
            <Box mb={4}>
              <Typography variant='body1' weight='medium' style={{ maxWidth: '750px' }}>
                Her signs and symptoms were classic for a stomach ulcer, perhaps now worsened by the
                chronic stress and erratic quick meals for which Veronica could steal a few minutes,
                given she was juggling 2 jobs and caring for her family. After trialing a few
                options, we ultimately sent Veronica to see a GI specialist to place a special
                camera into her stomach and try to heal the ulcer. What we didn’t expect to find was
                a diagnosis that would change Veronica’s life.
              </Typography>
            </Box>
            <Box mb={4}>
              <Typography variant='body1' weight='medium' style={{ maxWidth: '750px' }}>
                A biopsy of her stomach unfortunately showed gastric cancer. And thus started a
                series of events that Veronica would later explain as “a whirlwind, an emotional
                roller-coaster. I could barely understand all the medical jargon, let alone
                understand how much this was all going to cost.” Veronica was insured, although, in
                order to preserve as much disposable income as she could, she had opted into her
                employer’s High-Deductible Health Plan (HDHP), a reality which is{' '}
                <Link
                  type='external'
                  display='inline'
                  to='https://www.kff.org/report-section/ehbs-2020-section-8-high-deductible-health-plans-with-savings-option/#:~:text=Enrollment%20in%20HDHP%2FSOs%20has,HSA%2Dqualified%20HDHPs%20in%202020.'
                >
                  increasingly more prevalent{' '}
                </Link>
                across the American workforce.
              </Typography>
            </Box>
            <Box mb={4}>
              <Typography variant='body1' weight='medium' style={{ maxWidth: '750px' }}>
                Veronica needed the extra cash to pay for day care, special classes for her Autistic
                child, putting safety rails up in her bathroom, installing a ramp, paying a home
                visiting nurse for her bed-bound mother, and a host of medical bills and basic
                living expenses to care for her family. She never expected that at the young age of
                50, she’d be the one navigating a worrisome cancer diagnosis herself.
              </Typography>
            </Box>
            <Box mb={4}>
              <Typography variant='body1' weight='medium' style={{ maxWidth: '750px' }}>
                We spoke about her options and Veronica chose to see an Oncologist and Surgeon close
                to the small midwestern town where she lived and worked – she couldn’t afford to be
                too far away from her son and mother, and was still trying to work her evening shift
                job to make sure she could pay the bills. And so she began her journey to heal
                herself, so she could continue caring for her family.
              </Typography>
            </Box>
            <Box mb={4}>
              <Typography variant='body1' weight='medium' style={{ maxWidth: '750px' }}>
                Fast forward 10 months: after several intense rounds of treatments and surgeries,
                Veronica sat in front of me beaming as we reviewed her latest scan and laboratory
                work - the mass had been removed and she was now cancer free. We shared a touching
                moment which I have come to cherish as a physician, one in which we have guided a
                patient through a difficult time, mobilized the marvels of modern medicine, and
                ultimately healed them of an otherwise deadly condition. It is beautiful and
                profound, and the gratitude and love in those moments is something all of us in
                healthcare are thankful to experience.
              </Typography>
            </Box>
            <Box mb={4}>
              <Typography variant='body1' weight='medium' style={{ maxWidth: '750px' }}>
                Yet, Veronica’s tone quickly turned more somber. She pulled out a file from her bag
                and showed me what her total medical bills had added up to over the past few months:
                over $40,000 of out-of-pocket expenses. My heart sank. Of all the tragedies that we
                see in medicine and that we are trained to navigate, no one ever teaches you what to
                do when you’ve bankrupted your patient. I sat with Veronica as she explained how she
                came to realize the critical access hospital close to her home was actually out of
                network. While her Oncologist and Surgeon were covered by her insurance, the ER
                physician she saw when she had a bout of bleeding as well as the anesthesiologist
                who was part of her surgery were out-of-network and none of the post-recovery time
                or treatments were covered benefits under her health plan.
              </Typography>
            </Box>
            <Box mb={4}>
              <Typography variant='body1' weight='medium' style={{ maxWidth: '750px' }}>
                She had dutifully paid her premiums, co-pays, and deductibles, now she was strapped
                with co-insurance bills that would certainly lead to financial ruin. She began
                weeping as she described the calls from hospital debt collectors, how she recently
                applied for food stamps to feed her family, and how she had foregone the
                post-operative pain meds so she could use that money to fix her mother’s wheelchair,
                rather than the expensive co-pay.
              </Typography>
            </Box>
            <Box mb={4}>
              <Typography variant='body1' weight='medium' style={{ maxWidth: '750px' }}>
                How could this have happened? How, when someone had presumably done everything
                “right” in the most developed and medically advanced nation in the world, who is
                insured and just trying to care for their family, could they be led to bankruptcy
                for the random ill-fated chance of receiving a cancer diagnosis? The amazing
                advances we’ve seen in therapeutics and medicine had cured her and certainly added
                years to her life, yet at what cost? We had conquered death, but Veronica was now
                suffering from a new crisis and chronic disease, medically-induced poverty.
              </Typography>
            </Box>
            <Box mb={4}>
              <Typography variant='body1' weight='medium' style={{ maxWidth: '750px' }}>
                As a physician, a healthcare leader, a community member, and a human being, I
                believe this is fundamentally wrong.{' '}
                <Link
                  type='external'
                  display='inline'
                  to='https://jamanetwork.com/journals/jama/article-abstract/2782187'
                >
                  Nearly 1 in 5 of our friends and neighbors have incurred medical debt,{' '}
                </Link>
                and{' '}
                <Link type='external' display='inline' to='https://library.nclc.org/ca/090101'>
                  over half of all debt in collections in this country is due predominantly to
                  unpaid healthcare bills.{' '}
                </Link>
                Like Veronica,{' '}
                <Link type='external' display='inline' to='https://library.nclc.org/ca/090101'>
                  nearly 1 in 3 Americans receives a “surprise medical bill”{' '}
                </Link>
                and{' '}
                <Link
                  type='external'
                  display='inline'
                  to='https://www.nejm.org/doi/full/10.1056/NEJMp1608571'
                >
                  over 20% have received bills for out-of-network physicians, even though they went
                  to an in-network facility.
                </Link>
              </Typography>
            </Box>
            <Box mb={4}>
              <Typography variant='body1' weight='medium' style={{ maxWidth: '750px' }}>
                Our system is uniquely flawed – the cost of services both to employers and
                individuals is unsustainable and, in many circumstances, heartbreaking and
                personally devastating. Why were Veronica’s medical bills so unreasonable?
              </Typography>
            </Box>
            <Box mb={4}>
              <Typography variant='body1' weight='medium' style={{ maxWidth: '750px' }}>
                Well, it’s...complicated. Most parties tend to blame the other, whether it is big
                pharma, the device industry, health insurers, physician groups, health systems, or a
                host of other players, all believe they are justified in their cost structure and
                others are to be viewed more critically. In addition to cost, we also have a{' '}
                <Link
                  type='external'
                  display='inline'
                  to='https://catalyst.nejm.org/doi/abs/10.1056/CAT.19.1064'
                >
                  “trillion-dollar problem”{' '}
                </Link>
                just with wasted and duplicated services alone. Many value-based programs try to
                solve for this uncoordinated and low-value care that is provided across the system,
                which is incredibly important, but there is still a rarely-addressed elephant in the
                room, captured most eloquently in the 2003 article -{' '}
                <Link
                  type='external'
                  display='inline'
                  to='https://www.healthaffairs.org/doi/10.1377/hlthaff.22.3.89?url_ver=Z39.88-2003&rfr_id=ori%3Arid%3Acrossref.org&rfr_dat=cr_pub++0pubmed'
                >
                  “It’s the Prices, Stupid.”
                </Link>
              </Typography>
            </Box>
            <Box mb={4}>
              <Typography variant='body1' weight='medium' style={{ maxWidth: '750px' }}>
                Everything in American healthcare is more expensive.{' '}
                <Link
                  type='external'
                  display='inline'
                  to='https://jamanetwork.com/journals/jama/article-abstract/2674671'
                >
                  We pay more for administrative costs, pharmaceuticals, MRIs, doctor’s salaries, IV
                  pumps, transportation, electronic medical records, healthcare facilities, and on
                  and on.{' '}
                </Link>
                Almost everything, at the unit cost level, is more expensive in the United States
                than our OECD counterparts. In addition, a comprehensive{' '}
                <Link
                  type='external'
                  display='inline'
                  to='https://jamanetwork.com/journals/jama/article-abstract/2674671'
                >
                  study in JAMA confirmed the high cost of care in the US is not due to many of the
                  common misconceptions,{' '}
                </Link>
                such as low investment in social services, higher specialist mix, fee-for-service
                encouragement of overutilization, or defensive medicine. While there is certainly
                room for improvement in providing the right care at the right time, reducing
                unnecessary expensive utilization like inpatient hospitalizations and questionable
                procedures, the United States is not actually that far off from most other countries
                when it comes to how much we use healthcare, and that alone will not reduce
                healthcare spending across the board. It comes down to price.
              </Typography>
            </Box>
            <Box mb={4}>
              <Typography variant='body1' weight='medium' style={{ maxWidth: '750px' }}>
                So why does everything here have a higher price? That is another complex and likely
                impossible question to answer completely, but one basic tenet is this: we don’t have
                real competition and price transparency in healthcare. The US healthcare system is
                one of the only industries in the world where consumers rarely know the price of the
                product before they engage in a service or interaction. We lack transparency and the
                tools necessary to optimize and compete in the marketplace on true price and quality
                of services rendered. Thankfully,{' '}
                <Link
                  type='external'
                  display='inline'
                  to='https://www.cms.gov/hospital-price-transparency'
                >
                  CMS and recent administrations have started to understand the importance of
                  knowing prices up front and have continued to press hospitals on price
                  transparency.
                </Link>
              </Typography>
            </Box>
            <Box mb={4}>
              <Typography variant='body1' weight='medium' style={{ maxWidth: '750px' }}>
                <Link
                  type='external'
                  display='inline'
                  to='https://blogs.bmj.com/bmj/2020/07/07/american-patients-cant-shop-their-way-to-a-low-cost-system/'
                >
                  Critics have often pointed out that healthcare is not an industry many of us
                  typically “shop” for{' '}
                </Link>
                and that knowing prices alone won’t change behavior. While there
                <Link
                  type='external'
                  display='inline'
                  to='https://www.rand.org/pubs/monograph_reports/MR1355.html#:~:text=Despite%20the%20wide%20variety%20of,with%20values%20centering%20around%20%2D0.17.'
                >
                  may be some truth to the inelasticity of healthcare prices{' '}
                </Link>
                (meaning, consumers will continue to pay for life-saving treatments, regardless of
                high prices), more patients are looking to optimize the first-dollar spend for which
                they are responsible.{' '}
                <Link
                  type='external'
                  display='inline'
                  to='https://www.realclearhealth.com/articles/2020/07/16/despite_large_bipartisan_majority_hospitals_trying_beat_back_health_price_transparency_111071.html#:~:text=Not%20only%20do%20nearly%2090,the%20few%20truly%20bipartisan%20issues.'
                >
                  Close to 90% of Americans want more transparency in healthcare and 80% believe
                  this would lead to more “shopping” for appropriate prices.{' '}
                </Link>
                That’s why it is so important for
                <Link type='internal' display='inline' to='/'>
                  platforms like Mishe to collate and present easy-to-understand cash prices.
                </Link>
              </Typography>
            </Box>
            <Box mb={4}>
              <Typography variant='body1' weight='medium' style={{ maxWidth: '750px' }}>
                <Link
                  type='external'
                  display='inline'
                  to='https://hbr.org/2021/09/who-is-driving-the-great-resignation'
                >
                  With the great resignation{' '}
                </Link>
                upon us and historically low labor force participation, against a backdrop of the
                worst inflation we’ve seen in 40 years, we have an urgency and obligation to provide
                platforms that will allow for access to reliably priced critical healthcare
                services. Tens of millions of patients who are now responsible for the first 5-10k
                dollars of their healthcare expenses every year, should not be forced to pay
                artificially inflated prices, especially when none of the parties rendering care
                (clinicians, hospitals, payors) can even articulate how the full billing process
                works until it is all said and done.
              </Typography>
            </Box>
            <Box mb={4}>
              <Typography variant='body1' weight='medium' style={{ maxWidth: '750px' }}>
                The recent push for transparency and improvement in quality, STARs, HEDIS measures,
                etc have driven significant improvements in care, showing that what we measure and
                incentivize matter – we need that same level of transparency and competition around
                the true price of goods and services in healthcare. While there is absolutely a role
                and a place for insurance, both in catastrophic cases and for those with chronic
                conditions and regular usage of the healthcare system, our unit costs are still too
                high and we need to reign in the price of services across the board.
              </Typography>
            </Box>
            <Box mb={4}>
              <Typography variant='body1' weight='medium' style={{ maxWidth: '750px' }}>
                Mishe is the first platform I have seen that truly aims to democratize healthcare,
                give power back to patients and clinicians, and is agnostic to payor type, coverage
                status, race, socioeconomic status, location, identity, beliefs, or any number of
                vectors that drive poor outcomes across populations. Mishe reintroduces competition
                and price transparency amongst providers of care and services, and allows patients
                to make their own decisions based on quality and cost. Just as we trust third-party
                marketplace platforms like Kayak or Amazon to curate similar goods or services,
                present to us the pros/cons, and allow us to make an informed decision for
                ourselves, so to can healthcare be made simple, more affordable, and more rational,
                through the use of tools like Mishe.
              </Typography>
            </Box>
            <Box mb={4}>
              <Typography variant='body1' weight='medium' style={{ maxWidth: '750px' }}>
                Patients like Veronica deserve a transparent and complete financial understanding of
                services prior to entering into treatments and procedures along the healthcare
                continuum. Had Mishe existed when I knew Veronica, we could’ve worked together to
                identify a capitated bundle of services for cancer treatment or surgery, with no
                surprise bills, and no additional costs except the stated price. Clinicians
                providing care along that continuum would’ve worked with Mishe to set fair prices,
                market directly to consumers, and get paid directly with no delays or administrative
                morass. And most importantly, patients like Veronica could’ve more thoughtfully
                planned her treatments, cared for herself and her family, and avoided financial
                hardship and impossible decisions between medications and food on the table. Mishe
                is not only the best tool to connect clinicians and patients directly with the
                lowest and most transparent cash prices, it is a moral imperative to move the
                industry to a more open and sustainable future, and a cause that I am proud to
                support.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

export default BlogBody
