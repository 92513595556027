import PropTypes from 'prop-types'
import { Box, Divider } from '@mui/material'
import Typography from '@app/stories/Typography'
import { formatDateForUI, formatMarkdown } from '@app/libs/formatters'

import { MuiMarkdown } from 'mui-markdown'

const ReviewOrder = ({ values }) => {
  const {
    firstname,
    lastname,
    email,
    phone,
    dob,
    gender,
    street,
    street2,
    city,
    state,
    zip,
    noteContent,
    currentFile,
    serviceSelected,
    diagnosisCodeData,
    providerFirstname,
    providerNPI,
  } = values

  return (
    <Box>
      <Typography variant='h4' family='poppins'>
        Review Order
      </Typography>
      <Divider style={{ margin: '1rem 0' }} />
      <Box>
        <Typography variant='h5' family='poppins'>
          Patient Information
        </Typography>
        <Box display='flex' marginLeft='16px'>
          <Box display='flex' flexDirection='column' marginRight='20px'>
            <Typography variant='body1' family='poppins'>
              Name:
            </Typography>
            <Typography variant='body1' family='poppins'>
              Email:
            </Typography>
            <Typography variant='body1' family='poppins'>
              Phone:
            </Typography>
            <Typography variant='body1' family='poppins'>
              Birthday:
            </Typography>
            <Typography variant='body1' family='poppins'>
              Gender:
            </Typography>
            <Typography variant='body1' family='poppins'>
              Address:
            </Typography>
          </Box>
          <Box display='flex' flexDirection='column'>
            {(firstname && lastname && (
              <Typography variant='body1' family='poppins' style={{ textTransform: 'capitalize' }}>
                {firstname} {lastname}
              </Typography>
            )) || (
              <Box color='orange'>
                <Typography variant='body1' family='poppins' color='inherit'>
                  No name provided
                </Typography>
              </Box>
            )}
            {(email && (
              <Typography variant='body1' family='poppins'>
                {email}
              </Typography>
            )) || (
              <Box color='orange'>
                <Typography variant='body1' family='poppins' color='inherit'>
                  No email provided
                </Typography>
              </Box>
            )}
            {(phone && (
              <Typography variant='body1' family='poppins'>
                {phone}
              </Typography>
            )) || (
              <Box color='orange'>
                <Typography variant='body1' family='poppins' color='inherit'>
                  No phone number provided
                </Typography>
              </Box>
            )}
            {(formatDateForUI(dob) && (
              <Typography variant='body1' family='poppins'>
                {formatDateForUI(dob)}
              </Typography>
            )) || (
              <Box color='orange'>
                <Typography variant='body1' family='poppins' color='inherit'>
                  No date of birth provided
                </Typography>
              </Box>
            )}
            {(gender && (
              <Typography variant='body1' family='poppins'>
                {gender}
              </Typography>
            )) || (
              <Box color='orange'>
                <Typography variant='body1' family='poppins' color='inherit'>
                  No gender provided
                </Typography>
              </Box>
            )}
            {(street && city && state && zip && (
              <>
                <Typography variant='body1' family='poppins'>
                  {street}
                </Typography>
                <Typography variant='body1' family='poppins'>
                  {street2}
                </Typography>
                <Typography variant='body1' family='poppins'>
                  {city}, {state} {zip}
                </Typography>
              </>
            )) || (
              <Box color='orange'>
                <Typography variant='body1' family='poppins' color='inherit'>
                  No address provided, or incomplete
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Divider style={{ margin: '1rem 0' }} />
      <Box>
        <Typography variant='h5' family='poppins'>
          Service Information
        </Typography>
        {(serviceSelected && (
          <Box marginLeft='16px'>
            <Typography variant='body1' family='poppins'>
              {serviceSelected?.name}
            </Typography>
            <Typography variant='body1' family='poppins'>
              {serviceSelected?.cptCode}
            </Typography>
            <Typography variant='body1' family='poppins'>
              {serviceSelected?.clinicName}
            </Typography>
          </Box>
        )) || (
          <Box marginLeft='16px' color='red'>
            <Typography variant='body1' family='poppins' color='inherit'>
              No service selected.
              <br />
              Please select a service
            </Typography>
          </Box>
        )}
      </Box>
      <Divider style={{ margin: '1rem 0' }} />
      <Typography variant='h5' family='poppins'>
        Diagnosis Codes
      </Typography>
      {(diagnosisCodeData && (
        <Box marginLeft='16px'>
          <Typography variant='body1' family='poppins'>
            {diagnosisCodeData?.code}
          </Typography>
          <Typography variant='body1' family='poppins'>
            {diagnosisCodeData?.shortDescription}
          </Typography>
        </Box>
      )) || (
        <Box marginLeft='16px' color='red'>
          <Typography variant='body1' family='poppins' color='inherit'>
            No diagnosis code selected.
            <br />
            Please select a code diagnosis
          </Typography>
        </Box>
      )}
      <Divider style={{ margin: '1rem 0' }} />
      <Box>
        <Typography variant='h5' family='poppins'>
          Referring Provider
        </Typography>
        <Box marginLeft='16px'>
          {(providerFirstname && (
            <Typography variant='body1' family='poppins'>
              {providerFirstname}
            </Typography>
          )) || (
            <Box color='red'>
              <Typography variant='body1' family='poppins' color='inherit'>
                No Provider Name provided
              </Typography>
            </Box>
          )}
          {(providerNPI && (
            <Typography variant='body1' family='poppins'>
              {providerNPI}
            </Typography>
          )) || (
            <Box color='red'>
              <Typography variant='body1' family='poppins' color='inherit'>
                No NPI provided
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Divider style={{ margin: '1rem 0' }} />
      <Box>
        <Typography variant='h5' family='poppins'>
          Notes
        </Typography>
        <Box marginLeft='16px'>
          {noteContent && <MuiMarkdown>{formatMarkdown(noteContent)}</MuiMarkdown>}
          {/* File too */}
          {currentFile && (
            <Typography variant='body1' family='poppins'>
              {currentFile?.name}
            </Typography>
          )}
          {!noteContent && !currentFile && (
            <Typography variant='body1' family='poppins'>
              No notes or file attached
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  )
}

ReviewOrder.propTypes = {
  values: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    dob: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    gender: PropTypes.string,
    street: PropTypes.string,
    street2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    noteContent: PropTypes.string,
    currentFile: PropTypes.object,
    serviceSelected: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    diagnosisCodeData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    providerFirstname: PropTypes.string,
    providerNPI: PropTypes.string,
  }).isRequired,
}

export default ReviewOrder
