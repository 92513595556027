import { useState, useEffect } from 'react'
import { useCurrentUser } from '@app/context/CurrentUser'
import SelectField from '@app/stories/Select'
import { Box, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

import { useEmployerPlan } from '@app/hooks/useEmployerPlan'
import SpendUp from '@app/components/PatientDashboard/PlansTab/SpendUp'
import SpendDown from '@app/components/PatientDashboard/PlansTab/SpendDown'

const PlansPatientDashboardContent = () => {
  const { profileData } = useCurrentUser()

  const [plans, setPlans] = useState()
  const [selectedPlanID, setSelectedPlanID] = useState('')

  useEffect(() => {
    let formattedPlans = []
    if (profileData.plansAssociated) {
      formattedPlans = Object.entries(profileData.plansAssociated).map(([id, data]) => ({
        value: id,
        label: data.name,
        ...data,
      }))
    }
    setPlans(formattedPlans)
  }, [profileData.plansAssociated])

  const plan = useEmployerPlan(profileData.id, selectedPlanID)

  if (plans && plan.loaded && plan.empty) {
    return (
      <>
        <SelectField
          variant='standard'
          fullWidth
          size='large'
          label='Select Plan'
          options={plans}
          onChange={(e) => setSelectedPlanID(e.target.value)}
          value={selectedPlanID}
          titleSelect
        />
        <Box display='flex' justifyContent='center' alignItems='center' minHeight='200px'>
          Plan Details unavailable at this time
        </Box>
      </>
    )
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'scroll',
      }}
    >
      {plans && (
        <SelectField
          variant='standard'
          fullWidth
          size='large'
          label='Select Plan'
          options={plans}
          onChange={(e) => setSelectedPlanID(e.target.value)}
          value={selectedPlanID}
          titleSelect
        />
      )}
      {selectedPlanID && !plan.loaded && (
        <Box display='flex' alignItems='center' justifyContent='center' bgcolor='lightgray'>
          <CircularProgress />
        </Box>
      )}
      {plan && plan.loaded && (
        <>
          {plan.spendType === 'up' ? (
            <SpendUp profileId={profileData.id} planID={selectedPlanID} plan={plan} />
          ) : plan.spendType === 'down' ? (
            <SpendDown profileId={profileData.id} planID={selectedPlanID} plan={plan} />
          ) : (
            <Box display='flex' justifyContent='center' alignItems='center' minHeight='200px'>
              <Typography variant='h3' align='center'>
                Plan Details unavailable at this time
              </Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  )
}

export default PlansPatientDashboardContent
