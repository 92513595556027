import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import MuiButton from '@mui/material/Button'

const StyledButton = styled(MuiButton)`
  text-transform: inherit;
  ${(props) =>
    props.size === 'large' &&
    css`
      height: ${props.theme.sizing.button.height};
    `}
  ${(props) =>
    props.variant === 'outlined' &&
    css`
      box-shadow: ${props.theme.shadows[1]};
      background: ${props.theme.palette.background.paper};
      &:hover {
        background: ${props.theme.palette.background.smoke};
      }
    `}
  ${(props) => props.variant === 'unstyled' && css``}
  ${(props) =>
    props.customVariant === 'blank' &&
    css`
      padding: 0;
      margin: 0;
      cursor: 'pointer';
      background: 'none';
      &:hover {
        background: ${props.theme.palette.background.smoke};
      }
    `}
  ${(props) =>
    props.severity === 'danger' &&
    css`
      border-color: ${props.theme.palette.error.dark};
      color: ${props.theme.palette.error.dark};
      &:hover {
        border-color: ${props.theme.palette.error.main};
      }
    `}
    + button {
    margin-left: ${(props) => props.theme.spacing(2)};
  }
  ${(props) =>
    props.family === 'Poppins' &&
    css`
      font-family: Poppins;
    `}
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}
`

const Button = ({ children, severity = '', to = null, ...props }) => {
  const component = to ? Link : undefined

  return (
    <StyledButton
      color='primary'
      size='large'
      variant='contained'
      severity={severity}
      to={to}
      component={component}
      {...props}
    >
      {children}
    </StyledButton>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  severity: PropTypes.string,
}

export default Button
