import { FC, PropsWithChildren } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

interface ErrorBoundaryFallbackProps {
  title?: string
  error: Error
}

/** Renders error information */
const ErrorBoundaryFallback: FC<ErrorBoundaryFallbackProps> = ({ title, error }) => (
  <div data-testid='error-boundary-error' role='alert' style={{ margin: '0 auto' }}>
    <h4>{title || 'Something went wrong:'}</h4>
    <p>Please reload the page and try again.</p>
    <div style={{ color: 'red' }}>
      <pre>{error.message}</pre>
      <pre>{error.stack}</pre>
    </div>
  </div>
)

/** Catches uncaught exceptions and renders a fallback error component instead of a blank page */
export const ErrorBoundaryProvider: FC<PropsWithChildren> = ({ children }) => (
  <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>{children}</ErrorBoundary>
)
