import Container from '@app/stories/Container'
import Backdrop from '@mui/material/Backdrop'
import Typography from '@app/stories/Typography'
import Section from '@app/stories/Section'
import Modal from '@app/stories/Modal'
import TextField from '@app/stories/TextField'
import Button from '@app/stories/Button'
import Link from '@app/stories/Link'
import Icon from '@app/stories/Icons'
import PhoneField from '@app/stories/PhoneInput'
import SelectField from '@app/stories/Select'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import { useEffect, useState } from 'react'
import { GENDER_OPTIONS } from '@app/libs/validators'
import { Collapse, Snackbar } from '@mui/material'
import { enrollmentFormData } from '@app/firebase/functions'
import { useCurrentNetwork } from '@app/context/CurrentNetwork'
import LoadingButton from '@mui/lab/LoadingButton'
import { signIn } from '@app/firebase/auth'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import { useWhiteLabelingContext } from '@app/context/WhiteLabelingContext'

const EnrollmentModal = ({ open, handleClose }) => {
  const { networkId } = useCurrentNetwork()

  const [selectedBirthdayDate, setSelectedBirthdayDate] = useState(null)
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)
  const [enrollmentDisabled, setEnrollmentDisabled] = useState(true)
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [gender, setGender] = useState('')
  const [last4ssn, setLast4ssn] = useState('')
  const [terms, setTerms] = useState(false)
  const [hipaa, setHipaa] = useState(false)
  const [smsOptIn, setSmsOptIn] = useState(false)
  const [requestLoading, setRequestLoading] = useState(false)

  const [validPassword, setValidPassword] = useState(false)
  const [validPasswordLength, setValidPasswordLength] = useState(false)
  const [validPasswordNumber, setValidPasswordNumber] = useState(false)
  const [validPasswordUppercase, setValidPasswordUppercase] = useState(false)
  const [validPasswordLowercase, setValidPasswordLowercase] = useState(false)
  const [validPasswordSpecial, setValidPasswordSpecial] = useState(false)
  const [passwordMatch, setPasswordMatch] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState({ open: false, message: '' })

  const [showPasswordRules, setShowPasswordRules] = useState(false)
  const [showPasswordError, setShowPasswordError] = useState(false)
  const [showPasswordMatch, setShowPasswordMatch] = useState(false)
  const [showPasswordMatchError, setShowPasswordMatchError] = useState(false)
  const [enrollmentStatus, setEnrollmentStatus] = useState('')

  const handleCloseSnackbar = () => setSnackbarOpen({ open: false, message: '' })

  const { planColorPrimary } = useWhiteLabelingContext()

  useEffect(() => {
    if (
      firstname &&
      lastname &&
      email &&
      phone &&
      validPassword &&
      passwordMatch &&
      gender &&
      last4ssn &&
      terms &&
      hipaa &&
      selectedBirthdayDate
    ) {
      setEnrollmentDisabled(false)
    } else {
      setEnrollmentDisabled(true)
    }
  }, [
    firstname,
    lastname,
    email,
    phone,
    validPassword,
    passwordMatch,
    gender,
    last4ssn,
    terms,
    hipaa,
  ])

  useEffect(() => {
    if (password.length >= 8) {
      setValidPasswordLength(true)
    } else {
      setValidPasswordLength(false)
    }
    if (password.match(/[0-9]/)) {
      setValidPasswordNumber(true)
    } else {
      setValidPasswordNumber(false)
    }
    if (password.match(/[A-Z]/)) {
      setValidPasswordUppercase(true)
    } else {
      setValidPasswordUppercase(false)
    }
    if (password.match(/[a-z]/)) {
      setValidPasswordLowercase(true)
    } else {
      setValidPasswordLowercase(false)
    }
    if (/^(?=.*[!@#$%^&*_+?=-])/.test(password)) {
      setValidPasswordSpecial(true)
    } else {
      setValidPasswordSpecial(false)
    }
    if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*_+?=-]).{8,}$/.test(password)) {
      setValidPassword(true)
    } else {
      setValidPassword(false)
    }
  }, [password])

  useEffect(() => {
    if (password === passwordConfirmation) {
      setPasswordMatch(true)
    } else {
      setPasswordMatch(false)
    }
  }, [password, passwordConfirmation])

  const resetFields = () => {
    setShowPasswordRules(false)
    setShowPasswordError(false)
    setShowPasswordMatch(false)
    setShowPasswordMatchError(false)
    setSmsOptIn(false)
    setFirstname('')
    setLastname('')
    setEmail('')
    setPhone('')
    setPassword('')
    setPasswordConfirmation('')
    setGender('')
    setLast4ssn('')
    setTerms(false)
    setHipaa(false)
    setSelectedBirthdayDate(null)
    setRequestLoading(false)
  }

  const handleEnrollment = async () => {
    const dateDOB = new Date(selectedBirthdayDate)

    const year = String(dateDOB.getFullYear())
    const month = String(dateDOB.getMonth() + 1).padStart(2, '0') // Months are 0-based
    const day = String(dateDOB.getDate()).padStart(2, '0')
    const formattedDate = `${year}-${month}-${day}`

    setRequestLoading(true)
    await enrollmentFormData({
      firstname,
      lastname,
      email,
      phone,
      password,
      gender,
      last4ssn,
      terms,
      hipaa,
      dob: formattedDate,
      smsOptIn,
      planId: networkId,
    })
      .then(async (res) => {
        setRequestLoading(false)
        const { enrollment, errorMessage } = res
        if (enrollment === 'error') {
          setSnackbarOpen({ open: true, message: errorMessage })
        } else {
          if (enrollment) {
            setEnrollmentStatus('success')
            await signIn(email, password)
            resetFields()
          } else {
            setEnrollmentStatus('review')
            resetFields()
          }
        }
      })
      .catch((err) => {
        console.log('err', err)
        setRequestLoading(false)
        resetFields()
      })
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Container
        maxWidth='xs'
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {enrollmentStatus === '' && (
          <Section width='100%'>
            <Typography variant='h2' gutterBottom>
              Welcome to Mishe
            </Typography>
            <Typography variant='h5' gutterBottom>
              Please fill out the form below to enroll
            </Typography>
            <Box mt={4}>
              <TextField
                required
                name='firstname'
                label='First name'
                display='standard'
                inputProps={{
                  title: 'firstname',
                }}
                onChange={(e) => setFirstname(e.target.value.toLowerCase())}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: planColorPrimary,
                    },
                    '&:hover fieldset': {
                      borderColor: planColorPrimary,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: planColorPrimary,
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: planColorPrimary,
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: planColorPrimary,
                  },
                }}
              />
              <TextField
                required
                name='lastname'
                label='Last name'
                display='standard'
                inputProps={{
                  title: 'lastname',
                }}
                onChange={(e) => setLastname(e.target.value.toLowerCase())}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: planColorPrimary,
                    },
                    '&:hover fieldset': {
                      borderColor: planColorPrimary,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: planColorPrimary,
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: planColorPrimary,
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: planColorPrimary,
                  },
                }}
              />
              <TextField
                required
                name='email'
                type='email'
                label='Email address'
                display='standard'
                inputProps={{
                  title: 'email',
                }}
                onChange={(e) => setEmail(e.target.value.toLowerCase())}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: planColorPrimary,
                    },
                    '&:hover fieldset': {
                      borderColor: planColorPrimary,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: planColorPrimary,
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: planColorPrimary,
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: planColorPrimary,
                  },
                }}
              />
              <PhoneField
                required
                name='phone'
                label='Phone number'
                display='standard'
                inputProps={{
                  title: 'phone',
                }}
                onChange={(e) => setPhone(e.target.value)}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: planColorPrimary,
                    },
                    '&:hover fieldset': {
                      borderColor: planColorPrimary,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: planColorPrimary,
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: planColorPrimary,
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: planColorPrimary,
                  },
                }}
              />
              <Box position='relative'>
                <TextField
                  required
                  name='password'
                  type={passwordVisible ? 'text' : 'password'}
                  label='Password'
                  display='standard'
                  inputProps={{
                    title: 'password',
                  }}
                  onChange={(e) => setPassword(e.target.value)}
                  onFocus={() => setShowPasswordRules(true)}
                  onBlur={() => setShowPasswordError(true)}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: planColorPrimary,
                      },
                      '&:hover fieldset': {
                        borderColor: planColorPrimary,
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: planColorPrimary,
                      },
                    },
                    '& .MuiInputLabel-root': {
                      color: planColorPrimary,
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: planColorPrimary,
                    },
                  }}
                />
                <Box
                  position='absolute'
                  right={0}
                  top={0}
                  bottom={0}
                  mt={2}
                  mb={2}
                  color={planColorPrimary}
                >
                  <Button variant='icon' onClick={() => setPasswordVisible(!passwordVisible)}>
                    <Icon color='inherit' name={passwordVisible ? 'visibility' : 'visibilityOff'} />
                  </Button>
                </Box>
              </Box>
              <Collapse in={showPasswordRules}>
                <Box>
                  <Collapse in={!validPassword}>
                    <Typography variant='body2' color='black'>
                      Password must:
                    </Typography>
                  </Collapse>
                  <Box ml={1}>
                    <Collapse in={!validPasswordLength}>
                      <Typography
                        variant='body2'
                        color={
                          validPasswordLength ? 'green' : showPasswordError ? 'error' : 'black'
                        }
                      >
                        Be at least 8 characters
                      </Typography>
                    </Collapse>
                    <Collapse in={!validPasswordNumber}>
                      <Typography
                        variant='body2'
                        color={
                          validPasswordNumber ? 'green' : showPasswordError ? 'error' : 'black'
                        }
                      >
                        Contain at least one number
                      </Typography>
                    </Collapse>
                    <Collapse in={!validPasswordUppercase}>
                      <Typography
                        variant='body2'
                        color={
                          validPasswordUppercase ? 'green' : showPasswordError ? 'error' : 'black'
                        }
                      >
                        Contain at least one uppercase letter
                      </Typography>
                    </Collapse>
                    <Collapse in={!validPasswordLowercase}>
                      <Typography
                        variant='body2'
                        color={
                          validPasswordLowercase ? 'green' : showPasswordError ? 'error' : 'black'
                        }
                      >
                        Contain at least one lowercase letter
                      </Typography>
                    </Collapse>
                    <Collapse in={!validPasswordSpecial}>
                      <Typography
                        variant='body2'
                        color={
                          validPasswordSpecial ? 'green' : showPasswordError ? 'error' : 'black'
                        }
                      >
                        Contain at least one special character
                      </Typography>
                    </Collapse>
                  </Box>
                  <Collapse in={validPassword}>
                    <Typography variant='body2' color='green'>
                      Password meets all requirements
                    </Typography>
                  </Collapse>
                </Box>
              </Collapse>
              <Box position='relative'>
                <TextField
                  required
                  name='passwordConfirmation'
                  type={confirmPasswordVisible ? 'text' : 'password'}
                  label='Password Confirmation'
                  display='standard'
                  inputProps={{
                    title: 'password-confirmation',
                  }}
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                  onFocus={() => setShowPasswordMatch(true)}
                  onBlur={() => setShowPasswordMatchError(true)}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: planColorPrimary,
                      },
                      '&:hover fieldset': {
                        borderColor: planColorPrimary,
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: planColorPrimary,
                      },
                    },
                    '& .MuiInputLabel-root': {
                      color: planColorPrimary,
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: planColorPrimary,
                    },
                  }}
                />
                <Box
                  position='absolute'
                  right={0}
                  top={0}
                  bottom={0}
                  mt={2}
                  mb={2}
                  color={planColorPrimary}
                >
                  <Button
                    variant='icon'
                    onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                  >
                    <Icon
                      color='inherit'
                      name={confirmPasswordVisible ? 'visibility' : 'visibilityOff'}
                    />
                  </Button>
                </Box>
              </Box>
              {showPasswordMatch && (
                <Box>
                  <Collapse in={!passwordMatch}>
                    <Typography variant='body2' color={showPasswordMatchError ? 'error' : 'black'}>
                      Passwords do not match
                    </Typography>
                  </Collapse>
                  <Collapse in={passwordMatch}>
                    <Typography variant='body2' color='green'>
                      Passwords match
                    </Typography>
                  </Collapse>
                </Box>
              )}
              <Grid container spacing={2} justifyContent='space-between'>
                <Grid item xs={12} sm={6} style={{ marginTop: '16px' }}>
                  <DatePicker
                    fullWidth
                    label='Birthday'
                    inputVariant='outlined'
                    format='MM-dd-yyyy'
                    onChange={setSelectedBirthdayDate}
                    slotProps={{ textField: { fullWidth: true } }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: planColorPrimary,
                        },
                        '&:hover fieldset': {
                          borderColor: planColorPrimary,
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: planColorPrimary,
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: planColorPrimary,
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: planColorPrimary,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SelectField
                    required
                    label='Gender'
                    name='gender'
                    options={GENDER_OPTIONS}
                    defaultValue=''
                    onChange={(e) => setGender(e.target.value)}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: planColorPrimary,
                        },
                        '&:hover fieldset': {
                          borderColor: planColorPrimary,
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: planColorPrimary,
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: planColorPrimary,
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: planColorPrimary,
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <TextField
                required
                name='last4ssn'
                label='Last 4 of SSN'
                type='password'
                display='standard'
                inputProps={{
                  title: 'last4ssn',
                  maxLength: 4,
                }}
                onChange={(e) => setLast4ssn(e.target.value)}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: planColorPrimary,
                    },
                    '&:hover fieldset': {
                      borderColor: planColorPrimary,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: planColorPrimary,
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: planColorPrimary,
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: planColorPrimary,
                  },
                }}
              />
              <Box mt={2}>
                <Typography variant='body2'>
                  To use this account please review and agree to the following:
                </Typography>
              </Box>
              <Box display='flex' alignItems='center'>
                <Checkbox
                  name='terms'
                  color='primary'
                  inputProps={{ 'aria-label': 'terms' }}
                  onClick={() => setTerms(!terms)}
                  sx={{
                    color: planColorPrimary,
                    '&.Mui-checked': {
                      color: planColorPrimary,
                    },
                  }}
                />
                <Typography variant='body2'>
                  <Link
                    display='standard'
                    size='small'
                    target='_blank'
                    to='/privacy-policy'
                    style={{ fontWeight: '300', color: planColorPrimary }}
                  >
                    Privacy Policy
                  </Link>{' '}
                  ,{' '}
                  <Link
                    display='standard'
                    size='small'
                    target='_blank'
                    to='/terms'
                    style={{ fontWeight: '300', color: planColorPrimary }}
                  >
                    Terms of Use
                  </Link>{' '}
                  and{' '}
                  <Link
                    display='standard'
                    size='small'
                    target='_blank'
                    to='/eula-consumer'
                    style={{ fontWeight: '300', color: planColorPrimary }}
                  >
                    EULA
                  </Link>
                  .
                </Typography>
              </Box>
              <Box display='flex' alignItems='center'>
                <Checkbox
                  name='hipaa'
                  color='primary'
                  inputProps={{ 'aria-label': 'hipaa' }}
                  onClick={() => setHipaa(!hipaa)}
                  sx={{
                    color: planColorPrimary,
                    '&.Mui-checked': {
                      color: planColorPrimary,
                    },
                  }}
                />
                <Typography variant='body2'>
                  <Link
                    display='standard'
                    size='small'
                    target='_blank'
                    to='/hipaa'
                    style={{ fontWeight: '300', color: planColorPrimary }}
                  >
                    HIPAA Authorization
                  </Link>
                </Typography>
              </Box>
              <Box display='flex' alignItems='center'>
                <Checkbox
                  name='smsOptIn'
                  color='primary'
                  inputProps={{ 'aria-label': 'Text Opt In' }}
                  onClick={() => setSmsOptIn(!smsOptIn)}
                  sx={{
                    color: planColorPrimary,
                    '&.Mui-checked': {
                      color: planColorPrimary,
                    },
                  }}
                />
                <Typography variant='body2' color='initial'>
                  Agree to receive SMS about your account and orders. Message and data rates may
                  apply.
                </Typography>
              </Box>
              <Box mt={2}>
                <LoadingButton
                  fullWidth
                  disabled={enrollmentDisabled}
                  onClick={handleEnrollment}
                  loading={requestLoading}
                  style={{
                    backgroundColor:
                      enrollmentDisabled || requestLoading
                        ? 'rgba(0, 0, 0, 0.12)'
                        : planColorPrimary,
                    color: 'white',
                  }}
                >
                  Enroll
                </LoadingButton>
              </Box>
            </Box>
          </Section>
        )}
        {enrollmentStatus === 'success' && (
          <Section width='100%' position='relative'>
            <Box position='absolute' top={0} right={0} display='flex' justifyContent='flex-end'>
              <Button
                variant='icon'
                onClick={handleClose}
                style={{
                  padding: '0px',
                  minWidth: 'auto',
                  minHeight: 'auto',
                  height: '40px',
                  width: '40px',
                  borderRadius: '50%',
                }}
              >
                <Icon name='close' />
              </Button>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
              textAlign='center'
            >
              <Typography variant='h2' gutterBottom>
                Enrollment Successful
              </Typography>
              <Typography variant='h5' gutterBottom>
                Congratulations on enrolling in Mishe
              </Typography>
              <Typography variant='body2' align='center'>
                Welcome to your plan. You will receive an email with your account details.
              </Typography>
            </Box>
          </Section>
        )}
        {enrollmentStatus === 'review' && (
          <Section width='100%' position='relative'>
            <Box position='absolute' top={0} right={0} display='flex' justifyContent='flex-end'>
              <Button
                variant='icon'
                onClick={handleClose}
                style={{
                  padding: '0px',
                  minWidth: 'auto',
                  minHeight: 'auto',
                  height: '40px',
                  width: '40px',
                  borderRadius: '50%',
                }}
              >
                <Icon name='close' />
              </Button>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
              textAlign='center'
            >
              <Typography variant='h2' gutterBottom>
                Enrollment Under Review
              </Typography>
              <Typography variant='h5' gutterBottom>
                Thank you for enrolling in Mishe
              </Typography>
              <Typography variant='body2' align='center'>
                Your enrollment is under review. You will receive an email once your account is
                approved.
              </Typography>
            </Box>
          </Section>
        )}
      </Container>

      <Snackbar
        autoHideDuration={1700}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbarOpen.open}
        onClose={handleCloseSnackbar}
        message={snackbarOpen.message}
      />
    </Modal>
  )
}

export default EnrollmentModal
