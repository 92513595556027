import PropTypes from 'prop-types'
import { Grid, Box, CircularProgress } from '@mui/material'
import firebase from 'firebase/compat/app'
import { collection, updateDocumentModular } from '@app/firebase/firestore'
import Typography from '@app/stories/Typography'
import Button from '@app/stories/Button'
import Divider from '@app/stories/Divider'
import { useCurrentUser } from '@app/context/CurrentUser'
import useNotes from '@app/hooks/useNotes'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { useState } from 'react'
import { ADD_NOTE, SHARED_NOTE } from '@app/libs/constant'
import { useNotesModal } from '@app/context/AddNoteModal'
import { formatDateForUI } from '@app/libs/formatters'

const NotesAttributes = ({
  organization,
  timestamp,
  uid,
  originalOrderId = null,
  shared = [],
  showShare = true,
  sharedWithPatient,
  noteStatus,
  content,
}) => {
  const { authData } = useCurrentUser()
  const { setNoteType, handleClick, noteType, draftNote, setDraftNote } = useNotesModal()

  const { setNotes } = useNotes()

  const [sharePatientLoading, setSharePatientLoading] = useState(false)
  const [shareOrderLoading, setShareOrderLoading] = useState(false)

  const editDraft = (e) => {
    setDraftNote({ content, uid })
    setNoteType('text')
    handleClick(e)
  }

  const shareActionButton = (event, sharedAction) => {
    console.log('sharedAction', sharedAction)
    console.log('sharedWithPatient', sharedWithPatient)
    if (sharedAction === SHARED_NOTE.SHARED_WITH_PATIENT) {
      sharedWithPatient ? shareWithPatient(false) : shareWithPatient(true)
    } else if (sharedAction === SHARED_NOTE.SHARED_WITH_REFERRING_PROVIDER) {
      shared?.length > 0
        ? stopShareWithReferringProvider(uid, originalOrderId)
        : shareWithReferringProvider(uid, originalOrderId)
    }
  }

  const shareWithPatient = async (status) => {
    setSharePatientLoading(true)
    console.log('uid', uid)
    try {
      await updateDocumentModular({
        collectionName: 'notes',
        documentName: uid,
        data: {
          sharedWithPatient: status,
        },
      })
    } catch (error) {
      console.log('error', error)
    }
    setSharePatientLoading(false)
  }

  const shareWithReferringProvider = async (noteID, orderID) => {
    console.log('Share with referring provider')
    console.log(noteID)
    console.log(orderID)

    setShareOrderLoading(true)

    await collection('notes')
      .doc(noteID)
      .get()
      .then(async (doc) => {
        if (doc.exists) {
          console.log('Document data:', doc.data())

          let sharedStatus = shared

          if (sharedStatus) {
            // check if already shared
            if (!sharedStatus.includes(orderID)) {
              sharedStatus.push(orderID)
            }
          } else {
            sharedStatus = [orderID]
          }

          await doc.ref.update({ shared: sharedStatus })

          setNotes((prevNotes) => {
            const newNotes = prevNotes.map((prevNote) => {
              if (prevNote.uid === noteID) {
                return { ...prevNote, sharedStatus }
              }
              return prevNote
            })
            return newNotes
          })
          setShareOrderLoading(false)
        } else {
          // doc.data() will be undefined in this case
          setShareOrderLoading(false)
          alert(`Could not share, please contact support. NoteID: ${noteID} OrderID: ${orderID}`)
        }
      })
  }

  const stopShareWithReferringProvider = (noteID, orderID) => {
    console.log('Stop Share with referring provider')
    console.log(noteID)
    console.log(orderID)
    setShareOrderLoading(true)

    collection('notes')
      .doc(noteID)
      .get()
      .then(async (doc) => {
        if (doc.exists) {
          console.log('Document data:', doc.data())

          let sharedStatus = shared

          if (sharedStatus) {
            sharedStatus = sharedStatus.filter((item) => item !== orderID)
          } else {
            sharedStatus = []
          }

          await doc.ref.update({ shared: sharedStatus })

          setNotes((prevNotes) => {
            const newNotes = prevNotes.map((prevNote) => {
              if (prevNote.uid === noteID) {
                return { ...prevNote, sharedStatus }
              }
              return prevNote
            })
            return newNotes
          })
          setShareOrderLoading(false)
        } else {
          // doc.data() will be undefined in this case
          setShareOrderLoading(false)
          alert(
            `Could not stop sharing, please contact support. NoteID: ${noteID} OrderID: ${orderID}`,
          )
        }
      })
  }

  const displayShareWithPatient = sharedWithPatient ? SHARED_NOTE.SHARED_WITH_PATIENT : null
  const displayShareWithProvider =
    shared?.length > 0 ? SHARED_NOTE.SHARED_WITH_REFERRING_PROVIDER : null

  return (
    <Grid container>
      <Grid item xs={10} sm={10} md={10} lg={10}>
        <Box>
          <Typography variant='body1'>Created by: {organization?.provider || ''}</Typography>
        </Box>
        {timestamp && (
          <Box>
            <Typography variant='body1'>
              Created on: {formatDateForUI(timestamp, true) || '---'}
            </Typography>
          </Box>
        )}
      </Grid>
      {authData.roles.includes('PROVIDER') && showShare && noteStatus !== ADD_NOTE.DRAFT && (
        <Grid>
          <Divider {...{ style: { margin: '1rem 0' } }} />
          <ToggleButtonGroup
            color='primary'
            value={[displayShareWithPatient, displayShareWithProvider]}
            exclusive
            onChange={shareActionButton}
            aria-label='Platform'
          >
            <ToggleButton
              sx={{ textTransform: 'capitalize !important' }}
              title='Share with patient'
              value={SHARED_NOTE.SHARED_WITH_PATIENT}
            >
              {sharePatientLoading && <CircularProgress size={15} />}{' '}
              {displayShareWithPatient ? 'Unshare' : 'Share'} with Patient
            </ToggleButton>

            <ToggleButton
              sx={{ textTransform: 'capitalize !important' }}
              title='Share with referring provider'
              value={SHARED_NOTE.SHARED_WITH_REFERRING_PROVIDER}
            >
              {shareOrderLoading && <CircularProgress size={15} />}{' '}
              {displayShareWithProvider ? 'Unshare' : 'Share'} with Referring Provider
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      )}
      {authData.roles.includes('PROVIDER') && noteStatus === ADD_NOTE.DRAFT && (
        <Grid item xs={12}>
          <Divider {...{ style: { margin: '1rem 0' } }} />
          <Button
            fullWidth
            variant='outlined'
            size='small'
            color='primary'
            title='Edit Draft'
            onClick={editDraft}
          >
            Edit Draft
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

NotesAttributes.propTypes = {
  organization: PropTypes.shape({
    provider: PropTypes.string,
  }).isRequired,
  timestamp: PropTypes.oneOfType([
    PropTypes.instanceOf(firebase.firestore.Timestamp),
    PropTypes.instanceOf(Date),
  ]).isRequired,
  uid: PropTypes.string.isRequired,
  originalOrderId: PropTypes.string,
  shared: PropTypes.arrayOf(PropTypes.string),
  showShare: PropTypes.bool,
}

export default NotesAttributes
