import PropTypes from 'prop-types'
import { useState } from 'react'
import Avatar from '@mui/material/Avatar'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { outlook, office365, yahoo } from 'calendar-link'
import queryString from 'query-string'
import { add } from 'date-fns'
import Icon from '@app/stories/Icons'
import googleCalendar from '@app/assets/images/calenders/googleCalender.svg'
import outlookCalendar from '@app/assets/images/calenders/outlookCalender.svg'
import officeCalender from '@app/assets/images/calenders/office365.svg'
import yahooCalender from '@app/assets/images/calenders/yahoo.svg'
import icsCalender from '@app/assets/images/calenders/icsCalender.svg'
import { createDownloadICSFile } from '@app/libs/icsFile'
import { Timestamp } from 'firebase/firestore'

const AddToCalender = ({ calenderEvent, buttonLabel = 'Add To Calendar', buttonStyle }) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const calenderConfig = {
    ...calenderEvent,
  }

  if (!calenderConfig?.start) {
    calenderConfig.start = calenderConfig?.appointmentDate?.toDate() || null
  }

  const googleCalenderEventLink = () => {
    const { title, description, appointmentDate } = calenderEvent
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const startTime = appointmentDate.toDate()
    const endTime = add(startTime, { hours: 1 })

    const formattedStartTime = startTime.toISOString().replace(/-|:|\.\d\d\d/g, '')
    const formattedEndTime = endTime.toISOString().replace(/-|:|\.\d\d\d/g, '')

    const details = {
      action: 'TEMPLATE',
      text: title,
      details: description,
      dates: `${formattedStartTime}/${formattedEndTime}`,
      ctz: timeZone,
    }

    return `https://calendar.google.com/calendar/render?${queryString.stringify(details)}`
  }

  const handleListItemClick = (value) => {
    switch (value) {
      case 'google':
        window.open(googleCalenderEventLink(calenderConfig), '_blank')
        break
      case 'outlook':
        window.open(outlook(calenderConfig), '_blank')
        break
      case 'office365':
        window.open(office365(calenderConfig), '_blank')
        break
      case 'yahoo':
        window.open(yahoo(calenderConfig), '_blank')
        break
      case 'ics':
        icsFile(calenderConfig)
        break
      default:
        break
    }
  }

  const icsFile = () => {
    const { title, description, appointmentDate } = calenderEvent
    const endTime = add(appointmentDate.toDate(), { hours: 1 })
    const startTime = new Date(appointmentDate.toDate())

    createDownloadICSFile({
      title,
      description,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      startTime,
      endTime,
    })
  }

  const calenderList = [
    { name: 'Google', icon: googleCalendar, id: 'google' },
    { name: 'Outlook', icon: outlookCalendar, id: 'outlook' },
    { name: 'Office365', icon: officeCalender, id: 'office365' },
    { name: 'Yahoo', icon: yahooCalender, id: 'yahoo' },
    { name: 'Download ICS', icon: icsCalender, id: 'ics' },
  ]

  return (
    <Box>
      <Button size='small' variant='outlined' onClick={handleOpen} style={buttonStyle}>
        <Icon name='calendar' />
        {buttonLabel}
      </Button>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Add To Calendar</DialogTitle>
        <List>
          {calenderList.map(({ name, icon, id }) => (
            <ListItem disableGutters key={id}>
              <ListItemButton onClick={() => handleListItemClick(id)}>
                <ListItemAvatar>
                  <Avatar src={icon} alt='calendar' size='mediumlarge'>
                    <Icon name='calendar' />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Dialog>
    </Box>
  )
}

AddToCalender.propTypes = {
  calenderEvent: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    appointmentDate: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.instanceOf(Timestamp),
    ]),
  }).isRequired,
  buttonLabel: PropTypes.string,
  buttonStyle: PropTypes.object,
}

export default AddToCalender
