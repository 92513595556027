import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Box from '@mui/material/Box'
import Hero from '@app/components/Hero'
import PageSection from '@app/components/PageSection'
import Typography from '@mui/material/Typography'
import ComponentShifterFadeControl from '@app/components/ComponentShifterFadeControl'
import ComponentShifterFade from '@app/components/ComponentShifterFade'
import forbesLogo from '@app/assets/images/asMentionedInLogos/forbesLogo.png'
import healthAffairsLogo from '@app/assets/images/asMentionedInLogos/healthAffairsLogo.png'
import andersonLogo from '@app/assets/images/asMentionedInLogos/andersonLogo.png'
import medicalEconomicsLogo from '@app/assets/images/asMentionedInLogos/medicalEconomicsLogo.png'
import beckersWhiteLogo from '@app/assets/images/asMentionedInLogos/beckersWhiteLogo.png'
import medicineToMarketLogoWhite from '@app/assets/images/asMentionedInLogos/medicineToMarketLogoWhite.png'
import LiveChat from '@app/stories/LiveChat'
import ryanKline from '@app/assets/images/ryanKline.png'
import seanSmith from '@app/assets/images/seanSmith.png'
import mattC from '@app/assets/images/mattC.png'
import GetInTouchSection from '@app/components/GetInTouchSection'

const LandingPage = () => {
  const sections = [
    {
      title: 'Concierge Care Coordination',
      description:
        'Personalized, high-touch support that guides members to high-quality, affordable care—reducing friction and improving outcomes.',
    },
    {
      title: 'Direct Network Infrastructure',
      description:
        'Streamlined direct contracting that lowers medical spend for health plans while enabling providers to accelerate reimbursements, reduce administrative burden, and improve cash flow.',
    },
    {
      title: 'Streamlined Claim Operations',
      description:
        'Connect employers and providers in real time, enabling fast claims adjudication and seamless data sharing across stakeholders—from enrollment to remittance.',
    },
    {
      title: 'Guaranteed Same-Day Payment',
      description:
        'Covered treatments are pre-approved and pre-funded, allowing providers to skip manual claim submission and receive same-day EFT remittance after care is delivered.',
    },
    {
      title: 'Fully Integrated Platform',
      description:
        'End-to-end infrastructure that unifies direct contracting, care coordination, and claims processing into a seamless, fully connected ecosystem.',
    },
  ]

  useEffect(() => {
    window.Intercom('trackEvent', 'Page View', {
      page_name: 'Homepage',
    })
  }, [])

  const ReviewContainer = ({ review, name, affiliation, headShot }) => {
    return (
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        height='100%'
        width='100%'
        gap={4}
        sx={{
          flexDirection: {
            xs: 'column',
            lg: 'row',
          },
        }}
      >
        <Box
          height='100%'
          width='400px'
          position='relative'
          flexDirection='row'
          justifyContent='center'
          alignSelf='flex-start'
          sx={{
            display: {
              xs: 'none',
              lg: 'flex',
            },
          }}
        >
          <Box
            style={{
              backgroundImage: `url(${headShot})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
            borderRadius='50%'
            height='200px'
            width='200px'
            alignSelf='flex-start'
            border='4px solid #652d92'
          ></Box>
          <Box
            position='absolute'
            top='calc(100% + 8px)'
            left='0'
            display='flex'
            flexDirection='column'
            alignItems='flex-start'
            justifyContent='flex-end'
            padding='8px'
          >
            <Typography
              variant='h4'
              color='#652d92'
              sx={{
                fontSize: {
                  xs: '1.5rem',
                  md: '2rem',
                },
                fontFamily: 'Poppins',
                fontWeight: 600,
                textAlign: 'left',
                marginBottom: '0px',
              }}
            >
              {name}
            </Typography>
            <Typography
              variant='h4'
              color='#652d92'
              sx={{
                fontSize: {
                  xs: '1rem',
                  md: '1.5rem',
                },
                fontFamily: 'Poppins',
                fontWeight: 400,
                textAlign: 'left',
                marginBottom: '0px',
              }}
            >
              {affiliation}
            </Typography>
          </Box>
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          height='100%'
          padding='40px'
          border='4px solid #652d92'
          position='relative'
          sx={{
            width: {
              xs: '100%',
              md: 'calc(100% - 400px)',
            },
            height: {
              xs: 'min-content',
              md: '100%',
            },
            padding: {
              xs: '24px',
              md: '40px',
            },
          }}
        >
          <Box
            position='absolute'
            top='calc(50% - 25px)'
            left='-5px'
            width='6px'
            height='50px'
            backgroundColor='#f4f0f7'
            display='flex'
            justifyContent='center'
            alignItems='center'
            paddingTop='45px'
            sx={{
              rotate: '180deg',
            }}
          >
            <Typography color='#652d92' fontSize='100px' fontFamily='Poppins' fontWeight='900'>
              ”
            </Typography>
          </Box>
          <Box
            position='absolute'
            top='calc(50% - 25px)'
            right='-5px'
            width='6px'
            height='50px'
            backgroundColor='#f4f0f7'
            display='flex'
            justifyContent='center'
            alignItems='center'
            paddingTop='45px'
          >
            <Typography color='#652d92' fontSize='100px' fontFamily='Poppins' fontWeight='900'>
              ”
            </Typography>
          </Box>
          <Typography
            variant='h2'
            color='#652d92'
            sx={{
              fontSize: {
                xs: '1.3rem',
                md: '1.6rem',
              },
              textIndent: '-8px',
              fontFamily: 'Poppins',
              fontWeight: 400,
              textAlign: 'center',
              marginBottom: '0px',
            }}
          >
            {review}
          </Typography>
        </Box>
        <Box
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          width='100%'
          sx={{
            display: {
              xs: 'flex',
              lg: 'none',
            },
          }}
        >
          <Box
            display='flex'
            flexDirection='column'
            alignItems='flex-start'
            justifyContent='flex-end'
            paddingBottom='40px'
          >
            <Typography
              variant='h4'
              color='#652d92'
              sx={{
                fontSize: {
                  xs: '1.5rem',
                  md: '2rem',
                },
                fontFamily: 'Poppins',
                fontWeight: 600,
                textAlign: 'left',
                marginBottom: '0px',
              }}
            >
              {name}
            </Typography>
            <Typography
              variant='h4'
              color='#652d92'
              sx={{
                fontSize: {
                  xs: '1rem',
                  md: '1.5rem',
                },
                fontFamily: 'Poppins',
                fontWeight: 400,
                textAlign: 'left',
                marginBottom: '0px',
              }}
            >
              {affiliation}
            </Typography>
          </Box>
        </Box>
      </Box>
    )
  }

  const PhysicianPartnerLogoContainer = ({ logoSource, altText, customWidth, link }) => {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='150px'
        marginX='30px'
        sx={{
          width: {
            xs: customWidth || '300px',
            md: customWidth || '200px',
          },
        }}
      >
        <a href={link} target='_blank' rel='noreferrer'>
          <img
            src={logoSource}
            alt={altText}
            style={{
              width: '100%',
              objectFit: 'contain',
            }}
          />
        </a>
      </Box>
    )
  }

  return (
    <>
      <Helmet>
        <title>Mishe: Direct care made easy. No insurance needed.</title>
        <meta property='og:title' content='Mishe: Direct care made easy. No insurance needed.' />
        <meta
          name='description'
          property='og:description'
          content='Flexible treatment options, transparent pricing, modern payment tools, and your own personal care concierge team. Mishe is bringing healing back to healthcare'
        />
        <meta property='og:url' content='https://www.mishe.co' />
        <link rel='canonical' href='https://mishe.co' />
      </Helmet>
      {/* Hero Section */}
      <Hero />
      {/* Innovative healthcare infrastructure Desktop Section */}
      <Box
        sx={{
          display: {
            xs: 'none',
            md: 'block',
          },
        }}
      >
        <PageSection
          height={800}
          direction='up'
          slideBoxShadow='0px -8px 8px 0px rgba(0,0,0,0.1)'
          childrenOnSlide
          bgColor='#f4f0f7'
          bgColorSlide='#652d92'
          infoWidth='0%'
          customZIndex={1}
        >
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='100%'
            width='100%'
            flexDirection='column'
            sx={{
              padding: {
                xs: '16px',
                md: '48px',
              },
            }}
          >
            <Typography
              color='#fff'
              sx={{
                textAlign: {
                  xs: 'center',
                  lg: 'left',
                },
                fontSize: '3rem',
                lineHeight: {
                  xs: '1.25',
                },
                fontFamily: 'Poppins',
                fontStyle: 'italic',
                fontWeight: 700,
              }}
            >
              Innovative{' '}
              <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: 500,
                }}
              >
                healthcare infrastructure
              </span>
            </Typography>
            <Typography
              margin='0'
              color='#fff'
              sx={{
                textAlign: {
                  xs: 'center',
                },
                fontSize: '1.5rem',
                lineHeight: {
                  xs: '1.2',
                },
                marginBottom: {
                  xs: 2,
                  md: 4,
                },
                fontFamily: 'Poppins',
                fontWeight: 300,
              }}
            >
              Mishe offers both point solutions and a comprehensive, all-in-one platform to fit your
              needs
            </Typography>
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              height='70%'
              bgcolor='#8D63AF'
              borderRadius='32px'
              gap={4}
              sx={{
                padding: {
                  md: '16px',
                  xl: '32px',
                },
                flexDirection: {
                  xs: 'column',
                  md: 'row',
                },
                width: {
                  xs: '100%',
                  lg: '80%',
                },
                gap: {
                  md: 2,
                  xl: 4,
                },
              }}
            >
              {sections.map((section, index) => (
                <Box
                  alignSelf={index % 2 === 0 ? 'flex-start' : 'flex-end'}
                  height='min-content'
                  sx={{
                    p: {
                      md: 1,
                      xl: 2,
                    },
                    borderRadius: 4,
                    border: '2px solid #AA65E1',
                    backgroundColor: '#652d92',
                    color: 'black',
                    width: {
                      xs: '80%',
                      md: '20%',
                    },
                  }}
                  position='relative'
                  zIndex={1}
                >
                  {index % 2 === 0 && index !== 4 && (
                    <Box
                      position='absolute'
                      zIndex={-1}
                      width='calc(50% + 34px)'
                      height='360px'
                      top='10%'
                      left='calc(100% + 2px)'
                      borderRadius='0 32px 0 0'
                      borderTop='1px solid #000'
                      borderRight='1px solid #000'
                    />
                  )}
                  {index % 2 !== 0 && index !== 4 && (
                    <Box
                      position='absolute'
                      zIndex={-1}
                      width='calc(50% + 34px)'
                      height='360px'
                      bottom='10%'
                      left='calc(100% + 2px)'
                      borderRadius='0 0 32px 0'
                      borderBottom='1px solid #000'
                      borderRight='1px solid #000'
                    />
                  )}
                  <Typography
                    variant='h6'
                    align='center'
                    sx={{
                      color: '#fff',
                      fontWeight: 'bold',
                      lineHeight: '1.2',
                    }}
                  >
                    {section.title}
                  </Typography>
                  <Typography
                    variant='body2'
                    align='center'
                    sx={{
                      color: '#fff',
                      mt: 1,
                      fontSize: {
                        md: '15px',
                        xl: '16px',
                      },
                    }}
                  >
                    {section.description}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </PageSection>
      </Box>
      {/* Innovative healthcare infrastructure Mobile Section */}
      <Box
        sx={{
          display: {
            xs: 'block',
            md: 'none',
          },
        }}
      >
        <PageSection
          height={1400}
          direction='up'
          slideBoxShadow='0px -8px 8px 0px rgba(0,0,0,0.1)'
          childrenOnSlide
          bgColor='#f4f0f7'
          bgColorSlide='#652d92'
          infoWidth='0%'
          customZIndex={1}
        >
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='100%'
            width='100%'
            flexDirection='column'
            sx={{
              padding: {
                xs: '16px',
                md: '48px',
              },
            }}
          >
            <Typography
              color='#fff'
              sx={{
                textAlign: {
                  xs: 'center',
                  lg: 'left',
                },
                fontSize: {
                  xs: '2.2rem',
                  sm: '3rem',
                  md: '4rem',
                },
                lineHeight: {
                  xs: '1.25',
                },
                fontFamily: 'Poppins',
                fontStyle: 'italic',
                fontWeight: 700,
                marginBottom: 2,
              }}
            >
              Innovative{' '}
              <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: 500,
                }}
              >
                healthcare infrastructure
              </span>
            </Typography>
            <Typography
              margin='0'
              color='#fff'
              sx={{
                textAlign: {
                  xs: 'center',
                  lg: 'left',
                },
                fontSize: {
                  xs: '1.35rem',
                  sm: '1.5rem',
                  md: '1.75rem',
                },
                lineHeight: {
                  xs: '1.2',
                },
                marginBottom: {
                  xs: 4,
                  md: 4,
                },
                fontFamily: 'Poppins',
                fontWeight: 300,
              }}
            >
              Mishe offers both point solutions and a comprehensive, all-in-one platform to fit your
              needs
            </Typography>
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              height='min-content'
              width='85%'
              bgcolor='#f4f0f7'
              borderRadius='32px'
              sx={{
                padding: {
                  xs: '16px',
                  md: '32px',
                },
                flexDirection: {
                  xs: 'column',
                  md: 'row',
                },
                gap: {
                  xs: 2,
                  md: 4,
                },
              }}
            >
              {sections.map((section, index) => (
                <Box
                  alignSelf={index % 2 === 0 ? 'flex-start' : 'flex-end'}
                  height='min-content'
                  sx={{
                    p: 2,
                    borderRadius: 4,
                    border: '2px solid #fff',
                    backgroundColor: '#652d92',
                    color: 'black',
                    width: {
                      xs: '100%',
                      md: '20%',
                    },
                  }}
                  position='relative'
                  zIndex={1}
                >
                  <Typography
                    variant='h6'
                    align='center'
                    sx={{
                      color: '#fff',
                      fontWeight: 'bold',
                    }}
                  >
                    {section.title}
                  </Typography>
                  <Typography
                    variant='body2'
                    align='center'
                    sx={{
                      color: '#fff',
                      mt: 1,
                    }}
                  >
                    {section.description}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </PageSection>
      </Box>
      {/* Trusted by Desktop Section */}
      <Box
        sx={{
          display: {
            xs: 'none',
            lg: 'block',
          },
        }}
      >
        <PageSection
          height={600}
          direction='up'
          childrenOnSlide
          bgColor='#652d92'
          bgColorSlide='#f4f0f7'
          infoWidth='0%'
        >
          <Box
            position='relative'
            display='flex'
            justifyContent='center'
            flexDirection='column'
            height='100%'
            width='100%'
            sx={{
              padding: {
                xs: '40px 16px 16px 16px',
                md: '40px 0px 0px 0px',
              },
            }}
          >
            <Box>
              <Typography
                color='#652d92'
                align='center'
                sx={{
                  fontSize: {
                    md: '3rem',
                  },
                  lineHeight: {
                    xs: '1.25',
                  },
                  fontFamily: 'Poppins',
                  fontStyle: 'italic',
                  fontWeight: 700,
                }}
              >
                Trusted by:{' '}
                <span
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 500,
                  }}
                >
                  Top voices in the industry
                </span>
              </Typography>
            </Box>
            <ComponentShifterFadeControl cycleLength={7000} buttonColor='#652d92'>
              <ReviewContainer
                review='Mishe has been great to work with as we develop closer relationships with providers and embark on a same-day payment network strategy. With our Mishe-built custom member portal, we are now able to manage our directly contracted provider network and offer day of service EFT payments to our providers.'
                name='Ryan K.'
                affiliation='Mark Cuban Companies'
                headShot={ryanKline}
              />
              <ReviewContainer
                review="Mishe's network allows Downstate to extend its reach and provide accessibility to employers and individuals not covered by insurance or eligible for state programs. Mishe's billing automations and same-day reimbursement are welcome innovations making this partnership a true win-win-win."
                name='Sean S.'
                affiliation='University Hospital at Downstate'
                headShot={seanSmith}
              />
              <ReviewContainer
                review='Mishe simplifies our operations by streamlining direct contracts, claims, and payments while providing exceptional treatment benefits and transparent pricing. Their same-day EFT capability accelerates reimbursements and strengthens provider partnerships, transforming how we deliver value to our clients.'
                name='Matt C.'
                affiliation='Covet Health'
                headShot={mattC}
              />
            </ComponentShifterFadeControl>
          </Box>
        </PageSection>
      </Box>
      {/* Trusted by Mobile Section */}
      <Box
        sx={{
          display: {
            xs: 'block',
            lg: 'none',
          },
        }}
      >
        <PageSection
          height={620}
          direction='up'
          childrenOnSlide
          bgColor='#652d92'
          bgColorSlide='#f4f0f7'
          infoWidth='0%'
        >
          <Box
            position='relative'
            display='flex'
            justifyContent='center'
            flexDirection='column'
            height='100%'
            width='100%'
            sx={{
              padding: {
                xs: '80px 16px 16px 16px',
                md: '80px 0px 0px 0px',
              },
            }}
          >
            <Box>
              <Typography
                color='#652d92'
                align='center'
                sx={{
                  fontSize: {
                    xs: '2.2rem',
                    sm: '3rem',
                    md: '4rem',
                  },
                  lineHeight: {
                    xs: '1.25',
                  },
                  fontFamily: 'Poppins',
                  fontStyle: 'italic',
                  fontWeight: 700,
                  marginBottom: 2,
                }}
              >
                Trusted by:{' '}
                <span
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 500,
                  }}
                >
                  Top voices in the industry
                </span>
              </Typography>
            </Box>
            <ComponentShifterFade length={7000}>
              <ReviewContainer
                review='Mishe has been great to work with as we develop closer relationships with providers and embark on a same-day payment network strategy. With our Mishe-built custom member portal, we are now able to manage our directly contracted provider network and offer day of service EFT payments to our providers.'
                name='Ryan K.'
                affiliation='Mark Cuban Companies'
                headShot={ryanKline}
              />
              <ReviewContainer
                review="Mishe's network allows Downstate to extend its reach and provide accessibility to employers and individuals not covered by insurance or eligible for state programs. Mishe's billing automations and same-day reimbursement are welcome innovations making this partnership a true win-win-win."
                name='Sean S.'
                affiliation='University Hospital at Downstate'
                headShot={seanSmith}
              />
              <ReviewContainer
                review='Mishe simplifies our operations by streamlining direct contracts, claims, and payments while providing exceptional treatment benefits and transparent pricing. Their same-day EFT capability accelerates reimbursements and strengthens provider partnerships, transforming how we deliver value to our clients.'
                name='Matt C.'
                affiliation='Covet Health'
                headShot={mattC}
              />
            </ComponentShifterFade>
          </Box>
        </PageSection>
      </Box>
      {/* Spotlight on Mishe Desktop Section */}
      <Box
        sx={{
          display: {
            xs: 'none',
            md: 'block',
          },
        }}
      >
        <PageSection
          height={600}
          direction='up'
          childrenOnSlide
          bgColor='#f4f0f7'
          bgColorSlide='#652d92'
          infoWidth='0%'
        >
          <Box
            display='flex'
            justifyContent='center'
            flexDirection='column'
            height='100%'
            width='100%'
            sx={{
              padding: {
                xs: '16px',
                md: '48px',
              },
            }}
          >
            <Typography
              color='#fff'
              align='center'
              sx={{
                fontSize: {
                  xs: '2.2rem',
                  sm: '3rem',
                  md: '4rem',
                },
                lineHeight: {
                  xs: '1.25',
                },
                fontFamily: 'Poppins',
                fontStyle: 'italic',
                fontWeight: 700,
              }}
            >
              Spotlight{' '}
              <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: 500,
                }}
              >
                on Mishe
              </span>
            </Typography>
            <Box
              bgcolor='#f4f0f7'
              width='80%'
              height='4px'
              marginTop='20px'
              marginBottom='20px'
              position='relative'
              alignSelf='center'
            >
              <Box
                position='absolute'
                top='-8px'
                right='0'
                width='20px'
                height='20px'
                bgcolor='#f4f0f7'
                borderRadius='50%'
              />
            </Box>
            <Box width='100%' display='flex' justifyContent='center'>
              <Box
                display='flex'
                justifyContent='center'
                marginTop='20px'
                flexWrap='wrap'
                sx={{
                  width: {
                    xs: '100%',
                    md: '95%',
                    lg: '72%',
                    xl: '60%',
                  },
                }}
              >
                <PhysicianPartnerLogoContainer
                  logoSource={forbesLogo}
                  altText='forbes'
                  link='https://www.forbes.com/sites/gebai/2024/04/07/more-risks-more-opportunities-whats-next-for-employers-in-healthcare/?sh=7c3ff36465f4'
                />
                <PhysicianPartnerLogoContainer
                  logoSource={beckersWhiteLogo}
                  altText='beckers'
                  link='https://www.beckershospitalreview.com/finance/suny-downstate-health-sciences-u-partnership-aims-to-improve-price-transparency.html'
                />
                <PhysicianPartnerLogoContainer
                  logoSource={medicalEconomicsLogo}
                  altText='medicalEconomics'
                  link='https://www.medicaleconomics.com/view/why-cash-is-the-first-step-to-eliminating-barriers-in-health-care'
                />
                <PhysicianPartnerLogoContainer
                  logoSource={andersonLogo}
                  altText='anderson'
                  link='https://a16z.com/the-biggest-company-in-the-world/'
                />
                <PhysicianPartnerLogoContainer
                  logoSource={healthAffairsLogo}
                  altText='healthAffairs'
                  link='https://www.healthaffairs.org/content/forefront/employer-provider-direct-contracting-practice-and-policy'
                />
                <PhysicianPartnerLogoContainer
                  logoSource={medicineToMarketLogoWhite}
                  altText='medicineToMarket'
                  link='https://medicinetomarket.com/mishe-unveils-multi-channel-pharmacy-network/'
                />
              </Box>
            </Box>
          </Box>
        </PageSection>
      </Box>
      {/* Spotlight on Mishe Mobile Section */}
      <Box
        sx={{
          display: {
            xs: 'block',
            md: 'none',
          },
        }}
      >
        <PageSection
          height={400}
          direction='up'
          childrenOnSlide
          bgColor='#f4f0f7'
          bgColorSlide='#652d92'
          infoWidth='0%'
        >
          <Box
            display='flex'
            justifyContent='center'
            flexDirection='column'
            height='100%'
            width='100%'
            sx={{
              padding: {
                xs: '16px',
                md: '48px',
              },
            }}
          >
            <Typography
              color='#fff'
              align='center'
              sx={{
                fontSize: {
                  xs: '2.2rem',
                  sm: '3rem',
                  md: '4rem',
                },
                lineHeight: {
                  xs: '1.25',
                },
                fontFamily: 'Poppins',
                fontStyle: 'italic',
                fontWeight: 700,
              }}
            >
              Spotlight{' '}
              <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: 500,
                }}
              >
                on Mishe
              </span>
            </Typography>
            <Box
              bgcolor='#f4f0f7'
              width='100%'
              height='4px'
              marginTop='20px'
              marginBottom='20px'
              position='relative'
              alignSelf='center'
            >
              <Box
                position='absolute'
                top='-8px'
                right='0'
                width='20px'
                height='20px'
                bgcolor='#f4f0f7'
                borderRadius='50%'
              />
            </Box>
            <Box display='flex' justifyContent='center' width='100%' marginTop='20px'>
              <ComponentShifterFade length={4000}>
                <PhysicianPartnerLogoContainer
                  logoSource={forbesLogo}
                  altText='forbes'
                  link='https://www.forbes.com/sites/gebai/2024/04/07/more-risks-more-opportunities-whats-next-for-employers-in-healthcare/?sh=7c3ff36465f4'
                />
                <PhysicianPartnerLogoContainer
                  logoSource={beckersWhiteLogo}
                  altText='beckers'
                  link='https://www.beckershospitalreview.com/finance/suny-downstate-health-sciences-u-partnership-aims-to-improve-price-transparency.html'
                />
                <PhysicianPartnerLogoContainer
                  logoSource={medicineToMarketLogoWhite}
                  altText='medicineToMarket'
                  link='https://medicinetomarket.com/mishe-unveils-multi-channel-pharmacy-network/'
                />
                <PhysicianPartnerLogoContainer
                  logoSource={healthAffairsLogo}
                  altText='healthAffairs'
                  link='https://www.healthaffairs.org/content/forefront/employer-provider-direct-contracting-practice-and-policy'
                />
                <PhysicianPartnerLogoContainer
                  logoSource={medicalEconomicsLogo}
                  altText='medicalEconomics'
                  link='https://www.medicaleconomics.com/view/why-cash-is-the-first-step-to-eliminating-barriers-in-health-care'
                />
                <PhysicianPartnerLogoContainer
                  logoSource={andersonLogo}
                  altText='anderson'
                  link='https://a16z.com/the-biggest-company-in-the-world/'
                />
              </ComponentShifterFade>
            </Box>
          </Box>
        </PageSection>
      </Box>
      {/* Get in touch Section */}
      <GetInTouchSection />
      {/* Concierge */}
      {/* <LiveChat /> */}
    </>
  )
}

export default LandingPage
