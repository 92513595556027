import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Heading from '@app/stories/Heading'
import Avatar from '@app/stories/Avatar'
import Icon from '@app/stories/Icons'
import pricesIcon from '@app/assets/images/prices.svg'

const EmptyTab = ({ children }) => (
  <Box display='flex' alignItems='center' my={2} mx={2}>
    <Avatar size='tiny' position='left' src={pricesIcon} alt='priceTag'>
      <Icon name='search' fontSize='large' />
    </Avatar>
    <Heading variant='body1' component='p' align='left'>
      {children}
    </Heading>
  </Box>
)

EmptyTab.propTypes = {
  children: PropTypes.node,
}

export default EmptyTab
