import { Box } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Button from '@app/stories/Button'
import MisheLeaves from '@app/assets/images/MisheLeaves.png'
import PageSection from '@app/components/PageSection'
import Typography from '@app/stories/Typography'
import background from '@app/assets/images/backgroundTest2.png'
import PhysicianPage1 from '@app/assets/images/PhysicianPage1.png'
import MishePhysicianReceptionist from '@app/assets/images/MishePhysicianReceptionist.png'
import BubbleBuilder from '@app/components/SvgBuilders/BubbleBuilder'
import ComponentShifterFadeControl from '@app/components/ComponentShifterFadeControl'
import GetInTouchSection from '@app/components/GetInTouchSection'
import { scrollToHash } from '@app/libs/scroll'

import { useWhiteLabelingContext } from '@app/context/WhiteLabelingContext'

const BrokersPage = () => {
  const { planColorPrimary } = useWhiteLabelingContext()

  const CheckBox = ({ title, content, marginBottom }) => (
    <Box mb={marginBottom || 2}>
      <Typography
        variant='body2'
        gutterBottom
        weight='light'
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <CheckCircleIcon style={{ color: '#662D91' }} />
        <Box display='flex' flexDirection='column'>
          <span style={{ marginLeft: 20, fontWeight: '700' }}>{title}</span>
          <span style={{ marginLeft: 20 }}>{content}</span>
        </Box>
      </Typography>
    </Box>
  )

  const HowItWorksCard = ({ image, title, content }) => {
    return (
      <Box
        width={'100%'}
        minHeight='350px'
        display='flex'
        flexDirection='column'
        justifyContent='flex-start'
        alignItems='center'
        backgroundColor={planColorPrimary}
        outline='1px solid #fff'
        borderRadius='8px'
        padding='16px'
        style={{
          transform: 'scale(1)',
          transition: 'transform 0.3s',
        }}
        onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
        onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
      >
        {image && (
          <Box height='132px' padding='16px 0'>
            <BubbleBuilder color='#652d92' bubbleName={image} />
          </Box>
        )}
        <Box
          sx={{
            minHeight: {
              md: '100px',
              lg: '70px',
            },
            width: {
              md: '100%',
              lg: '90%',
            },
          }}
        >
          <Typography
            variant='h4'
            align='center'
            color='white'
            sx={{ marginBottom: { xs: '1rem', md: '0' } }}
          >
            {title}
          </Typography>
        </Box>
        <Typography variant='body1' align='center' color='white'>
          {content}
        </Typography>
      </Box>
    )
  }

  return (
    <Box>
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <PageSection
          startLoaded
          direction='left'
          image={PhysicianPage1}
          height={600}
          imgWidth='60%'
          infoWidth='545px'
          bgImage={background}
        >
          <Box>
            <Box
              padding='1rem 1rem 1rem 1rem'
              bgcolor='#f6eeff'
              boxShadow='0px 4px 4px rgba(0, 0, 0, 0.1)'
            >
              <Box padding='2rem' bgcolor='#652d92' marginLeft='2rem'>
                <Typography
                  variant='h5'
                  family='poppins'
                  weight='medium'
                  style={{
                    color: '#fff',
                    fontSize: '36px',
                    margin: '0',
                  }}
                >
                  Help your clients save
                  <br /> money and improve
                  <br /> access to care
                </Typography>
              </Box>
            </Box>
            <Box width='100%' display='flex' justifyContent='flex-end'>
              <Button
                variant='contained'
                size='large'
                sx={{
                  backgroundColor: '#652d92',
                  color: '#fff',
                  padding: '10px 40px',
                  marginTop: '20px',
                  borderRadius: '50px',
                  fontFamily: 'Poppins',
                  fontWeight: 700,
                  alignSelf: 'center',
                }}
                onClick={() => scrollToHash('#getInTouch')}
              >
                Partner with us
              </Button>
            </Box>
          </Box>
        </PageSection>
        <PageSection height={100} bgColorSlide='#652d92' bgColor='#6d319e'>
          <Box display='flex' justifyContent='space-around' padding='0 2rem' width='100%'>
            <Typography
              variant='h2'
              family='poppins'
              style={{
                color: '#fff',
                margin: '0',
              }}
            >
              How Mishe Helps Brokers
            </Typography>
          </Box>
        </PageSection>
        <PageSection
          height={600}
          direction='left'
          infoWidth='60%'
          imgWidth='45%'
          image={MishePhysicianReceptionist}
          bgColor='#f6eeff'
        >
          <Box margin='0 5rem 0 5rem'>
            <Box mb={1}>
              <Typography variant='caption' weight='semibold' family='poppins'>
                <Box component='span' color='primary.main'>
                  SEAMLESS HEALTHCARE
                </Box>
              </Typography>
            </Box>
            <Box mb={2}>
              <Typography variant='h3' gutterBottom family='poppins'>
                Design health plans that cost
                <br />
                <Box component='span' color='primary.main'>
                  less and offer more.
                </Box>
              </Typography>
            </Box>
            <Typography
              variant='body1'
              weight='light'
              style={{ maxWidth: '480px' }}
              family='poppins'
            >
              Mishe brings together plan design, care coordination, and real-time claims
              infrastructure to give your clients the best of both worlds: savings and service.
            </Typography>
            <br />
            <CheckBox
              title='Care Coordination'
              content="Mishe's care coordinators deliver a white-glove experience that improves outcomes, and lowers total costs. We help employees and their families navigate the healthcare system with less stress while making the most of their benefits."
            />
            <CheckBox
              title='Network & Pricing'
              content="We work with your clients' existing networks or establish direct contracts and Single Case Agreements when needed. Through our negotiations, we secure 125% of Medicare pricing or better."
            />
            <CheckBox
              title='Claims & Payments'
              content="Mishe enables real-time adjudication and same-day provider payments by drawing directly from the employer's claims account when the appointment is scheduled. Funds go straight to the provider's pay-to bank account—no delays, no confusion."
              marginBottom={0}
            />
          </Box>
        </PageSection>
        <Box width='100%' padding='32px' bgcolor={planColorPrimary}>
          <Box display='flex' justifyContent='center' alignItems='center' padding='16px'>
            <Typography
              variant='h2'
              family='poppins'
              style={{
                color: '#fff',
                textAlign: 'center',
              }}
            >
              Why Brokers Choose Mishe
            </Typography>
          </Box>
          <Box
            width='100%'
            justifyContent='center'
            alignItems='center'
            padding='32px'
            gap='32px'
            sx={{
              display: {
                xs: 'none',
                sm: 'none',
                md: 'flex',
                lg: 'flex',
              },
            }}
          >
            <HowItWorksCard
              image='outOfPocket'
              title='Drive Cost Savings'
              content='Reduce healthcare spend by up to 60% with direct contracting and streamlined administration. Simplify processes, cut costs and reduce unnecessary utilization.'
            />
            <HowItWorksCard
              image='coverage'
              title='Flexible Plan Design'
              content='Design custom health plans with flexible networks, navigation, and care coordination—no need to change carriers, TPAs, or existing partners.'
            />
            <HowItWorksCard
              image='claimStatus'
              title='Ensure Compliance'
              content='Stay ahead of fiduciary obligations with transparency tools, automated reporting, and real-time cost management.'
            />
          </Box>
        </Box>
        <GetInTouchSection customBodyText='Reach out to learn more about how our software can help your clients save money on tailored health plans.' />
      </Box>
      {/* Mobile Section */}
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        {/* Top Section */}
        <PageSection
          startLoaded
          height={500}
          imgWidth='100%'
          infoWidth='100%'
          bgImage={MisheLeaves}
        >
          <Box display='flex' flexDirection='column'>
            <Box
              padding='1rem'
              bgcolor='rgba(217, 193, 244, 0.9)'
              boxShadow='0px 4px 4px rgba(0, 0, 0, 0.1)'
              margin='1rem'
            >
              <Box padding='1rem' bgcolor='#652d92'>
                <Typography
                  variant='h5'
                  family='poppins'
                  weight='light'
                  style={{
                    color: '#fff',
                    fontSize: '24px',
                    margin: '0',
                  }}
                >
                  Help your clients save
                  <br /> money and improve
                  <br /> access to care
                </Typography>
              </Box>
            </Box>
            <Box width='100%' display='flex' justifyContent='center'>
              <Button
                variant='contained'
                size='large'
                sx={{
                  backgroundColor: '#652d92',
                  color: '#fff',
                  padding: '10px 40px',
                  marginTop: '20px',
                  borderRadius: '50px',
                  fontFamily: 'Poppins',
                  fontWeight: 700,
                  alignSelf: 'center',
                }}
                onClick={() => scrollToHash('#getInTouch')}
              >
                Partner with us
              </Button>
            </Box>
          </Box>
        </PageSection>
        {/* Info Section */}
        <PageSection height={700} direction='left' infoWidth='100%' imgWidth='0%' bgColor='#f6eeff'>
          <Box padding='16px'>
            <Box mb={0.5}>
              <Typography variant='caption' weight='semibold' family='poppins'>
                <Box component='span' color='primary.main'>
                  SEAMLESS HEALTHCARE
                </Box>
              </Typography>
            </Box>
            <Box mb={2}>
              <Typography variant='h4' gutterBottom family='poppins'>
                Design health plans that cost
                <br />
                <Box component='span' color='primary.main'>
                  less and offer more.
                </Box>
              </Typography>
            </Box>
            <Typography
              variant='body1'
              weight='light'
              style={{ maxWidth: '480px' }}
              family='poppins'
            >
              Mishe brings together plan design, care coordination, and real-time claims
              infrastructure to give your clients the best of both worlds: savings and service.
            </Typography>
            <br />
            <CheckBox
              title='Care Coordination'
              content="Mishe's care coordinators deliver a white-glove experience that improves outcomes, and lowers total costs. We help employees and their families navigate the healthcare system with less stress while making the most of their benefits."
            />
            <CheckBox
              title='Network & Pricing'
              content="We work with your clients' existing networks or establish direct contracts and Single Case Agreements when needed. Through our negotiations, we secure 125% of Medicare pricing or better."
            />
            <CheckBox
              title='Claims & Payments'
              content="Mishe enables real-time adjudication and same-day provider payments by drawing directly from the employer's claims account when the appointment is scheduled. Funds go straight to the provider's pay-to bank account—no delays, no confusion."
              marginBottom={0}
            />
          </Box>
        </PageSection>
        {/* Cards section */}
        <Box width='100%' padding='32px 0px' bgcolor={planColorPrimary}>
          <Box display='flex' justifyContent='center' alignItems='center' padding='16px'>
            <Typography
              variant='h2'
              family='poppins'
              // weight='light'
              style={{
                color: '#fff',
                textAlign: 'center',
              }}
            >
              Why Brokers Choose Mishe
            </Typography>
          </Box>
          <Box width='100%' justifyContent='center' alignItems='center'>
            <ComponentShifterFadeControl healthPlanHomePage buttonColor='#fff'>
              <HowItWorksCard
                image='outOfPocket'
                title='Drive Cost Savings'
                content='Reduce healthcare spend by up to 60% with direct contracting and streamlined administration. Simplify processes, cut costs and reduce unnecessary utilization.'
              />
              <HowItWorksCard
                image='coverage'
                title='Flexible Plan Design'
                content='Design custom health plans with flexible networks, navigation, and care coordination—no need to change carriers, TPAs, or existing partners.'
              />
              <HowItWorksCard
                image='claimStatus'
                title='Ensure Compliance'
                content='Stay ahead of fiduciary obligations with transparency tools, automated reporting, and real-time cost management.'
              />
            </ComponentShifterFadeControl>
          </Box>
        </Box>
        {/* Get in touch section */}
        <GetInTouchSection customBodyText='Reach out to learn more about how our software can help your clients save money on tailored health plans.' />
      </Box>
    </Box>
  )
}

export default BrokersPage
