import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { collection } from '@app/firebase/firestore'
import { useVouchers } from '@app/context/Vouchers'
import Vouchers from '@app/components/PatientVouchers'
import EmptyTab from '@app/components/EmptyTab'
import Modal from '@app/components/ClaimVoucherModal'
import { useCurrentUser } from '@app/context/CurrentUser'

const PatientVouchers = () => {
  const { vouchers, changeVouchers, externalClaims, changeExternalClaims } = useVouchers()
  const { profileData } = useCurrentUser()

  const [allClaimsAndVouchers, setAllClaimsAndVouchers] = useState([])

  const { search } = useLocation()
  const slug = new URLSearchParams(search).get('claim')
  const flag = true

  useEffect(() => {
    let unsubscribeVouchers, unsubscribeClaims
    if (profileData.id) {
      unsubscribeVouchers = collection('vouchers')
        .where('user.id', '==', profileData.id)
        .onSnapshot((docs) => {
          return changeVouchers(docs, true)
        })

      unsubscribeClaims = collection('external-claims')
        .where('patient_info.user_id', '==', profileData.id)
        // We have to query all because we need to show all financials on the main claims dashboard
        // .where('BILL_ENTRY_NUM', '==', '0001')
        .onSnapshot((docs) => {
          return changeExternalClaims(docs, true)
        })
    }
    return () => {
      if (unsubscribeVouchers) unsubscribeVouchers()
      if (unsubscribeClaims) unsubscribeClaims()
    }
  }, [profileData.id, changeVouchers, changeExternalClaims])

  useEffect(() => {
    const allData = [...vouchers, ...externalClaims]
    console.log('allData', allData)
    setAllClaimsAndVouchers(allData)
  }, [vouchers, externalClaims])

  if (allClaimsAndVouchers.length === 0)
    return <EmptyTab>There are no claims available to display.</EmptyTab>
  return (
    <>
      {slug && <Modal vouchers={null} flag={flag || false} />}
      <Vouchers rows={allClaimsAndVouchers} />
    </>
  )
}

export default PatientVouchers
