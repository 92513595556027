import { memo } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@app/stories/Typography'
import Divider from '@app/stories/Divider'
import Button from '@app/stories/Button'
import AddScriptButton from '@app/components/AddScriptButton'
import VoucherNewNoteDropdown from '@app/components/VoucherNewNoteDropdown'
import { useCurrentUser } from '@app/context/CurrentUser'
import OriginalOrderBar from '@app/components/OriginalOrderBar'
import { useVoucherDetails } from '@app/context/VoucherDetails'
import VoucherNotes from '@app/components/VoucherDetails/VoucherNotes'
import VoucherOrders from '@app/components/VoucherDetails/VoucherOrders'

import VoucherInfo from '@app/components/VoucherDetails/VoucherInfo'
import PatientInfo from '@app/components/VoucherDetails/PatientInfo'
import VoucherChatContainer from '@app/components/VoucherDetails/VoucherChatContainer'

import { useNavigate } from 'react-router-dom'
import { getVoucherType } from '@app/utils/userFormatterUtil'

import VouchersBundle from '@app/components/VouchersBundle'

const BUNDLE_HEADERS = [
  { id: 'service', label: 'Treatment', align: 'left', type: 'Text' },
  { id: 'appointmentDate', label: 'Appt Date', align: 'left', type: 'Calendar' },
  { id: 'details', label: 'Details', align: 'left', type: 'Detail' },
  { id: 'code', label: 'Claim Id', align: 'left', type: 'Text' },
  { id: 'status', label: 'Status', align: 'center', type: 'StatusChip' },
  { id: 'redeem', label: 'Redeem', align: 'center', type: 'RedeemAction' },
  { id: 'action', label: 'Actions', align: 'center', type: 'CopyLink' },
]

const VoucherDetailsControlBar = () => {
  const { notes, orders, originalOrder, voucher, handleClose } = useVoucherDetails()

  const { authData } = useCurrentUser()

  const navigate = useNavigate()
  const closeAndNavigateBack = () => {
    handleClose()
    navigate('/dashboard/vouchers') // navigate back to the vouchers page
  }

  return (
    <Box>
      <Box
        sx={{
          justifyContent: {
            xs: 'center',
            sm: 'center',
          },
          marginBottom: {
            xs: '20px',
            sm: '20px',
          },
        }}
      >
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} sm={12} md={6} id='voucherDetails'>
            <Box
              sx={{
                display: {
                  xs: 'flex',
                  sm: 'flex',
                },
                justifyContent: {
                  xs: 'center',
                  sm: 'center',
                  md: 'flex-start',
                  lg: 'flex-start',
                },
              }}
            >
              <Typography variant='h3'>
                {getVoucherType(voucher) === 'Bundle Parent' ? 'Bundle' : 'Claim'} Details
              </Typography>
            </Box>
          </Grid>
          {authData.roles.includes('PROVIDER') && (
            <>
              <Grid item xs={12} sm={12} md={2}>
                <VoucherNewNoteDropdown />
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <AddScriptButton />
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={12} md={2}>
            <Button
              variant='contained'
              color='primary'
              onClick={closeAndNavigateBack}
              style={{
                height: '40px',
                width: '100%',
              }}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Grid container spacing={2} mt={1} mb={3}>
        <Grid item xs={12} sm={12} md={6}>
          <PatientInfo voucher={voucher} />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <VoucherInfo voucher={voucher} isProvider={authData.roles.includes('PROVIDER')} />
        </Grid>
      </Grid>
      {originalOrder && <OriginalOrderBar order={originalOrder} />}
      <Divider />
      <Grid container spacing={2}>
        <VoucherChatContainer voucher={voucher} />

        {getVoucherType(voucher) === 'Bundle Parent' ? (
          <Grid item xs={12} sm={12} md={4}>
            <VouchersBundle headers={BUNDLE_HEADERS} open={true} voucher={voucher} />
          </Grid>
        ) : (
          <>
            <Grid item xs={12} sm={12} md={4}>
              <VoucherNotes voucher={voucher} notes={notes} />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <VoucherOrders orders={orders} />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  )
}

export default memo(VoucherDetailsControlBar)
