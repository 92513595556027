import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation, useParams } from 'react-router-dom'
import NetworkServiceInfoLander from '@app/components/NetworkServiceInfoLander'
import { useCurrentNetwork } from '@app/context/CurrentNetwork'
import LiveChat from '@app/stories/LiveChat'
import parseQueryString from '@app/libs/parseQueryStrings'
import MapEffectsProvider from '@app/context/MapEffects'
import NetworkHeroLogin from '@app/components/NetworkHeroLogin'
import { useCurrentUser } from '@app/context/CurrentUser'

const NetworkServiceLander = () => {
  const { search } = useLocation()
  const { network, misheCode } = useParams()
  const [ref, setRef] = useState('')
  const [utms, setUtms] = useState({})

  const [isMember, setIsMember] = useState(false)

  const { profileData, authData } = useCurrentUser()

  const {
    setAllUTMs,
    setUnformattedUTMs,
    loadNetworkBySlug,
    networkData,
    networkId,
    landerData,
    loadServicesByMisheCode,
    checkIfMember,
  } = useCurrentNetwork()

  // {* BEGIN Tracking Calls *}
  useEffect(() => {
    const { ...utmsObj } = parseQueryString(search)
    setRef(utmsObj.ref)
    setUtms(utmsObj)
    setUnformattedUTMs(search)
  }, [search, setUnformattedUTMs])

  if (ref) {
    localStorage.setItem('refvalue', ref)
  }

  useEffect(() => {
    const { IP, ...strippedUTMs } = utms
    const testPayload = {
      page_name: 'Network Service Lander',
      network,
      misheCode,
      RefCode: ref || '',
      ...strippedUTMs,
    }

    window.Intercom('trackEvent', 'Page View', {
      page_name: 'Network Service Lander',
      network,
      ...testPayload,
    })
  }, [utms])

  useEffect(() => {
    setAllUTMs(utms)
  }, [utms])
  // {* END Tracking Calls *}

  // START - Fetch page contents
  useEffect(() => {
    const GetNetwork = async () => {
      await loadNetworkBySlug(network)
    }

    // We check to see if the user has gone from one network to another, if not don't fetch the network data again
    if (network && (!networkData || networkData?.slug !== network)) {
      GetNetwork()
    }
  }, [network, networkData])

  // Fetch services by mishe code
  useEffect(() => {
    const GetServices = async () => {
      await loadServicesByMisheCode({ netId: networkId, misheCode })
    }

    if (misheCode && networkId) {
      GetServices()
    }
  }, [misheCode, networkId])

  useEffect(() => {
    if (!networkData?.membershipRequired) {
      setIsMember(true)
      return undefined
    }

    const checkMembership = async () => {
      const member = await checkIfMember({
        userID: profileData.id,
        clinicID: authData.organizationId,
        networkID: networkId,
      })
      setIsMember(member)
    }

    if (!profileData.id || !networkId) {
      setIsMember(false)
      return undefined
    }

    checkMembership()
  }, [networkId, authData.roles, profileData.id, landerData])

  return (
    <>
      <Helmet>
        {networkData?.metaTitle && <title>Mishe | {networkData?.metaTitle || ''}</title>}
        {networkData?.metaTitle && (
          <meta property='og:title' content={`Mishe | ${networkData?.metaTitle || ''}`} />
        )}
        {!networkData?.metaTitle && <title>Mishe {network}</title>}
        {!networkData?.metaTitle && <meta property='og:title' content={`Mishe | ${network}`} />}
        <meta
          property='og:url'
          content={`https://www.mishe.co/plans/${network}/service/${misheCode}`}
        />
        <link rel='canonical' href={`https://mishe.co/plans/${network}/service/${misheCode}`} />
      </Helmet>
      {!profileData.id && networkData && networkData?.loginRequired && (
        <NetworkHeroLogin reason='not-loggedIn' loginMethods={networkData?.loginMethods} />
      )}
      {profileData.id && !isMember && (
        <NetworkHeroLogin reason='not-member' loginMethods={networkData?.loginMethods} />
      )}
      {((isMember && profileData.id) || (networkData && !networkData?.loginRequired)) && (
        <MapEffectsProvider>
          <NetworkServiceInfoLander
            landerData={landerData}
            misheCode={misheCode}
            network={network}
          />
        </MapEffectsProvider>
      )}
      <LiveChat sectionId='concierge' />
    </>
  )
}

export default NetworkServiceLander
