import ResetPasswordFormContainer from '@app/containers/ResetPasswordForm'
import Container from '@app/stories/Container'
import Section from '@app/stories/Section'

const ResetPasswordPage = () => (
  <Container maxWidth='xs'>
    <Section>
      <ResetPasswordFormContainer />
    </Section>
  </Container>
)

export default ResetPasswordPage
