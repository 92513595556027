import { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'

import misheLogoWhite from '../assets/images/LogoTextWhite.svg'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const WhiteLabeling = createContext()

export const useWhiteLabelingContext = () => useContext(WhiteLabeling)

const WhiteLabelingProvider = ({ children }) => {
  const defaultLogo = misheLogoWhite

  // Accepts URL or Data URI
  const [logoSource, setLogoSource] = useState('')
  const location = useLocation()

  useEffect(() => {
    if (logoSource === '') {
      if (location.pathname.includes('/plans')) {
        setLogoSource('')
      } else {
        setLogoSource(defaultLogo)
      }
    }
  }, []) // Empty dependency array to run only on initial render

  // Array of jsx elements
  const [customNavElements, setCustomNavElements] = useState([])
  // show/hide default nav elements
  const [showDefaultNavElements, setShowDefaultNavElements] = useState(true)

  // WHITE LABELING OPTIONS
  // Used to color the top bar and UI of the plan
  const [planColorPrimary, setPlanColorPrimary] = useState('#652d92')
  const [planColorSecondary, setPlanColorSecondary] = useState('#fff')
  // Used to change the phone number in the top bar and options in the top bar dropdown
  const [planPhoneNumber, setPlanPhoneNumber] = useState('601-647-4326')
  const [planSMSNumber, setPlanSMSNumber] = useState('855-647-4326')

  // login options
  const [loginMethods, setloginMethods] = useState(['email', 'phone', 'register'])
  // plan slug
  const [planSlug, setPlanSlug] = useState('')
  // default zipcode of plan used for centering map on search
  const [defaultZipcode, setDefaultZipcode] = useState('')

  const value = {
    setLogoSource,
    logoSource,
    setCustomNavElements,
    customNavElements,
    setShowDefaultNavElements,
    showDefaultNavElements,
    setPlanColorPrimary,
    planColorPrimary,
    setPlanColorSecondary,
    planColorSecondary,
    setloginMethods,
    loginMethods,
    planSlug,
    setPlanSlug,
    planPhoneNumber,
    setPlanPhoneNumber,
    planSMSNumber,
    setPlanSMSNumber,
    defaultZipcode,
    setDefaultZipcode,
  }
  return <WhiteLabeling.Provider value={value}>{children}</WhiteLabeling.Provider>
}

WhiteLabelingProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default WhiteLabelingProvider
