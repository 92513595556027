import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { useQuickCheckout } from '@app/context/QuickCheckoutNetwork'
import CheckoutForm from '@app/components/AllInOneCheckout'

const QuickCheckoutNetwork = ({ networkId, price }) => {
  const { setServiceId, setNetworkName, setNetworkId, getNetworkByName } = useQuickCheckout()

  const { network, networkServiceId } = useParams()

  useEffect(() => {
    setNetworkName(network)
    getNetworkByName(network)
    setServiceId(networkServiceId)
  }, [network, networkServiceId, setNetworkName, setServiceId, getNetworkByName])

  useEffect(() => {
    if (networkId) {
      setNetworkId(networkId)
    }
  }, [network, setNetworkId, networkId])

  return (
    <Container>
      <Grid container spacing={3} justifyContent='center' alignItems='center'>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CheckoutForm price={price} />
        </Grid>
      </Grid>
    </Container>
  )
}

QuickCheckoutNetwork.propTypes = {
  networkId: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
}

export default QuickCheckoutNetwork
