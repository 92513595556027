import { useCallback, useState } from 'react'
import { collection, collectionModular, subCollectionModular } from '@app/firebase/firestore'
import { query, where, getDocs, and, or } from 'firebase/firestore'

const useReports = ({ organizationId }) => {
  const [revenueReferringProvider, setRevenueReferringProvider] = useState([])
  const [revenueProvider, setRevenueProvider] = useState([])
  const [revenueByPlan, setRevenueByPlan] = useState([])
  const [revenueByService, setRevenueByService] = useState([])

  const fetchRevenueByReferringProvider = useCallback(() => {
    try {
      const bundleVouchersDataEMRSubOrders = []
      const vouchersDataEMROrders = []
      setRevenueReferringProvider([])
      let bundleVoucherRef = collection('vouchers')
        .where('bundle', '==', true)
        .where('clinicIDs', 'array-contains', organizationId)
        .where('status', '==', 'redeemed')
        .where('emrOrderID', '!=', null)

      bundleVoucherRef.get().then((querySnapshot) => {
        let countLoop = 0
        let totalDocs = querySnapshot.docs.length

        if (querySnapshot.empty) {
          console.log('No matching documents.')

          setRevenueReferringProvider([])
          return undefined
        }
        querySnapshot.forEach((doc) => {
          const { emrOrderID } = doc.data()

          doc.ref
            .collection('subVouchers')
            .where('organization.id', '==', organizationId)
            .where('status', '==', 'redeemed')
            .where('emrOrderID', '!=', null)
            .get()
            .then((subVouchers) => {
              totalDocs = totalDocs + subVouchers.docs.length
              countLoop++
              subVouchers.forEach(async (subVoucherDoc) => {
                // console.log(subVoucherDoc.id, ' => ====', subVoucherDoc.data())
                await collection('emrOrders')
                  .doc(emrOrderID)
                  .collection('subOrders')
                  .get()
                  .then((subOrders) => {
                    countLoop++

                    totalDocs = totalDocs + subOrders.docs.length
                    subOrders.forEach(async (subOrdersDoc) => {
                      countLoop++

                      bundleVouchersDataEMRSubOrders.push({
                        voucher: subVoucherDoc.data(),
                        emrOrder: subOrdersDoc.data(),
                      })
                      if (countLoop === totalDocs) {
                        setRevenueReferringProvider((prevCount) => [
                          ...prevCount,
                          ...bundleVouchersDataEMRSubOrders,
                        ])
                      }
                    })
                  })
              })
            })
        })
      })

      collection('vouchers')
        .where('organization.id', '==', organizationId)
        .where('status', '==', 'redeemed')
        .where('emrOrderID', '!=', null)
        .onSnapshot(async (querySnapshot) => {
          let countLoop = 0
          const totalDocs = querySnapshot.docs.length

          if (querySnapshot.empty) {
            console.log('No matching documents.')

            setRevenueReferringProvider([])
            return undefined
          }

          querySnapshot.forEach(async (voucherDoc) => {
            const { emrOrderID } = voucherDoc.data()

            if (emrOrderID) {
              await collection('emrOrders')
                .doc(emrOrderID)
                .get()
                .then((emrDoc) => {
                  countLoop++
                  vouchersDataEMROrders.push({
                    voucher: voucherDoc.data(),
                    emrOrder: emrDoc.data(),
                  })
                  countLoop === totalDocs &&
                    setRevenueReferringProvider((prevCount) => [
                      ...prevCount,
                      ...vouchersDataEMROrders,
                    ])
                })
            }
          })
        })
    } catch (error) {
      console.log('error fetching vouchers & emrOrders Data')
      console.log(error)
      return false
    }
  }, [organizationId])

  const fetchRevenueByProvider = useCallback(() => {
    try {
      collection('vouchers')
        .where('organization.id', '==', organizationId)
        .where('status', '==', 'redeemed')
        .where('emrOrderID', '!=', null)
        .onSnapshot(async (querySnapshot) => {
          const vouchersDataEMROrders = []
          let countLoop = 0
          const totalDocs = querySnapshot.docs.length

          if (querySnapshot.empty) {
            console.log('No matching documents.')

            setRevenueReferringProvider([])
            return undefined
          }

          querySnapshot.forEach(async (voucherDoc) => {
            const { emrOrderID } = voucherDoc.data()
            await collection('emrOrders')
              .doc(emrOrderID)
              .get()
              .then((emrDoc) => {
                countLoop++
                vouchersDataEMROrders.push({
                  voucher: voucherDoc.data(),
                  emrOrder: emrDoc.data(),
                })

                countLoop === totalDocs && setRevenueProvider(vouchersDataEMROrders)
              })
          })
        })
    } catch (error) {
      console.log('error fetching vouchers & emrOrders Data')
      console.log(error)
      return false
    }
  }, [organizationId])

  const fetchRevenueByPlan = useCallback(() => {
    try {
      collection('vouchers')
        .where('organization.id', '==', organizationId)
        .where('status', '==', 'redeemed')
        .where('network', '!=', null)
        .onSnapshot(async (querySnapshot) => {
          const vouchersDataByPlan = []
          let countLoop = 0
          const totalDocs = querySnapshot.docs.length

          if (querySnapshot.empty) {
            console.log('No matching documents.')

            setRevenueReferringProvider([])
            return undefined
          }

          querySnapshot.forEach(async (voucherDoc) => {
            const { network, service } = voucherDoc.data()
            countLoop++
            vouchersDataByPlan.push({
              service,
              network,
            })
            countLoop === totalDocs && setRevenueByPlan(vouchersDataByPlan)
          })
        })
    } catch (error) {
      console.log('error fetching vouchers & emrOrders Data')
      console.log(error)
      return false
    }
  }, [organizationId])

  const fetchRevenueByService = useCallback(async () => {
    try {
      const voucherQuery = query(
        collectionModular('vouchers'),
        or(
          and(
            where('bundle', '==', true),
            where('clinicIDs', 'array-contains', organizationId),
            where('status', '==', 'redeemed'),
          ),
          and(where('organization.id', '==', organizationId), where('status', '==', 'redeemed')),
        ),
      )
      const querySnapshotDoc = await getDocs(voucherQuery)
      const vouchersDataByService = []
      let countLoop = 0
      let totalDocs = querySnapshotDoc.docs.length
      querySnapshotDoc.forEach(async (doc) => {
        const voucher = doc.data()
        countLoop++
        vouchersDataByService.push({
          voucher,
        })
        if (voucher.bundle) {
          const subVoucherQuery = query(
            subCollectionModular({
              docName: 'vouchers',
              docId: doc.id,
              subDocName: 'subVouchers',
            }),
            where('organization.id', '==', organizationId),
            where('status', '==', 'redeemed'),
          )
          const querySnapshotSubVoucherDoc = await getDocs(subVoucherQuery)
          totalDocs = totalDocs + querySnapshotSubVoucherDoc.docs.length
          querySnapshotSubVoucherDoc.forEach((subVoucherDoc) => {
            const subVoucher = subVoucherDoc.data()
            countLoop++
            vouchersDataByService.push({
              voucher: subVoucher,
            })
            countLoop === totalDocs && setRevenueByService(vouchersDataByService)
          })
        }
      })
    } catch (error) {
      console.log('error fetching vouchers by service Data')
      console.log(error)
      return false
    }
  }, [organizationId])

  return {
    fetchRevenueByReferringProvider,
    fetchRevenueByProvider,
    fetchRevenueByPlan,
    fetchRevenueByService,
    revenueReferringProvider,
    revenueProvider,
    revenueByPlan,
    revenueByService,
  }
}

export default useReports
