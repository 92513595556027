// src/utils/voucherUtils.js

import { collection, collectionModular } from '@app/firebase/firestore'
import { getDocs, query, and, where } from 'firebase/firestore'

export const getSubVouchers = async (voucher, isProvider, organizationId) => {
  try {
    let ownerId = voucher.user.id
    let key = 'user.id'
    if (isProvider) {
      ownerId = organizationId
      key = 'organization.id'
    }
    const subVouchers = await collection('vouchers')
      .doc(voucher.id)
      .collection('subVouchers')
      .where(key, '==', ownerId)
      // .where('status', '==', 'redeemed')
      .get()

    console.log('subVouchers', subVouchers)

    if (!subVouchers.empty) {
      console.log('subVouchers.docs', subVouchers.docs)
      return subVouchers.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
        parentUid: voucher.id,
      }))
    }

    console.log('subVouchers empty')

    return [] // Return an empty array if no vouchers found
  } catch (error) {
    console.error('Error getting sub vouchers', error)
    return [] // Return an empty array in case of error
  }
}

export const getVoucher = async (id) => {
  try {
    const voucher = await collection('vouchers').doc(id).get()

    if (!voucher.empty) {
      return voucher
    }

    return [] // Return an empty array if no vouchers found
  } catch (error) {
    console.error('Error getting sub vouchers', error)
    return [] // Return an empty array in case of error
  }
}

export const getExternalClaim = async (document_num, userId) => {
  try {
    const externalClaim = collectionModular('external-claims')

    const externalClaimQuery = query(
      externalClaim,
      and(where('DOCUMENT_NUM', '==', document_num), where('patient_info.user_id', '==', userId)),
    )

    const externalClaimSnapshot = await getDocs(externalClaimQuery)

    if (!externalClaimSnapshot.empty) {
      const test = externalClaimSnapshot.docs
        .map((doc) => ({
          ...doc.data(),
          id: doc.id,
          external: true,
        }))
        .sort((a, b) => parseInt(a.BILL_ENTRY_NUM) - parseInt(b.BILL_ENTRY_NUM))

      console.log('test', test)

      return test
    }

    return [] // Return an empty array if no vouchers found
  } catch (error) {
    console.error('Error getting external claim', error)
    return [] // Return an empty array in case of error
  }
}

export const getSubVoucher = async (id, subId) => {
  try {
    const subVoucher = await collection('vouchers')
      .doc(id)
      .collection('subVouchers')
      .doc(subId)
      .get()

    if (!subVoucher.empty) {
      return subVoucher
    }

    return [] // Return an empty array if no vouchers found
  } catch (error) {
    console.error('Error getting sub vouchers', error)
    return [] // Return an empty array in case of error
  }
}
