import Proptypes from 'prop-types'
import Box from '@mui/material/Box'
import BottomBar from '@app/stories/BottomBar'
import NavLink from '@app/stories/NavLink'
import Typography from '@app/stories/Typography'
import LogoTextWhite from '@app/assets/images/LogoTextWhite.svg'
import LogoText from '@app/assets/images/LogoText.svg'

import { useWhiteLabelingContext } from '@app/context/WhiteLabelingContext'
import { getBetterContrastColor } from '@app/utils/hexcodeUtils'

const Footer = () => {
  const { planColorPrimary } = useWhiteLabelingContext()

  const planColorPrimaryContrast = getBetterContrastColor(planColorPrimary)

  const currentYear = () => {
    return new Date().getFullYear()
  }

  return (
    <BottomBar
      style={{
        backgroundColor: planColorPrimary,
      }}
    >
      <Box
        width='100%'
        sx={{
          display: {
            xs: 'none',
            md: 'flex',
          },
        }}
        justifyContent='flex-end'
      >
        <Box display='flex' alignItems='center' justifyContent='flex-start' width='33%'>
          <Typography variant='body2' style={{ whiteSpace: 'nowrap' }}>
            &#169; Copyright {currentYear()} Mishe, Inc | All Rights Reserved
          </Typography>
        </Box>
        <Box display='flex' alignItems='center' justifyContent='center' width='34%'>
          <Box
            color={planColorPrimaryContrast}
            display='flex'
            alignItems='center'
            gap='8px'
            sx={{
              padding: {
                xs: '8px 8px 0px 8px',
                md: '8px',
              },
            }}
          >
            <Typography
              variant='body1'
              style={{
                fontSize: '15px',
                whiteSpace: 'nowrap',
              }}
            >
              Powered by
            </Typography>
            <img
              src={planColorPrimaryContrast === '#ffffff' ? LogoTextWhite : LogoText}
              alt='Mishe Logo'
              style={{ height: '25px', marginBottom: '2px' }}
            />
          </Box>
        </Box>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='flex-end'
          width='33%'
          paddingX='45px'
        >
          <Box display='flex' alignItems='center' justifyContent='flex-end'>
            <NavLink to='/privacy-policy' style={{ whiteSpace: 'nowrap' }}>
              privacy policy
            </NavLink>
            <NavLink to='/terms' style={{ whiteSpace: 'nowrap' }}>
              terms of use
            </NavLink>
          </Box>
        </Box>
      </Box>
      {/* Mobile View */}
      <Box
        alignItems='center'
        justifyContent='center'
        width='100%'
        flexDirection='column'
        sx={{
          display: {
            xs: 'flex',
            md: 'none',
          },
        }}
      >
        <Box
          color={planColorPrimaryContrast}
          display='flex'
          alignItems='center'
          gap='8px'
          sx={{
            padding: {
              xs: '8px 8px 0px 8px',
              md: '8px',
            },
          }}
        >
          <Typography
            variant='body1'
            style={{
              fontSize: '15px',
              whiteSpace: 'nowrap',
            }}
          >
            Powered by
          </Typography>
          <img
            src={planColorPrimaryContrast === '#ffffff' ? LogoTextWhite : LogoText}
            alt='Mishe Logo'
            style={{ height: '25px', marginBottom: '2px' }}
          />
        </Box>
        <Box margin='auto' mt={1} mb={2}>
          <NavLink to='/privacy-policy'>privacy policy</NavLink>
          <NavLink to='/terms'>terms of use</NavLink>
        </Box>
        <Box display='flex' alignItems='center' justifyContent='center' width='100%'>
          <Typography variant='body2' style={{ whiteSpace: 'nowrap', fontSize: '8px' }}>
            &#169; Copyright {currentYear()} Mishe, Inc | All Rights Reserved
          </Typography>
        </Box>
      </Box>
    </BottomBar>
  )
}

Footer.propTypes = {
  network: Proptypes.string, // Add PropType for network
}

export default Footer
