import PropTypes from 'prop-types'
import OrganizationServicesCard from '@app/components/OrganizationServicesCard'
import { distanceToMiles } from '@app/libs/geo'

const OrganizationServicesCardInput = ({
  service = {
    network: {
      slug: '',
    },
    misheCode: '',
    objectID: '',
    name: '',
    organization: {
      name: '',
      slug: '',
      image: '',
      review: {
        count: 0,
        link: '',
      },
    },
    price: 0,
    virtual: false,
    summary: '',
    description: '',
    review: {
      count: 0,
      link: '',
    },
  },
  distance = null,
  organizationReview = {
    count: 0,
    link: '',
  },
  organizationImage = '',
  exactMatch,
}) => {
  const zipcode = localStorage.getItem('zipcode') ? localStorage.getItem('zipcode') : ''

  // console.log(service)

  return (
    <OrganizationServicesCard
      service={service?.name}
      slug={service?.organization?.slug}
      price={service?.price}
      distance={distance && distanceToMiles(service, zipcode)}
      virtual={service?.virtual}
      description={service?.description}
      review={organizationReview}
      photo={organizationImage}
      networkSlug={service?.network?.slug}
      serviceID={service?.objectID}
      misheCode={service?.misheCode}
      exactMatch={exactMatch}
      orderRequired={service?.orderRequired}
    />
  )
}

OrganizationServicesCardInput.propTypes = {
  distance: PropTypes.bool,
  service: PropTypes.shape({
    network: PropTypes.shape({
      slug: PropTypes.string,
    }),
    misheCode: PropTypes.string,
    objectID: PropTypes.string,
    name: PropTypes.string,
    organization: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
      image: PropTypes.string,
      review: PropTypes.shape({
        count: PropTypes.number,
        link: PropTypes.string,
      }),
    }),
    price: PropTypes.number,
    virtual: PropTypes.bool,
    summary: PropTypes.string,
    description: PropTypes.string,
    review: PropTypes.shape({
      count: PropTypes.number,
      link: PropTypes.string,
    }),
  }),
  organizationReview: PropTypes.shape({
    count: PropTypes.number,
    link: PropTypes.string,
  }),
  organizationImage: PropTypes.string,
}

export default OrganizationServicesCardInput
