import { useEffect } from 'react'
import Container from '@app/stories/Container'
import Section from '@app/stories/Section'
import Heading from '@app/stories/Heading'
import {
  TermsBlock as Block,
  TermsText as Text,
  TermsTitle as Title,
  TermsLink as Link,
  TermsList as List,
  TermsListItem as Item,
} from '@app/stories/Terms'
import Bold from '@app/stories/Bold'
import CapsLock from '@app/stories/CapsLock'

const HIPAAPage = () => {
  useEffect(() => {
    window.Intercom('trackEvent', 'Page View', {
      page_name: 'HIPAA Page',
    })
  }, [])

  return (
    <Container>
      <Section>
        <Heading variant='h1'>Mishe, INC - HIPAA Authorization</Heading>
        <Block id='hippa1'>
          <Text>
            Mishe, INC (<Bold>&quot;Mishe&quot;</Bold>) offers services, such as assisting in your
            search for healthcare providers, arranging appointments and managing and forwarding your
            health history forms and other health-related information to share with your healthcare
            providers (<Bold>&quot;Healthcare Providers&quot;</Bold>) or with yourself, via an
            online portal. Mishe may also provide other administrative services as applicable.
          </Text>
          <Text>
            As part of providing such services, Mishe may collect, use, share, and exchange your
            health history forms and other health-related information with the Healthcare Providers.
            Under a federal law called the Health Insurance Portability and Accountability Act (
            <Bold>&quot;HIPAA&quot;</Bold>), some of this health and health-related information may
            be considered &quot;protected health information&quot; or <Bold>&quot;PHI&quot;</Bold>{' '}
            if such information is received from or on behalf of such Healthcare Providers.
          </Text>
        </Block>

        <Block id='hippa2'>
          <Title>Safeguards for PHI</Title>
          <Text>
            HIPAA protects the privacy and security of your PHI by limiting the uses and disclosures
            of PHI by most healthcare providers and by health plans, which includes the Healthcare
            Providers (called
            <Bold>&quot;Covered Entities&quot;</Bold>) as well as companies, like Mishe (called{' '}
            <Bold>&quot;Business Associates&quot;</Bold>) that provide certain types of assistance
            to Covered Entities. Under certain circumstances described in HIPAA, an individual needs
            to sign an Authorization form before a Covered Entity, like the Healthcare Providers,
            can disclose protected health information to a third party.
          </Text>
        </Block>

        <Block id='hippa3'>
          <Title>Non-Protected Health Information</Title>
          <Text>
            As a condition of utilizing the Program, you are required to read and agree to
            Mishe&apos;s{' '}
            <Link type='internal' to='eula'>
              End User License Agreement
            </Link>{' '}
            and{' '}
            <Link type='internal' to='privacy-policy'>
              Privacy Policy
            </Link>
            . Mishe&apos;s{' '}
            <Link type='internal' to='privacy-policy'>
              Privacy Policy
            </Link>{' '}
            explains how Mishe processes and shares information received from you that is not
            covered by HIPAA (<Bold>&quot;Non-PHI&quot;</Bold>).
          </Text>
        </Block>

        <Block id='hippa4'>
          <Title>Your PHI Authorization</Title>
          <Text>
            The purpose of this authorization (<Bold>&quot;Authorization&quot;</Bold>) is to request
            your written permission and authorization to allow Mishe to use and disclose your PHI in
            the same way as we use and disclose your Non-PHI. If Mishe is a Business Associate of
            the Healthcare Providers, Mishe needs your Authorization to be able to use and disclose
            your PHI in the same way it can currently use and disclose your Non-PHI when Mishe is
            not working on behalf of the Healthcare Providers, but is instead working on its own
            behalf. Therefore, when Mishe relies on this Authorization, and uses and discloses PHI
            as described in this Authorization, it is not working as a Business Associate and the
            HIPAA requirements that apply to Business Associates will not apply to such uses and
            disclosures.
          </Text>
          <Text>
            If you e-sign this Authorization, you give your permission to Mishe to retain your PHI
            and to use and/or disclose your PHI in the same way that you have agreed that your
            Non-PHI can be used and disclosed.
          </Text>
          <Text>Specifically, you agree that Mishe can use your PHI to:</Text>
          <List>
            <Item>enable and customize your use of the Program and Mishe&apos;s services;</Item>
            <Item>provide you alerts or other Mishe services regarding future appointments;</Item>
            <Item>
              notify you regarding providers we think you may be interested in learning more about;
            </Item>
            <Item>
              share information with you regarding services, products or resources about which we
              think you may be interested in learning more;
            </Item>
            <Item>
              provide you with updates and information about Mishe, the Program and its services;
            </Item>
            <Item>market to you about Mishe and third party products and services;</Item>
            <Item>conduct analysis for Mishe&apos;s business purposes;</Item>
            <Item>support development of Mishe, the Program and its services; and</Item>
            <Item>
              create de-identified information and then use and disclose this information in any way
              permitted by law, including to third parties in connection with their commercial and
              marketing efforts.
            </Item>
          </List>
          <Text>You also agree that Mishe can disclose your PHI to:</Text>
          <List>
            <Item>third parties assisting Mishe with any of the uses described above;</Item>
            <Item>
              the Healthcare Providers to enable them to refer you to, and make appointments with,
              other providers on your behalf, or to perform an analysis on potential health issues
              or treatments, provided that you choose to use the applicable Mishe services and/or
              the Program;
            </Item>
            <Item>
              our business partners who assist us by performing core services (such as hosting,
              billing, fulfillment, or data storage and security) related to the operation or
              provision of our services, even when Mishe is no longer working on behalf of the
              Healthcare Providers;
            </Item>
            <Item>a provider of medical services, in the event of an emergency; and</Item>
            <Item>
              organizations that collect, aggregate and organize your information so they can make
              it more easily accessible to your Healthcare Providers.
            </Item>
          </List>
        </Block>

        <Block id='hippa5'>
          <Title>Redisclosure</Title>
          <Text>
            If Mishe discloses your PHI, Mishe will require that the person or entity receiving your
            PHI agrees to only use and disclose your PHI to carry out its specific business
            obligations to Mishe or for the permitted purpose of the disclosure (as described
            above). Mishe cannot, however, guarantee that any such person or entity to which Mishe
            discloses your PHI or other information will not re-disclose it in ways that you or we
            did not intend or permit.
          </Text>
        </Block>

        <Block id='hippa6'>
          <Title>Expiration and Revocation of Authorization</Title>
          <Text>
            <CapsLock>
              YOU CAN CHANGE YOUR MIND AND REVOKE THIS AUTHORIZATION AT ANY TIME AND FOR ANY (OR NO)
              REASON.
            </CapsLock>
          </Text>
          <Text>
            Your Authorization remains in effect until you provide written notice of revocation to
            Mishe as set forth below. If you wish to revoke this Authorization, you must notify
            Mishe by submitting a revocation through the online portal. This Authorization will
            expire upon receipt of your revocation notice.
          </Text>
          <Text>
            Your decision not to execute this Authorization or to revoke it at any time will not
            affect your ability to use certain of the Mishe services and/or the Program.
          </Text>
          <Text>
            A Revocation of Authorization is effective after you submit it to Mishe, but it does not
            have any effect on Mishe&apos;s prior actions taken in reliance on the Authorization
            before revoked.
          </Text>
          <Text>
            Once Mishe receives your Revocation of Authorization, Mishe can only use and disclose
            your PHI as permitted in Mishe&apos;s agreements with the Healthcare Provider(s). Your
            Revocation of Authorization does not affect Mishe&apos;s use of your Non-PHI.
          </Text>
          <Text>
            We will make available to the Healthcare Providers, current and past, your agreement to
            or revocation of this Authorization.
          </Text>
        </Block>
      </Section>
    </Container>
  )
}

export default HIPAAPage
