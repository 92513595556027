import { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import TextField from '@app/stories/TextField'
import Button from '@app/stories/Button'
import Icon from '@app/stories/Icons'

const ConfirmPasswordForm = ({ onSubmit, onChange, setConfirmNewPassword, errors = {} }) => {
  const [newPasswordVisible, setNewPasswordVisible] = useState(false)
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)

  return (
    <form title='sign-in-form' onSubmit={onSubmit}>
      <Box position='relative'>
        <TextField
          required
          name='password'
          type={newPasswordVisible ? 'text' : 'password'}
          label='New password'
          placeholder='New password'
          inputProps={{
            title: 'New password',
          }}
          error={!!errors.password}
          helperText={errors.password}
          onChange={onChange}
        />
        <Box position='absolute' right={0} top={0} bottom={0} mt={2} mb={2}>
          <Button variant='icon' onClick={() => setNewPasswordVisible(!newPasswordVisible)}>
            <Icon color='primary' name={newPasswordVisible ? 'visibility' : 'visibilityOff'} />
          </Button>
        </Box>
      </Box>
      <Box position='relative'>
        <TextField
          required
          name='confirmPassword'
          type={confirmPasswordVisible ? 'text' : 'password'}
          label='Confirm password'
          placeholder='Confirm password'
          inputProps={{
            title: 'Confirm password',
          }}
          error={!!errors.password}
          helperText={errors.password}
          onChange={(e) => setConfirmNewPassword(e.target.value)}
        />
        <Box position='absolute' right={0} top={0} bottom={0} mt={2} mb={2}>
          <Button variant='icon' onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}>
            <Icon color='primary' name={confirmPasswordVisible ? 'visibility' : 'visibilityOff'} />
          </Button>
        </Box>
      </Box>
      <Box mt={2}>
        <Button type='submit' fullWidth>
          save password
        </Button>
      </Box>
    </form>
  )
}

ConfirmPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    password: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
}

export default ConfirmPasswordForm
