import { formatBenefit, formatDollarAmount, planDentalDesignFormatter } from '@app/utils/planUtil'

export const benefits = (planInfo) => {
  const plan = planInfo.planDesign
  return [
    {
      label: 'Deductible',
      id: 'deductible',
      value: `${formatDollarAmount(plan?.deductible?.individual)} / ${formatDollarAmount(plan?.deductible?.family)}`,
      description:
        'The amount you pay for covered healthcare services before your insurance plan starts to pay. An amount you could owe during a coverage period (usually one year) for covered health care services before your plan begins to pay. An overall deductible applies to all or almost all covered items and services. A plan with an overall deductible may also have separate deductibles that apply to specific services or groups of services. A plan may also have only separate deductibles. For example, if your deductible is $1000, your plan won’t pay anything until you’ve met your $1000 deductible for covered health care services subject to the deductible.',
    },
    {
      label: 'Coinsurance',
      id: 'coinsurance',
      value: `${plan?.coinsurance?.amount || '---'}%`,
      description:
        'Your share of the costs of a covered healthcare service, calculated as a percentage (for example, 20%) of the allowed amount for the service. You pay coinsurance plus any deductibles you owe. For instance, if the health insurance or plan’s allowed amount for an office visit is $100 and you’ve met your deductible, your 20% coinsurance payment would be $20. The health insurance or plan pays the rest.',
    },
    {
      label: 'Out-of-Pocket Max',
      id: 'outOfPocketMax',
      value: `${formatDollarAmount(plan?.outOfPocketMax?.individual)} / ${formatDollarAmount(plan?.outOfPocketMax?.family)}`,
      description:
        'The most you have to pay for covered services in a plan year. After you spend this amount on deductibles, copayments, and coinsurance for in-network care and services, your health plan pays 100% of the costs of covered benefits.',
    },
    {
      label: 'Preventive Care',
      id: 'preventativeCare',
      value:
        `${plan?.preventativeCare?.amount ? `${plan?.preventativeCare?.amount}% ` : ''} ${plan?.preventativeCare?.suffix || ''} ${plan?.preventativeCare?.message || ''}`.trim(),
      description:
        'Routine health care that includes screenings, check-ups, and patient counseling to prevent illnesses, disease, or other health problems.',
    },
    {
      label: 'Virtual Visit',
      id: 'virtualVisit',
      value: `${formatBenefit(plan?.virtualVisit)}`,
      description:
        'A consultation with a healthcare provider conducted remotely via telecommunications technology, such as video conferencing or phone calls, allowing patients to receive medical advice without an in-person visit.',
    },
    {
      label: 'Specialist Visit',
      id: 'officeOrSpecialistVisit',
      value: `${formatBenefit(plan?.officeOrSpecialistVisit)}`,
      description:
        'A consultation with a physician specialist who focuses on a specific area of medicine or a group of patients to diagnose, manage, prevent, or treat certain types of symptoms and conditions.',
    },
    {
      label: 'Urgent Care',
      id: 'urgentCare',
      value: `${formatBenefit(plan?.urgentCare)}`,
      description:
        'Care for an illness, injury, or condition serious enough that a reasonable person would seek care right away, but not so severe it requires emergency room care.',
    },
    {
      label: 'Emergency Room',
      id: 'emergencyRoom',
      value: `${formatBenefit(plan?.emergencyRoom)}`,
      description:
        'Services received in an emergency room for conditions that require immediate medical attention to prevent serious jeopardy to health, serious impairment to bodily functions, or serious dysfunction of any bodily organ or part.',
    },
    {
      label: 'Inpatient Hospital Services',
      id: 'inpatientHospitalServices',
      value: `${formatBenefit(plan?.inpatientHospitalServices)}`,
      description:
        'Healthcare services that require admission to a hospital and usually require an overnight stay. An overnight stay for observation could be outpatient care.',
    },
    {
      label: 'Outpatient Surgeries',
      id: 'outpatientSurgeriesOrTherapies',
      value: `${formatBenefit(plan?.outpatientSurgeriesOrTherapies)}`,
      description:
        'Surgical procedures that do not require an overnight hospital stay. Patients receive the surgery and are discharged on the same day.',
    },
  ]
}

export const dentalBenefits = (planInfo) => {
  const plan = planDentalDesignFormatter(planInfo)

  return [
    {
      label: 'Annual Max',
      id: 'annualMax',
      value: `${formatDollarAmount(plan.annualMax)}`,
      description: 'Calendar Year',
    },
    {
      label: 'Deductible',
      id: 'deductible',
      value: `${formatDollarAmount(plan.dedIndv)} / ${formatDollarAmount(plan.dedFam)}`,
      description: 'Individual/Family',
    },
    {
      label: 'Preventative Services',
      id: 'prevServices',
      value: `${plan.preveInsur}%`,
      description: 'Two cleanings per calendar year',
    },
    {
      label: 'Basics Services',
      id: 'basicServices',
      value: `${plan.basicInsr}%`,
      description: 'filings, perio & endo',
    },

    {
      label: 'Major Services',
      id: 'majorServices',
      value: `${plan.majorInsr}%`,
      description: 'crowns, dentures',
    },
    {
      label: 'Orthodontia Services',
      id: 'orthoServices',
      value: `${plan.orthoServ}%`,
      description: 'adults & children.',
    },
    {
      label: 'Orthodontia Lifetime Max',
      id: 'orthoLifeMax',
      value: `${formatDollarAmount(plan.lifeOrthoMax)}`,
      description: null,
    },
    {
      label: 'Out-of-Network Benefits',
      id: 'oonBenefits',
      value: `${plan?.oonBenefits}`,
      description: null,
    },
  ]
}
