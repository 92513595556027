import Box from '@mui/material/Box'
import MuiTypography from '@mui/material/Typography'
import Fade from '@mui/material/Fade'
import Button from '@app/stories/Button'
import misheLogo from '@app/assets/images/misheLogo.svg'
import { scrollToHash } from '@app/libs/scroll'

const Hero = () => {
  return (
    <Box
      bgcolor='#f4f0f7'
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: {
          xs: 'wrap',
          md: 'nowrap',
        },
        alignItems: 'center',
        justifyContent: {
          xs: 'center',
          md: 'flex-start',
        },
        minHeight: {
          xs: '600px',
          md: '650px',
        },
        position: 'relative',
        padding: {
          xs: 2,
          md: 10,
        },
      }}
    >
      <Box
        bgcolor='#f4f0f7'
        position='absolute'
        left='0'
        bottom='100%'
        width='100%'
        height='100%'
        zIndex={0}
      />
      <Box
        sx={{
          width: {
            xs: '100%',
            md: '70%',
          },
          height: '100%',
        }}
      >
        <Fade in={true} timeout={2500}>
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            position='relative'
            zIndex={1}
            sx={{
              height: '100%',
              color: '#652d92',
              alignItems: {
                xs: 'center',
                md: 'flex-start',
              },
            }}
          >
            <Box marginBottom='20px'>
              <MuiTypography
                color='#652d92'
                sx={{
                  textAlign: {
                    xs: 'center',
                    lg: 'left',
                  },
                  fontSize: {
                    xs: '2.2rem',
                    sm: '3rem',
                    md: '4rem',
                  },
                  lineHeight: {
                    xs: '1.25',
                  },
                  fontFamily: 'Poppins',
                  fontWeight: 700,
                }}
              >
                Integrated, real-time claim adjudication infrastructure for self-funded employers.
              </MuiTypography>
            </Box>
            <MuiTypography
              margin='0'
              color='#652d92'
              sx={{
                textAlign: {
                  xs: 'center',
                  lg: 'left',
                },
                fontSize: {
                  xs: '1.35rem',
                  sm: '1.5rem',
                  md: '1.75rem',
                },
                lineHeight: {
                  xs: '1.2',
                },
                marginBottom: {
                  xs: 1,
                  md: 1,
                },
                fontFamily: 'Poppins',
                fontWeight: 300,
              }}
            >
              The end-to-end payer platform for health plans—directly connecting employers and their
              members with providers and health systems.
            </MuiTypography>
            <Box
              display='flex'
              justifyContent='center'
              sx={{
                flexDirection: {
                  xs: 'column',
                  md: 'row',
                },
                gap: {
                  xs: 0,
                  md: 2,
                },
              }}
            >
              <Button
                variant='contained'
                size='large'
                sx={{
                  backgroundColor: '#652d92',
                  color: '#fff',
                  padding: '10px 40px',
                  marginTop: '20px',
                  borderRadius: '50px',
                  fontFamily: 'Poppins',
                  fontWeight: 700,
                }}
                onClick={() => scrollToHash('#getInTouch')}
              >
                Get in touch
              </Button>
              {/* Will add back when misheHealthPlan is no longer gated */}
              {/* <Button
                variant='contained'
                size='large'
                sx={{
                  backgroundColor: '#652d92',
                  color: '#fff',
                  padding: '10px 40px',
                  marginTop: '20px',
                  borderRadius: '50px',
                  fontFamily: 'Poppins',
                  fontWeight: 700,
                }}
                href='/plans/mishehealthplan'
              >
                Find a treatment now
              </Button> */}
            </Box>
          </Box>
        </Fade>
      </Box>
      <Box
        position='absolute'
        left='50%'
        padding='20px'
        display='flex'
        flexDirection='row'
        justifyContent='center'
        height='100%'
        zIndex={0}
        sx={{
          left: {
            xs: '8px',
            lg: '50%',
          },
        }}
      >
        <img
          src={misheLogo}
          alt='Mishe Logo'
          style={{ width: '100%', height: '100%', opacity: 0.1 }}
        />
      </Box>
    </Box>
  )
}

export default Hero
