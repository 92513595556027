import PropTypes from 'prop-types'
import styled from 'styled-components'
import Box from '@mui/material/Box'
import ToggleButton from '@mui/material/ToggleButton'
import Typography from '@app/stories/Typography'

const AmPmButton = styled(ToggleButton)(() => ({
  width: '50px',
  height: '40px',
  borderRadius: '10%',
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '16px',
  color: '#000',
  '&.Mui-selected, &.Mui-selected:hover': {
    color: '#fff',
    backgroundColor: '#662d91',
  },
}))

const Calendar = ({ setDates, dates }) => {
  const handleToggle = (value) => () => {
    const currentIndex = dates.indexOf(value)
    const newSelected = [...dates]

    if (currentIndex === -1) {
      newSelected.push(value)
    } else {
      newSelected.splice(currentIndex, 1)
    }

    setDates(newSelected)
  }

  const addDays = (days, currentDay) => {
    const dat = new Date(currentDay)
    dat.setDate(dat.getDate() + days)
    return dat
  }

  const getDates = (startDate, stopDate) => {
    const dateArray = []
    let currentDate = startDate
    while (currentDate <= stopDate) {
      let month = currentDate.getMonth()
      let day = currentDate.getDate()
      if (month !== 12) {
        month =
          (currentDate.getMonth() + 1).toString().length === 1
            ? `0${currentDate.getMonth() + 1}`
            : currentDate.getMonth() + 1
      }
      day = day.toString().length === 1 ? `0${day}` : day
      dateArray.push(`${month}/${day}`)
      currentDate = addDays(1, currentDate)
    }
    return dateArray
  }

  return (
    <Box
      padding={2}
      sx={{
        overflowX: 'scroll',
        overflowY: 'hidden',
        whiteSpace: 'nowrap',
        webkitOverflowScrolling: 'touch',
      }}
    >
      {getDates(new Date(), addDays(30, new Date())).map((child) => (
        <Box key={child} sx={{ display: 'inline-block', marginRight: '1rem' }}>
          <Typography mb={2} component='div' variant='body2' color='primary'>
            {child}
          </Typography>
          <Box mb={2}>
            <AmPmButton
              value={`${child}/AM`}
              selected={dates.indexOf(`${child}/AM`) !== -1}
              onClick={handleToggle(`${child}/AM`)}
            >
              AM
            </AmPmButton>
          </Box>
          <Box mt={0}>
            <AmPmButton
              value={`${child}/PM`}
              selected={dates.indexOf(`${child}/PM`) !== -1}
              onClick={handleToggle(`${child}/PM`)}
            >
              PM
            </AmPmButton>
          </Box>
        </Box>
      ))}
    </Box>
  )
}

Calendar.propTypes = {
  setDates: PropTypes.func.isRequired,
  dates: PropTypes.array.isRequired,
}

export default Calendar
