import { cloneElement } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import useToggleOpen from '@app/hooks/useToggleOpen'
import Modal from '@app/stories/Modal'
import Heading from '@app/stories/Heading'
import Divider from '@app/stories/Divider'
import NavLink from '@app/stories/NavLink'
import Button from '@app/stories/Button'
import Typography from '@app/stories/Typography'

import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

const GeneralModal = ({
  buttonText = '...',
  headerText = 'More Info',
  customButton = null,
  footerText = '',
  customFooter = null,
  customClose = null,
  allowClickAway = false,
  maxWidth,
  children,
  onOpen,
}) => {
  const [anchorel, handleClick, handleClose] = useToggleOpen(allowClickAway)
  const open = Boolean(anchorel)

  const handleOpen = (event) => {
    handleClick(event)
    if (onOpen) {
      onOpen()
    }
  }

  const addOnClickToCustomButton = (btn) => {
    return cloneElement(btn, {
      onClick: handleOpen, // Change this line
    })
  }

  return (
    <>
      {customButton ? (
        addOnClickToCustomButton(customButton)
      ) : (
        <NavLink type='button' onClick={handleOpen} style={{ textDecoration: 'underline' }}>
          {buttonText}
        </NavLink>
      )}
      <Modal maxWidth={maxWidth || 'sm'} open={open} anchorel={anchorel} onClose={handleClose}>
        <Box>
          <Heading variant='h3' align='center'>
            {headerText}
          </Heading>
          <Divider spacing='vertical' />
          {children}
        </Box>
        <Box mt={4}>
          {customFooter || (
            <Typography variant='body2' align='center'>
              {footerText}
            </Typography>
          )}
        </Box>
        {customClose ? (
          customClose(handleClose)
        ) : (
          <Box mt={4}>
            <Button size='medium' variant='contained' onClick={handleClose}>
              <Typography variant='body2' style={{ marginLeft: '8px', marginRight: '8px' }}>
                close
              </Typography>
            </Button>
          </Box>
        )}
      </Modal>
    </>
  )
}

GeneralModal.propTypes = {
  buttonText: PropTypes.string,
  headerText: PropTypes.string,
  customButton: PropTypes.node,
  customFooter: PropTypes.node,
  footerText: PropTypes.string,
  children: PropTypes.node,
  onOpen: PropTypes.func, // Add this line
}

export const GeneralModalNew = ({
  open,
  handleClose,
  title,
  content,
  primaryAction,
  secondaryAction,
}) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <Typography variant='body1' style={{ whiteSpace: 'pre-line' }} paragraph>
          {content}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant='outlined'
          onClick={secondaryAction.action}
          color='primary'
          sx={{
            width: '50%',
          }}
        >
          {secondaryAction.label}
        </Button>
        <Button
          onClick={primaryAction.action}
          color='primary'
          autoFocus
          sx={{
            width: '50%',
          }}
        >
          {primaryAction.label}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

GeneralModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.string,
  primaryAction: PropTypes.shape({
    label: PropTypes.string,
    action: PropTypes.func,
  }),
  secondaryAction: PropTypes.shape({
    label: PropTypes.string,
    action: PropTypes.func,
  }),
}

export default GeneralModal
