import { useState } from 'react'
import Box from '@mui/material/Box'
import InstantSearchContainerCustom from '@app/containers/InstantSearchContainerCustom'
import ServiceSearchCardNetwork from '@app/components/ServiceSearchCardNetwork'
import { useCurrentUser } from '@app/context/CurrentUser'

const BuildCard = ({ key, data, onClick, variant, exactMatch }) => {
  return (
    <ServiceSearchCardNetwork
      key={key}
      data={data}
      onClick={onClick}
      variant={variant}
      exactMatch={exactMatch}
      hideButton={true}
    />
  )
}

const FeeSchedule = () => {
  const { managedPlans } = useCurrentUser()
  const [searchLimit, setSearchLimit] = useState(10)

  const SearchComponent = () => {
    const configuration = {
      hitsPerPage: searchLimit,
      filters: `networkId:${managedPlans?.managedPlanID}`,
    }

    return (
      <InstantSearchContainerCustom
        HitComponent={BuildCard}
        indexName='network_services'
        showInitialHits
        configuration={configuration}
        searchBoxText='Search for symptom, service, or provider'
        noResultsMessage='No results'
        noResultsColor='#000'
        customZIndex={5}
        networkLanderSearch
        setSearchLimit={setSearchLimit}
        searchLimit={searchLimit}
        usePagination
        overflowShown
        sortByItems={[
          {
            label: 'Most Relevant',
            value: 'network_services',
          },
          {
            label: 'Price: Low to High',
            value: 'network_services_price_asc',
          },
          {
            label: 'Price: High to Low',
            value: 'network_services_price_desc',
          },
        ]}
      />
    )
  }

  return (
    <Box>
      <SearchComponent />
    </Box>
  )
}

export default FeeSchedule
