import {
  Typography,
  Divider,
  List,
  ListItem,
  Tooltip,
  IconButton,
  Box,
  CircularProgress,
} from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { benefits, dentalBenefits } from '@app/libs/planConstants'
import { useEffect, useMemo, useState } from 'react'

const SubTooltip = ({ subtext }) => {
  return (
    <Tooltip title={subtext} arrow>
      <IconButton size='small'>
        <InfoOutlinedIcon fontSize='small' />
      </IconButton>
    </Tooltip>
  )
}

const PatientPlanCopayInfo = ({ copayInfo }) => {
  const planType = useMemo(() => {
    if (!copayInfo) return false
    return copayInfo.planData.planDesign?.designType?.typeOfPlan || 'medical'
  }, [copayInfo])

  const benefitsFormatter = useMemo(() => {
    return planType === 'dental' ? dentalBenefits : benefits
  }, [planType])

  if (!benefitsFormatter || !copayInfo) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
        <CircularProgress />
      </Box>
    )
  }
  return (
    <>
      <Typography variant='h5' gutterBottom align='center'>
        Health Plan Copay Information
      </Typography>
      <Divider sx={{ mb: 2 }} />

      <List>
        {benefitsFormatter(copayInfo.planData).map((benefit) => (
          <ListItem key={benefit.label} disableGutters>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ fontWeight: 'bold' }}>{benefit.label}</Typography>
                {benefit.description && <SubTooltip subtext={benefit.description} />}
              </Box>
              <Typography sx={{ textAlign: 'right' }}>{benefit.value || '--'}</Typography>
            </Box>
            <Divider sx={{ my: 1 }} />
          </ListItem>
        ))}
      </List>
    </>
  )
}

export default PatientPlanCopayInfo
