import { useEffect } from 'react'
import Container from '@app/stories/Container'
import Section from '@app/stories/Section'
import PrivacyPolicyContent from './PrivacyPolicyContent'

const PrivacyPolicyPage = () => {
  useEffect(() => {
    window.Intercom('trackEvent', 'Page View', {
      page_name: 'Privacy Policy Lander',
    })
  }, [])

  return (
    <>
      <Container>
        <Section>
          <PrivacyPolicyContent />
        </Section>
      </Container>
    </>
  )
}

export default PrivacyPolicyPage
