import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'
import Heading from '@app/stories/Heading'
import AcceptTermsNotification from '@app/containers/AcceptTermsNotification'
import { useCurrentUser } from '@app/context/CurrentUser'
import Loader from '@app/stories/Loader'
import DashboardProviders from './DashboardProviders'
import { setTimezoneFromCoordinates } from '@app/firebase/functions'
import { collection } from '@app/firebase/firestore'

const DashboardPage = ({ children }) => {
  const { profileData, managedPlans, authData } = useCurrentUser()

  const { search } = useLocation()
  const ref = new URLSearchParams(search).get('ref')

  if (ref) {
    localStorage.setItem('refvalue', ref)
  }

  const getOrgData = async (clinicID) => {
    return collection('organizations')
      .doc(clinicID)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          console.error('  ** No such clinic document!')
          return null
        }
        return {
          id: doc.id,
          ...doc.data(),
        }
      })
      .catch((err) => {
        console.error('  ** Error getting clinic data:', err)
        return null
      })
  }

  useEffect(() => {
    window.Intercom('trackEvent', 'Page View', {
      page_name: 'Dashboard',
    })
    authData.organizationId &&
      getOrgData(authData.organizationId).then(async (orgData) => {
        if (!orgData.timezone && orgData.primaryLocationCoords) {
          const { lat, lng } = orgData.primaryLocationCoords
          await setTimezoneFromCoordinates({
            lat,
            lng,
            organizationId: authData.organizationId,
          }).then((timezone) => {
            console.log('timezone updated', timezone)
          })
        }
      })
  }, [authData.organizationId])

  if (!profileData.id) {
    return <Loader />
  }

  return (
    <Box padding='32px'>
      {!authData.acceptedTerms && authData.roles.includes('PROVIDER') && (
        <AcceptTermsNotification />
      )}
      <Box mb={6}>
        {profileData.firstname && (
          <Heading gutterBottom>
            {profileData.firstname?.endsWith('s') ? (
              <Box component='span' color='primary.main' style={{ textTransform: 'capitalize' }}>
                {profileData.firstname}&apos;
              </Box>
            ) : (
              <Box component='span' color='primary.main' style={{ textTransform: 'capitalize' }}>
                {profileData.firstname}&apos;s
              </Box>
            )}{' '}
            <i>my mishe</i> dashboard
          </Heading>
        )}
        {authData.roles.includes('PROVIDER') && (
          <Heading variant='h4' weight='medium' component='p' gutterBottom>
            Clinician at {authData.organizationName}
          </Heading>
        )}
        {authData.roles?.includes('PLAN_ADMIN') && (
          <Heading variant='h4' weight='medium' component='p' gutterBottom>
            Admin of {managedPlans?.planData?.name || 'pending'}
          </Heading>
        )}
      </Box>
      <DashboardProviders>{children}</DashboardProviders>
    </Box>
  )
}

export default DashboardPage
