import { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Highlight, useInstantSearch } from 'react-instantsearch'
import Box from '@mui/material/Box'
import Typography from '@app/stories/Typography'
import Button from '@app/stories/Button'
import Collapse from '@mui/material/Collapse'
import Icon from '@app/stories/Icons'
import Divider from '@app/stories/Divider'

import { useMapEffects } from '@app/context/MapEffects'

import { useWhiteLabelingContext } from '@app/context/WhiteLabelingContext'

import { formatCurrency } from '@app/libs/formatters'

const ServiceSearchCardNetwork = ({
  data = { referralPrice: null, network: null },
  onClick = null,
  preventBooking,
  showServiceSearchResponsibilityBreakdown,
}) => {
  const {
    price,
    network,
    clinicName,
    referralPrice,
    bundle,
    payerPrice,
    orderRequired,
    _rankingInfo,
    clinicSlug,
  } = data
  const { indexUiState } = useInstantSearch()
  const exactMatch = data.cptCode && indexUiState.query?.toString() === data.cptCode?.toString()
  const [open, setOpen] = useState(false)
  const [openPaymentBreakdown, setOpenPaymentBreakdown] = useState(false)
  const [exactMatchScale, setExactMatchScale] = useState(false)
  const timeoutRef = useRef(null)
  const intervalRef = useRef(null)
  const [exactMatchAnimationStep, setExactMatchAnimationStep] = useState(0)
  const exactMatchAnimation = ['scale(1.1) rotate(0.0015turn)', 'scale(1.1) rotate(-0.0015turn)']
  const { handleHover, handleClick } = useMapEffects()

  const { planColorPrimary } = useWhiteLabelingContext()

  useEffect(() => {
    if (!exactMatch) return
    setExactMatchScale(!exactMatchScale)
    intervalRef.current = setInterval(() => {
      setExactMatchAnimationStep((prev) => (prev === 0 ? 1 : 0))
    }, 300)
    timeoutRef.current = setTimeout(() => {
      setExactMatchScale(false)
      clearInterval(intervalRef.current)
    }, 1200)

    return () => clearTimeout(timeoutRef.current)
  }, [])

  const formattedCurrency = formatCurrency(referralPrice || price || payerPrice) || '---'

  return (
    <Box
      onMouseEnter={() => !data?.bundle && handleHover(true, data.clinicID, data)}
      onMouseLeave={() => handleHover(false)}
      width='100%'
      padding='16px'
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      gap='8px'
      sx={{
        flexDirection: {
          xs: 'column',
          md: open ? 'column' : 'row',
        },
        cursor: 'pointer',
        '&:hover': {
          bgcolor: `${planColorPrimary}22`,
        },
      }}
      borderBottom={`1px solid ${planColorPrimary}22`}
    >
      <Box width='100%' color={planColorPrimary}>
        {exactMatch && (
          <Box color={planColorPrimary} width='min-content'>
            <Typography
              variant='body1'
              weight='semibold'
              style={{
                fontSize: '14px',
                marginLeft: '-4px',
                whiteSpace: 'nowrap',
                transition: 'all 0.3s ease-in-out',
                transform: exactMatchScale
                  ? exactMatchAnimation[exactMatchAnimationStep]
                  : 'scale(1)',
              }}
            >
              Procedure Code Match
            </Typography>
          </Box>
        )}
        {bundle && (
          <Box color={planColorPrimary}>
            <Typography
              variant='body1'
              weight='semibold'
              style={{ fontSize: '14px', marginLeft: '-4px' }}
            >
              Bundle
            </Typography>
          </Box>
        )}
        <Typography variant='body1' weight='bold'>
          <Highlight attribute='name' hit={data} />
        </Typography>
        {!bundle ? (
          <Box paddingLeft='4px'>
            {clinicSlug ? (
              <Box display='flex' flexDirection='row' alignItems='center'>
                <Button
                  variant='unstyled'
                  to={`/clinics/${clinicSlug}`}
                  size='small'
                  sx={{
                    color: planColorPrimary,
                    height: 'auto',
                    padding: '0px',
                  }}
                >
                  <Typography
                    variant='body1'
                    style={{ color: planColorPrimary, textDecoration: 'underline' }}
                  >
                    {clinicName || 'No Clinic Listed'}
                  </Typography>
                </Button>
                <Typography
                  variant='body1'
                  style={{
                    color: `${planColorPrimary}dd`,
                  }}
                >
                  - {network?.name || 'No Network Listed'}
                </Typography>
              </Box>
            ) : (
              <>
                <Typography
                  variant='body1'
                  style={{
                    color: `${planColorPrimary}dd`,
                  }}
                >
                  {clinicName || 'No Clinic Listed'} - {network?.name || 'No Network Listed'}
                </Typography>
                {data.rendering && (
                  <Typography
                    variant='body1'
                    style={{
                      color: `${planColorPrimary}dd`,
                    }}
                  >
                    {data.rendering}
                  </Typography>
                )}
              </>
            )}
          </Box>
        ) : (
          <>
            <Button
              variant='text'
              onClick={() => setOpen(!open)}
              size='small'
              sx={{
                color: planColorPrimary,
                '&:hover': {
                  backgroundColor: `${planColorPrimary}33`, // Adjust the hover background color
                },
                '&.Mui-focused': {
                  backgroundColor: `${planColorPrimary}33`, // Adjust the onClick background color
                },
                borderRadius: '16px',
              }}
            >
              <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                color={`${planColorPrimary}dd`}
                paddingLeft='6px'
              >
                <Typography variant='body1'>Bundled Services</Typography>
                <Box
                  style={{
                    transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
                    transition: 'transform 0.3s',
                  }}
                >
                  <Icon name='chevronRight' />
                </Box>
              </Box>
            </Button>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Box paddingLeft='16px' color={`${planColorPrimary}aa`} paddingBottom='8px'>
                {data.bundleComponentsUI
                  .sort((a, b) => b.totalPrice - a.totalPrice)
                  .map((service, index) => (
                    <Box>
                      <Box
                        display='flex'
                        justifyContent='space-between'
                        alignItems='center'
                        key={index}
                        onMouseEnter={() => handleHover(true, service.clinicID, service)}
                        onMouseLeave={() => handleHover(false)}
                        sx={{
                          cursor: 'pointer',
                          '&:hover': {
                            bgcolor: `${planColorPrimary}22`,
                          },
                        }}
                      >
                        <Box display='flex' flexDirection='column' width='100%'>
                          <Box display='flex' color={planColorPrimary}>
                            <Typography variant='body1' style={{ fontSize: '14px' }}>
                              {service.name && service.name}
                            </Typography>
                            <Typography variant='body1' style={{ fontSize: '14px' }}>
                              &nbsp;
                              {service.count > 1 ? `(x${service.count})` : ''}
                            </Typography>
                          </Box>
                          {service.cptCode && (
                            <Box width='100%' display='flex' justifyContent='space-between'>
                              <Typography
                                variant='body1'
                                weight='light'
                                style={{ fontSize: '14px' }}
                              >
                                Procedure Code:
                              </Typography>
                              <Typography
                                variant='body1'
                                weight='light'
                                style={{ fontSize: '14px' }}
                              >
                                {service.cptCode}
                              </Typography>
                            </Box>
                          )}
                          <Box width='100%' display='flex' justifyContent='space-between'>
                            {service.rendering && (
                              <Typography
                                variant='body1'
                                weight='light'
                                style={{
                                  fontSize: '14px',
                                }}
                              >
                                {service.rendering}:
                              </Typography>
                            )}
                            <Typography
                              variant='body1'
                              weight='light'
                              style={{
                                fontSize: '14px',
                              }}
                            >
                              {service.clinicName}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      {index < data.bundleComponentsUI.length - 1 && (
                        <Divider
                          style={{
                            margin: '2px 0',
                          }}
                        />
                      )}
                    </Box>
                  ))}
              </Box>
            </Collapse>
          </>
        )}
        {/* Displayed Price */}
        <Box
          color={planColorPrimary}
          sx={{
            paddingBottom: {
              xs: '8px',
              sm: '8px',
              md: '0',
              lg: '0',
            },
          }}
        >
          {showServiceSearchResponsibilityBreakdown ? (
            <Box>
              <Box display='flex' alignItems='center' gap='2px'>
                <Button
                  variant='text'
                  onClick={() => setOpenPaymentBreakdown(!openPaymentBreakdown)}
                  size='small'
                  sx={{
                    color: planColorPrimary,
                    '&:hover': {
                      backgroundColor: `${planColorPrimary}33`, // Adjust the hover background color
                    },
                    '&.Mui-focused': {
                      backgroundColor: `${planColorPrimary}33`, // Adjust the onClick background color
                    },
                    minWidth: '0px',
                    height: '32px',
                    borderRadius: '16px',
                  }}
                >
                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                    color={`${planColorPrimary}dd`}
                    paddingLeft='8px'
                  >
                    <Typography variant='body1' weight='bold'>
                      You Pay: $0.00
                    </Typography>
                    <Box
                      style={{
                        transform: openPaymentBreakdown ? 'rotate(90deg)' : 'rotate(0deg)',
                        transition: 'transform 0.3s',
                      }}
                    >
                      <Icon name='chevronRight' />
                    </Box>
                  </Box>
                </Button>
              </Box>
              <Collapse in={openPaymentBreakdown} timeout='auto' unmountOnExit>
                <Box color={`${planColorPrimary}dd`} paddingLeft='8px'>
                  <Typography variant='body1' color='inherit'>
                    Plan Covers: {formattedCurrency}
                  </Typography>
                </Box>
              </Collapse>
            </Box>
          ) : (
            <Typography variant='body1' weight='bold' color='inherit'>
              {formattedCurrency}
            </Typography>
          )}
        </Box>
        {_rankingInfo?.geoDistance > 0 && (
          // distance converted from meters to miles, add commas for readability
          <Typography variant='body1' weight='light'>
            Distance: {parseInt(_rankingInfo.geoDistance / 1609.344, 10).toLocaleString()}miles
          </Typography>
        )}
      </Box>
      {onClick && !preventBooking && (
        <Box
          display='flex'
          height='100%'
          alignItems='center'
          sx={{
            flexDirection: {
              xs: 'row',
              md: open ? 'row' : 'column',
            },
            justifyContent: {
              xs: 'flex-start',
              md: 'center',
            },
          }}
        >
          {data.misheCode && (
            <Button
              variant='unstyled'
              to={`${onClick}${data.misheCode}`}
              style={{
                height: '40px',
                margin: '4px',
                width: '120px',
                backgroundColor: '#fff',
                border: `solid 1px ${planColorPrimary}`,
                '&:hover': {
                  backgroundColor: `${planColorPrimary}`, // Adjust the hover background color
                },
                '&.Mui-focused': {
                  backgroundColor: `${planColorPrimary}`, // Adjust the onClick background color
                },
              }}
            >
              <Typography
                variant='h5'
                weight='light'
                style={{
                  margin: '0px',
                  whiteSpace: 'nowrap',
                  color: planColorPrimary,
                }}
              >
                Learn More
              </Typography>
            </Button>
          )}
          {!orderRequired && (referralPrice || price || payerPrice) ? (
            <Button
              style={{
                height: '40px',
                margin: '4px',
                width: '120px',
                backgroundColor: planColorPrimary,
                '&:hover': {
                  backgroundColor: `${planColorPrimary}`, // Adjust the hover background color
                },
                '&.Mui-focused': {
                  backgroundColor: `${planColorPrimary}`, // Adjust the onClick background color
                },
              }}
              to={`/plans/${data.network.slug}/service/${data.id}/checkout`}
            >
              <Typography
                variant='h5'
                weight='light'
                style={{ margin: '0px', whiteSpace: 'nowrap' }}
              >
                Book Now
              </Typography>
            </Button>
          ) : (
            <Box>
              <Typography variant='body1'>Order Required</Typography>
              <Button
                variant='outlined'
                style={{ height: '40px', margin: '4px', width: '120px' }}
                className='service-search-card-button'
              >
                <Typography
                  variant='h5'
                  weight='light'
                  style={{ margin: '0px', whiteSpace: 'nowrap' }}
                >
                  Get Started
                </Typography>
              </Button>
            </Box>
          )}
        </Box>
      )}
      {preventBooking && (
        <Box
          display='flex'
          justifyContent='space-between'
          height='100%'
          alignItems='center'
          sx={{
            flexDirection: {
              xs: 'row',
              sm: 'row',
              md: 'column',
              lg: 'column',
            },
          }}
          className='provider-search-card-button-container'
        >
          <Button
            variant='unstyled'
            style={{
              height: '40px',
              margin: '4px',
              width: '120px',
              backgroundColor: planColorPrimary,
            }}
            onClick={() => window?.Intercom('show')}
            className='provider-search-card-button'
          >
            <Typography
              variant='h5'
              weight='light'
              style={{
                margin: '0px',
                whiteSpace: 'nowrap',
                color: 'white',
              }}
            >
              Get Started
            </Typography>
          </Button>
        </Box>
      )}
      <Divider
        style={{
          margin: '8px 0',
          display: {
            xs: 'none',
            md: 'block',
          },
        }}
      />
    </Box>
  )
}

ServiceSearchCardNetwork.propTypes = {
  data: PropTypes.shape({
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.string,
    network: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
    }),
    clinicName: PropTypes.string,
    referralPrice: PropTypes.number,
    rendering: PropTypes.string,
    cptCode: PropTypes.string,
    misheCode: PropTypes.string,
    bundle: PropTypes.bool,
    bundledServiceNames: PropTypes.array,
  }),
  onClick: PropTypes.string,
}

export default ServiceSearchCardNetwork
