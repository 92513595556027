import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import TextField from '@app/stories/TextField'
import Button from '@app/stories/Button'
import Typography from '@app/stories/Typography'

const ResendEmailForm = ({ onSubmit, onChange, errors = {}, email = '' }) => (
  <form title='sign-in-form' onSubmit={onSubmit}>
    <Typography variant='body1' weight='light' gutterBottom>
      For data security purposes, the reset password link expires within 1 hour. If you are seeing
      this message, please click this button to resend the Create New Password email.
    </Typography>
    <TextField
      required
      value={email}
      name='email'
      type='email'
      label='Email'
      placeholder='Email Associated with your mishe account'
      inputProps={{
        title: 'Email',
      }}
      error={!!errors.password}
      helperText={errors.password}
      onChange={onChange}
    />
    <Box mt={2}>
      <Button type='submit' fullWidth>
        Resend Email
      </Button>
    </Box>
  </form>
)

ResendEmailForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    password: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  email: PropTypes.string,
}

export default ResendEmailForm
