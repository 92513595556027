import { useCurrentUser } from '@app/context/CurrentUser'
import usePayouts from '@app/hooks/usePayouts'
import { useEffect } from 'react'
import PaymentTable from './ClinicPayoutsTable'

const ClinicPayouts = () => {
  const { fetchPayouts, payoutData, payoutsLoading } = usePayouts()

  const { authData } = useCurrentUser()
  useEffect(() => {
    authData.organizationId && fetchPayouts({ organizationId: authData.organizationId })
  }, [authData.organizationId])

  return <div>{!payoutsLoading && <PaymentTable payoutData={payoutData} />}</div>
}

export default ClinicPayouts
