import { memo, useRef } from 'react'
import Box from '@mui/material/Box'
import Typography from '@app/stories/Typography'
import {
  getFullName,
  getAddress,
  getBirthday,
  getGender,
  getPhone,
} from '@app/utils/userFormatterUtil'
import Icon from '@app/stories/Icons'

const PATIENT_INFO_HEADERS = [
  { label: 'Name', icon: 'star', formatter: getFullName },
  { label: 'Address', icon: 'location', formatter: getAddress },
  { label: 'Birthday', icon: 'cake', formatter: getBirthday },
  { label: 'Gender', icon: 'wc', formatter: getGender },
  { label: 'Phone', icon: 'phone', formatter: getPhone },
]

const InfoRow = ({ label = '', body, icon }) => (
  <Box display='flex' alignItems='center' my={2}>
    {icon && <Icon name={icon} fontSize='small' color='primary' />}
    <Box fontWeight={500} ml={2}>
      {label}
    </Box>
    <Box ml={1} mr={2}>
      {body}
    </Box>
  </Box>
)

const PatientInfo = ({ voucher }) => (
  <Box>
    <Typography variant='h4' mt={2} mb={1}>
      Patient Info
    </Typography>
    <Box>
      {PATIENT_INFO_HEADERS.map((field, index) => (
        <Typography
          key={index}
          variant='body1'
          style={{
            textTransform: 'capitalize',
          }}
        >
          <InfoRow label={field.label} body={field.formatter(voucher)} icon={field.icon} />
        </Typography>
      ))}
    </Box>
  </Box>
)

export default memo(PatientInfo)
