import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Notification from '@app/stories/Notification'

const NotificationBox = ({ severity = 'success', message, autoHide = true, children }) => {
  const [hidden, setHidden] = useState(false)

  useEffect(() => {
    let timeout
    if (!hidden && autoHide) {
      timeout = setTimeout(() => setHidden(true), 7000)
    }
    return () => {
      // clear timeout on re-render
      clearTimeout(timeout)
    }
  }, [autoHide, hidden, setHidden])

  if (hidden) return null

  return (
    <Notification severity={severity}>
      <Box display='inline' mr={2}>
        {message}
      </Box>
      {children}
    </Notification>
  )
}

NotificationBox.propTypes = {
  autoHide: PropTypes.bool,
  children: PropTypes.node,
  severity: PropTypes.string,
  message: PropTypes.string.isRequired,
}

export default NotificationBox
