import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import Notification from '@app/stories/Notification'
import Link from '@app/stories/Link'

const AcceptTermsNotification = ({ onTermsChange, onBaaChange }) => (
  <Notification severity='warning'>
    <Box
      display='flex'
      flexWrap={{ xs: 'wrap', md: 'nowrap' }}
      alignItems='center'
      justifyContent='center'
    >
      <Box width={{ xs: '100%', md: 'auto' }}>
        <Typography variant='body2' mr={1}>
          To use this account please review and agree to our:
        </Typography>
      </Box>
      <Box display='flex' alignItems='center' ml={1}>
        <Checkbox
          required
          color='primary'
          inputProps={{ 'aria-label': 'terms' }}
          onChange={onTermsChange}
        />
        <Typography variant='body2'>
          <Link display='inline' size='small' target='_blank' to='/privacy-policy'>
            Privacy Policy
          </Link>{' '}
          ,{' '}
          <Link display='inline' size='small' target='_blank' to='/terms'>
            Terms of Use
          </Link>{' '}
          and{' '}
          <Link display='inline' size='small' target='_blank' to='/eula-provider'>
            EULA
          </Link>
          .
        </Typography>
      </Box>
      <Box display='flex' alignItems='center' ml={1}>
        <Checkbox
          required
          color='primary'
          inputProps={{ 'aria-label': 'hipaa' }}
          onChange={onBaaChange}
        />
        <Link display='inline' size='small' target='_blank' to='/baa'>
          BAA
        </Link>
        .
      </Box>
    </Box>
  </Notification>
)

AcceptTermsNotification.propTypes = {
  onTermsChange: PropTypes.func.isRequired,
  onBaaChange: PropTypes.func.isRequired,
}

export default AcceptTermsNotification
