import { Box } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Button from '@app/stories/Button'
import MisheLeaves from '@app/assets/images/MisheLeaves.png'
import PageSection from '@app/components/PageSection'
import Typography from '@app/stories/Typography'
import background from '@app/assets/images/backgroundTest2.png'
import PhysicianPage2 from '@app/assets/images/PhysicianPage2.png'
import MishePhysicianReceptionist from '@app/assets/images/MishePhysicianReceptionist.png'
import BubbleBuilder from '@app/components/SvgBuilders/BubbleBuilder'
import ComponentShifterFadeControl from '@app/components/ComponentShifterFadeControl'
import JoinMisheUtils from '@app/utils/JoinMisheUtil'

import { useWhiteLabelingContext } from '@app/context/WhiteLabelingContext'

const { getRouteFromDomain } = JoinMisheUtils

const PhysiciansPage = () => {
  const { planColorPrimary } = useWhiteLabelingContext()

  const handleJoinMisheRedirect = () => {
    window.location.href = getRouteFromDomain(window.location.hostname, true)
  }

  const CheckBox = ({ content, marginBottom }) => (
    <Box mb={marginBottom || 2}>
      <Typography
        variant='body2'
        gutterBottom
        weight='light'
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <CheckCircleIcon style={{ color: '#662D91' }} />
        <span style={{ marginLeft: 20 }}>{content}</span>
      </Typography>
    </Box>
  )

  const HowItWorksCard = ({ image, title, content }) => {
    return (
      <Box
        width={'100%'}
        minHeight='350px'
        display='flex'
        flexDirection='column'
        justifyContent='flex-start'
        alignItems='center'
        backgroundColor={planColorPrimary}
        outline='1px solid #fff'
        borderRadius='8px'
        padding='16px'
        style={{
          transform: 'scale(1)',
          transition: 'transform 0.3s',
        }}
        onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
        onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
      >
        {image && (
          <Box height='132px' padding='16px 0'>
            <BubbleBuilder color='#652d92' bubbleName={image} />
          </Box>
        )}
        <Box
          sx={{
            minHeight: {
              md: '100px',
              lg: '70px',
            },
            width: {
              md: '100%',
              lg: '90%',
            },
          }}
        >
          <Typography
            variant='h4'
            align='center'
            color='white'
            sx={{ marginBottom: { xs: '1rem', md: '0' } }}
          >
            {title}
          </Typography>
        </Box>
        <Typography variant='body1' align='center' color='white'>
          {content}
        </Typography>
      </Box>
    )
  }

  return (
    <Box>
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <PageSection
          startLoaded
          direction='left'
          image={PhysicianPage2}
          height={600}
          imgWidth='60%'
          infoWidth='545px'
          bgImage={background}
        >
          <Box
            padding='1rem 1rem 0 1rem'
            bgcolor='#f6eeff'
            boxShadow='0px 4px 4px rgba(0, 0, 0, 0.1)'
          >
            <Box padding='2rem' bgcolor='#652d92' marginLeft='2rem'>
              <Typography
                variant='h5'
                family='poppins'
                weight='medium'
                style={{
                  color: '#fff',
                  fontSize: '36px',
                  margin: '0',
                }}
              >
                Seamless Healthcare
                <br /> Payments & <br /> Administration
              </Typography>
            </Box>
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              padding='0.5rem 0rem 0.5rem 2rem'
            >
              <Typography variant='body1' family='poppins' weight='light' color='primary'>
                Are you a provider?
              </Typography>
              <Button
                variant='unstyled'
                style={{
                  height: 'auto',
                  borderRadius: '20px',
                  border: '1px solid transparent',
                }}
                onMouseEnter={(e) => (e.currentTarget.style.border = '1px solid #652d92')}
                onMouseLeave={(e) => (e.currentTarget.style.border = '1px solid transparent')}
                onClick={handleJoinMisheRedirect}
              >
                <Typography variant='body1' family='poppins' weight='light' color='primary'>
                  Join Today
                </Typography>
              </Button>
            </Box>
          </Box>
        </PageSection>
        <PageSection height={100} bgColorSlide='#652d92' bgColor='#6d319e'>
          <Box display='flex' justifyContent='space-around' padding='0 2rem' width='100%'>
            <Typography
              variant='h2'
              family='poppins'
              style={{
                color: '#fff',
                margin: '0',
              }}
            >
              How Mishe Works For You
            </Typography>
          </Box>
        </PageSection>
        <PageSection height={200} childrenOnSlide bgColor='#f6eeff' paddingTop='4px'>
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            margin='0 10rem'
            padding='1.5rem'
            bgcolor='#fff'
            boxShadow='0px 8px 8px 0px rgba(0,0,0,0.1)'
            borderRadius='20px'
          >
            <Typography
              variant='h3'
              family='poppins'
              weight='light'
              style={{ margin: '0px' }}
              textAlign='center'
            >
              Same-day payment. Automated claims. Simplified revenue cycle.
            </Typography>
          </Box>
        </PageSection>
        <PageSection
          height={600}
          direction='left'
          infoWidth='60%'
          imgWidth='45%'
          image={MishePhysicianReceptionist}
          bgColor='#f6eeff'
        >
          <Box margin='0 5rem 0 5rem'>
            <Box mb={1}>
              <Typography variant='caption' weight='semibold' family='poppins'>
                <Box component='span' color='primary.main'>
                  SEAMLESS HEALTHCARE
                </Box>
              </Typography>
            </Box>
            <Box mb={2}>
              <Typography variant='h3' gutterBottom family='poppins'>
                No claims. No denials. Just
                <br />
                <Box component='span' color='primary.main'>
                  instant payment for every visit.
                </Box>
              </Typography>
            </Box>
            <Typography
              variant='body1'
              weight='light'
              style={{ maxWidth: '480px' }}
              family='poppins'
            >
              Mishe is transforming healthcare administration with a fully integrated, real-time
              claim adjudication infrastructure. As a provider, you gain access to:
            </Typography>
            <br />
            <CheckBox content='Same-day provider payments via EFT (Electronic Funds Transfer).' />
            <CheckBox content='Real-time benefits verification before patient visits.' />
            <CheckBox content='Automated claims submission & processing—no manual claims.' />
            <CheckBox content='Direct contract management with employer groups.' marginBottom={0} />
          </Box>
        </PageSection>
        <Box width='100%' padding='32px' bgcolor={planColorPrimary}>
          <Box display='flex' justifyContent='center' alignItems='center' padding='16px'>
            <Typography
              variant='h2'
              family='poppins'
              style={{
                color: '#fff',
                textAlign: 'center',
              }}
            >
              How it works
            </Typography>
          </Box>
          <Box
            width='100%'
            justifyContent='center'
            alignItems='center'
            padding='32px'
            gap='32px'
            sx={{
              display: {
                xs: 'none',
                sm: 'none',
                md: 'flex',
                lg: 'flex',
              },
            }}
          >
            <HowItWorksCard
              image='support'
              title='Treatment Scheduled & Pre-Approved'
              content='Providers submit a treatment request, and Mishe instantly verifies eligibility. The plan and/or patient pre-pay, ensuring funds are secured before the visit.'
            />
            <HowItWorksCard
              image='providerSearch'
              title='Providers Deliver Care'
              content='Patients receive automated appointment reminders, and providers can focus on delivering care without the hassle of billing or claims submission.'
            />
            <HowItWorksCard
              image='outOfPocket'
              title='Providers Paid Instantly'
              content='After treatment, Providers redeem the voucher, triggering an EFT payment within 24 hours. No billing, no delays—just fast, hassle-free payments.'
            />
          </Box>
        </Box>
        <PageSection height={600} bgImage={MisheLeaves}>
          <Box
            padding='32px'
            bgcolor='#f6eeffee'
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            borderRadius='20px'
            border='2px solid #652d92'
            gap='16px'
            boxShadow='0px 8px 8px 0px rgba(0,0,0,0.1)'
          >
            <Typography
              variant='h2'
              family='poppins'
              style={{
                color: '#652d92',
                textAlign: 'center',
              }}
            >
              Ready to join?
            </Typography>
            <Typography
              variant='body1'
              family='poppins'
              weight='light'
              style={{
                color: '#652d92',
                textAlign: 'center',
                maxWidth: '480px',
              }}
            >
              Contact our team to begin the simple enrollment process and start experiencing the
              benefits of our innovative real-time claim adjudication infrastructure.
            </Typography>
            <Button
              size='medium'
              style={{
                margin: '16px 0',
                borderRadius: '20px',
              }}
              onClick={handleJoinMisheRedirect}
            >
              <Typography variant='body1' family='poppins' weight='light'>
                Join Today
              </Typography>
            </Button>
          </Box>
        </PageSection>
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <PageSection
          startLoaded
          height={500}
          imgWidth='100%'
          infoWidth='100%'
          bgImage={MisheLeaves}
        >
          <Box
            padding='1rem'
            bgcolor='rgba(217, 193, 244, 0.9)'
            boxShadow='0px 4px 4px rgba(0, 0, 0, 0.1)'
            margin='1rem'
          >
            <Box padding='1rem' bgcolor='#652d92'>
              <Typography
                variant='h5'
                family='poppins'
                weight='light'
                style={{
                  color: '#fff',
                  fontSize: '24px',
                  margin: '0',
                }}
              >
                Seamless Healthcare Payments & Administration
              </Typography>
            </Box>
            <Box
              paddingTop='1rem'
              display='flex'
              justifyContent='space-between'
              alignItems='center'
            >
              <Typography variant='body1' family='poppins' weight='light' color='primary'>
                Are you a provider?
              </Typography>
              <Button
                variant='unstyled'
                style={{
                  margin: '0',
                  borderRadius: '20px',
                  height: 'auto',
                }}
                onMouseEnter={(e) => (e.currentTarget.style.border = '1px solid #652d92')}
                onMouseLeave={(e) => (e.currentTarget.style.border = '1px solid transparent')}
                onClick={handleJoinMisheRedirect}
              >
                <Typography variant='body1' family='poppins' weight='light' color='primary'>
                  Join Today
                </Typography>
              </Button>
            </Box>
          </Box>
        </PageSection>
        <PageSection height={500} direction='left' infoWidth='100%' imgWidth='0%' bgColor='#f6eeff'>
          <Box padding='16px'>
            <Box mb={0.5}>
              <Typography variant='caption' weight='semibold' family='poppins'>
                <Box component='span' color='primary.main'>
                  SEAMLESS HEALTHCARE
                </Box>
              </Typography>
            </Box>
            <Box mb={2}>
              <Typography variant='h4' gutterBottom family='poppins'>
                No claims. No denials. Just
                <br />
                <Box component='span' color='primary.main'>
                  instant payment for every visit.
                </Box>
              </Typography>
            </Box>
            <Typography
              variant='body1'
              weight='light'
              style={{ maxWidth: '480px' }}
              family='poppins'
            >
              Mishe is transforming healthcare administration with a fully integrated, real-time
              claim adjudication infrastructure. As a provider, you gain access to:
            </Typography>
            <br />
            <CheckBox content='Same-day provider payments via EFT (Electronic Funds Transfer).' />
            <CheckBox content='Real-time benefits verification before patient visits.' />
            <CheckBox content='Automated claims submission & processing—no manual claims.' />
            <CheckBox content='Direct contract management with employer groups.' marginBottom={0} />
          </Box>
        </PageSection>
        <Box width='100%' padding='32px 0px' bgcolor={planColorPrimary}>
          <Box display='flex' justifyContent='center' alignItems='center' padding='16px'>
            <Typography
              variant='h2'
              family='poppins'
              // weight='light'
              style={{
                color: '#fff',
                textAlign: 'center',
              }}
            >
              How it works
            </Typography>
          </Box>
          <Box width='100%' justifyContent='center' alignItems='center'>
            <ComponentShifterFadeControl healthPlanHomePage buttonColor='#fff'>
              <HowItWorksCard
                image='support'
                title='Treatment Scheduled & Pre-Approved'
                content='Providers submit a treatment request, and Mishe instantly verifies eligibility. The plan and/or patient pre-pay, ensuring funds are secured before the visit.'
              />
              <HowItWorksCard
                image='providerSearch'
                title='Providers Deliver Care'
                content='Patients receive automated appointment reminders, and providers can focus on delivering care without the hassle of billing or claims submission.'
              />
              <HowItWorksCard
                image='outOfPocket'
                title='Providers Paid Instantly'
                content='After treatment, Providers redeem the voucher, triggering an EFT payment within 24 hours. No billing, no delays—just fast, hassle-free payments.'
              />
            </ComponentShifterFadeControl>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default PhysiciansPage
