import { FC, ReactNode } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useCurrentUser } from '@app/context/CurrentUser'

const AnonymousRoute: FC = () => {
  const { state } = useLocation()
  const { profileData } = useCurrentUser()

  let node: ReactNode = 'Loading'
  if (!profileData?.id) {
    console.log('its anon, going to outlet')
    node = <Outlet />
  } else if (profileData.id) {
    console.log('PROFILE has an id, go to dashboard if no state exists')
    node = <Navigate to={state || '/dashboard'} replace />
  }

  return node
}

export default AnonymousRoute
