export const SHARED_NOTE = {
  SHARED_WITH_PATIENT: 'SHARED_WITH_PATIENT',
  SHARED_WITH_REFERRING_PROVIDER: 'SHARED_WITH_REFERRING_PROVIDER',
}

export const ADD_NOTE = {
  DRAFT: 'draft',
  FINALIZE: 'finalize',
}

export const DASHBOARD_TABS = {
  vouchers: 'vouchers',
  info: 'info',
  security: 'security',
  orders: 'orders',
  reports: 'reports',
  plans: 'plans',
  billing: 'billing',
}

export const STRIPE_CONNECT_ERROR = {
  INVALID_EMAIL: 'Email is required',
  INVALID_COMPANY: 'Company Name is required',
  INVALID_BUSINESS_NAME: 'Business Name is required',
  INVALID_SUPPORT_EMAIL: 'Support Email is required',
}

export const MANUAL_PAYOUT_STATUS = {
  TRANSFERRED_TO_ACCOUNT: 'transferred_to_account',
  TRANSFERRED_TO_EXTERNAL_BANK_SUCCESSFULLY: 'transferred_to_external_bank_successfully',
  TRANSFERRED_TO_EXTERNAL_BANK_FAILED: 'transferred_to_external_bank_failed',
  TRANSFER_TO_EXTERNAL_BANK_INITIATED: 'transfer_to_external_bank_initiated',
  FAILED: 'failed',
}

export const SETUP_INTENT_STATUS = {
  SUCCEEDED: 'succeeded',
  REQUIRES_ACTION: 'requires_action',
  REQUIRE_CONFIRMATION: 'require_confirmation',
  REQUIRES_PAYMENT_METHOD: 'requires_payment_method',
  CANCELLED: 'cancelled',
  PROCESSING: 'processing',
}

export const PATIENT_DASHBOARD_TABS = [
  { label: 'Claims', path: 'vouchers' },
  { label: 'Records', path: 'patientdata' },
  { label: 'My Info', path: 'info' },
  { label: 'Orders', path: 'orders' },
  { label: 'Reports', path: 'reports' },
  { label: 'Plans', path: 'plans' },
]

export const PROVIDER_DASHBOARD_TABS = [
  { label: 'Claims', path: 'vouchers' },
  { label: 'My Info', path: 'info' },
  { label: 'Orders', path: 'orders' },
  { label: 'Reports', path: 'reports' },
  { label: 'Plans', path: 'plans' },
  { label: 'EFT Enrollment', path: 'enrollment' },
  { label: 'Payouts', path: 'payouts' },
]

export const PLANADMIN_DASHBOARD_TABS = [
  { label: 'Check Register', path: 'claims' },
  { label: 'Claims Activity', path: 'activity' },
  { label: 'Member Census', path: 'census' },
  { label: 'Provider Directory', path: 'directory' },
  { label: 'Fee Schedule', path: 'fee-schedule' },
  { label: 'EFT Enrollment', path: 'enrollment' },
  { label: 'Plan Design/ Details', path: 'plan-design' },
]
