import { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import GoogleMapReact from 'google-map-react'
import { Box } from '@mui/material'
import Button from '@app/stories/Button'
import Typography from '@app/stories/Typography'
import {
  buildStandardMarkers,
  buildPharmacyMarkers,
  calculateBounds,
  calculateCenter,
  calculateZoom,
} from '@app/utils/SearchNetworkMap/buildMarkersUtil'
import { useMapEffects } from '@app/context/MapEffects'

import { useCurrentNetwork } from '@app/context/CurrentNetwork'

import { useWhiteLabelingContext } from '@app/context/WhiteLabelingContext'

const GoogleMap = ({
  clinics = [],
  displayHeight = '45vh',
  pharmacyPage = false,
  handleSelectLocation,
  handleSwap,
  activeStep,
}) => {
  const [center, setCenter] = useState(null)
  const [markers, setMarkers] = useState([])
  const [zoom, setZoom] = useState(12)
  const [mapAreaCoordinates, setMapAreaCoordinates] = useState(null)
  const { hoveredData, setSearchMapCoordinates } = useMapEffects()

  // instance of bad code. this context is not available in clinics/mishe route. context
  // should always be available when google map is loaded
  const { networkData } = useCurrentNetwork() ?? {}

  const calculateAndSetMapDetails = (cords) => {
    const bounds = calculateBounds(cords)
    const center = calculateCenter(bounds)
    const zoom = calculateZoom(bounds)
    setCenter(center)
    setZoom(zoom)
  }

  // build the markers pased on the current page view
  const { planColorPrimary, defaultZipcode } = useWhiteLabelingContext()

  useEffect(() => {
    if (!clinics) {
      return undefined
    }

    let builtMarkers
    if (pharmacyPage) {
      builtMarkers = buildPharmacyMarkers(
        clinics,
        center,
        handleSelectLocation,
        handleSwap,
        activeStep,
      )
    } else {
      builtMarkers = buildStandardMarkers(clinics, center)
    }

    setMarkers(builtMarkers)
  }, [clinics])

  useEffect(() => {
    if (!markers?.length) return

    if (hoveredData?._geoloc?.length) {
      const cords = hoveredData._geoloc.filter(({ lat, lng }) => lat && lng)
      calculateAndSetMapDetails(cords)
    }
  }, [hoveredData])

  // set the map position when the markers change like we paginate
  useEffect(() => {
    if (!markers?.length) return

    if (networkData?.lat && networkData?.lng) {
      calculateAndSetMapDetails([{ lat: networkData.lat, lng: networkData.lng }])
      return
    }

    const cords = markers.map(({ props }) => {
      return { lat: props.lat, lng: props.lng }
    })
    calculateAndSetMapDetails(cords)
  }, [markers])

  const onBoundsChange = useCallback(
    (changedCoordinates) => {
      setMapAreaCoordinates(changedCoordinates)
    },
    [setMapAreaCoordinates],
  )

  const onMapSearch = useCallback(() => {
    setSearchMapCoordinates(mapAreaCoordinates)
  }, [mapAreaCoordinates, setSearchMapCoordinates])

  return (
    <Box>
      <Button
        onClick={onMapSearch}
        variant='unstyled'
        size='small'
        fullWidth
        sx={{
          borderRadius: 0,
          backgroundColor: planColorPrimary,
          color: 'white', // Ensure text color is white
          '&:hover': {
            backgroundColor: `${planColorPrimary}CC`, // Slightly transparent on hover
          },
        }}
      >
        <Typography variant='body1'>Search this area</Typography>
      </Button>
      <Box sx={{ height: displayHeight, width: '100%' }}>
        {center?.lat && center?.lng ? (
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyBOTTnaTYTcp5rfhvnPQQl_Wv6eVc-7OnQ' }}
            center={center}
            zoom={zoom}
            onBoundsChange={onBoundsChange}
          >
            {markers?.length ? markers : null}
          </GoogleMapReact>
        ) : (
          <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
            <Typography variant='h4' align='center'>
              {pharmacyPage
                ? clinics?.length >= 0
                  ? 'Search for a pharmacy.'
                  : 'We are unable to display this map.'
                : clinics?.length >= 0
                  ? 'Loading Clinics...'
                  : 'We are unable to display this map.'}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  )
}

GoogleMap.propTypes = {
  clinics: PropTypes.array,
  displayHeight: PropTypes.string,
  pharmacyPage: PropTypes.bool,
  locationSelected: PropTypes.object,
}

export default GoogleMap
