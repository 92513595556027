import PropTypes from 'prop-types'
import { Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@mui/material'

// Injected CSS styles for responsive design
const styles = `
  .responsive-table {
    width: 100%;
    border-collapse: collapse;
  }

  /* responsive styles */
  @media (max-width: 600px) {
    .responsive-table, .responsive-table thead, .responsive-table tbody, .responsive-table tr, .responsive-table th, .responsive-table td {
      display: block;
      width: 100%;
    }

    .responsive-table thead tr {
      display: none; /* to hide the table headers on mobile */
    }

    .responsive-table tr {
      margin-bottom: 16px;
      padding: 8px;
      border: 2px solid #f6eeff;
      border-radius: 8px;
    }

    .responsive-table td {
      display: flex;
      justify-content: space-between;
      padding: 8px 0;
      text-align: left;
      align-items: center;
      border-bottom: none;
    }

    .responsive-table td::before {
      content: attr(data-label); // win
      font-weight: bold;
      text-transform: uppercase;
      margin-right: 8px;
      flex-shrink: 0;
    }
  }
`

const InjectedStyles = () => <style>{styles}</style>

/**
 * ResponsiveTable Component
 *
 * The `ResponsiveTable` component basically sets up the styling and css that will be used to adjust
 * the size of the table and all its rows and subrows to a responsive feel.
 *
 * It accepts a dynamic `headers` array for configuring column headers and rows. Follow the header
 * format defined
 *
 * ## Example usage
 *
 * ```jsx
 * const headers = [
 *   { id: 'info', label: 'Info', type: 'iconOpenClose', align: 'center' },
 *   { id: 'organization', label: 'Clinic', type: 'link', align: 'left' },
 *   { id: 'service', label: 'Service', type: 'text', align: 'left' },
 *   { id: 'appDate', label: 'Appt Date', type: 'date', align: 'left' },
 *   { id: 'status', label: 'Status', type: 'chip', align: 'center' },
 * ]
 *
 * ;<ResponsiveTable headers={headers}>
 *   <ResponsiveRows />
 *   <ResponsiveRows />
 *   <ResponsiveRows />
 *   ....
 * </ResponsiveTable>
 * ```
 *
 * @param {Object} props - Component props.
 * @param {Array} props.headers - Defines each column's label and type. see above format.
 * @param {string} props.headerColor - The background color of the table header.
 * @param {React.ReactNode} props.children - Row components that will be rendered within the table
 *   body.
 */
const ResponsiveTable = ({ headers, headerColor, children }) => (
  <>
    <InjectedStyles />
    <Paper>
      <Table className='responsive-table'>
        <TableHead style={{ backgroundColor: headerColor }}>
          <TableRow>
            {headers.map(({ id, label }) => (
              <TableCell key={id}>{label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </Paper>
  </>
)

ResponsiveTable.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      align: PropTypes.string,
    }),
  ).isRequired,
  children: PropTypes.node.isRequired,
}

export default ResponsiveTable
