import { useRefinementList } from 'react-instantsearch'

import Box from '@mui/material/Box'
import { Divider } from '@mui/material'
import Typography from '@app/stories/Typography'
import TextField from '@app/stories/TextField'

import Grid from '@mui/material/Grid'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { useWhiteLabelingContext } from '@app/context/WhiteLabelingContext'

const CustomRefinementList = ({
  attribute,
  customTitle,
  searchable,
  limit,
  searchText,
  customMarginTop,
}) => {
  const { planColorPrimary } = useWhiteLabelingContext()

  const { items, refine, searchForItems, canRefine, isFromSearch } = useRefinementList({
    attribute,
  })

  if (!canRefine) {
    return null
  }

  let itemsToDisplay = items
  // apply limit if provided
  if (limit) {
    itemsToDisplay = items.slice(0, limit)
  }

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      mb={1}
      style={{ marginTop: customMarginTop ? customMarginTop : '16px' }}
    >
      {!searchable ? (
        <Typography variant='h6' mb={searchable ? 1 : 0}>
          {customTitle}
        </Typography>
      ) : (
        <TextField
          id='search'
          size='small'
          variant='outlined'
          sx={{
            marginTop: '0px',
            marginBottom: '8px',
            width: '100%',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: planColorPrimary,
              },
              '&:hover fieldset': {
                borderColor: planColorPrimary,
              },
              '&.Mui-focused fieldset': {
                borderColor: planColorPrimary,
              },
            },
            '& .MuiInputLabel-root': {
              color: planColorPrimary,
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: planColorPrimary,
            },
          }}
          display='standard'
          autoComplete='off'
          autoCorrect='off'
          autoCapitalize='off'
          spellCheck={false}
          maxLength={512}
          type='search'
          onChange={(event) => searchForItems(event.currentTarget.value)}
          label={searchText}
        />
      )}
      {isFromSearch && items.length === 0 && <Typography variant='body1'>No results</Typography>}
      {itemsToDisplay.map((item) => (
        <Box paddingLeft='8px'>
          <FormControlLabel
            key={item.label}
            control={
              <Checkbox
                checked={item.isRefined}
                onChange={() => refine(item.value)}
                color='primary'
                sx={{
                  color: planColorPrimary,
                  '&.Mui-checked': {
                    color: planColorPrimary,
                  },
                }}
              />
            }
            label={item.label}
          />
        </Box>
      ))}
      <Divider style={{ marginTop: '8px' }} />
    </Grid>
  )
}

export default CustomRefinementList
