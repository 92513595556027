import PropTypes from 'prop-types'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import MedicationCheckoutForm from '@app/components/MedicationCheckout'

const QuickCheckoutMedication = ({
  orderCompletionCallback,
  deliveryMethod,
  hasScript,
  paymentIntentID = null,
  medicationRequested,
  pharmacyRequested = null,
  otherMedicationInfo,
}) => {
  return (
    <Container>
      <Grid container spacing={3} justifyContent='center' alignItems='center'>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MedicationCheckoutForm
            orderCompletionCallback={orderCompletionCallback}
            deliveryMethod={deliveryMethod}
            hasScript={hasScript}
            paymentIntentID={paymentIntentID}
            medicationRequested={medicationRequested}
            pharmacyRequested={pharmacyRequested}
            otherMedicationInfo={otherMedicationInfo}
          />
        </Grid>
      </Grid>
    </Container>
  )
}

QuickCheckoutMedication.propTypes = {
  orderCompletionCallback: PropTypes.func.isRequired,
  deliveryMethod: PropTypes.string.isRequired,
  hasScript: PropTypes.bool.isRequired,
  paymentIntentID: PropTypes.string,
  medicationRequested: PropTypes.shape({
    medID: PropTypes.string,
    name: PropTypes.string,
    dosage: PropTypes.string,
    form: PropTypes.string,
    quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  pharmacyRequested: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipcode: PropTypes.string,
    phone: PropTypes.string,
    fax: PropTypes.string,
    email: PropTypes.string,
    geopoint: PropTypes.object,
    operatingHours: PropTypes.string,
    relevantSpecialties: PropTypes.array,
  }),
  otherMedicationInfo: PropTypes.object.isRequired,
}

export default QuickCheckoutMedication
