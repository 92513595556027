import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import parseQueryString from '@app/libs/parseQueryStrings'
import SignInFormContainer from '@app/containers/SignInForm'
import SignInFormPhoneContainer from '@app/containers/SignInFormPhone'
import Typography from '@app/stories/Typography'
import Link from '@app/stories/Link'
import Button from '@app/stories/Button'
import Box from '@mui/material/Box'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import EmailIcon from '@mui/icons-material/Email'
import Divider from '@app/stories/Divider'

const SignInPage = () => {
  // We need to parse the query string into the parseQueryString function
  // to get the method from the query string
  const { search } = useLocation()

  const { method } = parseQueryString(search)
  console.log('method', method)

  const [loginMethod, setLoginMethod] = useState('')

  useEffect(() => {
    window.Intercom('trackEvent', 'Page View', {
      page_name: 'Sign In',
      method: method || 'no method in url',
    })
  }, [])

  if (method === 'phone') {
    return (
      <>
        <Helmet>
          <title>Mishe | Shop Care, Bypass Insurance</title>
          <meta property='og:title' content='Mishe | Shop Care, Bypass Insurance' />
          <meta
            name='description'
            property='og:description'
            content='Bypass insurance and shop cash price care options from licensed clinicians with Mishe.'
          />
          <meta property='og:url' content='https://mishe.co/signin' />
          <link rel='canonical' href='https://mishe.co/signin' />
        </Helmet>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '60vh',
          }}
        >
          <Box padding={1} sx={{ display: 'block', width: { xs: '90%', sm: '400px' } }}>
            <Typography variant='h2' gutterBottom>
              Login
            </Typography>
            <Divider
              style={{
                marginTop: '20px',
                marginBottom: '20px',
              }}
            />
            <SignInFormPhoneContainer />
            <Divider
              style={{
                marginTop: '20px',
                marginBottom: '20px',
              }}
            />
            <Box mb={2}>
              <Typography variant='body2' weight='light'>
                Don&apos;t have an account?{' '}
                <Link to='/signup' display='standard'>
                  Register here.
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
      </>
    )
  }

  if (method === 'email') {
    return (
      <>
        <Helmet>
          <title>Mishe | Shop Care, Bypass Insurance</title>
          <meta property='og:title' content='Mishe | Shop Care, Bypass Insurance' />
          <meta
            name='description'
            property='og:description'
            content='Bypass insurance and shop cash price care options from licensed clinicians with Mishe.'
          />
          <meta property='og:url' content='https://mishe.co/signin' />
          <link rel='canonical' href='https://mishe.co/signin' />
        </Helmet>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '60vh',
          }}
        >
          <Box padding={1} sx={{ display: 'block', width: { xs: '90%', sm: '400px' } }}>
            <Typography variant='h2' gutterBottom>
              Login
            </Typography>
            <Divider
              style={{
                marginTop: '20px',
                marginBottom: '20px',
              }}
            />
            <SignInFormContainer />
            <br />
            <Link to='/reset-password' display='standard'>
              Forgot your password?
            </Link>
            <Divider
              style={{
                marginTop: '20px',
                marginBottom: '20px',
              }}
            />
            <Box mb={2}>
              <Typography variant='body2' weight='light'>
                Don&apos;t have an account?{' '}
                <Link to='/signup' display='standard'>
                  Register here.
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
      </>
    )
  }

  return (
    <>
      <Helmet>
        <title>Mishe | Shop Care, Bypass Insurance</title>
        <meta property='og:title' content='Mishe | Shop Care, Bypass Insurance' />
        <meta
          name='description'
          property='og:description'
          content='Bypass insurance and shop cash price care options from licensed clinicians with Mishe.'
        />
        <meta property='og:url' content='https://mishe.co/signin' />
        <link rel='canonical' href='https://mishe.co/signin' />
      </Helmet>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '60vh',
        }}
      >
        <Box padding={1} sx={{ display: 'block', width: { xs: '90%', sm: '400px' } }}>
          <Typography variant='h2' gutterBottom>
            Login
          </Typography>
          <Divider
            style={{
              marginTop: '20px',
              marginBottom: '20px',
            }}
          />
          {loginMethod === '' && (
            <>
              <Box mb={2}>
                <Button
                  fullWidth
                  size='medium'
                  variant='outlined'
                  display='standard'
                  onClick={() => {
                    setLoginMethod('email')
                  }}
                >
                  <EmailIcon fontSize='medium' style={{ color: '#662D91', marginRight: '5px' }} />
                  Login with Email
                </Button>
              </Box>
              <Box mb={2}>
                <Button
                  fullWidth
                  size='medium'
                  variant='outlined'
                  display='standard'
                  onClick={() => {
                    setLoginMethod('phone')
                  }}
                >
                  <PhoneIphoneIcon
                    fontSize='medium'
                    style={{ color: '#662D91', marginRight: '5px' }}
                  />
                  Phone Number
                </Button>
              </Box>
            </>
          )}
          {loginMethod === 'email' && (
            <>
              <SignInFormContainer />
              <br />
              <Link to='/reset-password' display='standard'>
                Forgot your password?
              </Link>
            </>
          )}
          {loginMethod === 'phone' && <SignInFormPhoneContainer />}
          {loginMethod !== '' && (
            <Box mt={2}>
              <Button
                fullWidth
                size='medium'
                variant='outlined'
                display='standard'
                onClick={() => {
                  setLoginMethod('')
                }}
              >
                Back
              </Button>
            </Box>
          )}
          <Divider
            style={{
              marginTop: '20px',
              marginBottom: '20px',
            }}
          />
          <Box mb={2}>
            <Typography variant='body2' weight='light'>
              Don&apos;t have an account?{' '}
              <Link to='/signup' display='standard'>
                Register here.
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default SignInPage
