import { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useCurrentUser } from '@app/context/CurrentUser'
import Button from '@app/stories/Button'
import { Typography } from '@mui/material'
import RedeemVoucherModalContainer from '@app/containers/RedeemVoucherModalContainer'

const RedeemVoucherButton = ({ voucher }) => {
  const { authData } = useCurrentUser()
  const [open, setOpen] = useState()

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [open, setOpen])

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [open, setOpen])

  return (
    <>
      <Button
        size='small'
        variant='outlined'
        onClick={handleOpen}
        disabled={!authData.acceptedTerms}
      >
        <Typography variant='body1' sx={{ fontSize: '12px' }}>
          redeem
        </Typography>
      </Button>
      {open && (
        <RedeemVoucherModalContainer open={open} handleClose={handleClose} voucher={voucher} />
      )}
    </>
  )
}

RedeemVoucherButton.propTypes = {
  voucher: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    service: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    customer: PropTypes.shape({
      lastname: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default RedeemVoucherButton
