import Heading from '@app/stories/Heading'
import {
  TermsBlock as Block,
  TermsText as Text,
  TermsTitle as Title,
  TermsLink as Link,
  TermsList as List,
  TermsListItem as Item,
} from '@app/stories/Terms'

const PrivacyPolicyContent = () => (
  <div>
    <Heading variant='h1'>Privacy Policy</Heading>
    <Block id='privacy1'>
      <Text>
        Mishe, Inc. its affiliates and its subsidiaries (“Mishe,” “we,” “us,” or “our”) respect your
        privacy. This Privacy Notice describes the processing of Personal Information (defined
        below) that is provided, collected, or shared on the sites, mobile applications (the “App”),
        social media pages, or other platforms that link to this Privacy Notice (collectively, the
        “Services”). Some of our Services may require you to accept an End User License Agreement
        (“License“). To the extent that there is a conflict between this Privacy Notice and such
        License, the License will control with respect to your use of the Services; this Privacy
        Policy will control with respect to our collection, use, disclosure and disposal of your
        information.
      </Text>
      <Text>
        Please note, unless otherwise provided for in a HIPAA authorization that you provide to us,
        this Privacy Notice does not apply to the collection, use, and disclosure of your “protected
        health information” (“PHI”) as defined under the Health Insurance Portability and
        Accountability Act of 1996, as amended (“HIPAA”). Your PHI includes information we collect
        and process for or on behalf of healthcare providers pursuant to a HIPAA business associate
        agreement. For additional information on how your PHI is collected, used, and disclosed, and
        your rights with respect to your PHI, please see your healthcare provider’s HIPAA Notice of
        Privacy Practices.
      </Text>
    </Block>

    <Block id='privacy2'>
      <Title>1. Personal Information We Collect</Title>
      <Text>
        We may collect a range of Personal Information. “Personal Information” means information
        that uniquely identifies, relates to, describes, or is reasonably capable of being
        associated with or linked to you.
      </Text>
      <Text>The types of Personal Information we collect may include:</Text>
      <Text>
        <List>
          <Item>
            <b>Contact Information</b> – We may collect your contact and demographic information.
            This may include your name, last name, date of birth, email address, gender, telephone
            number, and password.
          </Item>
          <Item>
            <b>Commercial information</b> – Transaction-specific information, such as search terms,
            credit card information, service information, organization information and requests for
            information submitted.
          </Item>
          <Item>
            <b>Location Data</b> – While navigating our Services your mobile device or browser may
            share your location data, both through WiFi and GPS. We will collect this information
            based on the settings of your phone and browser.
          </Item>
          <Item>
            <b>Usage Information</b> – When you use our Services, our servers may automatically
            record information, including your Internet Protocol address (IP Address), browser type,
            referring URLs (e.g., the site you visited before coming to ours), domain names
            associated with your internet service provider, information on your interaction with the
            Services, and other such information.
          </Item>
          <Item>
            <b>Professional or Employment-related Information</b> – If you apply for employment, we
            will collect your employment and work history, as well as other Personal Information
            related to your potential employment. This may include your education and employment
            history, address and contact information, demographic information, and any other
            information included in your resume or application.
          </Item>
          <Item>
            <b>Communication Information</b> – We may collect the contents of your communications
            with us, whether via e-mail, chat functionality, social media, telephone or otherwise,
            and inferences we may make from other personal information we collect. Where permitted
            by applicable law, we may collect and maintain records of calls and chats with our
            agents, representatives, or employees via message, chat, post, or similar functionality.
            Other information you input into the Services.
          </Item>
        </List>
      </Text>
    </Block>

    <Block id='privacy3'>
      <Title>2. How We Collect Your Personal Information</Title>
      <List>
        <Item>
          <b>We May Collect Your Personal Information Directly From You</b> – For example, if you
          choose to contact us.
        </Item>
        <Item>
          <b>We May Also Collect Personal Information From Third Parties</b>– For example, we may
          work with business partners, subcontractors, advertising networks, analytics providers,
          and search information providers, who may provide us with Personal Information about you.
        </Item>
        <Item>
          <b>Through Online Tracking Technologies</b> – We and our service providers may use cookies
          and similar technologies such as pixels, web beacons, and local storage to collect usage
          and browser information about how you use the Services. We process the information
          collected through such technologies, which may include or be combined with Personal
          Information, to help operate certain features of the Services, to enhance your experience
          through personalization, and to help us better understand the features of the Services
          that you and other users are most interested in.
        </Item>
      </List>
    </Block>

    <Block id='privacy4'>
      <Title>3. Cookies and Other Tracking Technologies </Title>
      <Text>
        We process the information collected through such technologies, which may include or be
        combined with Personal Information, to help operate certain features of the Services, to
        enhance your experience through personalization, and to help us better understand the
        features of the Services that you and other users are most interested in.
      </Text>
      <Text>
        <i>Cookies.</i> We collect certain data from cookies, which are pieces of data stored
        directly on the computer or mobile device that you are using. Cookies allow us to collect
        data such as browser type, time spent on the Services, pages visited, referring URL,
        language preferences, and other aggregated traffic data. We use the data for security
        purposes, to facilitate navigation, to display data more effectively, to collect statistical
        data, to personalize your experience while using the Services and to recognize your computer
        to assist your use of the Services. We also gather statistical data about use of the
        Services to continually improve design and functionality, understand how it is used and
        assist us with resolving questions.
      </Text>
      <Text>
        <i>Random Number Generation.</i> When you download and install our App onto your mobile
        device, we assign a random number to your App installation. Through this number we can
        identify you personally once you become a registered user of the App. We use this random
        number in a manner similar to our use of cookies as described in this privacy policy. Unlike
        cookies, however, this number cannot be removed through settings. If you do not want us to
        use the number for the purposes for which we use cookies, please do not use the App. We also
        obtain any information about your mobile device, which is limited to information regarding
        the brand, make, and model and type of operating software your device uses.
      </Text>
      <Text>
        <i>Pixel Tags and Other Similar Technologies.</i> We collect data from pixel tags (also
        known as web beacons and clear GIFs), which are used to, among other things, track the
        actions of users of the Services, measure the success of our marketing campaigns, and
        compile statistics about usage of the Services.
      </Text>
      <Text>
        <i>Online Behavioral Advertising.</i> We use third parties and/or service providers to
        provide interest-based advertising services. These services may serve advertisements on our
        behalf that are customized based on predictions about your interests generated from your
        visits to websites (including this site) over time and across different websites. The data
        collected may be associated with your Personal Information. These advertisements may appear
        on the Services and on other websites, and may be sent to you via email.
      </Text>
      <Text>
        <i>Cookies.</i> We collect certain data from cookies, which are pieces of data stored
        directly on the computer or mobile device that you are using. Cookies allow us to collect
        data such as browser type, time spent on the Services, pages visited, referring URL,
        language preferences, and other aggregated traffic data. We use the data for security
        purposes, to facilitate navigation, to display data more effectively, to collect statistical
        data, to personalize your experience while using the Services and to recognize your computer
        to assist your use of the Services. We also gather statistical data about use of the
        Services to continually improve design and functionality, understand how it is used and
        assist us with resolving questions.
      </Text>
      <Text>
        We use Google Ads to serve ads across various websites. Google uses Cookies to collect data
        about your visits to the Site to generate targeted advertisements to you on other websites
        that you visit. To opt-out of this type of advertising by Google, to customize your ad
        preferences, or to limit Google’s collection or use of such data, visit{' '}
        <Link to='https://safety.google' target='_blank'>
          Google’s Safety Center
        </Link>{' '}
        and{' '}
        <Link to='https://myadcenter.google.com/home?sasb=true&ref=ad-settings' target='_blank'>
          Google’s Ad Settings
        </Link>{' '}
        and follow{' '}
        <Link
          to='https://support.google.com/My-Ad-Center-Help/answer/12155764?hl=en&ref_topic=7048998&visit_id=638277928939171435-3415484272&rd=1'
          target='_blank'
        >
          Google’s personalized ad opt-out instructions.
        </Link>{' '}
        Opting out will not affect your use of the Site.
      </Text>
      <Text>
        To change your preferences with respect to certain online ads or to obtain more information
        about ad networks and online behavioral advertising, visit{' '}
        <Link to='https://optout.networkadvertising.org/?c=1' target='_blank'>
          National Advertising Initiative Consumer opt-out page
        </Link>{' '}
        or the{' '}
        <Link to='https://youradchoices.com' target='_blank'>
          Digital Advertising Alliance Self-Regulatory Program.
        </Link>{' '}
        Changing your settings with individual browsers or ad networks will not necessarily carry
        over to other browsers or ad networks. As a result, depending on the opt-outs you request,
        you may still see our ads. Opting-out of targeted advertising does not opt you out of all
        ads, just those targeted to you.
      </Text>
      <Text>
        <i>Through Analytics.</i> We use analytics services, including Google Analytics, to assist
        us with analyzing our website traffic through cookies and similar technologies. To learn
        more about how Google uses data, visit{' '}
        <Link to='https://policies.google.com/privacy' target='_blank'>
          Google’s Privacy Policy
        </Link>{' '}
        and Google’s page on{' '}
        <Link to='https://policies.google.com/technologies/partner-sites' target='_blank'>
          “How Google uses data when you use our partners’ sites or apps.”
        </Link>{' '}
        You may download the{' '}
        <Link to='https://tools.google.com/dlpage/gaoptout' target='_blank'>
          Google Analytics Opt-out Browser Add-on
        </Link>{' '}
        for each web browser you use, but this does not prevent the use of other analytics tools. To
        learn more about Google Analytics cookies, visit{' '}
        <Link
          to='https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage'
          target='_blank'
        >
          Google Analytics Cookie Usage on Websites.
        </Link>
      </Text>
      <Text>
        <i>Refusing cookies and similar technologies.</i> Most browsers provide you with the ability
        to block, delete, or disable cookies, and your mobile device may allow you to disable
        transmission of unique identifiers and location data. If you choose to reject cookies or
        block device identifiers, some features of the Services may not be available, or some
        functionality may be limited or unavailable. Please review the help pages of your browser or
        mobile device for assistance with changing your settings.
      </Text>
      <Text>
        Some of our service providers or plug-ins may use cookies or other methods to gather
        information regarding your use of the Services and may combine the information in these
        cookies with any Personal Information about you that they may have. The use of such tracking
        information by a third party depends on the privacy notice of that third party. We do not
        respond to Do Not Track (“DNT”) signals sent to us by your browser at this time. To learn
        more about how DNT works, please visit{' '}
        <Link to='https://allaboutdnt.com' target='_blank'>
          http://allaboutdnt.com/.
        </Link>
      </Text>
    </Block>

    <Block id='privacy5'>
      <Title>4. How We Use Personal Information</Title>
      <Text>To the extent permitted by applicable law, we may use Personal Information to:</Text>
      <List>
        <Item>
          To provide you with the Services, and any other information, products or services that you
          request from us.
        </Item>
        <Item>
          To help you keep a record of health care treatment and health-related information.
        </Item>
        <Item>
          To carry out our obligations and enforce our rights arising from any contracts entered
          into between you and us.
        </Item>
        <Item>
          To notify you when Service updates are available, and of changes to any products or
          services we offer or provide though it.
        </Item>
        <Item>To fulfill any other purpose for which you provide it.</Item>
        <Item>
          fulfill customer requests, such as to create an account or complete customer purchases;
        </Item>
        <Item>protect against criminal activity, claims and other liabilities;</Item>
        <Item>send you information about our company, products, services, and promotions;</Item>
        <Item>respond to reviews, comments, or other feedback provided to us;</Item>
        <Item>support and personalize the Services and our advertising efforts;</Item>
        <Item>protect the security and integrity of the Services;</Item>
        <Item>provide customer support;</Item>
        <Item>
          the extent required for benchmarking, data analysis, audits, developing new products,
          enhancing the Services, facilitating product, software and applications development,
          improving the Services or our services, conducting research, analysis, studies or surveys,
          identifying usage trends, as well as for other analytics purposes;
        </Item>
        <Item>meet our contractual requirements;</Item>
        <Item>comply with applicable legal or regulatory requirements and our policies;</Item>
        <Item>respond to inquiries related to employment opportunities;</Item>
        <Item>market, advertise, and provide the Services; and</Item>
        <Item>
          the extent necessary for any other lawful purpose for which the Personal Information is
          collected.
        </Item>
      </List>
    </Block>

    <Block id='privacy6'>
      <Title>5. Sharing of Personal Information</Title>
      <Text>We may share your Personal Information in the following circumstances:</Text>
      <List>
        <Item>
          <b>To Business Partners</b> – We may share Personal Information with our trusted business
          partners. These partners and affiliates may use your Personal Information to, among other
          things, send you information or contact you about their services and products.
        </Item>
        <Item>
          <b>For Legal Obligation or Safety Reasons</b> – When we have a good faith belief that
          access, use, preservation or disclosure of Personal Information is reasonably necessary to
          (a) satisfy or comply with any requirement of law, regulation, legal process, or
          enforceable governmental request, (b) enforce or investigate a potential violation of the
          Terms of Use, (c) detect, prevent, or otherwise respond to fraud, security or technical
          concerns, (d) support auditing and compliance functions, or (e) protect the rights,
          property, or safety of Mishe, its users, or the public against harm.
        </Item>
        <Item>
          <b>In the Case of a Merger or Sale</b> – If and when we are involved in a merger,
          acquisition, or any form of transfer or sale of some or all of its business, whether as a
          going concern or as part of bankruptcy, liquidation, or similar proceeding. Personal
          Information may be transferred along with the business. Where legally required we will
          give you prior notice and if you have a legal right to do so, an opportunity to object to
          this transfer.
        </Item>
        <Item>
          <b>To Service Providers</b> – When we hire a service provider to help operate the Services
          or our business, we may give access to Personal Information as necessary to perform the
          service for Mishe. This may include service providers that operate our Services, send our
          communications, or run our promotions.
        </Item>
        <Item>
          <b>Aggregated Data</b> – To third parties or advertisers in aggregate form that is in a
          manner that cannot be traced back to you
        </Item>
        <Item>
          <b>Marketing</b> – To our marketing partners with whom you have explicitly authorized Us
          to share this information, and whose offers you have elected to receive via electronic
          delivery, telemarketing and direct mail.
        </Item>
        <Item>For any other purpose disclosed by us when you provide the information</Item>
      </List>
    </Block>

    <Block id='privacy7'>
      <Title>6. How Long Do We Keep Your Personal Information</Title>
      <Text>
        We will retain your Personal Information for as long as necessary to fulfill the purposes
        for which it has been collected, as outlined in this Privacy Notice, or any longer retention
        period required by law. If you are a California resident, please see the section below
        titled Notice to California Residents for additional information about how long we retain
        your Personal Information.
      </Text>
    </Block>

    <Block id='privacy8'>
      <Title>7. How We Protect Your Personal Information</Title>
      <Text>
        We use our commercially reasonable efforts to protect the confidentiality and security of
        Personal Information we process. However, despite these efforts to store Personal
        Information in a secure operating environment, we cannot guarantee the security of Personal
        Information during its transmission or its storage on our systems. Further, while we attempt
        to ensure the integrity and security of Personal Information, we cannot guarantee that our
        security measures will prevent third parties such as hackers from illegally obtaining access
        to Personal Information.
      </Text>
    </Block>

    <Block id='privacy9'>
      <Title>8. Your Choices and Controls</Title>
      <Text>
        To opt out of marketing emails, please email us at legal@mishe.co or by following the
        instructions included in the email or text correspondence. Please note that, even if you
        unsubscribe from certain correspondence, we may still need to contact you with important
        transactional or administrative information, as permitted by law. Additionally, if you
        withdraw your consent or object to processing of your Personal Information, or if you choose
        not to provide certain Personal Information, we may be unable to provide some or all of our
        services to you.
      </Text>
    </Block>

    <Block id='privacy10'>
      <Title>9. International Use of the Services</Title>
      <Text>
        Our Services are hosted in the United States. If you are visiting our Services from outside
        of the United States, please note that by providing your Personal Information it is being
        transferred to, stored, collected, or processed in the United States, where our data center
        and servers are located and operated.
      </Text>
    </Block>

    <Block id='privacy11'>
      <Title>10. Children’s Privacy </Title>
      <Text>
        Our Services are not intended for use by individuals under the age of 18. We do not
        knowingly collect or solicit any Personal Information from children under the age of 18 In
        the event that we learn that we have collected Personal Information from a child, we will
        promptly take steps to delete that information. If you are a parent or legal guardian and
        think your child has given us their Personal Information, you can email us at legal@mishe.co
        or contact us using the information listed in Section 13.
      </Text>
    </Block>

    <Block id='privacy12'>
      <Title>11. Links to Third-Party Websites.</Title>
      <Text>
        We are not responsible for the practices employed by any websites or services linked to or
        from the Services, including the information or content contained within them. We encourage
        you to investigate and ask questions before disclosing Personal Information to third
        parties, since any Personal Information disclosed will be subject to the applicable third
        party’s Privacy Notice.
      </Text>
    </Block>

    <Block id='privacy13'>
      <Title>12. California Shine the Light Law</Title>
      <Text>
        Under California Civil Code Section 1798.83, individual customers who reside in California
        and who have an existing business relationship with us may request information about our
        disclosure of certain categories of personal information to third parties for the third
        parties’ direct marketing purposes, if any. To make such a request, please contact us using
        the information in the Contact Us section below. Please be aware that not all information
        sharing is covered by these California privacy rights requirements and only information on
        covered sharing will be included in our response. This request may be made no more than once
        per calendar year.
      </Text>
    </Block>

    <Block id='privacy14'>
      <Title>13. Changes to this Privacy Notice</Title>
      <Text>
        Please note that we may modify or update this Privacy Notice from time to time, so please
        review it periodically. We may provide you with an updated Privacy Notice if material
        changes are made. Unless otherwise indicated, any changes to this Privacy Notice will apply
        immediately upon posting to the Services.
      </Text>
    </Block>

    <Block id='privacy15'>
      <Title>14. Contact Us</Title>
      <Text>
        If you have any questions about our practices or this Privacy Notice, please contact us at
        legal@mishe.co. You may also write to us at:
        <br />
        Mishe, Inc
        <br />
        421 Broadway
        <br />
        San Diego, CA 92101
        <br />
        Attn: Legal
      </Text>
    </Block>

    <Block id='privacy16'>
      <Title>Notice of Effective Date:</Title>
      <Text>The effective date of the notice is July 1, 2024.</Text>
    </Block>
  </div>
)

export default PrivacyPolicyContent
