import { createContext, useContext, useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { collectionModular } from '@app/firebase/firestore'
import useNotes from '@app/hooks/useNotes'
import useEmrOrders from '@app/hooks/useEmrOrders'
import { useCurrentUser } from './CurrentUser'
import { query, where, onSnapshot, orderBy, and, getDocs, doc, getDoc } from 'firebase/firestore'

const VoucherDetails = createContext()

export const useVoucherDetails = () => useContext(VoucherDetails)

const VoucherDetailsProvider = ({ children }) => {
  // TODO change voucher to voucherdetails
  const { addFileNote, addImageNote, addTextNote, notes, setNotes } = useNotes()
  const { orders, setOrders, updateOrders } = useEmrOrders({})
  const [voucher, setVoucher] = useState(null)
  const [externalClaim, setExternalClaim] = useState(null)
  const [subVoucher, setSubVoucher] = useState(null)
  const [originalOrder, setOriginalOrder] = useState(null)
  const [currentFile, setCurrentFile] = useState(null)

  const { profileData, authData } = useCurrentUser()

  const handleClick = useCallback((passedVoucher) => setVoucher(passedVoucher), [setVoucher])
  const handleClose = useCallback(() => {
    setVoucher(null)
    setSubVoucher(null)
    setCurrentFile(null)
    setExternalClaim(null)
    setOriginalOrder(null)
    setNotes([])
    setOrders([])
  }, [setVoucher, setNotes, setOrders])

  // TODO Combine all calls into one promise array and await

  const getNotes = useCallback(async () => {
    const whereQueryForPatient = authData.roles.includes('PATIENT')
      ? [where('sharedWithPatient', '==', true)]
      : []

    const noteQuery = query(
      collectionModular('notes'),
      where('voucher.code', '==', voucher.code),
      ...whereQueryForPatient,
      orderBy('timestamp', 'desc'),
    )
    onSnapshot(noteQuery, (querySnapshot) => {
      const notesList = []
      if (querySnapshot.docs.length === 0) {
        setNotes([])
        return
      }
      querySnapshot.forEach(
        (each) => {
          notesList.push({ ...each.data(), uid: each.id })
        },
        (error) => {
          console.warn(error)
          setNotes([])
        },
      )
      setNotes(notesList)
    })
  }, [voucher, setNotes, authData.roles])

  useEffect(() => {
    const triggerNotes = async () => {
      if (!voucher) {
        return undefined
      }
      getNotes()
    }
    triggerNotes()
  }, [getNotes, voucher])

  const getOrders = useCallback(async () => {
    try {
      let ordersQuery = collectionModular('emrOrders')

      if (authData.roles.includes('PATIENT')) {
        ordersQuery = query(
          ordersQuery,
          and(
            where('patient.id', '==', profileData.id),
            where('sourceVoucherId', '==', voucher.id),
          ),
        )
      } else if (authData.roles.includes('PROVIDER')) {
        ordersQuery = query(
          ordersQuery,
          and(
            where('referred.clinic.id', '==', authData.organizationId),
            where('sourceVoucherId', '==', voucher.id),
          ),
        )
      } else {
        setOrders([])
        return
      }

      const ordersSnapshot = await getDocs(ordersQuery)

      if (ordersSnapshot.empty) {
        setOrders([])
        return
      }

      const ordersList = []
      ordersSnapshot.forEach((doc) => {
        ordersList.push({ ...doc.data(), id: doc.id })
      })
      setOrders(ordersList)
    } catch (err) {
      console.warn('Error getting orders:', err)
      setOrders([])
    }
  }, [voucher, setOrders, authData.roles, authData.organizationId])

  useEffect(() => {
    const triggerOrders = async () => {
      if (!voucher) {
        return undefined
      }
      getOrders()
    }
    triggerOrders()
  }, [getOrders, voucher])

  // need to update for bundles
  const getOriginalOrder = useCallback(
    async (orderId) => {
      try {
        const originalOrderRef = doc(collectionModular('emrOrders'), orderId)
        const originalOrderSnapshot = await getDoc(originalOrderRef)

        if (!originalOrderSnapshot.exists()) {
          setOriginalOrder(null)
          return
        }

        setOriginalOrder({
          ...originalOrderSnapshot.data(),
          id: originalOrderSnapshot.id,
        })
      } catch (err) {
        console.error(err)
        setOriginalOrder(null)
      }
    },
    [setOriginalOrder],
  )

  useEffect(() => {
    if (!voucher) {
      return undefined
    }
    if (!voucher?.emrOrderID) {
      return undefined
    }
    getOriginalOrder(voucher?.emrOrderID)
  }, [voucher, getOriginalOrder])

  const value = {
    voucher,
    notes,
    orders,
    setOrders,
    updateOrders,
    currentFile,
    originalOrder,
    setCurrentFile,
    handleClick,
    handleClose,
    setVoucher,
    addFileNote,
    addImageNote,
    addTextNote,
    setSubVoucher,
    subVoucher,
    setExternalClaim,
    externalClaim,
  }
  return <VoucherDetails.Provider value={value}>{children}</VoucherDetails.Provider>
}

VoucherDetailsProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default VoucherDetailsProvider
